import React, { useState } from 'react';
import ProfileSection from '../ProfileSection';
import './Seguridad.scss';
import { useForm } from 'react-hook-form';
import Input from '../Input';
import Arrow from '../../assets/images/arrow-left.png';
import SaveIcon from '../../assets/images/save_icon.svg';
import Button from '../Button';
import { useDispatch } from 'react-redux';
import { changePasswordRequest, signOutRequest } from '../../ducks/actions';
import CommonDialog from '../CommonDialog';
import { REGEX_PASS } from '../../utils/constants';
import { useHistory } from 'react-router-dom';

const Seguridad = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const signOut = () => {
    dispatch(signOutRequest())
  }

  const changePassword = (values) => {
    dispatch(changePasswordRequest(values, () => { 
      setSending(false);
      setError(false);
      setSuccess(true);
    }, () => {
      setSending(false);
      setError(true);
      setSuccess(false);
    }));
  }

  const onSubmit = data => {
    setSending(true);
    changePassword(data);
  };

  return (
    <div className="seguridad">
      <ProfileSection
          title="Cambiar mi contraseña"
      >
        <div className="mis-datos__group">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mis-datos__row mis-datos__row--edit">
              <div className="mis-datos__column">
                <Input
                  {...register("oldPass", { required: 'Requerido'})}
                  error={errors?.oldPass?.message}
                  hasError={errors?.oldPass?.message}
                  disclaimer=" "
                  type="password"
                  label="Contraseña actual"
                  placeholder="************"
                />
              </div>
              <div className="mis-datos__column">
                <Input
                  {...register("pass", { 
                    required: 'Requerido',
                    validate: (value) => {
                      if (!value) {
                        return true;
                      }
                      if (value?.length < 8) {
                        return "Debe poseer mínimo 8 caracteres";
                      }
                      const matches = value.match(
                        REGEX_PASS
                      );
                      return matches?.length > 0 || "Debe poseer mínimo 1 Número, 1 letra mayúscula y 1 letra minúscula.";
                    }
                  })}
                  error={errors?.pass?.message}
                  hasError={errors?.pass?.message}
                  disclaimer="Mínimo 8 caracteres"
                  type="password"
                  label="Contraseña nueva"
                  placeholder="************"
                />
              </div>
              <div className="mis-datos__column">
                <Input
                  disclaimer="Asegurá de que las contraseñas sean idénticas."
                  type="password"
                  label="Confirmar contraseña"
                  placeholder="************"
                  error={errors?.confirmaContra?.message}
                  hasError={errors?.confirmaContra?.message}
                  {...register("confirmaContra", { 
                    required: 'Requerido',
                    validate: {
                      match: value => (value === getValues().pass) || 'Contraseñas deben coincidir'
                    }
                  })}
                />
              </div>
            </div>
            <div className="mis-datos__submit-disclaimer">
              <h4>La contraseña debe cumplir:</h4>
              <p>Mínimo 8 caracteres, 1 mayúscula, 1 minúscula y 1 número.</p>
            </div>
            <div className="mis-datos__submit-container" style={{'justify-content': 'end'}}>
              <Button
                disabled={sending}
                type="submit"
                black
                suffix={<img src={Arrow} alt="Icon" />}
                value="Cambiar contraseña"
              />
            </div>
          </form>
        </div>
      </ProfileSection>
      <CommonDialog
        open={error || success}
        icon={SaveIcon}
        onClose={() => {
          if (success) {
            signOut();
            history.push('/redirect?to=/solicitar');
            return;
          }
          setError(false);
          setSuccess(false);
          setSending(false);
          reset();
        }}
        title={success ?
          "¡Cambio de contraseña exitoso!":
          "No se pudo cambiar la contraseña"}
      />
    </div>
  );
};

export default Seguridad;
