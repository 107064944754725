import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../Button';
import Arrow from '../../assets/images/arrow-left.png';
import Dialog from '../Dialog';
import Close from '../../assets/images/close.png';
import './Activate.scss';
import Input from '../Input';
import { useDispatch } from 'react-redux';
import { resetAgeHubRegister, setPassword } from '../../ducks/actions';
import { REGEX_PASS } from '../../utils/constants';
// import { useDispatch } from 'react-redux';
// import { registerUserRequest } from '../../ducks/actions';

const Activate = ({ open, user, onClose, token, onLogin }) => {
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    setSending(true);
    dispatch(setPassword(
      {
        ...data,
        token
      },
      (data) => {
        setFeedback(data);
        setSending(false);
      } 
    ));
  };

  return (
    <Dialog open={open} medium scroll>
      <div className="activate-registro">
        <button onClick={() => {
            dispatch(resetAgeHubRegister());
            onClose();
          }} 
          className="activate-registro__close"
        >
          <img src={Close} alt="Close" />
        </button>
        {user?.option === 'successfully' ? (
            feedback ? (
              <>
                {feedback?.option === 'successfully' ? (
                  <>
                    <h1 className="activate-registro__title">¡Haz realizado tu registro exitosamente!</h1>
                    <Button
                      onClick={onLogin}
                      className="activate-registro__ingresar"
                      value="Ingresar"
                      type="button"
                    />
                  </>
                ): (
                  <>
                    <h1 className="activate-registro__title">Error!</h1>
                    <p className="completar-registro__text">
                      {feedback?.message || 'Hubo un error cambiando la contraseña'}
                    </p>
                  </>
                )}
              </>
            ):
            (
              <>
                <h1 className="activate-registro__title">Completar registro</h1>
                <p className="activate-registro__text">
                  Generá tu contraseña para poder ingresar a nuestra plataforma.
                </p>
                {/* <p className="activate-registro__name">Santiago Bernabeu</p>
                <p className="activate-registro__id">123456789</p> */}
                <span className="activate-registro__user">
                  Usuario: {user?.user?.name} {user?.user?.lastname}
                </span>
                <span className="activate-registro__info">
                  Número de identificación: {user?.user?.dni}
                </span>
                <span className="activate-registro__info">
                  Creá tu Contraseña
                </span>
              <form
                className="activate-registro__form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="activate-registro__input-container">
                  <Input
                    type="password"
                    className="activate-registro__input-new"
                    placeholder="Contraseña"
                    {...register('pass', { required: 'Requerido', 
                      validate: (value) => {
                      if (!value) {
                        return true;
                      }
                      if (value?.length < 8) {
                        return "Debe poseer mínimo 8 caracteres";
                      }
                      const matches = value.match(
                        REGEX_PASS
                      );
                      return matches?.length > 0 || "Debe poseer mínimo 1 Número, 1 letra mayúscula y 1 letra minúscula.";
                    }})}
                    hasError={!!errors.pass}
                  />
                </div>
                  
                <div className="activate-registro__input-container">
                  <Input
                    type="password"
                    className="activate-registro__input-new"
                    placeholder="Repetir Contraseña"
                    {...register('pass_c', { 
                      required: 'Requerido',
                      validate: {
                          match: value => (value === getValues().pass) || 'Contraseñas deben coincidir'
                      }
                    })}
                    hasError={!!errors.pass_c}
                  />
                </div>
                <Button
                  className="activate-registro__submit"
                  value={sending ? 'Cambiando' : "Cambiar contraseña"}
                  disabled={sending}
                  suffix={<img src={Arrow} alt="Arrow" />}
                  type="submit"
                />
                </form>
              </>
            )
          
        ): (
          <>
            <h1 className="activate-registro__title">Error!</h1>
            <p className="completar-registro__text">
              {user?.message || 'Hubo un error con el link que ingresaste'}
            </p>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default Activate;
