import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link, useParams } from 'react-router-dom';
import Date from '../../assets/images/date2.svg';
import { useBlogData, useSinglePost } from "../../ducks/blog/hooks";
import BlogHeader from "../BlogHeader/BlogHeader";
import BlogSidebar from "../BlogSidebar";
import './Post.scss';
import './WordpressPost.scss';

const SinglePost = ({ blog }) => {
  const blogData = useBlogData(blog);
  console.log(blogData);
  return (
    <div className='blog-post__content'>
      <div className='blog-post__content-meta'>
        <span className='blog-post__content-meta--date'>
          <img src={Date} alt={blogData.date} />
          {blogData.date}
        </span>
        <span className='blog-post__content-meta--categories'>
          {blogData?.categories?.map(cat => (
            <Link to={`/blog/categoria/${cat.slug}`}>{cat.name}</Link>
          ))}
        </span>
      </div>
      <div className='blog-post__content-flow'>
        <h1>{blogData?.title?.rendered}</h1>
        <div className='blog-post__content-flow-renderer' dangerouslySetInnerHTML={{ __html: blogData?.content?.rendered}} />
      </div>
      <div className='blog-post__content-steps'>
        <div className='blog-post__content-steps-back'>
          {blogData.previous && (
            <Link to={`/blog/post/${blogData.previous?.id}`}>Anterior</Link>
          )}
        </div>
        <div className='blog-post__content-steps-forward'>
          {blogData.next && (
              <Link to={`/blog/post/${blogData.next?.id}`}>Siguiente</Link>
            )}
        </div>
      </div>
    </div>
  );
};
const Post = () => {
  const { postId } = useParams();
  const [blog, isLoading] = useSinglePost(postId);
  
  return (
    <div className="blog-post">
      <BlogHeader isLoading={isLoading} backgroundImage={blog?.acf?.banner?.url} />
      <BlogSidebar isPost={true}>
        {isLoading ? (
          <>
            <Skeleton height={80} />
            <br />
            <Skeleton height={80} />
            <br />
            <Skeleton height={80} />
            <br />
            <Skeleton height={80} />
            <br />
          </> 
        ): (
          <SinglePost blog={blog}/>
        )}
        {/* <div className="blog-post__content" dangerouslySetInnerHTML={{ __html: }}>

        </div> */}
      </BlogSidebar>
    </div>
  )
};

export default Post;