import classNames from 'classnames';
import React, { useState } from 'react';
import './RadioButtons.scss';

const RadioButtons = ({
  name,
  label,
  options,
  showOther,
  onChange,
  value,
  black,
  hasError
}) => {
  const [other, setOther] = useState('');
  return (
    <div
      className={classNames('radio-buttons', { 'radio-buttons--black': black, 'radio-buttons--has-error': hasError })}
    >
      <div className="radio-buttons__label">{label}</div>
      <div className="radio-buttons__control-container">
        {options.map(option => (
          <div className="radio-buttons__row" key={option.value}>
            <input
              type="radio"
              id={option.value}
              name={name}
              className="radio-buttons__control"
              value={option.value}
              onChange={() => onChange(option.value)}
              checked={option.value === value}
            />
            <label
              className="radio-buttons__option-label"
              htmlFor={option.value}
            >
              {option.label}
            </label>
          </div>
        ))}
        {showOther && (
          <div className="radio-buttons__other">
            <div className="radio-buttons__row">
              <input
                type="radio"
                id="other"
                name={name}
                className="radio-buttons__control"
                value={other}
                onChange={() => onChange(other, true)}
                checked={other === value}
              />
              <label className="radio-buttons__option-label" htmlFor="other">
                Otro:
              </label>
            </div>
            <div className="radio-buttons__option-input-container">
              <input
                className="radio-buttons__option-input"
                value={other}
                onChange={({ target: { value: newValue } }) => {
                  setOther(newValue);
                  onChange(newValue);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
RadioButtons.defaultProps = {
  options: [],
};

export default RadioButtons;
