import classNames from 'classnames';
import React from 'react';
import './New.scss';

const New = ({ white }) => (
  <svg
    className={classNames('new', { 'new--white': white })}
    xmlns="http://www.w3.org/2000/svg"
    width="38.207"
    height="22.218"
    viewBox="0 0 38.207 22.218"
  >
    <g id="Group_19238" data-name="Group 19238" transform="translate(0 0)">
      <g id="outline">
        <path
          id="Path_13875"
          data-name="Path 13875"
          d="M51.428,517.422l1.161-2.322-.507-1.015a.653.653,0,0,1,.584-.946h5.073l3.607-1.8a7.719,7.719,0,0,1,3.434-.811h4.963a7.18,7.18,0,0,1,3.81,1.093l3.48,2.175h3.693a10.665,10.665,0,0,1,5.284,1.4,5.132,5.132,0,0,1,2.556,3.817l.108.011a.653.653,0,0,1,.589.65v1.96a.653.653,0,0,1-.653.653H84.03a3.26,3.26,0,0,1-3.916,0H60.505a3.26,3.26,0,0,1-4.325-.365l-4.31-.958a.653.653,0,0,1-.443-.346A3.593,3.593,0,0,1,51.428,517.422Zm6.466-2.975h-4.17l.181.361a.654.654,0,0,1,0,.585l-.933,1.865,2.841.631a3.266,3.266,0,0,1,5.728,3.092h4.116L62.742,518.9a.653.653,0,0,1-.274-.532v-3.594l-2.029-1.522-2.253,1.126A.654.654,0,0,1,57.894,514.447Zm17.2,6.535,1.1-2.74V515.1H63.775v2.931l4.13,2.95ZM61.746,512.6l1.594,1.2h1.089v-1.95a6.409,6.409,0,0,0-2.5.662Zm11.114.129a5.876,5.876,0,0,0-3.117-.894H65.735v1.96h8.83Zm12.5,3.6a9.357,9.357,0,0,0-4.635-1.231H77.5v3.267a.653.653,0,0,1-.047.243l-.948,2.371h2.576a3.267,3.267,0,1,1,6.1-2.306l2.033.2A3.83,3.83,0,0,0,85.359,516.332Zm2.594,3.935L85.323,520a3.242,3.242,0,0,1-.257.979h2.887Zm-3.921-.591a1.96,1.96,0,1,0-1.96,1.96A1.96,1.96,0,0,0,84.032,519.675Zm-23.525,0a1.96,1.96,0,1,0-1.96,1.96,1.96,1.96,0,0,0,1.96-1.96Zm-8.025.088,2.88.64a3.236,3.236,0,0,1-.035-1.282l-2.9-.645A2.288,2.288,0,0,0,52.483,519.763Z"
          transform="translate(-51.053 -500.724)"
          fill="#fff"
        />
        <ellipse
          id="Ellipse_234"
          data-name="Ellipse 234"
          cx="0.817"
          cy="0.817"
          rx="0.817"
          ry="0.817"
          transform="translate(30.202 18.134)"
          fill="#fff"
        />
        <ellipse
          id="Ellipse_235"
          data-name="Ellipse 235"
          cx="0.817"
          cy="0.817"
          rx="0.817"
          ry="0.817"
          transform="translate(6.678 18.134)"
          fill="#fff"
        />
        <path
          id="Path_13876"
          data-name="Path 13876"
          d="M613.285,663.684h1.307a.653.653,0,0,1,0,1.307h-1.307a.653.653,0,0,1,0-1.307Z"
          transform="translate(-598.602 -648.001)"
          fill="#fff"
        />
        <path
          id="Path_13877"
          data-name="Path 13877"
          d="M804.692,292.5a3.754,3.754,0,0,0-1.668,1.668.653.653,0,0,1-1.169,0,3.755,3.755,0,0,0-1.668-1.668.653.653,0,0,1,0-1.169,3.755,3.755,0,0,0,1.668-1.668.653.653,0,0,1,1.169,0,3.754,3.754,0,0,0,1.668,1.668.653.653,0,0,1,0,1.169Zm-2.253-1.345a5.1,5.1,0,0,1-.761.761.761.761,0,0,0,1.522,0A5.1,5.1,0,0,1,802.44,291.153Z"
          transform="translate(-795.598 -287.993)"
          fill="#fff"
        />
        <path
          id="Path_13878"
          data-name="Path 13878"
          d="M123.99,258.463a3.754,3.754,0,0,0-1.668,1.668.653.653,0,0,1-1.169,0,3.754,3.754,0,0,0-1.668-1.668.653.653,0,0,1,0-1.169,3.754,3.754,0,0,0,1.668-1.668.653.653,0,0,1,1.169,0,3.754,3.754,0,0,0,1.668,1.668.653.653,0,0,1,0,1.169Zm-2.253-1.345a5.1,5.1,0,0,1-.761.761,5.1,5.1,0,0,1,.761.761,5.1,5.1,0,0,1,.761-.761A5.1,5.1,0,0,1,121.738,257.117Z"
          transform="translate(-88.758 -255.264)"
          fill="#fff"
        />
        <path
          id="Path_13879"
          data-name="Path 13879"
          d="M106.973,445.656a3.754,3.754,0,0,0-1.668,1.668.653.653,0,0,1-1.169,0,3.754,3.754,0,0,0-1.668-1.668.653.653,0,0,1,0-1.169,3.755,3.755,0,0,0,1.668-1.668.653.653,0,0,1,1.169,0,3.755,3.755,0,0,0,1.668,1.668.653.653,0,0,1,0,1.169Zm-2.253-1.345a5.1,5.1,0,0,1-.761.761,5.1,5.1,0,0,1,.761.761,5.1,5.1,0,0,1,.761-.761A5.1,5.1,0,0,1,104.72,444.31Z"
          transform="translate(-71.087 -435.269)"
          fill="#fff"
        />
        <path
          id="Path_13880"
          data-name="Path 13880"
          d="M907.157,394.018a.654.654,0,0,1-.361.584,3.755,3.755,0,0,0-1.668,1.668.653.653,0,0,1-1.169,0,3.754,3.754,0,0,0-1.668-1.668.654.654,0,0,1,0-1.169,3.754,3.754,0,0,0,1.668-1.668.653.653,0,0,1,1.169,0,3.755,3.755,0,0,0,1.668,1.668A.654.654,0,0,1,907.157,394.018Zm-2.614-.761a5.1,5.1,0,0,1-.761.761.761.761,0,0,0,1.522,0A5.1,5.1,0,0,1,904.544,393.258Z"
          transform="translate(-901.623 -386.177)"
          fill="#fff"
        />
        <path
          id="Path_13881"
          data-name="Path 13881"
          d="M227.109,343.293a.654.654,0,0,1-.361.584,4.489,4.489,0,0,0-1.995,1.995.653.653,0,0,1-1.169,0,4.489,4.489,0,0,0-1.995-1.995.653.653,0,0,1,0-1.169,4.489,4.489,0,0,0,1.995-1.995.653.653,0,0,1,1.169,0,4.489,4.489,0,0,0,1.995,1.995A.653.653,0,0,1,227.109,343.293Zm-2.941-1.063a5.834,5.834,0,0,1-1.063,1.063,5.835,5.835,0,0,1,1.063,1.063,5.835,5.835,0,0,1,1.063-1.063A5.834,5.834,0,0,1,224.169,342.229Z"
          transform="translate(-195.436 -337.085)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default New;
