import React, { useState } from 'react';
import { useLocation } from 'react-router';

const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const location = useLocation();
  const [openRegister, setOpenRegister] = useState(null);
  const [openRecuperar, setOpenRecuperar] = useState(null);
  const [login2] = useState(location?.search?.indexOf('login2') !== -1);
  const [showBlocked, setShowBlocked] = useState(false);

  return (
    <AppContext.Provider
      value={{
        openRegister,
        setOpenRegister,
        openRecuperar,
        setOpenRecuperar,
        login2,
        showBlocked,
        setShowBlocked,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const AppContextConsumer = AppContext.Consumer;

export default AppContext;
