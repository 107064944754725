import { useEffect } from 'react';

const useClickOutside = (ref, triggerRef, handler) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        (!triggerRef.current || !triggerRef.current.contains(event.target))
      ) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handler, ref, triggerRef]);
};

export default useClickOutside;
