import React from 'react';
import classNames from 'classnames';
import './Currency.scss';

export const currencies = {
  COLON: { name: 'colon', sign: '₡', labels: 'colon, colón, colones' },
  DOLLAR: { name: 'dollar', sign: '$', labels: ''},
};

const Currency = ({ currency, setCurrency, singleCurrencySign = null }) => (
  <div className="moneda">
    <span className="moneda__label">Moneda:</span>
    <div className={`${classNames('moneda__control', {
      'moneda__control__single': !!singleCurrencySign,
    })}`}>
      {singleCurrencySign ? (
        <span className="moneda__btn moneda__btn--single">{singleCurrencySign}</span>
      ):(
        <>
          <button
            onClick={() => setCurrency(currencies.DOLLAR)}
            className={classNames('moneda__btn moneda__dollar', {
              'moneda__btn--active': currency.name === currencies.DOLLAR.name,
            })}
            type="button"
          >
            $
          </button>
          <button
            onClick={() => setCurrency(currencies.COLON)}
            className={classNames('moneda__btn moneda__colon', {
              'moneda__btn--active': currency.name === currencies.COLON.name,
            })}
            type="button"
          >
            ₡
          </button>
        </>
      )}
    </div>
  </div>
);
Currency.defaultProps = {
  value: currencies.DOLLAR,
};

export default Currency;
