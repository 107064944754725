import classNames from 'classnames';
import React from 'react';
import './Checkbox2.scss';

import Check from '../../assets/images/check2.svg';
import Unchecked from '../../assets/images/check2_unchecked.svg';

const Checkbox2 = ({ setChecked, checked, text }) => (
  <button
    onClick={() => setChecked(!checked)}
    className={classNames('checkbox', { 'checkbox--checked': checked })}
  >
    <img src={checked ? Check : Unchecked} alt="Check" />
    {text && <label>{text}</label>}
  </button>
);

export default Checkbox2;
