import classNames from 'classnames';
import React from 'react';

const ViewIcon = ({ disabled }) => (
<svg 
  xmlns="http://www.w3.org/2000/svg" 
  width="17.804" 
  height="11.87" 
  viewBox="0 0 17.804 11.87" 
  className={classNames('delete-icon', { 'delete-icon--disabled': disabled })}>
    <path 
      id="Icon_awesome-eye" 
      data-name="Icon awesome-eye" 
      d="M17.7,9.983A9.914,9.914,0,0,0,8.9,4.5,9.916,9.916,0,0,0,.108,9.984a1,1,0,0,0,0,.9A9.914,9.914,0,0,0,8.9,16.369,9.916,9.916,0,0,0,17.7,10.886,1,1,0,0,0,17.7,9.983ZM8.9,14.886a4.451,4.451,0,1,1,4.451-4.451A4.451,4.451,0,0,1,8.9,14.886Zm0-7.418a2.946,2.946,0,0,0-.782.117A1.479,1.479,0,0,1,6.052,9.652,2.961,2.961,0,1,0,8.9,7.467Z" 
      transform="translate(0 -4.5)" 
      fill="#939393"
    />
</svg>
);

export default ViewIcon;
