import React from 'react';
import Loader from '../Loader/Loader';
import Empty from './Empty';

const EmptyComponent = ({ children, data, isLoading, message, dontShow }) => {
    if (isLoading) {
        return <Loader />
    }

    if (dontShow && !data?.length) {
        return null;
    }

    if (data?.length) {
        return children;
    }

    return <Empty message={message} />

};

export default EmptyComponent;