import Revision from '../assets/images/in_revision.svg';
import Rejected from '../assets/images/rejected.svg';
import Check from '../assets/images/status_icon.svg';
import Checked from '../assets/images/status_icon_checked.svg';


// Usar localhost en produccion

export const ENDPOINT_URL =
  window.location.hostname.indexOf('localhost') === -1
    ? `${window.location.origin}/api`
    : 'http://localhost:8888/api';


export const BLOG_URL =
  window.location.hostname.indexOf('localhost') === -1
    ? `${window.location.origin}/`
    : 'http://localhost:8888/';

export const AGE_HUB =
  window.location.hostname.indexOf('localhost') === -1
    ? window.location.hostname.indexOf('stag') >= 0
      ? 'https://stag-veinsahub.azurewebsites.net'
      : 'https://www.veinsahub.com'
    : 'https://stag-veinsahub.azurewebsites.net';

export const ALERTS_DURATION = 3000;

export const REGEX_PASS = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})";

export const results = {
  PREAPPROVED: 'PREAPPROVED',
  APPROVED: 'APPROVED',
  APPROVED_CONDITIONS: 'APPROVED_CONDITIONS',
  REJECTED: 'REJECTED',
  CHECKING_CREDIT: 'CHECKING_CREDIT',
  CHECKING_DOCS: 'CHECKING_DOCS',
  PENDING_DOCS: 'PENDING_DOCS',
  FORMALIZATION: 'FORMALIZATION',
  READY: 'READY',
  CLOSED: 'CLOSED',
  FORMALIZED: 'FORMALIZED',
};

export const STATUS = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  SENT: 'SENT',
  REJECTED: 'REJECTED',
  REVISION: 'REVISION',
};

export const getRemainingDocs = (documents) => {
  return documents?.filter(
    document => FINANCIAL_DOCUMENTS.includes(document.tipoDocumento)
      || (!PERSONAL_DOCUMENTS.includes(document.tipoDocumento) && !FORMALIZATION_DOCUMENTS.includes(document.tipoDocumento) && !FINANCIAL_DOCUMENTS.includes(document.tipoDocumento))
  ) || [];
};

export const FINANCIAL_DOCUMENTS = [
  'Orden Patronal',
  'Constancia Salarial',
  'Estados de Cuenta Bancarios de la empresa',
  'Estados Financieros de la empresa'
];

export const PERSONAL_DOCUMENTS = [
  'Copia de Cédula de Identidad',
  'Copia de cédula de identidad del beneficiario',
  'Copia de Cédula de Identidad del representante Legal',
  'Copia de la personería jurídica',
];

export const ALLOW_CAMARA = [
  ...FINANCIAL_DOCUMENTS,
  ...PERSONAL_DOCUMENTS,
]

export const WHATSAPP_LINK = "https://wa.me/50661517386";

export const FORMALIZATION_DOCUMENTS = [
  'Formulario DTR',
  'CIC',
  'CIC de la empresa'
];

export const ICON_BY_STATUS = {
  APPROVED: Checked,
  REJECTED: Rejected,
  REVISION: Revision,
  PENDING: Check,
  SENT: Check,
};

export const CURRENCIES = [
  { name: 'dollar', sign: '$', labels: 'dolares, dolar, dollar, dolár, usd, us' },
  { name: 'colon', sign: '₡', labels: 'colon, colón, colones, crc, cr' },
];

export const FB_PIXEL = '734447111045880';
export const GA = 'G-TS9DEJ2GV7';

export const responsiveCarousel = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 965 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 964, min: 0 },
    items: 1
  }
};

export const GATEWAY_KEYS = {
  CHECKOUT: 'checkout_public_6v7d598HugbMJaPDMp6P37djD2rHvSSJ',
};
