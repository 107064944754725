import React, { forwardRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './Select.scss';
import classNames from 'classnames';

const Select = forwardRef(
  (
    {
      name,
      label,
      options,
      onChange,
      value,
      placeholder,
      onBlur,
      disabled,
      disabledForm,
      defaultValue,
      hasError,
      circular,
    },
    ref
  ) => {
    const [selected, setSelected] = useState(value);
    useEffect(() => {
      setSelected(value);
    }, [value]);
    return (
      <div className="select">
        <div className="select__label">
          <label htmlFor={name}>{label}</label>
        </div>
        <div
          className={classNames('select__control-container', {
            'select__control-container--disabled': disabledForm,
          })}
        >
          <select
            name={name}
            id={name}
            ref={ref}
            className={classNames('select__control', {
              'select__control--error': hasError,
              'select__control--empty': !selected,
              'select__control--circular': circular,
            })}
            onChange={e => {
              onChange(e);
              setSelected(e.target.value);
            }}
            value={selected}
            placeholder="placeholder"
            onBlur={onBlur}
            disabled={disabled}
          >
            <option value="" className="select__placeholder">
              {placeholder || 'Seleccione'}
            </option>
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <span className="select__arrow">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
      </div>
    );
  }
);
Select.defaultProps = {
  options: [],
};

export default Select;
