import React, { useEffect, useState } from 'react';
import Button from '../Button';
import Dialog from '../Dialog';
import Select from '../Select';
import Close from '../../assets/images/close.png';

import './CancelarSolicitud.scss';
import Input from '../Input';

const CancelarSolicitud = ({ cancel, isLoading, onClose }) => {
  const [option, setOption] = useState(null);
  const [extra, setExtra] = useState('');
  const [isRequired, setIsRequired] = useState();

  const options = [
    {"value":"Tasa interés", "label":"Tasa interés"},
    {"value":"Precio Vehículo", "label":"Precio Vehículo"},
    {"value":"Pago Prima", "label":"Pago Prima"},
    {"value":"Entrega Documentos", "label":"Entrega Documentos"},
    {"value":"Producto: Leasing-Prendario", "label":"Producto: Leasing-Prendario"},
    {"value":"Historial Crediticio (Score)", "label":"Historial Crediticio (Score)"},
    {"value":"Servicio al Cliente", "label":"Servicio al Cliente"},
    {"value":"Historial Comportamiento pago", "label":"Historial Comportamiento pago"},
    {"value":"Mejor Opción (Otro Banco)", "label":"Mejor Opción (Otro Banco)"},
    {"value":"Cuota", "label":"Cuota"},
    {"value":"Otro", "label":"Otro"}
  ];

  useEffect(() => {
    if (option === 'Otro' && !extra) {
      setIsRequired(true);
      return;
    }
    if (isRequired) {
      setIsRequired(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option, extra]);

  console.log(isRequired);
  return (
    <Dialog classname="cancelar-solicitud" open={true} medium>
      <div>
        <button onClick={onClose} className="cancelar-solicitud__close">
          <img src={Close} alt="Close" />
        </button>
        <h2>¿Seguro(a) que deseás cancelar esta solicitud?</h2>
        <h3>Por favor indica el motivo del rechazo:</h3>
        <Select options={options} onChange={({ target: { value }}) => setOption(value)} circular />
        {option === 'Otro' && <Input 
          onChange={({ target: { value }}) => {
            setExtra(value);
          }}
          placeholder="Motivo de rechazo"
          type="text"
          hasError={isRequired}
          circular
        />}
        <div className='cancelar-solicitud__actions'>
          <Button onClick={() => cancel(option, extra)} disabled={isRequired || isLoading || !option} value="Cancelar Solicitud" />
        </div>
      </div>
    </Dialog>
  );
};

export default CancelarSolicitud;