import React from 'react';
import ReactDependentScript from 'react-dependent-script';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from '../../store';
import { GATEWAY_KEYS } from '../../utils/constants';
import { AppContextConsumer, AppProvider } from '../Context/AppContext';
import Footer from '../Footer';
import MainRouter from '../MainRouter';
import ScrollToTop from '../ScrollToTop';
import './App.scss';


function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <BrowserRouter>
          <ReactDependentScript
            scripts={["https://credomatic.compassmerchantsolutions.com/js/v1/Gateway.js"]}
          >
            <script>
              <Helmet
                script={[
                  {
                    type: 'text/javascript',
                    innerHTML: `if (!window.processor) { window.processor = Gateway.create('${GATEWAY_KEYS.CHECKOUT}');}`
                  }
                ]} />
            </script>
          </ReactDependentScript>
          <ScrollToTop />
          <div className="App__main">
            <div className="App__content">
              <AppProvider>
                <AppContextConsumer>
                  {() => (
                    <MainRouter />
                  )}
                </AppContextConsumer>
              </AppProvider>
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
