import React from 'react';
import EmptyIcon from './EmptyIcon.svg';

import './Empty.scss';
const Empty = ({ message }) => {
    return (
        <div className="empty-content">
            <div className="empty-content__inner">
                <img src={EmptyIcon} alt="Empty" />
                <h2>{message}</h2>
            </div>
        </div>
    )
};

export default Empty;