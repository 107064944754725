import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link, useParams } from 'react-router-dom';
import { useBlogSearch, useSearchBlogData } from '../../ducks/blog/hooks';
import BlogHeader from '../BlogHeader/BlogHeader';
import EmptyComponent from '../Empty/EmptyComponent';
import './BlogSearch.scss';

const BlogItem = ({ blog }) => {
  const blogData = useSearchBlogData(blog);
  console.log(blogData, blog);
  return (
    <div className='blog-search__item'>
      <h3>{blogData.title}</h3>
      <p dangerouslySetInnerHTML={{ __html: blogData.excerpt }} />
      <Link to={blogData.url}>Ver más</Link>
    </div>
  );
}

const BlogSearch = () => {
  const { search } = useParams();
  const [data, isLoading] = useBlogSearch(search);

  return (
    <div className="blog-search">
      <BlogHeader />
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <br />
          <Skeleton height={50} />
          <br />
          <Skeleton height={50} />
          <br />
          <Skeleton height={50} />
          <br />
        </>
        
      ):(
        <EmptyComponent data={data} isLoading={isLoading} message="No pudimos encontrar posts con los datos utilizados">
          <>
            <h1>Resultados de búsqueda</h1>
            {data?.map(blog => (
              <BlogItem key={blog.id} blog={blog} />
            ))}
          </>
          
        </EmptyComponent>
      )}
    </div>
  )
};

export default BlogSearch;