// @flow
/**
 * @module Actions/Faq
 * @desc Actions for Faq
 */
import ActionTypes from './actionTypes';

/**
 * Fetch faq request
 * @param {string} email
 * @param {string} password
 * @returns {Object}
 */
export function fetchFaqRequest(email, password) {
  return {
    type: ActionTypes.FETCH_FAQ_REQUEST,
    payload: { email, password },
  };
}

/**
 * Fetch faq success
 *
 * @param {object} faq
 * @returns {Object}
 */
export function fetchFaqSuccess(faq) {
  return {
    type: ActionTypes.FETCH_FAQ_SUCCESS,
    payload: { faq },
  };
}

/**
 * Fetch faq failure
 *
 * @returns {Object}
 */
export function fetchFaqFailure() {
  return {
    type: ActionTypes.FETCH_FAQ_FAILURE,
  };
}
