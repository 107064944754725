const ActionTypes = {
    FETCH_HOMEPAGE_POSTS: 'FETCH_HOMEPAGE_POSTS',
    FETCH_HOMEPAGE_POSTS_SUCCESS: 'FETCH_HOMEPAGE_POSTS_SUCCESS',
    FETCH_HOMEPAGE_POSTS_FAILURE: 'FETCH_HOMEPAGE_POSTS_FAILURE',

    FETCH_CATEGORIES: 'FETCH_CATEGORIES',
    FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
    FETCH_CATEGORIES_FAILURE: 'FETCH_CATEGORIES_FAILURE',

    FETCH_SEARCH: 'FETCH_SEARCH',
    FETCH_SEARCH_SUCCESS: 'FETCH_SEARCH_SUCCESS',
    FETCH_SEARCH_FAILURE: 'FETCH_SEARCH_FAILURE',

    FETCH_CAPSULAS: 'FETCH_CAPSULAS',
    FETCH_CAPSULAS_SUCCESS: 'FETCH_CAPSULAS_SUCCESS',
    FETCH_CAPSULAS_FAILURE: 'FETCH_CAPSULAS_FAILURE',

    FETCH_CATEGORIES_BY_SLUG: 'FETCH_CATEGORIES_BY_SLUG',
    FETCH_CATEGORIES_BY_SLUG_SUCCESS: 'FETCH_CATEGORIES_BY_SLUG_SUCCESS',
    FETCH_CATEGORIES_BY_SLUG_FAILURE: 'FETCH_CATEGORIES_BY_SLUG_FAILURE',

    FETCH_BLOG_POST: 'FETCH_BLOG_POST',
    FETCH_BLOG_POST_SUCCESS: 'FETCH_BLOG_POST_SUCCESS',
    FETCH_BLOG_POST_FAILURE: 'FETCH_BLOG_POST_FAILURE',

    FETCH_ALL_BLOG: 'FETCH_ALL_BLOG',
    FETCH_ALL_BLOG_SUCCESS: 'FETCH_ALL_BLOG_SUCCESS',
    FETCH_ALL_BLOG_FAILURE: 'FETCH_ALL_BLOG_FAILURE',
  };
  
  export default ActionTypes;
  