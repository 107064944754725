import classNames from 'classnames';
import React, { forwardRef, useState } from 'react';
import DatePickerLib from 'react-datepicker';
import InputMask from 'react-input-mask';
import 'react-datepicker/dist/react-datepicker.css';

import './DatePicker.scss';

const ExampleCustomInput = forwardRef((props, ref) => (
  <InputMask {...props} mask="99/99/9999" maskChar=" " inputRef={ref} />
));

const DatePicker = forwardRef(
  (
    {
      className,
      size2,
      disabled,
      type2,
      whiteBackground,
      label,
      name,
      pre,
      hasError,
      value,
      onChange,
      id,
      bigger,
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState(value);
    // useEffect(() => {
    //   setSelectedValue(value);
    // }, [value]);
    const currentDate = new Date();
    const maxDate = new Date();
    maxDate.setFullYear(currentDate.getFullYear() + 10);
    return (
      <div
        className={classNames(`date-picker ${className || ''}`, {
          'date-picker--size2': size2,
          'date-picker--disabled': disabled,
          'date-picker--type2': type2,
          'date-picker--background': whiteBackground,
          'date-picker--bigger': bigger,
        })}
      >
        {!type2 && label && (
          <div className="date-picker__label">
            <label htmlFor={name}>{label}</label>
          </div>
        )}
        <div
          className={classNames('date-picker__control-container', {
            'date-picker__control-container--has-pre': !!pre,
            'date-picker__control-container--error': hasError,
          })}
        >
          {pre && <div className="date-picker__pre">{pre}</div>}
          <DatePickerLib
            ref={ref}
            // selected={value}
            // onChange={date => {
            // setSelectedValue(date);
            // onChange({ target: { name, id, value: date } });
            // }}
            selected={selectedValue}
            onChange={date => {
              setSelectedValue(date);
              onChange({ target: { name, id, value: date } });
            }}
            className="date-picker__control"
            placeholderText="Día / Mes / Año"
            dateFormat="dd/MM/yyyy"
            // onChangeRaw={e => e.preventDefault()}
            name={name}
            showYearDropdown
            yearDropdownItemNumber={100}
            maxDate={maxDate}
            customInput={<ExampleCustomInput />}
          />
        </div>
      </div>
    );
  }
);

export default DatePicker;
