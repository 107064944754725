import ReactDOM from 'react-dom';
import './Modal.scss';
import { useEffect } from 'react';

const modalRoot = document.getElementById('modal-root');
const el = document.createElement('div');
const overlay = document.createElement('div');
const content = document.createElement('div');

overlay.classList.add('modal__overlay');
content.classList.add('modal__content');
el.classList.add('modal');
el.appendChild(overlay);
el.appendChild(content);

const Modal = ({ children }) => {
  useEffect(() => {
    modalRoot.appendChild(el);
    document.body.classList.add('App--no-scroll');
    return () => {
      document.body.classList.remove('App--no-scroll');
      modalRoot.removeChild(el);
    };
  }, []);
  return ReactDOM.createPortal(children, content);
};

export default Modal;
