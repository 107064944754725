// @flow
/**
 * @module Epics/user
 * @desc user
 */
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError, map, mergeMap
} from 'rxjs/operators';

import { BaseApiService } from '../../utils/BaseApiService';
import {
  checkUserLoggedInFailure, checkUserLoggedInSuccess, fetchOperationsSuccess,
  fetchOperationsToPaySuccess, getUserByTokenFailure, getUserByTokenSuccess, recoverPasswordSuccess, registerAgeHubSuccess, saveExtraDataSuccess, setPasswordSuccess, signInFailure, signInSuccess, signOutFailure,
  // signInFailure,
  signOutSuccess
} from './actions';
import ActionTypes from './actionTypes';

export function signIn(action$) {
  return action$.pipe(
    ofType(ActionTypes.SIGN_IN_REQUEST),
    mergeMap(action => {
      // return signInSuccess({
      //   email: action.payload.email,
      //   name: 'Andoni Zubizarreta',
      // });
      return BaseApiService.post(`/user/login${action.payload.login2 ? '2' : ''}/`, {
        email: action.payload.email,
        password: action.payload.password,
      }).pipe(
        map(({ response }) => response.data),
        map(response => {
          if (action.payload?.callback) action.payload.callback();
          return signInSuccess(response.user, response.token)
        }),
        catchError(
          BaseApiService.getErrorHandler('userEpic', 'signIn', action)
        ),
        catchError(
          /* istanbul ignore next  */({ actions = [], error }) =>
            of(signInFailure(error), ...actions)
        )
      );
    })
  );
}

export function signOut(action$) {
  return action$.pipe(
    ofType(ActionTypes.SIGN_OUT_REQUEST),
    mergeMap(action => {
      return BaseApiService.post('/user/logout/').pipe(
        map(() => signOutSuccess()),
        catchError(
          BaseApiService.getErrorHandler('userEpic', 'signOut', action)
        ),
        catchError(
          /* istanbul ignore next  */({ actions = [] }) =>
            of(signOutFailure(), ...actions)
        )
      );
    })
  );
}

export function fetchOperationsRequest(action$) {
  return action$.pipe(
    ofType(ActionTypes.GET_OPERATIONS_REQUEST),
    mergeMap(action => {
      return BaseApiService.post('/operaciones/activas/').pipe(
        map(({ response }) => response.data),
        map(response => fetchOperationsSuccess(response)),
        catchError(
          BaseApiService.getErrorHandler('userEpic', 'getOperations', action)
        ),
        catchError(
          /* istanbul ignore next  */({ actions = [] }) =>
            of(fetchOperationsSuccess([]), ...actions)
        )
      );
    })
  );
}


export function fetchOperationsToPayRequest(action$) {
  return action$.pipe(
    ofType(ActionTypes.GET_OPERATIONS_TOPAY_REQUEST),
    mergeMap(action => {
      return BaseApiService.post('/operaciones/porPagar/').pipe(
        map(({ response }) => response.data),
        map(response => fetchOperationsToPaySuccess(response)),
        catchError(
          BaseApiService.getErrorHandler('userEpic', 'getOperations', action)
        ),
        catchError(
          /* istanbul ignore next  */({ actions = [] }) =>
            of(fetchOperationsToPaySuccess([]), ...actions)
        )
      );
    })
  );
}

export function checkUserLoggedIn(action$) {
  return action$.pipe(
    ofType(ActionTypes.CHECK_USER_LOGGED_IN_REQUEST),
    mergeMap(action => {
      return BaseApiService.post('/user/authenticated/').pipe(
        map(({ response }) => response.data),
        map(user => checkUserLoggedInSuccess(user.user, user.token)),
        catchError(
          BaseApiService.getErrorHandler(
            'userEpic',
            'checkUserLoggedIn',
            action
          )
        ),
        catchError(
          /* istanbul ignore next  */({ response, actions = [] }) =>
            of(checkUserLoggedInFailure(response), ...actions)
        )
      );
    })
  );
}


export function changePasswordRequest(action$) {
  return action$.pipe(
    ofType(ActionTypes.CHANGE_PASSWORD_REQUEST),
    mergeMap(action => {
      return BaseApiService.post('/user/changePassword/', action.payload.values).pipe(
        map(response => {
          action.payload.callback();
          return {
            type: ActionTypes.CHANGE_PASSWORD_SUCCESS
          };
        }),
        catchError((err) => {
          action.payload.errorCallback();
          return of({
            type: ActionTypes.CHANGE_PASSWORD_FAILURE
          });
        })
      );
    })
  );
}

export function saveExtraData(action$) {
  return action$.pipe(
    ofType(ActionTypes.SAVE_EXTRA_FIELDS),
    mergeMap(action => {
      return BaseApiService.post('/user/profile/', {
        ...action.payload.data
      }).pipe(
        map(({ response }) => response.data),
        map(response => saveExtraDataSuccess(response)),
        catchError(
          BaseApiService.getErrorHandler('userEpic', 'saveExtraData', action)
        ),
        catchError(
          /* istanbul ignore next  */({ actions = [] }) =>
            of(saveExtraDataSuccess([]), ...actions)
        )
      );
    })
  );
}

export function registerAgeHub(action$) {
  return action$.pipe(
    ofType(ActionTypes.REGISTER_AGE_USER),
    mergeMap(action => {
      return BaseApiService.post('/user/register/', {
        ...action.payload.data
      }, true).pipe(
        map(({ response }) => response.data),
        map(response => registerAgeHubSuccess(response)),
        catchError(
          BaseApiService.getErrorHandler('userEpic', 'registerAgeHub', action)
        ),
        catchError(
          /* istanbul ignore next  */({ actions = [] }) =>
            of(registerAgeHubSuccess([]), ...actions)
        )
      );
    })
  );
}

export function recoverPassword(action$) {
  return action$.pipe(
    ofType(ActionTypes.RECOVER_USER_PASS),
    mergeMap(action => {
      return BaseApiService.post('/user/recover/', {
        ...action.payload.data
      }, true).pipe(
        map(({ response }) => response.data),
        map(response => recoverPasswordSuccess(response)),
        catchError(
          BaseApiService.getErrorHandler('userEpic', 'recoverPassword', action)
        ),
        catchError(
          /* istanbul ignore next  */({ actions = [] }) =>
            of(recoverPasswordSuccess([]), ...actions)
        )
      );
    })
  );
}

export function getUserByToken(action$) {
  return action$.pipe(
    ofType(ActionTypes.GET_USER_BY_TOKEN),
    mergeMap(action => {
      return BaseApiService.post('/user/validateToken/', {
        ...action.payload
      }, true).pipe(
        map(({ response }) => response.data),
        map(response => getUserByTokenSuccess(response)),
        catchError(
          BaseApiService.getErrorHandler('userEpic', 'getUserByToken', action)
        ),
        catchError(
          /* istanbul ignore next  */({ actions = [] }) =>
            of(getUserByTokenFailure([]), ...actions)
        )
      );
    })
  );
}

export function setPassword(action$) {
  return action$.pipe(
    ofType(ActionTypes.SET_PASSWORD),
    mergeMap(action => {
      return BaseApiService.post('/user/activate/', {
        ...action.payload.data
      }, true).pipe(
        map(({ response }) => response.data),
        map(response => {
          action.payload.callback(response);
          return setPasswordSuccess(response)
        }),
        catchError(
          BaseApiService.getErrorHandler('userEpic', 'getUserByToken', action)
        ),
        catchError(
          /* istanbul ignore next  */({ actions = [] }) =>
            of(getUserByTokenFailure([]), ...actions)
        )
      );
    })
  );
}