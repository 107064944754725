import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { cancelationRequest, continueFormalization, downloadApprovedLetterRequest, downloadLetterRequest } from '../../ducks/actions';
import { results } from '../../utils/constants';
import { getQuoteStatus, getResultData } from '../../utils/helpers';
import ViewIcon from '../ViewIcon/ViewIcon';
import CancelarSolicitud from './CancelarSolicitud';
import './Result.scss';

const Result = ({ quote, defaultStatus, user, oneStepFoward, step }) => {
  const [downloading, setDownloading] = useState(false);
  const { loadingApproval } = useSelector(state => ({
    loadingApproval: state.app.approval.isLoading,
  }));
  const [sending, setSending] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const status = defaultStatus || getQuoteStatus(quote.status);
  const history = useHistory();
  const data = getResultData(status);
  const dispatch = useDispatch();
  // const [approved, setApproved] = useState(!data?.needTerms);
  // const [canEdit, setCanEdit] = useState(!data?.needTerms);
  const cancel = (reason, extra) => {
    setSending(true);
    dispatch(cancelationRequest(
      quote.id,
      reason,
      extra,
      () => {
        setShowCancel(false);
        history.push('/perfil/mi-solicitud/')
      }
    ));
  };

  useEffect(() => {
    if (downloading && !loadingApproval) {
      setDownloading(false);
    }
  }, [loadingApproval, downloading]);

  return (
    <>
      {data?.edit && (
        <div className="mi-solicitud__editar-container">
          <Link
            className="mi-solicitud__link"
            to={`/perfil/mi-solicitud/${quote.id}`}
            title="Editar"
          >
            <FontAwesomeIcon icon={faPen} />
          </Link>
        </div>
      )}
      {data?.view && (
        <div className="mi-solicitud__editar-container">
          <Link
            className="mi-solicitud__link"
            to={`/perfil/mi-solicitud/${quote.id}`}
            title="Visualizar"
          >
            <ViewIcon />
          </Link>
        </div>
      )}
      <div
        className={classNames('status', {
          'status--approved':
            status === results.APPROVED ||
            status === results.APPROVED_CONDITIONS ||
            status === results.READY ||
            status === results.FORMALIZED,
          'status--rejected':
            status === results.REJECTED || status === results.CLOSED,
          'status--more': status === results.PREAPPROVED,
          'status--mustard':
            status === results.PENDING_DOCS ||
            status === results.CHECKING_DOCS ||
            status === results.CHECKING_CREDIT ||
            status === results.FORMALIZATION,
          //'status--pending': status === results.APPROVED_CONDITIONS,
        })}
      >
        <div className="status__badge">
          <img className="status__img" src={data?.icon} alt="Status" />
          <p className="status__text">{data?.text}</p>
        </div>
        <div className="status__details">
          <div className="status__details--solicitud">
            <div className="status__title">
              Número de solicitud: <span>{quote.id}</span>
            </div>
            {/* <div className="status__number">{quote.id}</div> */}
          </div>
          <p>{data?.subtext}</p>
          {data?.downloadConditions && (
            <div className="status__details--download status__details--conditions">
              <p>Conocé tus condiciones {status === results.APPROVED || status === results.APPROVED_CONDITIONS ? 'aprobadas' : 'pre aprobadas'} <button onClick={(ev) => {
                ev.preventDefault()
                ev.stopPropagation();
                ev.nativeEvent.stopImmediatePropagation();

                if (downloading) return false;
                setDownloading(true);
                if (status === results.APPROVED || status === results.APPROVED_CONDITIONS) {
                  dispatch(downloadApprovedLetterRequest(quote.id, quote.moneda));
                  return false;
                }
                dispatch(downloadLetterRequest(quote));
                return false;
              }}>
                {downloading ? 'Descargando...' : 'Descargar PDF'}
              </button>
              </p>
            </div>
          )}
          {step <= 3 && (status === results.APPROVED || status === results.APPROVED_CONDITIONS) && (
            <div className="status__details--options">
              <p className="status__details--title">¿Que deseás hacer ahora?</p>
              <button className="status__details--options--button_black"
                onClick={(e) => {
                  e.preventDefault();
                  setShowCancel(true)
                  return false;
                }}>
                Cancelar solicitud
              </button>
              <button className="status__details--options--button_gold"
                onClick={() => {
                  dispatch(continueFormalization(quote.id));
                  oneStepFoward(4);
                }}>
                Continuar formalización
              </button>
            </div>
          )}


          {showCancel && <CancelarSolicitud isLoading={sending} cancel={cancel} onClose={() => setShowCancel(false)} />}
          {/*{data?.needTerms && !canEdit && (
            <div className="status__details--download status__details--terms">
              <p>
                <Checkbox2 checked={approved} setChecked={() => setApproved(!approved)}/>
                
                <a
                  href="/wp-content/uploads/docs/Términos y condiciones AGE Capital.pdf"
                  target="_blank">
                  Acepto los términos y condiciones
                </a>
                <button disabled={!approved} onClick={() => {
                  var current = new Date();
                  current.setHours(current.getHours() - 6);
                  dispatch(approveOffer(quote?.id));
                  dispatch(approveTYC({
                    cedula: user?.dni,
                    fecha: current.getTime(),
                    texto: getTyCText(user?.name, user?.lastname, user?.dni),
                  }));
                  setCanEdit(true);
                }}>
                  Enviar
                </button>
              </p>
              
            </div>
          )}*/}
          {/* <div className="status__brand">Mitsubishi - L200</div> */}
          {/* <p className="status__description">
          Info de lo anteriormente seleccionado, natoque penatibus et magnis dis
          parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies
          nec, pellentesque eu, pretium quis, sem.{' '}
        </p>
        <p className="status__description">
          <span className="bold">
            Serás contactado/a por un ejecutivo en 48 horas.
          </span>{' '}
          Si lo deseás, también podés comunicarte con nosotros al correo {' '}
          <a className="status__link" href="mailto:correo@capital.com">
            correo@capital.com
          </a>{' '}
          con tu número de solicitud para ser atendido/a.
        </p> */}
        </div>
      </div>
    </>
  );
};

export default Result;
