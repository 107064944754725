import classNames from 'classnames';
import React from 'react';
import './RequestStatusIcon.scss';

const RequestStatusIcon = ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    className={classNames('request-status-icon', {
      'request-status-icon--active': active,
    })}
  >
    <g
      id="Ellipse_215"
      data-name="Ellipse 215"
      fill="#fff"
      stroke="#707070"
      strokeWidth="1"
    >
      <circle cx="21" cy="21" r="20" stroke="none" />
      <circle cx="21" cy="21" r="19.5" fill="none" />
    </g>
    <g id="clock" transform="translate(10.545 10.546)">
      <g
        id="Group_19181"
        data-name="Group 19181"
        transform="translate(9.722 5.387)"
      >
        <g id="Group_19180" data-name="Group 19180">
          <path
            id="Path_13830"
            data-name="Path 13830"
            d="M240.27,125.517l-2.636-1.977v-4.028a.732.732,0,1,0-1.465,0v4.394a.731.731,0,0,0,.293.586l2.929,2.2a.732.732,0,0,0,.879-1.172Z"
            transform="translate(-236.169 -118.779)"
            fill="#707070"
          />
        </g>
      </g>
      <g id="Group_19183" data-name="Group 19183">
        <g id="Group_19182" data-name="Group 19182">
          <path
            id="Path_13831"
            data-name="Path 13831"
            d="M9.455,0a9.455,9.455,0,1,0,9.455,9.455A9.465,9.465,0,0,0,9.455,0Zm0,17.444a7.99,7.99,0,1,1,7.99-7.99A8,8,0,0,1,9.455,17.444Z"
            transform="translate(1 1)"
            fill="#707070"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default RequestStatusIcon;
