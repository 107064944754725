import React from 'react';
import Dialog from '../Dialog';
import './ProcessingDialog.scss';
import LoadingImage from '../../assets/images/loading.png';

const ProcessingDialog = ({ open, title, body }) => (
  <Dialog open={open}>
    <div className="pm">
      <h1 className="pm__title">
        {title || 'Estamos procesando tus datos, ¡No cerrés la ventana!'}
      </h1>
      <div className="pm__loading">
        <img className="pm__image" src={LoadingImage} alt="Loading" />
      </div>
      <div className="pm__content">
        {body ? (
          <p className="pm__text">{body}</p>
        ) : (
          <p className="pm__text">
            Estamos procesando tus datos, esto tomará solo unos segundos. <br/>
            El resultado de tu solicitud se enviará a tu{' '} 
            <span className="bold">correo electrónico</span> 
            , o bien podés esperar hasta que terminemos la consulta. <br/><br/>
            {/* Te hemos enviado un correo electrónico con tu usuario para que 
            generés tu contraseña y podás <br/> 
            ingresar a nuestra plataforma para ver tu estado. */}
          </p>
        )}
      </div>
    </div>
  </Dialog>
);

export default ProcessingDialog;
