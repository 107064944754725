import { Link, useParams } from 'react-router-dom';
import { useBlogData, useCategoryPosts } from '../../ducks/blog/hooks';
import { HomepageBlog } from '../HomepageBlogs/HomepageBlogs';
import './BlogCategory.scss';
import BlogCategoryWrapper from './BlogCategoryWrapper';

export const BlogCategoryItem = ({ blog }) => {
  const blogData = useBlogData(blog);
  return (
    <div className='blog-category__item'>
      <HomepageBlog blog={blog} blogUrl={blogData.url} />
      <div className='blog-category__item--desc'>
        <p dangerouslySetInnerHTML={{__html: blogData.excerpt }}/>
        <Link to={blogData.url}>Ver más</Link>
      </div>
    </div>
    
  );
};

const BlogCategory = ({ className = '', category}) => {
  const params = useParams();
  const [posts, isLoading] = useCategoryPosts(category || params?.category);
  
  return (
    <BlogCategoryWrapper className={className} data={posts} isLoading={isLoading} message={"Esta categoría todavía no tiene posts"}>
      {posts?.map(blog => {
        return (
          <BlogCategoryItem key={blog.id} blog={blog} />
        )
      })}
    </BlogCategoryWrapper>
  )
};

export default BlogCategory;