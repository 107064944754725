import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import Play from '../../assets/images/play.svg';
import { KNOWN_CATEGORIES } from '../../ducks/blog/constants';
import { useBlogData, useCategoryPosts } from '../../ducks/blog/hooks';
import { BlogCategoryEmpty } from '../BlogCategory/BlogCategoryWrapper';
import './BlogCapsula.scss';

const BlogCapsulaItem = ({ blog }) => {
  const { featuredImage, title: { rendered } } = useBlogData(blog);

  return (
    <Link to={`/blog/categoria/capsulas-financieras/${blog.id}`} className='blog-capsula__item' style={{ backgroundImage: featuredImage && `url(${featuredImage.source_url})` }}>
      <div className='blog-capsula__item-content'>
        <span><img src={Play} alt="Play"/></span>
        <h3>{rendered}</h3>
      </div>
    </Link>
  );
};

const BlogCapsula = () => {
  const { postId } = useParams();
  const cat = KNOWN_CATEGORIES.CAPSULAS.slug;
  const [posts, isLoading] = useCategoryPosts(cat);
  const selectedCapsula = useMemo(() => {
    if (!posts?.length) {
      return null;
    }
    if (!postId) {
      return posts[0];
    }
    // Disabling because react params got it as string
    // eslint-disable-next-line eqeqeq
    return posts.find(x => x.id == postId);
  }, [posts, postId]);

  return (
    <BlogCategoryEmpty blogTitle={
      <>Bienvenidos a nuestras<br />Microcápsulas con Daniel Suchar</>
    } blogSubtitle={'¡Estamos aquí para ayudarte! Aprendé con AGE Capital.'} title={selectedCapsula?.title?.rendered} subtitle={selectedCapsula?.content?.rendered} grid={false} category={cat} data={posts} isLoading={isLoading} message="Todavía no tenemos cápsulas capital">
      <div className='blog-capsula'>
        <div className='blog-capsula__selected'>
          <iframe src={selectedCapsula?.acf?.microcapsula} title={selectedCapsula?.title} />
        </div>
        {!!posts?.length && (
          <div className='blog-capsula__options'>
            <h2>Los mas populares</h2>
            <div className='blog-capsula__options-grid'>
              {posts?.map(capsula => 
                <BlogCapsulaItem blog={capsula} key={capsula.id} />
              )}
            </div>
            
          </div>
        )}
        
      </div>
    </BlogCategoryEmpty>
  )
};

export default BlogCapsula;