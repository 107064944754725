import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getQueryParam } from '../../utils/helpers';
import CompletarRegistro from '../CompletarRegistro';
import Loader from '../Loader/Loader';
import RecuperarPass from '../RecuperarPass';
import SignInForm from '../SignInForm';

const RedirectTo = () => {
  const [openRegister, setOpenRegister] = useState(false);
  const [openRecuperar, setOpenRecuperar] = useState(false);
  const { user } = useSelector(state => ({ user: state.user }));
  const history = useHistory();
  const location = useLocation();

  const to = getQueryParam('to', location?.search);

  const isLogged = !!user.data;

  if (user.isSigning) {
    return <Loader />
  }
  if (isLogged) {
    history.push(to);
  }

  return (
    <>
      <SignInForm
        open={!openRegister}
        onSignIn={() => { }}
        openRegister={() => setOpenRegister(true)}
        onClose={() => {
          history.push('/solicitar');
        }}
      />
      <CompletarRegistro
        onClose={() => {
          setOpenRegister(false);
        }}
        openLogin={() => {
          setOpenRegister(false);
        }}
        open={openRegister}
      />
      <RecuperarPass
        onClose={() => {
          setOpenRecuperar(false);
        }}
        openLogin={() => {
          setOpenRecuperar(false);
        }}
        open={openRecuperar}
      />
    </>
  )
};

export default RedirectTo;