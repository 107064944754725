const ActionTypes = {
  SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  SIGN_IN_RESET: 'SIGN_IN_RESET',

  REGISTER_USER_REQUEST: 'REGISTER_USER_REQUEST',
  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',
  REGISTER_USER_RESET: 'REGISTER_USER_RESET',

  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',

  SIGN_OUT_REQUEST: 'SIGN_OUT_REQUEST',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',

  FETCH_USER_REQUEST: 'FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',
  FETCH_USER_RESET: 'FETCH_USER_RESET',

  FETCH_USER_FROM_TOKEN_REQUEST: 'FETCH_USER_FROM_TOKEN_REQUEST',
  FETCH_USER_FROM_TOKEN_SUCCESS: 'FETCH_USER_FROM_TOKEN_SUCCESS',
  FETCH_USER_FROM_TOKEN_FAILURE: 'FETCH_USER_FROM_TOKEN_FAILURE',

  REQUEST_CHANGE_PASSWORD_REQUEST: 'REQUEST_CHANGE_PASSWORD_REQUEST',
  REQUEST_CHANGE_PASSWORD_SUCCESS: 'REQUEST_CHANGE_PASSWORD_SUCCESS',
  REQUEST_CHANGE_PASSWORD_FAILURE: 'REQUEST_CHANGE_PASSWORD_FAILURE',
  REQUEST_CHANGE_PASSWORD_RESET: 'REQUEST_CHANGE_PASSWORD_RESET',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  CHANGE_PASSWORD_RESET: 'CHANGE_PASSWORD_RESET',

  FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  UPDATE_USER_RESET: 'UPDATE_USER_RESET',

  ADD_USER_REQUEST: 'ADD_USER_REQUEST',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE: 'ADD_USER_FAILURE',

  CHECK_USER_LOGGED_IN_REQUEST: 'CHECK_USER_LOGGED_IN_REQUEST',
  CHECK_USER_LOGGED_IN_SUCCESS: 'CHECK_USER_LOGGED_IN_SUCCESS',
  CHECK_USER_LOGGED_IN_FAILURE: 'CHECK_USER_LOGGED_IN_FAILURE',

  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  CREATE_USER_RESET: 'CREATE_USER_RESET',

  FETCH_USER_CONFIG_REQUEST: 'FETCH_USER_CONFIG_REQUEST',
  FETCH_USER_CONFIG_SUCCESS: 'FETCH_USER_CONFIG_SUCCESS',
  FETCH_USER_CONFIG_FAILURE: 'FETCH_USER_CONFIG_FAILURE',

  LOCK_USER_REQUEST: 'LOCK_USER_REQUEST',
  LOCK_USER_SUCCESS: 'LOCK_USER_SUCCESS',
  LOCK_USER_FAILURE: 'LOCK_USER_FAILURE',

  UNLOCK_USER_REQUEST: 'UNLOCK_USER_REQUEST',
  UNLOCK_USER_SUCCESS: 'UNLOCK_USER_SUCCESS',
  UNLOCK_USER_FAILURE: 'UNLOCK_USER_FAILURE',

  UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

  FETCH_ROLES_REQUEST: 'FETCH_ROLES_REQUEST',
  FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
  FETCH_ROLES_FAILURE: 'FETCH_ROLES_FAILURE',
  FETCH_ROLES_RESET: 'FETCH_ROLES_RESET',

  TOGGLE_PERMISSION_REQUEST: 'TOGGLE_PERMISSION_REQUEST',
  TOGGLE_PERMISSION_SUCCESS: 'TOGGLE_PERMISSION_SUCCESS',
  TOGGLE_PERMISSION_FAILURE: 'TOGGLE_PERMISSION_FAILURE',

  GET_OPERATIONS_REQUEST: 'GET_OPERATIONS_REQUEST',
  GET_OPERATIONS_SUCCESS: 'GET_OPERATIONS_SUCCESS',
  GET_OPERATIONS_FAILURE: 'GET_OPERATIONS_FAILURE',

  GET_OPERATIONS_TOPAY_REQUEST: 'GET_OPERATIONS_TOPAY_REQUEST',
  GET_OPERATIONS_TOPAY_SUCCESS: 'GET_OPERATIONS_TOPAY_SUCCESS',

  SAVE_PERMISSIONS_REQUEST: 'SAVE_PERMISSIONS_REQUEST',
  SAVE_PERMISSIONS_SUCCESS: 'SAVE_PERMISSIONS_SUCCESS',
  SAVE_PERMISSIONS_FAILURE: 'SAVE_PERMISSIONS_FAILURE',
  SAVE_PERMISSIONS_RESET: 'SAVE_PERMISSIONS_RESET',

  SAVE_EXTRA_FIELDS: 'SAVE_EXTRA_FIELDS',
  SAVE_EXTRA_FIELDS_SUCCESS: 'SAVE_EXTRA_FIELDS_SUCCESS',

  REGISTER_AGE_USER: 'REGISTER_AGE_USER',
  REGISTER_AGE_USER_SUCCESS: 'REGISTER_AGE_USER_SUCCESS',
  REGISTER_AGE_USER_RESET: 'REGISTER_AGE_USER_RESET',

  RECOVER_USER_PASS: 'RECOVER_USER_PASS',
  RECOVER_USER_PASS_SUCCESS: 'RECOVER_USER_PASS_SUCCESS',
  RECOVER_USER_PASS_RESET: 'RECOVER_USER_PASS_RESET',

  GET_USER_BY_TOKEN: 'GET_USER_BY_TOKEN',
  GET_USER_BY_TOKEN_SUCCESS: 'GET_USER_BY_TOKEN_SUCCESS',
  GET_USER_BY_TOKEN_FAILURE: 'GET_USER_BY_TOKEN_FAILURE',

  SET_PASSWORD: 'SET_PASSWORD',
  SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
  SET_PASSWORD_FAILURE: 'SET_PASSWORD_FAILURE'
};

export default ActionTypes;
