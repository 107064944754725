export const PAISES = [
  { value: 'Afganistan', label: 'Afganistan' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Alemania', label: 'Alemania' },
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Angola', label: 'Angola' },
  { value: 'Antigua y Barbuda', label: 'Antigua y Barbuda' },
  {
    value: 'Arabia Saudita / Arabia Saudi',
    label: 'Arabia Saudita / Arabia Saudi',
  },
  { value: 'Argelia', label: 'Argelia' },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Australia', label: 'Australia' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Azerbaiyan', label: 'Azerbaiyan' },
  { value: 'Bahamas', label: 'Bahamas' },
  { value: 'Banglades', label: 'Banglades' },
  { value: 'Barbados', label: 'Barbados' },
  { value: 'Barein', label: 'Barein' },
  { value: 'Belgica', label: 'Belgica' },
  { value: 'Belice', label: 'Belice' },
  { value: 'Bielorrusia', label: 'Bielorrusia' },
  { value: 'Benin', label: 'Benin' },
  { value: 'Birmania / Myanmar', label: 'Birmania / Myanmar' },
  { value: 'Bolivia', label: 'Bolivia' },
  {
    value: 'Bosnia y Herzegovina / Bosnia-Herzegovina',
    label: 'Bosnia y Herzegovina / Bosnia-Herzegovina',
  },
  { value: 'Botsuana', label: 'Botsuana' },
  { value: 'Brasil', label: 'Brasil' },
  { value: 'Brunei', label: 'Brunei' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Burkina Faso', label: 'Burkina Faso' },
  { value: 'Burundi', label: 'Burundi' },
  { value: 'Butan', label: 'Butan' },
  { value: 'Cabo Verde', label: 'Cabo Verde' },
  { value: 'Camboya', label: 'Camboya' },
  { value: 'Camerun', label: 'Camerun' },
  { value: 'Canada', label: 'Canada' },
  { value: 'Catar', label: 'Catar' },
  { value: 'Republica Centroafricana', label: 'Republica Centroafricana' },
  { value: 'Chad', label: 'Chad' },
  { value: 'Republica Checa / Chequia', label: 'Republica Checa / Chequia' },
  { value: 'Chile', label: 'Chile' },
  { value: 'China', label: 'China' },
  { value: 'Chipre', label: 'Chipre' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Comoras', label: 'Comoras' },
  { value: 'Republica del Congo', label: 'Republica del Congo' },
  {
    value: 'Republica Democratica del Congo',
    label: 'Republica Democratica del Congo',
  },
  { value: 'Corea del Norte', label: 'Corea del Norte' },
  { value: 'Corea del Sur', label: 'Corea del Sur' },
  { value: 'Costa de Marfil', label: 'Costa de Marfil' },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: 'Croacia', label: 'Croacia' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'Dinamarca', label: 'Dinamarca' },
  { value: 'Dominica', label: 'Dominica' },
  { value: 'Republica Dominicana', label: 'Republica Dominicana' },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Egipto', label: 'Egipto' },
  { value: 'El Salvador', label: 'El Salvador' },
  { value: 'Emiratos arabes Unidos', label: 'Emiratos arabes Unidos' },
  { value: 'Eritrea', label: 'Eritrea' },
  { value: 'Eslovaquia', label: 'Eslovaquia' },
  { value: 'Eslovenia', label: 'Eslovenia' },
  { value: 'España', label: 'España' },
  { value: 'Estados Unidos', label: 'Estados Unidos' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Etiopia', label: 'Etiopia' },
  { value: 'Filipinas', label: 'Filipinas' },
  { value: 'Finlandia', label: 'Finlandia' },
  { value: 'Fiyi', label: 'Fiyi' },
  { value: 'Francia', label: 'Francia' },
  { value: 'Gabon', label: 'Gabon' },
  { value: 'Gambia', label: 'Gambia' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Granada', label: 'Granada' },
  { value: 'Grecia', label: 'Grecia' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Guinea', label: 'Guinea' },
  { value: 'Guinea-Bisau', label: 'Guinea-Bisau' },
  { value: 'Guinea Ecuatorial', label: 'Guinea Ecuatorial' },
  { value: 'Guyana', label: 'Guyana' },
  { value: 'Haiti', label: 'Haiti' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Hungria', label: 'Hungria' },
  { value: 'India', label: 'India' },
  { value: 'Indonesia', label: 'Indonesia' },
  { value: 'Irak', label: 'Irak' },
  { value: 'Iran', label: 'Iran' },
  { value: 'Irlanda', label: 'Irlanda' },
  { value: 'Islandia', label: 'Islandia' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Italia', label: 'Italia' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Japon', label: 'Japon' },
  { value: 'Jordania', label: 'Jordania' },
  { value: 'Kazajistan', label: 'Kazajistan' },
  { value: 'Kenia', label: 'Kenia' },
  { value: 'Kirguistan', label: 'Kirguistan' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: 'Kuwait', label: 'Kuwait' },
  { value: 'Laos', label: 'Laos' },
  { value: 'Lesoto', label: 'Lesoto' },
  { value: 'Letonia', label: 'Letonia' },
  { value: 'Libano', label: 'Libano' },
  { value: 'Liberia', label: 'Liberia' },
  { value: 'Libia', label: 'Libia' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lituania', label: 'Lituania' },
  { value: 'Luxemburgo', label: 'Luxemburgo' },
  { value: 'Macedonia del Norte', label: 'Macedonia del Norte' },
  { value: 'Madagascar', label: 'Madagascar' },
  { value: 'Malasia', label: 'Malasia' },
  { value: 'Malaui', label: 'Malaui' },
  { value: 'Maldivas', label: 'Maldivas' },
  { value: 'Mali / Mali', label: 'Mali / Mali' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Marruecos', label: 'Marruecos' },
  { value: 'Islas Marshall', label: 'Islas Marshall' },
  { value: 'Mauricio', label: 'Mauricio' },
  { value: 'Mauritania', label: 'Mauritania' },
  { value: 'Mexico', label: 'Mexico' },
  { value: 'Micronesia', label: 'Micronesia' },
  { value: 'Moldavia', label: 'Moldavia' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Mongolia', label: 'Mongolia' },
  { value: 'Montenegro', label: 'Montenegro' },
  { value: 'Mozambique', label: 'Mozambique' },
  { value: 'Namibia', label: 'Namibia' },
  { value: 'Nauru', label: 'Nauru' },
  { value: 'Nepal', label: 'Nepal' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Niger', label: 'Niger' },
  { value: 'Nigeria', label: 'Nigeria' },
  { value: 'Noruega', label: 'Noruega' },
  {
    value: 'Nueva Zelanda / Nueva Zelandia',
    label: 'Nueva Zelanda / Nueva Zelandia',
  },
  { value: 'Oman', label: 'Oman' },
  { value: 'Paises Bajos', label: 'Paises Bajos' },
  { value: 'Pakistan', label: 'Pakistan' },
  { value: 'Palaos', label: 'Palaos' },
  { value: 'Palestina', label: 'Palestina' },
  { value: 'Panama', label: 'Panama' },
  { value: 'Papua Nueva Guinea', label: 'Papua Nueva Guinea' },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Peru', label: 'Peru' },
  { value: 'Polonia', label: 'Polonia' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Reino Unido', label: 'Reino Unido' },
  { value: 'Ruanda', label: 'Ruanda' },
  { value: 'Rumania / Rumania', label: 'Rumania / Rumania' },
  { value: 'Rusia', label: 'Rusia' },
  { value: 'Islas Salomon', label: 'Islas Salomon' },
  { value: 'Samoa', label: 'Samoa' },
  { value: 'San Cristobal y Nieves', label: 'San Cristobal y Nieves' },
  { value: 'San Marino', label: 'San Marino' },
  {
    value: 'San Vicente y las Granadinas',
    label: 'San Vicente y las Granadinas',
  },
  { value: 'Santa Lucia', label: 'Santa Lucia' },
  { value: 'Santo Tome y Principe', label: 'Santo Tome y Principe' },
  { value: 'Senegal', label: 'Senegal' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Sierra Leona', label: 'Sierra Leona' },
  { value: 'Singapur', label: 'Singapur' },
  { value: 'Siria', label: 'Siria' },
  { value: 'Somalia', label: 'Somalia' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'Suazilandia / Esuatini', label: 'Suazilandia / Esuatini' },
  { value: 'Sudafrica', label: 'Sudafrica' },
  { value: 'Sudan', label: 'Sudan' },
  { value: 'Sudan del Sur', label: 'Sudan del Sur' },
  { value: 'Suecia', label: 'Suecia' },
  { value: 'Suiza', label: 'Suiza' },
  { value: 'Surinam', label: 'Surinam' },
  { value: 'Tailandia', label: 'Tailandia' },
  { value: 'Tanzania', label: 'Tanzania' },
  { value: 'Tayikistan', label: 'Tayikistan' },
  { value: 'Timor Oriental', label: 'Timor Oriental' },
  { value: 'Togo', label: 'Togo' },
  { value: 'Tonga', label: 'Tonga' },
  { value: 'Trinidad y Tobago', label: 'Trinidad y Tobago' },
  { value: 'Tunez', label: 'Tunez' },
  { value: 'Turkmenistan', label: 'Turkmenistan' },
  { value: 'Turquia', label: 'Turquia' },
  { value: 'Tuvalu', label: 'Tuvalu' },
  { value: 'Ucrania', label: 'Ucrania' },
  { value: 'Uganda', label: 'Uganda' },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Uzbekistan', label: 'Uzbekistan' },
  { value: 'Vanuatu', label: 'Vanuatu' },
  { value: 'Ciudad del Vaticano', label: 'Ciudad del Vaticano' },
  { value: 'Venezuela', label: 'Venezuela' },
  { value: 'Vietnam', label: 'Vietnam' },
  { value: 'Yemen', label: 'Yemen' },
  { value: 'Yibuti', label: 'Yibuti' },
  { value: 'Zambia', label: 'Zambia' },
  { value: 'Zimbabue', label: 'Zimbabue' },
];

export const PROVINCIAS = [
  { value: 'San Jose', label: 'San Jose' },
  { value: 'Alajuela', label: 'Alajuela' },
  { value: 'Cartago', label: 'Cartago' },
  { value: 'Heredia', label: 'Heredia' },
  { value: 'Guanacaste', label: 'Guanacaste' },
  { value: 'Puntarenas', label: 'Puntarenas' },
  { value: 'Limon', label: 'Limon' },
];

export const CANTONES = [
  { provincia: 'San Jose', value: 'San Jose', label: 'San Jose' },
  { provincia: 'San Jose', value: 'Escazu', label: 'Escazu' },
  { provincia: 'San Jose', value: 'Desamparados', label: 'Desamparados' },
  { provincia: 'San Jose', value: 'Puriscal', label: 'Puriscal' },
  { provincia: 'San Jose', value: 'Tarrazu', label: 'Tarrazu' },
  { provincia: 'San Jose', value: 'Aserri', label: 'Aserri' },
  { provincia: 'San Jose', value: 'Mora', label: 'Mora' },
  { provincia: 'San Jose', value: 'Goicoechea', label: 'Goicoechea' },
  { provincia: 'San Jose', value: 'Santa Ana', label: 'Santa Ana' },
  { provincia: 'San Jose', value: 'Alajuelita', label: 'Alajuelita' },
  { provincia: 'San Jose', value: 'Coronado', label: 'Coronado' },
  { provincia: 'San Jose', value: 'Acosta', label: 'Acosta' },
  { provincia: 'San Jose', value: 'Tibas', label: 'Tibas' },
  { provincia: 'San Jose', value: 'Moravia', label: 'Moravia' },
  { provincia: 'San Jose', value: 'Montes De Oca', label: 'Montes De Oca' },
  { provincia: 'San Jose', value: 'Turrubares', label: 'Turrubares' },
  { provincia: 'San Jose', value: 'Dota', label: 'Dota' },
  { provincia: 'San Jose', value: 'Curridabat', label: 'Curridabat' },
  { provincia: 'San Jose', value: 'Perez Zeledon', label: 'Perez Zeledon' },
  { provincia: 'San Jose', value: 'Leon Cortes', label: 'Leon Cortes' },
  { provincia: 'Alajuela', value: 'Alajuela', label: 'Alajuela' },
  { provincia: 'Alajuela', value: 'San Ramon', label: 'San Ramon' },
  { provincia: 'Alajuela', value: 'Grecia', label: 'Grecia' },
  { provincia: 'Alajuela', value: 'San Mateo', label: 'San Mateo' },
  { provincia: 'Alajuela', value: 'Atenas', label: 'Atenas' },
  { provincia: 'Alajuela', value: 'Naranjo', label: 'Naranjo' },
  { provincia: 'Alajuela', value: 'Palmares', label: 'Palmares' },
  { provincia: 'Alajuela', value: 'Poas', label: 'Poas' },
  { provincia: 'Alajuela', value: 'Orotina', label: 'Orotina' },
  { provincia: 'Alajuela', value: 'San Carlos', label: 'San Carlos' },
  { provincia: 'Alajuela', value: 'Alfaro Ruiz', label: 'Alfaro Ruiz' },
  { provincia: 'Alajuela', value: 'Valverde Vega', label: 'Valverde Vega' },
  { provincia: 'Alajuela', value: 'Upala', label: 'Upala' },
  { provincia: 'Alajuela', value: 'Los Chiles', label: 'Los Chiles' },
  { provincia: 'Alajuela', value: 'Guatuso', label: 'Guatuso' },
  { provincia: 'Cartago', value: 'Cartago', label: 'Cartago' },
  { provincia: 'Cartago', value: 'Paraiso', label: 'Paraiso' },
  { provincia: 'Cartago', value: 'La Union', label: 'La Union' },
  { provincia: 'Cartago', value: 'Jimenez', label: 'Jimenez' },
  { provincia: 'Cartago', value: 'Turrialba', label: 'Turrialba' },
  { provincia: 'Cartago', value: 'Alvarado', label: 'Alvarado' },
  { provincia: 'Cartago', value: 'Oreamuno', label: 'Oreamuno' },
  { provincia: 'Cartago', value: 'El Guarco', label: 'El Guarco' },
  { provincia: 'Heredia', value: 'Heredia', label: 'Heredia' },
  { provincia: 'Heredia', value: 'Barva', label: 'Barva' },
  { provincia: 'Heredia', value: 'Santo Domingo', label: 'Santo Domingo' },
  { provincia: 'Heredia', value: 'Santa Barbara', label: 'Santa Barbara' },
  { provincia: 'Heredia', value: 'San Rafael', label: 'San Rafael' },
  { provincia: 'Heredia', value: 'San Isidro', label: 'San Isidro' },
  { provincia: 'Heredia', value: 'Belen', label: 'Belen' },
  { provincia: 'Heredia', value: 'Flores', label: 'Flores' },
  { provincia: 'Heredia', value: 'San Pablo', label: 'San Pablo' },
  { provincia: 'Heredia', value: 'Sarapiqui', label: 'Sarapiqui' },
  { provincia: 'Guanacaste', value: 'Liberia', label: 'Liberia' },
  { provincia: 'Guanacaste', value: 'Nicoya', label: 'Nicoya' },
  { provincia: 'Guanacaste', value: 'Santa Cruz', label: 'Santa Cruz' },
  { provincia: 'Guanacaste', value: 'Bagaces', label: 'Bagaces' },
  { provincia: 'Guanacaste', value: 'Carrillo', label: 'Carrillo' },
  { provincia: 'Guanacaste', value: 'Cañas', label: 'Cañas' },
  { provincia: 'Guanacaste', value: 'Abangares', label: 'Abangares' },
  { provincia: 'Guanacaste', value: 'Tilaran', label: 'Tilaran' },
  { provincia: 'Guanacaste', value: 'Nandayure', label: 'Nandayure' },
  { provincia: 'Guanacaste', value: 'La Cruz', label: 'La Cruz' },
  { provincia: 'Guanacaste', value: 'Hojancha', label: 'Hojancha' },
  { provincia: 'Puntarenas', value: 'Puntarenas', label: 'Puntarenas' },
  { provincia: 'Puntarenas', value: 'Esparza', label: 'Esparza' },
  { provincia: 'Puntarenas', value: 'Buenos Aires', label: 'Buenos Aires' },
  { provincia: 'Puntarenas', value: 'Montes De Oro', label: 'Montes De Oro' },
  { provincia: 'Puntarenas', value: 'Osa', label: 'Osa' },
  { provincia: 'Puntarenas', value: 'Aguirre', label: 'Aguirre' },
  { provincia: 'Puntarenas', value: 'Golfito', label: 'Golfito' },
  { provincia: 'Puntarenas', value: 'Coto Brus', label: 'Coto Brus' },
  { provincia: 'Puntarenas', value: 'Parrita', label: 'Parrita' },
  { provincia: 'Puntarenas', value: 'Corredores', label: 'Corredores' },
  { provincia: 'Puntarenas', value: 'Garabito', label: 'Garabito' },
  { provincia: 'Limon', value: 'Limon', label: 'Limon' },
  { provincia: 'Limon', value: 'Pococi', label: 'Pococi' },
  { provincia: 'Limon', value: 'Siquirres', label: 'Siquirres' },
  { provincia: 'Limon', value: 'Talamanca', label: 'Talamanca' },
  { provincia: 'Limon', value: 'Matina', label: 'Matina' },
  { provincia: 'Limon', value: 'Guacimo', label: 'Guacimo' },
];

export const DISTRITOS = [
  { canton: 'San Jose', value: 'Carmen', label: 'Carmen' },
  { canton: 'San Jose', value: 'Merced', label: 'Merced' },
  { canton: 'San Jose', value: 'Hospital', label: 'Hospital' },
  { canton: 'San Jose', value: 'Catedral', label: 'Catedral' },
  { canton: 'San Jose', value: 'Zapote', label: 'Zapote' },
  {
    canton: 'San Jose',
    value: 'San Francisco De Dos Rios',
    label: 'San Francisco De Dos Rios',
  },
  { canton: 'San Jose', value: 'Uruca', label: 'Uruca' },
  { canton: 'San Jose', value: 'Mata Redonda', label: 'Mata Redonda' },
  { canton: 'San Jose', value: 'Pavas', label: 'Pavas' },
  { canton: 'San Jose', value: 'Hatillo', label: 'Hatillo' },
  { canton: 'San Jose', value: 'San Sebastian', label: 'San Sebastian' },
  { canton: 'Escazu', value: 'Escazu', label: 'Escazu' },
  { canton: 'Escazu', value: 'San Antonio', label: 'San Antonio' },
  { canton: 'Escazu', value: 'San Rafael', label: 'San Rafael' },
  { canton: 'Desamparados', value: 'Desamparados', label: 'Desamparados' },
  { canton: 'Desamparados', value: 'San Miguel', label: 'San Miguel' },
  {
    canton: 'Desamparados',
    value: 'San Juan De Dios',
    label: 'San Juan De Dios',
  },
  {
    canton: 'Desamparados',
    value: 'San Rafael Arriba',
    label: 'San Rafael Arriba',
  },
  { canton: 'Desamparados', value: 'San Antonio', label: 'San Antonio' },
  { canton: 'Desamparados', value: 'Frailes', label: 'Frailes' },
  { canton: 'Desamparados', value: 'Patarra', label: 'Patarra' },
  { canton: 'Desamparados', value: 'San Cristobal', label: 'San Cristobal' },
  { canton: 'Desamparados', value: 'Rosario', label: 'Rosario' },
  { canton: 'Desamparados', value: 'Damas', label: 'Damas' },
  {
    canton: 'Desamparados',
    value: 'San Rafael Abajo',
    label: 'San Rafael Abajo',
  },
  { canton: 'Desamparados', value: 'Gravilias', label: 'Gravilias' },
  { canton: 'Desamparados', value: 'Los Guido', label: 'Los Guido' },
  { canton: 'Puriscal', value: 'Santiago', label: 'Santiago' },
  { canton: 'Puriscal', value: 'Mercedes Sur', label: 'Mercedes Sur' },
  { canton: 'Puriscal', value: 'Barbacoas', label: 'Barbacoas' },
  { canton: 'Puriscal', value: 'Grifo Alto', label: 'Grifo Alto' },
  { canton: 'Puriscal', value: 'San Rafael', label: 'San Rafael' },
  { canton: 'Puriscal', value: 'Candelaria', label: 'Candelaria' },
  { canton: 'Puriscal', value: 'Desamparaditos', label: 'Desamparaditos' },
  { canton: 'Puriscal', value: 'San Antonio', label: 'San Antonio' },
  { canton: 'Puriscal', value: 'Chires', label: 'Chires' },
  { canton: 'Tarrazu', value: 'San Marcos', label: 'San Marcos' },
  { canton: 'Tarrazu', value: 'San Lorenzo', label: 'San Lorenzo' },
  { canton: 'Tarrazu', value: 'San Carlos', label: 'San Carlos' },
  { canton: 'Aserri', value: 'Aserri', label: 'Aserri' },
  { canton: 'Aserri', value: 'Tarbaca O Praga', label: 'Tarbaca O Praga' },
  { canton: 'Aserri', value: 'Vuelta De Jorco', label: 'Vuelta De Jorco' },
  { canton: 'Aserri', value: 'San Gabriel', label: 'San Gabriel' },
  { canton: 'Aserri', value: 'La Legua', label: 'La Legua' },
  { canton: 'Aserri', value: 'Monterrey', label: 'Monterrey' },
  { canton: 'Aserri', value: 'Salitrillos', label: 'Salitrillos' },
  { canton: 'Mora', value: 'Colon', label: 'Colon' },
  { canton: 'Mora', value: 'Guayabo', label: 'Guayabo' },
  { canton: 'Mora', value: 'Tabarcia', label: 'Tabarcia' },
  { canton: 'Mora', value: 'Piedras Negras', label: 'Piedras Negras' },
  { canton: 'Mora', value: 'Picagres', label: 'Picagres' },
  { canton: 'Goicoechea', value: 'Guadalupe', label: 'Guadalupe' },
  { canton: 'Goicoechea', value: 'San Francisco', label: 'San Francisco' },
  { canton: 'Goicoechea', value: 'Calle Blancos', label: 'Calle Blancos' },
  { canton: 'Goicoechea', value: 'Mata De Platano', label: 'Mata De Platano' },
  { canton: 'Goicoechea', value: 'Ipis', label: 'Ipis' },
  { canton: 'Goicoechea', value: 'Rancho Redondo', label: 'Rancho Redondo' },
  { canton: 'Goicoechea', value: 'Purral', label: 'Purral' },
  { canton: 'Santa Ana', value: 'Santa Ana', label: 'Santa Ana' },
  { canton: 'Santa Ana', value: 'Salitral', label: 'Salitral' },
  {
    canton: 'Santa Ana',
    value: 'Pozos O Concepcion',
    label: 'Pozos O Concepcion',
  },
  {
    canton: 'Santa Ana',
    value: 'Uruca O San Joaquin',
    label: 'Uruca O San Joaquin',
  },
  { canton: 'Santa Ana', value: 'Piedades', label: 'Piedades' },
  { canton: 'Santa Ana', value: 'Brasil', label: 'Brasil' },
  { canton: 'Alajuelita', value: 'Alajuelita', label: 'Alajuelita' },
  { canton: 'Alajuelita', value: 'San Josecito', label: 'San Josecito' },
  { canton: 'Alajuelita', value: 'San Antonio', label: 'San Antonio' },
  { canton: 'Alajuelita', value: 'Concepcion', label: 'Concepcion' },
  { canton: 'Alajuelita', value: 'San Felipe', label: 'San Felipe' },
  { canton: 'Coronado', value: 'San Isidro', label: 'San Isidro' },
  { canton: 'Coronado', value: 'San Rafael', label: 'San Rafael' },
  {
    canton: 'Coronado',
    value: 'Dulce Nombre O Jesus',
    label: 'Dulce Nombre O Jesus',
  },
  { canton: 'Coronado', value: 'Patalillo', label: 'Patalillo' },
  { canton: 'Coronado', value: 'Cascajal', label: 'Cascajal' },
  { canton: 'Acosta', value: 'San Ignacio', label: 'San Ignacio' },
  { canton: 'Acosta', value: 'Guaitil', label: 'Guaitil' },
  { canton: 'Acosta', value: 'Palmichal', label: 'Palmichal' },
  { canton: 'Acosta', value: 'Cangrejal', label: 'Cangrejal' },
  { canton: 'Acosta', value: 'Sabanillas', label: 'Sabanillas' },
  { canton: 'Tibas', value: 'San Juan', label: 'San Juan' },
  { canton: 'Tibas', value: 'Cinco Esquinas', label: 'Cinco Esquinas' },
  { canton: 'Tibas', value: 'Anselmo Llorente', label: 'Anselmo Llorente' },
  { canton: 'Tibas', value: 'Leon Xiii', label: 'Leon Xiii' },
  { canton: 'Tibas', value: 'Colima', label: 'Colima' },
  { canton: 'Moravia', value: 'San Vicente', label: 'San Vicente' },
  { canton: 'Moravia', value: 'San Jeronimo', label: 'San Jeronimo' },
  { canton: 'Moravia', value: 'La Trinidad', label: 'La Trinidad' },
  { canton: 'Montes De Oca', value: 'San Pedro', label: 'San Pedro' },
  { canton: 'Montes De Oca', value: 'Sabanilla', label: 'Sabanilla' },
  {
    canton: 'Montes De Oca',
    value: 'Mercedes O Betania',
    label: 'Mercedes O Betania',
  },
  { canton: 'Montes De Oca', value: 'San Rafael', label: 'San Rafael' },
  { canton: 'Turrubares', value: 'San Pablo', label: 'San Pablo' },
  { canton: 'Turrubares', value: 'San Pedro', label: 'San Pedro' },
  {
    canton: 'Turrubares',
    value: 'San Juan De Mata',
    label: 'San Juan De Mata',
  },
  { canton: 'Turrubares', value: 'San Luis', label: 'San Luis' },
  { canton: 'Turrubares', value: 'Carara', label: 'Carara' },
  { canton: 'Dota', value: 'Santa Maria', label: 'Santa Maria' },
  { canton: 'Dota', value: 'Jardin', label: 'Jardin' },
  { canton: 'Dota', value: 'Copey', label: 'Copey' },
  { canton: 'Curridabat', value: 'Curridabat', label: 'Curridabat' },
  { canton: 'Curridabat', value: 'Granadilla', label: 'Granadilla' },
  { canton: 'Curridabat', value: 'Sanchez', label: 'Sanchez' },
  { canton: 'Curridabat', value: 'Tirrases', label: 'Tirrases' },
  {
    canton: 'Perez Zeledon',
    value: 'San Isidro De El General',
    label: 'San Isidro De El General',
  },
  { canton: 'Perez Zeledon', value: 'El General', label: 'El General' },
  { canton: 'Perez Zeledon', value: 'Daniel Flores', label: 'Daniel Flores' },
  { canton: 'Perez Zeledon', value: 'Rivas', label: 'Rivas' },
  { canton: 'Perez Zeledon', value: 'San Pedro', label: 'San Pedro' },
  { canton: 'Perez Zeledon', value: 'Platanares', label: 'Platanares' },
  { canton: 'Perez Zeledon', value: 'Pejibaye', label: 'Pejibaye' },
  { canton: 'Perez Zeledon', value: 'Cajon O Carmen', label: 'Cajon O Carmen' },
  { canton: 'Perez Zeledon', value: 'Baru', label: 'Baru' },
  { canton: 'Perez Zeledon', value: 'Rio Nuevo', label: 'Rio Nuevo' },
  { canton: 'Perez Zeledon', value: 'Paramo', label: 'Paramo' },
  { canton: 'Leon Cortes', value: 'San Pablo', label: 'San Pablo' },
  { canton: 'Leon Cortes', value: 'San Andres', label: 'San Andres' },
  { canton: 'Leon Cortes', value: 'Llano Bonito', label: 'Llano Bonito' },
  { canton: 'Leon Cortes', value: 'San Isidro', label: 'San Isidro' },
  { canton: 'Leon Cortes', value: 'Santa Cruz', label: 'Santa Cruz' },
  { canton: 'Leon Cortes', value: 'San Antonio', label: 'San Antonio' },
  { canton: 'Alajuela', value: 'Alajuela', label: 'Alajuela' },
  { canton: 'Alajuela', value: 'San Jose', label: 'San Jose' },
  { canton: 'Alajuela', value: 'Carrizal', label: 'Carrizal' },
  { canton: 'Alajuela', value: 'San Antonio', label: 'San Antonio' },
  { canton: 'Alajuela', value: 'Guacima', label: 'Guacima' },
  { canton: 'Alajuela', value: 'San Isidro', label: 'San Isidro' },
  { canton: 'Alajuela', value: 'Sabanilla', label: 'Sabanilla' },
  { canton: 'Alajuela', value: 'San Rafael', label: 'San Rafael' },
  { canton: 'Alajuela', value: 'Rio Segundo', label: 'Rio Segundo' },
  { canton: 'Alajuela', value: 'Desamparados', label: 'Desamparados' },
  { canton: 'Alajuela', value: 'Turrucares', label: 'Turrucares' },
  { canton: 'Alajuela', value: 'Tambor', label: 'Tambor' },
  { canton: 'Alajuela', value: 'La Garita', label: 'La Garita' },
  { canton: 'Alajuela', value: 'Sarapiqui', label: 'Sarapiqui' },
  { canton: 'San Ramon', value: 'San Ramon', label: 'San Ramon' },
  { canton: 'San Ramon', value: 'Santiago', label: 'Santiago' },
  { canton: 'San Ramon', value: 'San Juan', label: 'San Juan' },
  { canton: 'San Ramon', value: 'Piedades Norte', label: 'Piedades Norte' },
  { canton: 'San Ramon', value: 'Piedades Sur', label: 'Piedades Sur' },
  { canton: 'San Ramon', value: 'San Rafael', label: 'San Rafael' },
  { canton: 'San Ramon', value: 'San Isidro', label: 'San Isidro' },
  { canton: 'San Ramon', value: 'Angeles', label: 'Angeles' },
  { canton: 'San Ramon', value: 'Alfaro', label: 'Alfaro' },
  { canton: 'San Ramon', value: 'Volio', label: 'Volio' },
  { canton: 'San Ramon', value: 'Concepcion', label: 'Concepcion' },
  { canton: 'San Ramon', value: 'Zapotal', label: 'Zapotal' },
  {
    canton: 'San Ramon',
    value: 'San Isidro De Peñas Blancas',
    label: 'San Isidro De Peñas Blancas',
  },
  { canton: 'Grecia', value: 'Grecia', label: 'Grecia' },
  { canton: 'Grecia', value: 'San Isidro', label: 'San Isidro' },
  { canton: 'Grecia', value: 'San Jose', label: 'San Jose' },
  { canton: 'Grecia', value: 'San Roque', label: 'San Roque' },
  { canton: 'Grecia', value: 'Tacares', label: 'Tacares' },
  { canton: 'Grecia', value: 'Rio Cuarto', label: 'Rio Cuarto' },
  { canton: 'Grecia', value: 'Puente Piedra', label: 'Puente Piedra' },
  { canton: 'Grecia', value: 'Bolivar', label: 'Bolivar' },
  { canton: 'San Mateo', value: 'San Mateo', label: 'San Mateo' },
  { canton: 'San Mateo', value: 'Desmonte', label: 'Desmonte' },
  { canton: 'San Mateo', value: 'Jesus Maria', label: 'Jesus Maria' },
  { canton: 'Atenas', value: 'Atenas', label: 'Atenas' },
  { canton: 'Atenas', value: 'Jesus', label: 'Jesus' },
  { canton: 'Atenas', value: 'Mercedes', label: 'Mercedes' },
  { canton: 'Atenas', value: 'San Isidro', label: 'San Isidro' },
  { canton: 'Atenas', value: 'Concepcion', label: 'Concepcion' },
  { canton: 'Atenas', value: 'San Jose', label: 'San Jose' },
  { canton: 'Atenas', value: 'Santa Eulalia', label: 'Santa Eulalia' },
  { canton: 'Atenas', value: 'Escobal', label: 'Escobal' },
  { canton: 'Naranjo', value: 'Naranjo', label: 'Naranjo' },
  { canton: 'Naranjo', value: 'San Miguel', label: 'San Miguel' },
  { canton: 'Naranjo', value: 'San Jose', label: 'San Jose' },
  { canton: 'Naranjo', value: 'Cirri Sur', label: 'Cirri Sur' },
  { canton: 'Naranjo', value: 'San Jeronimo', label: 'San Jeronimo' },
  { canton: 'Naranjo', value: 'San Juan', label: 'San Juan' },
  { canton: 'Naranjo', value: 'Rosario', label: 'Rosario' },
  { canton: 'Palmares', value: 'Palmares', label: 'Palmares' },
  { canton: 'Palmares', value: 'Zaragoza', label: 'Zaragoza' },
  { canton: 'Palmares', value: 'Buenos Aires', label: 'Buenos Aires' },
  { canton: 'Palmares', value: 'Santiago', label: 'Santiago' },
  { canton: 'Palmares', value: 'Candelaria', label: 'Candelaria' },
  { canton: 'Palmares', value: 'Esquipulas', label: 'Esquipulas' },
  { canton: 'Palmares', value: 'La Granja', label: 'La Granja' },
  { canton: 'Poas', value: 'San Pedro', label: 'San Pedro' },
  { canton: 'Poas', value: 'San Juan', label: 'San Juan' },
  { canton: 'Poas', value: 'San Rafael', label: 'San Rafael' },
  { canton: 'Poas', value: 'Carrillos', label: 'Carrillos' },
  { canton: 'Poas', value: 'Sabana Redonda', label: 'Sabana Redonda' },
  { canton: 'Orotina', value: 'Orotina', label: 'Orotina' },
  { canton: 'Orotina', value: 'Mastate', label: 'Mastate' },
  { canton: 'Orotina', value: 'Hacienda Vieja', label: 'Hacienda Vieja' },
  { canton: 'Orotina', value: 'Coyolar', label: 'Coyolar' },
  { canton: 'Orotina', value: 'Ceiba', label: 'Ceiba' },
  { canton: 'San Carlos', value: 'Quesada', label: 'Quesada' },
  { canton: 'San Carlos', value: 'Florencia', label: 'Florencia' },
  { canton: 'San Carlos', value: 'Buenavista', label: 'Buenavista' },
  { canton: 'San Carlos', value: 'Aguas Zarcas', label: 'Aguas Zarcas' },
  { canton: 'San Carlos', value: 'Venecia', label: 'Venecia' },
  { canton: 'San Carlos', value: 'Pital', label: 'Pital' },
  { canton: 'San Carlos', value: 'Fortuna', label: 'Fortuna' },
  { canton: 'San Carlos', value: 'Tigra', label: 'Tigra' },
  { canton: 'San Carlos', value: 'Palmera', label: 'Palmera' },
  { canton: 'San Carlos', value: 'Venado', label: 'Venado' },
  { canton: 'San Carlos', value: 'Cutris', label: 'Cutris' },
  { canton: 'San Carlos', value: 'Monterrey', label: 'Monterrey' },
  { canton: 'San Carlos', value: 'Pocosol', label: 'Pocosol' },
  { canton: 'Alfaro Ruiz', value: 'Zarcero', label: 'Zarcero' },
  { canton: 'Alfaro Ruiz', value: 'Laguna', label: 'Laguna' },
  { canton: 'Alfaro Ruiz', value: 'Tapezco', label: 'Tapezco' },
  { canton: 'Alfaro Ruiz', value: 'Guadalupe', label: 'Guadalupe' },
  { canton: 'Alfaro Ruiz', value: 'Palmira', label: 'Palmira' },
  { canton: 'Alfaro Ruiz', value: 'Zapote', label: 'Zapote' },
  { canton: 'Alfaro Ruiz', value: 'Brisas', label: 'Brisas' },
  { canton: 'Valverde Vega', value: 'Sarchi Norte', label: 'Sarchi Norte' },
  { canton: 'Valverde Vega', value: 'Sarchi Sur', label: 'Sarchi Sur' },
  { canton: 'Valverde Vega', value: 'Toro Amarillo', label: 'Toro Amarillo' },
  { canton: 'Valverde Vega', value: 'San Pedro', label: 'San Pedro' },
  { canton: 'Valverde Vega', value: 'Rodriguez', label: 'Rodriguez' },
  { canton: 'Upala', value: 'Upala', label: 'Upala' },
  { canton: 'Upala', value: 'Aguas Claras', label: 'Aguas Claras' },
  { canton: 'Upala', value: 'San Jose O Pizote', label: 'San Jose O Pizote' },
  { canton: 'Upala', value: 'Bijagua', label: 'Bijagua' },
  { canton: 'Upala', value: 'Delicias', label: 'Delicias' },
  { canton: 'Upala', value: 'Dos Rios', label: 'Dos Rios' },
  { canton: 'Upala', value: 'Yolillal', label: 'Yolillal' },
  { canton: 'Los Chiles', value: 'Los Chiles', label: 'Los Chiles' },
  { canton: 'Los Chiles', value: 'Caño Negro', label: 'Caño Negro' },
  { canton: 'Los Chiles', value: 'Amparo', label: 'Amparo' },
  { canton: 'Los Chiles', value: 'San Jorge', label: 'San Jorge' },
  { canton: 'Guatuso', value: 'San Rafael', label: 'San Rafael' },
  { canton: 'Guatuso', value: 'Buenavista', label: 'Buenavista' },
  { canton: 'Guatuso', value: 'Cote', label: 'Cote' },
  { canton: 'Cartago', value: 'Oriental', label: 'Oriental' },
  { canton: 'Cartago', value: 'Occidental', label: 'Occidental' },
  { canton: 'Cartago', value: 'Carmen', label: 'Carmen' },
  { canton: 'Cartago', value: 'San Nicolas', label: 'San Nicolas' },
  {
    canton: 'Cartago',
    value: 'Aguacaliente (San Francisco)',
    label: 'Aguacaliente (San Francisco)',
  },
  {
    canton: 'Cartago',
    value: 'Guadalupe (Arenilla)',
    label: 'Guadalupe (Arenilla)',
  },
  { canton: 'Cartago', value: 'Corralillo', label: 'Corralillo' },
  { canton: 'Cartago', value: 'Tierra Blanca', label: 'Tierra Blanca' },
  { canton: 'Cartago', value: 'Dulce Nombre', label: 'Dulce Nombre' },
  { canton: 'Cartago', value: 'Llano Grande', label: 'Llano Grande' },
  { canton: 'Cartago', value: 'Quebradilla', label: 'Quebradilla' },
  { canton: 'Paraiso', value: 'Paraiso', label: 'Paraiso' },
  { canton: 'Paraiso', value: 'Santiago', label: 'Santiago' },
  { canton: 'Paraiso', value: 'Orosi', label: 'Orosi' },
  { canton: 'Paraiso', value: 'Cachi', label: 'Cachi' },
  {
    canton: 'Paraiso',
    value: 'Llanos De Sta Lucia',
    label: 'Llanos De Sta Lucia',
  },
  { canton: 'La Union', value: 'Tres Rios', label: 'Tres Rios' },
  { canton: 'La Union', value: 'San Diego', label: 'San Diego' },
  { canton: 'La Union', value: 'San Juan', label: 'San Juan' },
  { canton: 'La Union', value: 'San Rafael', label: 'San Rafael' },
  { canton: 'La Union', value: 'Concepcion', label: 'Concepcion' },
  { canton: 'La Union', value: 'Dulce Nombre', label: 'Dulce Nombre' },
  { canton: 'La Union', value: 'San Ramon', label: 'San Ramon' },
  { canton: 'La Union', value: 'Rio Azul', label: 'Rio Azul' },
  { canton: 'Jimenez', value: 'Juan Viñas', label: 'Juan Viñas' },
  { canton: 'Jimenez', value: 'Tucurrique', label: 'Tucurrique' },
  { canton: 'Jimenez', value: 'Pejibaye', label: 'Pejibaye' },
  { canton: 'Turrialba', value: 'Turrialba', label: 'Turrialba' },
  { canton: 'Turrialba', value: 'La Suiza', label: 'La Suiza' },
  { canton: 'Turrialba', value: 'Peralta', label: 'Peralta' },
  { canton: 'Turrialba', value: 'Santa Cruz', label: 'Santa Cruz' },
  { canton: 'Turrialba', value: 'Santa Teresita', label: 'Santa Teresita' },
  { canton: 'Turrialba', value: 'Pavones', label: 'Pavones' },
  { canton: 'Turrialba', value: 'Tuis', label: 'Tuis' },
  { canton: 'Turrialba', value: 'Tayutic', label: 'Tayutic' },
  { canton: 'Turrialba', value: 'Santa Rosa', label: 'Santa Rosa' },
  { canton: 'Turrialba', value: 'Tres Equis', label: 'Tres Equis' },
  { canton: 'Turrialba', value: 'La Isabel', label: 'La Isabel' },
  { canton: 'Turrialba', value: 'Chirripo', label: 'Chirripo' },
  { canton: 'Alvarado', value: 'Pacayas', label: 'Pacayas' },
  { canton: 'Alvarado', value: 'Cervantes', label: 'Cervantes' },
  { canton: 'Alvarado', value: 'Capellades', label: 'Capellades' },
  { canton: 'Oreamuno', value: 'San Rafael', label: 'San Rafael' },
  { canton: 'Oreamuno', value: 'Cot', label: 'Cot' },
  { canton: 'Oreamuno', value: 'Potrero Cerrado', label: 'Potrero Cerrado' },
  { canton: 'Oreamuno', value: 'Cipreses', label: 'Cipreses' },
  { canton: 'Oreamuno', value: 'Santa Rosa', label: 'Santa Rosa' },
  { canton: 'El Guarco', value: 'El Tejar', label: 'El Tejar' },
  { canton: 'El Guarco', value: 'San Isidro', label: 'San Isidro' },
  { canton: 'El Guarco', value: 'Tobosi', label: 'Tobosi' },
  { canton: 'El Guarco', value: 'Patio De Agua', label: 'Patio De Agua' },
  { canton: 'Heredia', value: 'Heredia', label: 'Heredia' },
  { canton: 'Heredia', value: 'Mercedes', label: 'Mercedes' },
  { canton: 'Heredia', value: 'San Francisco', label: 'San Francisco' },
  { canton: 'Heredia', value: 'Ulloa', label: 'Ulloa' },
  { canton: 'Heredia', value: 'Vara Blanca', label: 'Vara Blanca' },
  { canton: 'Barva', value: 'Barva', label: 'Barva' },
  { canton: 'Barva', value: 'San Pedro', label: 'San Pedro' },
  { canton: 'Barva', value: 'San Pablo', label: 'San Pablo' },
  { canton: 'Barva', value: 'San Roque', label: 'San Roque' },
  { canton: 'Barva', value: 'Santa Lucia', label: 'Santa Lucia' },
  {
    canton: 'Barva',
    value: 'San Jose De La Montaña',
    label: 'San Jose De La Montaña',
  },
  { canton: 'Santo Domingo', value: 'Santo Domingo', label: 'Santo Domingo' },
  { canton: 'Santo Domingo', value: 'San Vicente', label: 'San Vicente' },
  { canton: 'Santo Domingo', value: 'San Miguel', label: 'San Miguel' },
  { canton: 'Santo Domingo', value: 'Paracito', label: 'Paracito' },
  { canton: 'Santo Domingo', value: 'Santo Tomas', label: 'Santo Tomas' },
  { canton: 'Santo Domingo', value: 'Santa Rosa', label: 'Santa Rosa' },
  { canton: 'Santo Domingo', value: 'Tures', label: 'Tures' },
  { canton: 'Santo Domingo', value: 'Para', label: 'Para' },
  { canton: 'Santa Barbara', value: 'Santa Barbara', label: 'Santa Barbara' },
  { canton: 'Santa Barbara', value: 'San Pedro', label: 'San Pedro' },
  { canton: 'Santa Barbara', value: 'San Juan', label: 'San Juan' },
  { canton: 'Santa Barbara', value: 'Jesus', label: 'Jesus' },
  {
    canton: 'Santa Barbara',
    value: 'Santo Domingo Del Roble',
    label: 'Santo Domingo Del Roble',
  },
  { canton: 'Santa Barbara', value: 'Puraba', label: 'Puraba' },
  { canton: 'San Rafael', value: 'San Rafael', label: 'San Rafael' },
  { canton: 'San Rafael', value: 'San Josecito', label: 'San Josecito' },
  { canton: 'San Rafael', value: 'Santiago', label: 'Santiago' },
  { canton: 'San Rafael', value: 'Angeles', label: 'Angeles' },
  { canton: 'San Rafael', value: 'Concepcion', label: 'Concepcion' },
  { canton: 'San Isidro', value: 'San Isidro', label: 'San Isidro' },
  { canton: 'San Isidro', value: 'San Jose', label: 'San Jose' },
  { canton: 'San Isidro', value: 'Concepcion', label: 'Concepcion' },
  { canton: 'San Isidro', value: 'San Francisco', label: 'San Francisco' },
  { canton: 'Belen', value: 'San Antonio', label: 'San Antonio' },
  { canton: 'Belen', value: 'La Rivera', label: 'La Rivera' },
  { canton: 'Belen', value: 'Asuncion', label: 'Asuncion' },
  { canton: 'Flores', value: 'San Joaquin', label: 'San Joaquin' },
  { canton: 'Flores', value: 'Barrantes', label: 'Barrantes' },
  { canton: 'Flores', value: 'Llorente', label: 'Llorente' },
  { canton: 'San Pablo', value: 'San Pablo', label: 'San Pablo' },
  { canton: 'Sarapiqui', value: 'Puerto Viejo', label: 'Puerto Viejo' },
  { canton: 'Sarapiqui', value: 'La Virgen', label: 'La Virgen' },
  { canton: 'Sarapiqui', value: 'Horquetas', label: 'Horquetas' },
  {
    canton: 'Sarapiqui',
    value: 'Llanuras Del Gaspar',
    label: 'Llanuras Del Gaspar',
  },
  { canton: 'Sarapiqui', value: 'Cureña', label: 'Cureña' },
  { canton: 'Liberia', value: 'Liberia', label: 'Liberia' },
  { canton: 'Liberia', value: 'Cañas Dulces', label: 'Cañas Dulces' },
  { canton: 'Liberia', value: 'Mayorga', label: 'Mayorga' },
  { canton: 'Liberia', value: 'Nacascolo', label: 'Nacascolo' },
  { canton: 'Liberia', value: 'Curubande', label: 'Curubande' },
  { canton: 'Nicoya', value: 'Nicoya', label: 'Nicoya' },
  { canton: 'Nicoya', value: 'Mansion', label: 'Mansion' },
  { canton: 'Nicoya', value: 'San Antonio', label: 'San Antonio' },
  { canton: 'Nicoya', value: 'Quebrada Honda', label: 'Quebrada Honda' },
  { canton: 'Nicoya', value: 'Samara', label: 'Samara' },
  { canton: 'Nicoya', value: 'Nosara', label: 'Nosara' },
  { canton: 'Nicoya', value: 'Belen De Nosarita', label: 'Belen De Nosarita' },
  { canton: 'Santa Cruz', value: 'Santa Cruz', label: 'Santa Cruz' },
  { canton: 'Santa Cruz', value: 'Bolson', label: 'Bolson' },
  {
    canton: 'Santa Cruz',
    value: 'Veintisiete De Abril',
    label: 'Veintisiete De Abril',
  },
  { canton: 'Santa Cruz', value: 'Tempate', label: 'Tempate' },
  { canton: 'Santa Cruz', value: 'Cartagena', label: 'Cartagena' },
  { canton: 'Santa Cruz', value: 'Cuajiniquil', label: 'Cuajiniquil' },
  { canton: 'Santa Cruz', value: 'Diria', label: 'Diria' },
  { canton: 'Santa Cruz', value: 'Cabo Velas', label: 'Cabo Velas' },
  { canton: 'Santa Cruz', value: 'Tamarindo', label: 'Tamarindo' },
  { canton: 'Bagaces', value: 'Bagaces', label: 'Bagaces' },
  { canton: 'Bagaces', value: 'Fortuna', label: 'Fortuna' },
  { canton: 'Bagaces', value: 'Mogote', label: 'Mogote' },
  { canton: 'Bagaces', value: 'Rio Naranjo', label: 'Rio Naranjo' },
  { canton: 'Carrillo', value: 'Filadelfia', label: 'Filadelfia' },
  { canton: 'Carrillo', value: 'Palmira', label: 'Palmira' },
  { canton: 'Carrillo', value: 'Sardinal', label: 'Sardinal' },
  { canton: 'Carrillo', value: 'Belen', label: 'Belen' },
  { canton: 'Cañas', value: 'Cañas', label: 'Cañas' },
  { canton: 'Cañas', value: 'Palmira', label: 'Palmira' },
  { canton: 'Cañas', value: 'San Miguel', label: 'San Miguel' },
  { canton: 'Cañas', value: 'Bebedero', label: 'Bebedero' },
  { canton: 'Cañas', value: 'Porozal', label: 'Porozal' },
  { canton: 'Abangares', value: 'Juntas', label: 'Juntas' },
  { canton: 'Abangares', value: 'Sierra', label: 'Sierra' },
  { canton: 'Abangares', value: 'San Juan', label: 'San Juan' },
  { canton: 'Abangares', value: 'Colorado', label: 'Colorado' },
  { canton: 'Tilaran', value: 'Tilaran', label: 'Tilaran' },
  { canton: 'Tilaran', value: 'Quebrada Grande', label: 'Quebrada Grande' },
  { canton: 'Tilaran', value: 'Tronadora', label: 'Tronadora' },
  { canton: 'Tilaran', value: 'Santa Rosa', label: 'Santa Rosa' },
  { canton: 'Tilaran', value: 'Libano', label: 'Libano' },
  { canton: 'Tilaran', value: 'Tierras Morenas', label: 'Tierras Morenas' },
  { canton: 'Tilaran', value: 'Arenal', label: 'Arenal' },
  { canton: 'Nandayure', value: 'Carmona', label: 'Carmona' },
  { canton: 'Nandayure', value: 'Santa Rita', label: 'Santa Rita' },
  { canton: 'Nandayure', value: 'Zapotal', label: 'Zapotal' },
  { canton: 'Nandayure', value: 'San Pablo', label: 'San Pablo' },
  { canton: 'Nandayure', value: 'Porvenir', label: 'Porvenir' },
  { canton: 'Nandayure', value: 'Bejuco', label: 'Bejuco' },
  { canton: 'La Cruz', value: 'La Cruz', label: 'La Cruz' },
  { canton: 'La Cruz', value: 'Santa Cecilia', label: 'Santa Cecilia' },
  { canton: 'La Cruz', value: 'Garita', label: 'Garita' },
  { canton: 'La Cruz', value: 'Santa Elena', label: 'Santa Elena' },
  { canton: 'Hojancha', value: 'Hojancha', label: 'Hojancha' },
  { canton: 'Hojancha', value: 'Monte Romo', label: 'Monte Romo' },
  { canton: 'Hojancha', value: 'Puerto Carrillo', label: 'Puerto Carrillo' },
  { canton: 'Hojancha', value: 'Huacas', label: 'Huacas' },
  { canton: 'Puntarenas', value: 'Puntarenas', label: 'Puntarenas' },
  { canton: 'Puntarenas', value: 'Pitahaya', label: 'Pitahaya' },
  { canton: 'Puntarenas', value: 'Chomes', label: 'Chomes' },
  { canton: 'Puntarenas', value: 'Lepanto', label: 'Lepanto' },
  { canton: 'Puntarenas', value: 'Paquera', label: 'Paquera' },
  { canton: 'Puntarenas', value: 'Manzanillo', label: 'Manzanillo' },
  { canton: 'Puntarenas', value: 'Guacimal', label: 'Guacimal' },
  { canton: 'Puntarenas', value: 'Barranca', label: 'Barranca' },
  { canton: 'Puntarenas', value: 'Monte Verde', label: 'Monte Verde' },
  { canton: 'Puntarenas', value: 'Isla Del Coco', label: 'Isla Del Coco' },
  { canton: 'Puntarenas', value: 'Cobano', label: 'Cobano' },
  { canton: 'Puntarenas', value: 'Chacarita', label: 'Chacarita' },
  { canton: 'Puntarenas', value: 'Chira (Isla)', label: 'Chira (Isla)' },
  { canton: 'Puntarenas', value: 'Acapulco', label: 'Acapulco' },
  { canton: 'Puntarenas', value: 'El Roble', label: 'El Roble' },
  { canton: 'Puntarenas', value: 'Arancibia', label: 'Arancibia' },
  { canton: 'Esparza', value: 'Espiritu Santo', label: 'Espiritu Santo' },
  { canton: 'Esparza', value: 'San Juan Grande', label: 'San Juan Grande' },
  { canton: 'Esparza', value: 'Macacona', label: 'Macacona' },
  { canton: 'Esparza', value: 'San Rafael', label: 'San Rafael' },
  { canton: 'Esparza', value: 'San Jeronimo', label: 'San Jeronimo' },
  { canton: 'Buenos Aires', value: 'Buenos Aires', label: 'Buenos Aires' },
  { canton: 'Buenos Aires', value: 'Volcan', label: 'Volcan' },
  { canton: 'Buenos Aires', value: 'Potrero Grande', label: 'Potrero Grande' },
  { canton: 'Buenos Aires', value: 'Boruca', label: 'Boruca' },
  { canton: 'Buenos Aires', value: 'Pilas', label: 'Pilas' },
  {
    canton: 'Buenos Aires',
    value: 'Colinas O Bajo De Maiz',
    label: 'Colinas O Bajo De Maiz',
  },
  { canton: 'Buenos Aires', value: 'Changuena', label: 'Changuena' },
  { canton: 'Buenos Aires', value: 'Bioley', label: 'Bioley' },
  { canton: 'Buenos Aires', value: 'Brunka', label: 'Brunka' },
  { canton: 'Montes De Oro', value: 'Miramar', label: 'Miramar' },
  { canton: 'Montes De Oro', value: 'Union', label: 'Union' },
  { canton: 'Montes De Oro', value: 'San Isidro', label: 'San Isidro' },
  { canton: 'Osa', value: 'Puerto Cortes', label: 'Puerto Cortes' },
  { canton: 'Osa', value: 'Palmar', label: 'Palmar' },
  { canton: 'Osa', value: 'Sierpe', label: 'Sierpe' },
  { canton: 'Osa', value: 'Bahia Ballena', label: 'Bahia Ballena' },
  { canton: 'Osa', value: 'Piedras Blancas', label: 'Piedras Blancas' },
  { canton: 'Aguirre', value: 'Quepos', label: 'Quepos' },
  { canton: 'Aguirre', value: 'Savegre', label: 'Savegre' },
  { canton: 'Aguirre', value: 'Naranjito', label: 'Naranjito' },
  { canton: 'Golfito', value: 'Golfito', label: 'Golfito' },
  { canton: 'Golfito', value: 'Puerto Jimenez', label: 'Puerto Jimenez' },
  { canton: 'Golfito', value: 'Guaycara', label: 'Guaycara' },
  {
    canton: 'Golfito',
    value: 'Pavones O Villa Conte',
    label: 'Pavones O Villa Conte',
  },
  { canton: 'Coto Brus', value: 'San Vito', label: 'San Vito' },
  { canton: 'Coto Brus', value: 'Sabalito', label: 'Sabalito' },
  { canton: 'Coto Brus', value: 'Agua Buena', label: 'Agua Buena' },
  { canton: 'Coto Brus', value: 'Limoncito', label: 'Limoncito' },
  { canton: 'Coto Brus', value: 'Pittier', label: 'Pittier' },
  { canton: 'Parrita', value: 'Parrita', label: 'Parrita' },
  { canton: 'Corredores', value: 'Corredores', label: 'Corredores' },
  { canton: 'Corredores', value: 'La Cuesta', label: 'La Cuesta' },
  { canton: 'Corredores', value: 'Paso Canoas', label: 'Paso Canoas' },
  { canton: 'Corredores', value: 'Laurel', label: 'Laurel' },
  { canton: 'Garabito', value: 'Jaco', label: 'Jaco' },
  { canton: 'Garabito', value: 'Tarcoles', label: 'Tarcoles' },
  { canton: 'Limon', value: 'Limon', label: 'Limon' },
  { canton: 'Limon', value: 'Valle La Estrella', label: 'Valle La Estrella' },
  { canton: 'Limon', value: 'Rio Blanco', label: 'Rio Blanco' },
  { canton: 'Limon', value: 'Matama', label: 'Matama' },
  { canton: 'Pococi', value: 'Guapiles', label: 'Guapiles' },
  { canton: 'Pococi', value: 'Jimenez', label: 'Jimenez' },
  { canton: 'Pococi', value: 'Rita', label: 'Rita' },
  { canton: 'Pococi', value: 'Roxana', label: 'Roxana' },
  { canton: 'Pococi', value: 'Cariari', label: 'Cariari' },
  { canton: 'Pococi', value: 'Colorado', label: 'Colorado' },
  { canton: 'Siquirres', value: 'Siquirres', label: 'Siquirres' },
  { canton: 'Siquirres', value: 'Pacuarito', label: 'Pacuarito' },
  { canton: 'Siquirres', value: 'Florida', label: 'Florida' },
  { canton: 'Siquirres', value: 'Germania', label: 'Germania' },
  { canton: 'Siquirres', value: 'Cairo', label: 'Cairo' },
  { canton: 'Siquirres', value: 'Alegria', label: 'Alegria' },
  { canton: 'Talamanca', value: 'Bratsi', label: 'Bratsi' },
  { canton: 'Talamanca', value: 'Sixaola', label: 'Sixaola' },
  { canton: 'Talamanca', value: 'Cahuita', label: 'Cahuita' },
  { canton: 'Talamanca', value: 'Telire', label: 'Telire' },
  { canton: 'Matina', value: 'Matina', label: 'Matina' },
  { canton: 'Matina', value: 'Batan', label: 'Batan' },
  { canton: 'Matina', value: 'Carrandi', label: 'Carrandi' },
  { canton: 'Guacimo', value: 'Guacimo', label: 'Guacimo' },
  { canton: 'Guacimo', value: 'Mercedes', label: 'Mercedes' },
  { canton: 'Guacimo', value: 'Pocora', label: 'Pocora' },
  { canton: 'Guacimo', value: 'Rio Jimenez', label: 'Rio Jimenez' },
  { canton: 'Guacimo', value: 'Duacari', label: 'Duacari' },
];

export const TIPOS_IDENTIFICACION = [
  { value: 'Identificacion nacional', label: 'Identificacion nacional' },
  { value: 'Cedula juridica', label: 'Cedula juridica' },
  { value: 'Pasaporte', label: 'Pasaporte' },
  { value: 'DIMEX', label: 'DIMEX' },
  { value: 'NITE', label: 'NITE' },
  { value: 'Menor de edad', label: 'Menor de edad' },
  { value: 'Entidad extranjera', label: 'Entidad extranjera' },
];

export const ESTADOS_CIVILES = [
  { value: 'Casado (a)', label: 'Casado (a)' },
  { value: 'Soltero (a)', label: 'Soltero (a)' },
  { value: 'Divorciado (a)', label: 'Divorciado (a)' },
  { value: 'Viudo (a) ', label: 'Viudo (a) ' },
  { value: 'Union libre', label: 'Union libre' },
];

export const GRADOS_ACADEMICOS = [
  { value: 'Bachillerato universitario', label: 'Bachillerato universitario' },
  { value: 'Doctorado', label: 'Doctorado' },
  { value: 'Post grado', label: 'Post grado' },
  { value: 'Licenciatura', label: 'Licenciatura' },
  { value: 'Maestria', label: 'Maestria' },
  { value: 'Ninguno', label: 'Ninguno' },
  { value: 'Diplomado', label: 'Diplomado' },
  { value: 'Primaria', label: 'Primaria' },
  { value: 'Secundaria', label: 'Secundaria' },
  { value: 'Tecnico', label: 'Tecnico' },
];

export const PROFESIONES = [
  { value: 'Actor', label: 'Actor' },
  { value: 'Administracion tributaria', label: 'Administracion tributaria' },
  { value: 'Administrador de empresas', label: 'Administrador de empresas' },
  { value: 'Administrador de sistemas', label: 'Administrador de sistemas' },
  { value: 'Agente de aduana', label: 'Agente de aduana' },
  { value: 'Agente de call center', label: 'Agente de call center' },
  { value: 'Agente de proveeduria', label: 'Agente de proveeduria' },
  { value: 'Agente de seguros', label: 'Agente de seguros' },
  { value: 'Agricultor', label: 'Agricultor' },
  { value: 'Albañil', label: 'Albañil' },
  { value: 'Alcalde', label: 'Alcalde' },
  { value: 'Alfarero', label: 'Alfarero' },
  { value: 'Analista de credito', label: 'Analista de credito' },
  { value: 'Analista financiero', label: 'Analista financiero' },
  { value: 'Anestesiologo', label: 'Anestesiologo' },
  { value: 'Animador de eventos', label: 'Animador de eventos' },
  { value: 'Antropologo', label: 'Antropologo' },
  { value: 'Arquitecto', label: 'Arquitecto' },
  { value: 'Artesano', label: 'Artesano' },
  { value: 'Asesor financiero', label: 'Asesor financiero' },
  {
    value: 'Asistente consultorio medico',
    label: 'Asistente consultorio medico',
  },
  { value: 'Asistente contable', label: 'Asistente contable' },
  { value: 'Asistente de departamento', label: 'Asistente de departamento' },
  { value: 'Asistente legal', label: 'Asistente legal' },
  { value: 'Astronomo', label: 'Astronomo' },
  { value: 'Audiologo', label: 'Audiologo' },
  { value: 'Auxiliar de contabilidad', label: 'Auxiliar de contabilidad' },
  { value: 'Auxiliar dental', label: 'Auxiliar dental' },
  { value: 'Auxiliar departamento', label: 'Auxiliar departamento' },
  { value: 'Ayudante de ambulancia', label: 'Ayudante de ambulancia' },
  { value: 'Ayudante de cocina', label: 'Ayudante de cocina' },
  { value: 'Ayudante de construccion', label: 'Ayudante de construccion' },
  { value: 'Ayudante mecanico general', label: 'Ayudante mecanico general' },
  { value: 'Barbero', label: 'Barbero' },
  { value: 'Barista', label: 'Barista' },
  { value: 'Bartender', label: 'Bartender' },
  { value: 'Biologo', label: 'Biologo' },
  { value: 'Bodeguero', label: 'Bodeguero' },
  { value: 'Bombero', label: 'Bombero' },
  { value: 'Botanico', label: 'Botanico' },
  { value: 'Cajero', label: 'Cajero' },
  { value: 'Camarografo de prensa', label: 'Camarografo de prensa' },
  { value: 'Cantante', label: 'Cantante' },
  { value: 'Cantinero', label: 'Cantinero' },
  { value: 'Carnicero', label: 'Carnicero' },
  { value: 'Carpintero', label: 'Carpintero' },
  { value: 'Cerrajero', label: 'Cerrajero' },
  {
    value: 'Chequeador de agencia aduanal',
    label: 'Chequeador de agencia aduanal',
  },
  { value: 'Chequeador de buses', label: 'Chequeador de buses' },
  { value: 'Chofer de bus', label: 'Chofer de bus' },
  { value: 'Chofer de microbus', label: 'Chofer de microbus' },
  { value: 'Chofer de trailer', label: 'Chofer de trailer' },
  { value: 'Chofer de vehiculo liviano', label: 'Chofer de vehiculo liviano' },
  { value: 'Chofer de vehiculo pesado', label: 'Chofer de vehiculo pesado' },
  { value: 'Cobrador motorizado', label: 'Cobrador motorizado' },
  { value: 'Cocinero', label: 'Cocinero' },
  { value: 'Contador', label: 'Contador' },
  { value: 'Contador privado', label: 'Contador privado' },
  {
    value: 'Contratista de mano de obra',
    label: 'Contratista de mano de obra',
  },
  {
    value: 'Controlador de trafico aereo',
    label: 'Controlador de trafico aereo',
  },
  {
    value: 'Controlador de trafico maritimo',
    label: 'Controlador de trafico maritimo',
  },
  { value: 'Costurera/O', label: 'Costurera/O' },
  { value: 'Criminologo', label: 'Criminologo' },
  { value: 'Demostrador', label: 'Demostrador' },
  { value: 'Dentista', label: 'Dentista' },
  { value: 'Dependiente', label: 'Dependiente' },
  { value: 'Deportista', label: 'Deportista' },
  { value: 'Desarrollador de software', label: 'Desarrollador de software' },
  {
    value: 'Desarrollador web y multimedia',
    label: 'Desarrollador web y multimedia',
  },
  { value: 'Dibujante tecnico', label: 'Dibujante tecnico' },
  { value: 'Digitador', label: 'Digitador' },
  {
    value: 'Director de servi de bienestar',
    label: 'Director de servi de bienestar',
  },
  {
    value: 'Dir ser cuido para adultos may',
    label: 'Dir ser cuido para adultos may',
  },
  {
    value: 'Director de servicios de salud',
    label: 'Director de servicios de salud',
  },
  { value: 'Director de teatro / cine', label: 'Director de teatro / cine' },
  { value: 'Diseñador de productos', label: 'Diseñador de productos' },
  {
    value: 'Diseñador y administrador de bd',
    label: 'Diseñador y administrador de bd',
  },
  {
    value: 'Diseñador y decorador interior',
    label: 'Diseñador y decorador interior',
  },
  { value: 'Ebanista', label: 'Ebanista' },
  { value: 'Economista', label: 'Economista' },
  { value: 'Ejecutivo de analisis', label: 'Ejecutivo de analisis' },
  { value: 'Ejecutivo de credito', label: 'Ejecutivo de credito' },
  { value: 'Electricista', label: 'Electricista' },
  { value: 'Electromecanico', label: 'Electromecanico' },
  { value: 'Empacador', label: 'Empacador' },
  { value: 'Empleada domestica', label: 'Empleada domestica' },
  {
    value: 'Encarg de camaras frigorificas',
    label: 'Encarg de camaras frigorificas',
  },
  { value: 'Encargado de dpto.', label: 'Encargado de dpto.' },
  {
    value: 'Enc mantenimiento de edificios',
    label: 'Enc mantenimiento de edificios',
  },
  { value: 'Encuestador', label: 'Encuestador' },
  { value: 'Enderezador automotriz', label: 'Enderezador automotriz' },
  { value: 'Enfermero', label: 'Enfermero' },
  {
    value: 'Ensamblador de computadoras',
    label: 'Ensamblador de computadoras',
  },
  {
    value: 'Entrenador/instructor act. dep',
    label: 'Entrenador/instructor act. dep',
  },
  { value: 'Esparcidor De Plaguicidas', label: 'Esparcidor de plaguicidas' },
  { value: 'Estadisitico', label: 'Estadisitico' },
  { value: 'Esteticista', label: 'Esteticista' },
  { value: 'Estilista', label: 'Estilista' },
  { value: 'Farmaceutico', label: 'Farmaceutico' },
  { value: 'Filologo', label: 'Filologo' },
  { value: 'Filosofo', label: 'Filosofo' },
  { value: 'Fisico', label: 'Fisico' },
  { value: 'Fisioterapeuta', label: 'Fisioterapeuta' },
  { value: 'Florista', label: 'Florista' },
  { value: 'Fontanero', label: 'Fontanero' },
  { value: 'Fotocopiador', label: 'Fotocopiador' },
  { value: 'Fotografo de prensa', label: 'Fotografo de prensa' },
  { value: 'Fotografo', label: 'Fotografo' },
  { value: 'Fresador', label: 'Fresador' },
  { value: 'Fumigador', label: 'Fumigador' },
  { value: 'Ganadero', label: 'Ganadero' },
  { value: 'Geofisico', label: 'Geofisico' },
  { value: 'Geologo', label: 'Geologo' },
  { value: 'Gerente de sucursal', label: 'Gerente de sucursal' },
  { value: 'Gerente de zona', label: 'Gerente de zona' },
  { value: 'Gerente departamento', label: 'Gerente departamento' },
  { value: 'Gerente general', label: 'Gerente general' },
  { value: 'Gestor de cobro', label: 'Gestor de cobro' },
  { value: 'Ginecologo', label: 'Ginecologo' },
  { value: 'Gondolero', label: 'Gondolero' },
  { value: 'Guarda custodio valores', label: 'Guarda custodio valores' },
  { value: 'Guia turistico', label: 'Guia turistico' },
  { value: 'Historiador', label: 'Historiador' },
  { value: 'Hojalatero', label: 'Hojalatero' },
  { value: 'Horneador de alimentos', label: 'Horneador de alimentos' },
  { value: 'Ingeniero agronomo', label: 'Ingeniero agronomo' },
  { value: 'Ingeniero biomedico', label: 'Ingeniero biomedico' },
  { value: 'Ingeniero civil', label: 'Ingeniero civil' },
  { value: 'Ingeniero de transito', label: 'Ingeniero de transito' },
  { value: 'Ingeniero electrico', label: 'Ingeniero electrico' },
  { value: 'Ingeniero electronico', label: 'Ingeniero electronico' },
  { value: 'Ingeniero en computacion', label: 'Ingeniero en computacion' },
  { value: 'Ingeniero en materiales', label: 'Ingeniero en materiales' },
  { value: 'Ingeniero en minas', label: 'Ingeniero en minas' },
  {
    value: 'Ing. en produccion industrial',
    label: 'Ing. en produccion industrial',
  },
  {
    value: 'Ingeniero en salud ocupacional',
    label: 'Ingeniero en salud ocupacional',
  },
  { value: 'Ingeniero en sistemas', label: 'Ingeniero en sistemas' },
  { value: 'Ing. en telecomunicaciones', label: 'Ing. en telecomunicaciones' },
  { value: 'Ingeniero industrial', label: 'Ingeniero industrial' },
  { value: 'Ingeniero informatico', label: 'Ingeniero informatico' },
  { value: 'Ingeniero mecanico', label: 'Ingeniero mecanico' },
  { value: 'Ingeniero medio ambiental', label: 'Ingeniero medio ambiental' },
  { value: 'Ingeniero metalugircos', label: 'Ingeniero metalugircos' },
  { value: 'Ingeniero quimico', label: 'Ingeniero quimico' },
  { value: 'Inspector de transito', label: 'Inspector de transito' },
  { value: 'Instructor de baile', label: 'Instructor de baile' },
  { value: 'Instructor de manejo', label: 'Instructor de manejo' },
  { value: 'Jardinero', label: 'Jardinero' },
  { value: 'Jefe de cocina', label: 'Jefe de cocina' },
  { value: 'Jefe de departamento', label: 'Jefe de departamento' },
  { value: 'Jefe de saloneros', label: 'Jefe de saloneros' },
  { value: 'Jonalero', label: 'Jonalero' },
  { value: 'Joyero', label: 'Joyero' },
  { value: 'Juez', label: 'Juez' },
  { value: 'Laboratista civil', label: 'Laboratista civil' },
  { value: 'Laboratista clinico', label: 'Laboratista clinico' },
  { value: 'Lavador de carros', label: 'Lavador de carros' },
  {
    value: 'Limpiador de tanques septicos',
    label: 'Limpiador de tanques septicos',
  },
  { value: 'Llantero', label: 'Llantero' },
  { value: 'Locutor de radioemisora', label: 'Locutor de radioemisora' },
  { value: 'Locutor de television', label: 'Locutor de television' },
  { value: 'Maestro de obras', label: 'Maestro de obras' },
  { value: 'Manicurista', label: 'Manicurista' },
  { value: 'Maquillador/A', label: 'Maquillador/A' },
  { value: 'Maquinista de locomotora', label: 'Maquinista de locomotora' },
  { value: 'Marinero', label: 'Marinero' },
  { value: 'Masajista', label: 'Masajista' },
  { value: 'Matematico', label: 'Matematico' },
  { value: 'Mecanica precision', label: 'Mecanica precision' },
  { value: 'Mecanico calderas', label: 'Mecanico calderas' },
  { value: 'Mecanico general', label: 'Mecanico general' },
  {
    value: 'Mecanico en maq de coser indus',
    label: 'Mecanico en maq de coser indus',
  },
  { value: 'Medico especialista', label: 'Medico especialista' },
  { value: 'Medico general', label: 'Medico general' },
  { value: 'Mensajero', label: 'Mensajero' },
  { value: 'Meteorologo', label: 'Meteorologo' },
  { value: 'Miscelanea', label: 'Miscelanea' },
  {
    value: 'Monitoreador de camaras video',
    label: 'Monitoreador de camaras video',
  },
  { value: 'Montacarguista', label: 'Montacarguista' },
  {
    value: 'Montador de estruct. metalica',
    label: 'Montador de estruct. metalica',
  },
  { value: 'Mucama', label: 'Mucama' },
  {
    value: 'Musicalizador en radioemisoras',
    label: 'Musicalizador en radioemisoras',
  },
  { value: 'Musico', label: 'Musico' },
  { value: 'Nutricionista', label: 'Nutricionista' },
  { value: 'Oficial de prestamos y cr.', label: 'Oficial de prestamos y cr.' },
  { value: 'Oficial de seguridad', label: 'Oficial de seguridad' },
  { value: 'Oficinista', label: 'Oficinista' },
  { value: 'Oncologo', label: 'Oncologo' },
  { value: 'Operador de computacion', label: 'Operador de computacion' },
  {
    value: 'Operador de grua estacionaria',
    label: 'Operador de grua estacionaria',
  },
  {
    value: 'Operador de maquinaria pesada',
    label: 'Operador de maquinaria pesada',
  },
  { value: 'Operario', label: 'Operario' },
  {
    value: 'Operario en talleres dentales',
    label: 'Operario en talleres dentales',
  },
  { value: 'Optometrista', label: 'Optometrista' },
  { value: 'Panadero', label: 'Panadero' },
  { value: 'Paramedico', label: 'Paramedico' },
  { value: 'Parrillero', label: 'Parrillero' },
  { value: 'Pastelero', label: 'Pastelero' },
  { value: 'Pediatra', label: 'Pediatra' },
  { value: 'Peluquero', label: 'Peluquero' },
  { value: 'Peon agricola', label: 'Peon agricola' },
  { value: 'Peon de bodegas frias', label: 'Peon de bodegas frias' },
  {
    value: 'Peon de camion distribuidor',
    label: 'Peon de camion distribuidor',
  },
  { value: 'Peon de carga y descarga', label: 'Peon de carga y descarga' },
  { value: 'Peon de construccion', label: 'Peon de construccion' },
  { value: 'Periodista', label: 'Periodista' },
  { value: 'Pescador', label: 'Pescador' },
  { value: 'Piloto de aviacion', label: 'Piloto de aviacion' },
  { value: 'Pintor automotriz', label: 'Pintor automotriz' },
  { value: 'Pistero', label: 'Pistero' },
  { value: 'Pizzero', label: 'Pizzero' },
  { value: 'Plomero', label: 'Plomero' },
  { value: 'Portero', label: 'Portero' },
  { value: 'Presidente', label: 'Presidente' },
  {
    value: 'Profesional en proteccion amb',
    label: 'Profesional en proteccion amb',
  },
  { value: 'Profesor de primaria', label: 'Profesor de primaria' },
  { value: 'Profesor de secundaria', label: 'Profesor de secundaria' },
  { value: 'Profesor universitario', label: 'Profesor universitario' },
  {
    value: 'Programador de aplicaciones',
    label: 'Programador de aplicaciones',
  },
  { value: 'Programador de computacion', label: 'Programador de computacion' },
  {
    value: 'Programador en radioemisoras',
    label: 'Programador en radioemisoras',
  },
  { value: 'Proveedor', label: 'Proveedor' },
  { value: 'Psicologo', label: 'Psicologo' },
  { value: 'Publicidad', label: 'Publicidad' },
  { value: 'Quimicos', label: 'Quimicos' },
  { value: 'Recepcionista', label: 'Recepcionista' },
  { value: 'Recolector de basura', label: 'Recolector de basura' },
  { value: 'Recolector de cafe', label: 'Recolector de cafe' },
  { value: 'Recursos humanos', label: 'Recursos humanos' },
  { value: 'Relaciones publicas', label: 'Relaciones publicas' },
  { value: 'Relojero', label: 'Relojero' },
  {
    value: 'Repartido de cargas livianas',
    label: 'Repartido de cargas livianas',
  },
  { value: 'Repostero', label: 'Repostero' },
  { value: 'Representante comercial', label: 'Representante comercial' },
  { value: 'Salonero', label: 'Salonero' },
  { value: 'Sastre', label: 'Sastre' },
  { value: 'Secretaria', label: 'Secretaria' },
  { value: 'Serigrafia', label: 'Serigrafia' },
  { value: 'Servicio al cliente', label: 'Servicio al cliente' },
  { value: 'Sociologo', label: 'Sociologo' },
  { value: 'Soldador', label: 'Soldador' },
  { value: 'Soporte tecnico', label: 'Soporte Ttcnico' },
  { value: 'Sub.gerente departamento', label: 'Sub.gerente departamento' },
  { value: 'Sub-gerente de sucursal', label: 'Sub-gerente de sucursal' },
  { value: 'Sub-gerente general', label: 'Sub-gerente general' },
  { value: 'Supervisor', label: 'Supervisor' },
  { value: 'Tapicero', label: 'Tapicero' },
  { value: 'Tatuador', label: 'Tatuador' },
  { value: 'Taxista', label: 'Taxista' },
  {
    value: 'Tecnico de laboratorio medico',
    label: 'Tecnico de laboratorio medico',
  },
  {
    value: 'Tec. Protesis medicas/dentales',
    label: 'Tec. Protesis medicas/dentales',
  },
  { value: 'Tecnico en adminitracion', label: 'Tecnico en adminitracion' },
  {
    value: 'Tecnico en aire acondicionado',
    label: 'Tecnico en aire acondicionado',
  },
  { value: 'Tecnico en electronica', label: 'Tecnico en electronica' },
  {
    value: 'Tecnico en quimica industrial',
    label: 'Tecnico en quimica industrial',
  },
  { value: 'Tecnico en redes', label: 'Tecnico en redes' },
  {
    value: 'Tecnico en salud ocupacional',
    label: 'Tecnico en salud ocupacional',
  },
  {
    value: 'Tec.  En seguridad aeronautica',
    label: 'Tec.  En seguridad aeronautica',
  },
  {
    value: 'Tec. y asistente de farmacia',
    label: 'Tec. y asistente de farmacia',
  },
  {
    value: 'Tec. Y asistente veterinario',
    label: 'Tec. Y asistente veterinario',
  },
  { value: 'Topografo', label: 'Topografo' },
  { value: 'Trabajo Social', label: 'Trabajo Social' },
  { value: 'Tractorista', label: 'Tractorista' },
  { value: 'Traductor', label: 'Traductor' },
  { value: 'Urbanista', label: 'Urbanista' },
  { value: 'Vagonetero', label: 'Vagonetero' },
  { value: 'Vendedor', label: 'Vendedor' },
  { value: 'Vendedor ambulante', label: 'Vendedor ambulante' },
  { value: 'Verdulero', label: 'Verdulero' },
  { value: 'Veterinario', label: 'Veterinario' },
  { value: 'Zapatero', label: 'Zapatero' },
  { value: 'Zoologo', label: 'Zoologo' },
  { value: 'Comerciante', label: 'Comerciante' },
  { value: 'Abogado', label: 'Abogado' },
  { value: 'Pensionado', label: 'Pensionado' },
  { value: 'Medico', label: 'Medico' },
  { value: 'Transportista', label: 'Transportista' },
  { value: 'Sin titulo academico', label: 'Sin titulo academico' },
];

export const ORIGENES_DE_FONDOS = [
  { value: 'Salario', label: 'Salario' },
  {
    value: 'Producto de su actividad comercial',
    label: 'Producto de su actividad comercial',
  },
  { value: 'Ahorros', label: 'Ahorros' },
  { value: 'Herencia', label: 'Herencia' },
  { value: 'Trabajador independiente', label: 'Trabajador independiente' },
  { value: 'Venta de vehiculo', label: 'Venta de vehiculo' },
  { value: 'Venta de propiedad', label: 'Venta de propiedad' },
];

export const SI_NO = [
  { value: '1', label: 'Si' },
  { value: '0', label: 'No' },
];

export const MONEDAS = [
  { value: 'Dolar', label: 'Dolar' },
  { value: 'Colon', label: 'Colon' },
  { value: 'Otra', label: 'Otra' },
];

export const ACTIVIDAD_ECONOMICA = [
  {
    value: 'Agricultura, ganaderia, silvicultura y pesca',
    label: 'Agricultura, ganaderia, silvicultura y pesca',
  },
  {
    value: 'Explotacion de minas y canteras',
    label: 'Explotacion de minas y canteras',
  },
  {
    value: 'Industrias manufactureras',
    label: 'Industrias manufactureras',
  },
  {
    value: 'Suministro de electricidad, gas, vapor y aire acondicionado',
    label: 'Suministro de electricidad, gas, vapor y aire acondicionado',
  },
  {
    value:
      'Suministro de agua; evacuacion de agua residuales, gestion de desechos y descontaminacion',
    label:
      'Suministro de agua; evacuacion de agua residuales, gestion de desechos y descontaminacion',
  },
  {
    value: 'Construccion',
    label: 'Construccion',
  },
  {
    value:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    label:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
  },
  {
    value: 'Transporte y almacenamiento',
    label: 'Transporte y almacenamiento',
  },
  {
    value: 'Informacion y comunicaciones.',
    label: 'Informacion y comunicaciones.',
  },
  {
    value: 'Actividades financieras y de seguros.',
    label: 'Actividades financieras y de seguros.',
  },
  {
    value: 'Actividades inmobiliarias',
    label: 'Actividades inmobiliarias',
  },
  {
    value: 'Actividades profesionales,  cientificas y tecnicas',
    label: 'Actividades profesionales,  cientificas y tecnicas',
  },
  {
    value: 'Actividades de servicios administrativas y de apoyo',
    label: 'Actividades de servicios administrativas y de apoyo',
  },
  {
    value:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    label:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
  },
  {
    value: 'Actividades de atencion de la salud humana y asistencia social',
    label: 'Actividades de atencion de la salud humana y asistencia social',
  },
  {
    value: 'Actividades artisticas, de entretenimiento  y recreativas',
    label: 'Actividades artisticas, de entretenimiento  y recreativas',
  },
  {
    value: 'Otras actividades de servicios',
    label: 'Otras actividades de servicios',
  },
];

export const SUBACTIVIDAD_ECONOMICA = [
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de frijol',
    label: 'Cultivo de frijol',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de maiz',
    label: 'Cultivo de maiz',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de otros cereales, legumbres y semillas oleaginosas n.c.p.',
    label: 'Cultivo de otros cereales, legumbres y semillas oleaginosas n.c.p.',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de arroz',
    label: 'Cultivo de arroz',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de sandia',
    label: 'Cultivo de sandia',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de melon',
    label: 'Cultivo de melon',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de cebolla',
    label: 'Cultivo de cebolla',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de chayote',
    label: 'Cultivo de chayote',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de papa',
    label: 'Cultivo de papa',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de yuca',
    label: 'Cultivo de yuca',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de otras hortalizas, raices o tuberculos n.c.p.',
    label: 'Cultivo de otras hortalizas, raices o tuberculos n.c.p.',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de cana de azucar',
    label: 'Cultivo de cana de azucar',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de tabaco',
    label: 'Cultivo de tabaco',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de plantas de fibras',
    label: 'Cultivo de plantas de fibras',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de Flores',
    label: 'Cultivo de Flores',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de Follaje',
    label: 'Cultivo de Follaje',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de otras  plantas  no perennes(excepto flores y follajes)',
    label: 'Cultivo de otras  plantas  no perennes(excepto flores y follajes)',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de uvas',
    label: 'Cultivo de uvas',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de banano',
    label: 'Cultivo de banano',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de platano',
    label: 'Cultivo de platano',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de pina',
    label: 'Cultivo de pina',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de otras frutas tropicales y subtropicales',
    label: 'Cultivo de otras frutas tropicales y subtropicales',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de frutas  citricas',
    label: 'Cultivo de frutas  citricas',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de frutas  de pepita y de hueso',
    label: 'Cultivo de frutas  de pepita y de hueso',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de otros  frutos  y nueces de arboles  y arbustos',
    label: 'Cultivo de otros  frutos  y nueces de arboles  y arbustos',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de palma africana  (aceitera)',
    label: 'Cultivo de palma africana  (aceitera)',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de otros frutos oleaginosos n.c.p',
    label: 'Cultivo de otros frutos oleaginosos n.c.p',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivo de cafe',
    label: 'Cultivo de cafe',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value:
      'Cultivo de otras  plantas  con las que se preparan bebidas, excepto el cultivo de cafe n. c. p',
    label:
      'Cultivo de otras  plantas  con las que se preparan bebidas, excepto el cultivo de cafe n. c. p',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value:
      'Cultivo de especies  de plantas aromaticas, medicinales y farmaceuticas',
    label:
      'Cultivo de especies  de plantas aromaticas, medicinales y farmaceuticas',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cultivos de otras plantas perennes',
    label: 'Cultivos de otras plantas perennes',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Propagacion de plantas',
    label: 'Propagacion de plantas',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cria de ganado  Bovino (vacuno) para carne',
    label: 'Cria de ganado  Bovino (vacuno) para carne',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cria de ganado  Bovino ( vacuno) para produccion  de leche cruda',
    label: 'Cria de ganado  Bovino ( vacuno) para produccion  de leche cruda',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cria de Bufalos y otra produccion de ganado bovino (vacuno).',
    label: 'Cria de Bufalos y otra produccion de ganado bovino (vacuno).',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cria de caballos  y otros  equinos',
    label: 'Cria de caballos  y otros  equinos',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cria de camellos  y camelidos',
    label: 'Cria de camellos  y camelidos',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cria de ovejas y cabras',
    label: 'Cria de ovejas y cabras',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cria de cerdos',
    label: 'Cria de cerdos',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cria de pollos para carne',
    label: 'Cria de pollos para carne',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Actividades de granja para la produccion de huevos de gallina',
    label: 'Actividades de granja para la produccion de huevos de gallina',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cria de otras  aves de corral, excepto pollos',
    label: 'Cria de otras  aves de corral, excepto pollos',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Cria de otros  animales',
    label: 'Cria de otros  animales',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value:
      'Cultivo de productos  agricolas  en combinacion con la cria de animales (explotacion mixta)',
    label:
      'Cultivo de productos  agricolas  en combinacion con la cria de animales(explotacion mixta)',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Servicios de alquiler de maquinaria agricola con chofer',
    label: 'Servicios de alquiler de maquinaria agricola con chofer',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Servicios de fumigacion y riego agricola',
    label: 'Servicios de fumigacion y riego agricola',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Otros servicios de apoyo a la agricultura',
    label: 'Otros servicios de apoyo a la agricultura',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Servicios de Inseminacion artificial y mejoramiento genetico',
    label: 'Servicios de Inseminacion artificial y mejoramiento genetico',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Otras actividades de apoyo a la ganaderia',
    label: 'Otras actividades de apoyo a la ganaderia',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Beneficio de cafe',
    label: 'Beneficio de cafe',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Otras actividades posteriores a la cosecha',
    label: 'Otras actividades posteriores a la cosecha',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Tratamiento ( Procesamiento )de semillas para la propagacion',
    label: 'Tratamiento ( Procesamiento )de semillas para la propagacion',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Caza ordinaria, mediante trampas y actividades de servicio conexas',
    label: 'Caza ordinaria, mediante trampas y actividades de servicio conexas',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Silvicultura y otras actividades  forestales',
    label: 'Silvicultura y otras actividades  forestales',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Extraccion de madera',
    label: 'Extraccion de madera',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Servicios de apoyo a la silvicultura',
    label: 'Servicios de apoyo a la silvicultura',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Pesca',
    label: 'Pesca',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Pesca de agua dulce',
    label: 'Pesca de agua dulce',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Pesca de agua dulce',
    label: 'Pesca de agua dulce',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Acuicultura marina',
    label: 'Acuicultura marina',
  },
  {
    actividad: 'Agricultura, ganaderia, silvicultura y pesca',
    value: 'Acuicultura de agua dulce',
    label: 'Acuicultura de agua dulce',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value: 'Extraccion de carbon de piedra',
    label: 'Extraccion de carbon de piedra',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value: 'Extraccion de lignito',
    label: 'Extraccion de lignito',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value: 'Extraccion de petroleo crudo',
    label: 'Extraccion de petroleo crudo',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value: 'Extraccion de gas natural',
    label: 'Extraccion de gas natural',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value: 'Extraccion de minerales  de hierro',
    label: 'Extraccion de minerales  de hierro',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value: 'Extraccion de minerales  metaliferos  no ferrosos',
    label: 'Extraccion de minerales  metaliferos  no ferrosos',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value: 'Extraccion de otros minerales  metaliferos  no ferrosos',
    label: 'Extraccion de otros minerales  metaliferos  no ferrosos',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value: 'Extraccion de piedra, arena y arcilla',
    label: 'Extraccion de piedra, arena y arcilla',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value:
      'Extraccion de minerales  para la fabricacion de abonos  y productos quimicos',
    label:
      'Extraccion de minerales  para la fabricacion de abonos  y productos quimicos',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value: 'Extraccion de turba',
    label: 'Extraccion de turba',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value: 'Extraccion de sal',
    label: 'Extraccion de sal',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value: 'Explotacion de otras minas y canteras  n.c.p.',
    label: 'Explotacion de otras minas y canteras  n.c.p.',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value: 'Actividades de apoyo para la extraccion de petroleo y gas natural',
    label: 'Actividades de apoyo para la extraccion de petroleo y gas natural',
  },
  {
    actividad: 'Explotacion de minas y canteras',
    value: 'Actividades de apoyo a la explotacion de otras minas y canteras',
    label: 'Actividades de apoyo a la explotacion de otras minas y canteras',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Elaboracion y conservacion de carne y embutidos de ganado vacuno y porcino',
    label:
      'Elaboracion y conservacion de carne y embutidos de ganado vacuno y porcino',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion y conservacion de carne y embutidos de aves',
    label: 'Elaboracion y conservacion de carne y embutidos de aves',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion y conservacion de otro tipo de carnes n.c.p.',
    label: 'Elaboracion y conservacion de otro tipo de carnes n.c.p.',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion y conservacion de pescados, crustaceos y moluscos',
    label: 'Elaboracion y conservacion de pescados, crustaceos y moluscos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Elaboracion y conservacion de frutas, legumbres y hortalizas (vegetales)',
    label:
      'Elaboracion y conservacion de frutas, legumbres y hortalizas (vegetales)',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion de aceites y grasas de origen vegetal y animal',
    label: 'Elaboracion de aceites y grasas de origen vegetal y animal',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion de productos lacteos',
    label: 'Elaboracion de productos lacteos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Beneficio de arroz',
    label: 'Beneficio de arroz',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion de tortillas',
    label: 'Elaboracion de tortillas',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion de productos de molineria excepto arroz',
    label: 'Elaboracion de productos de molineria excepto arroz',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion de almidones y productos derivados del almidon',
    label: 'Elaboracion de almidones y productos derivados del almidon',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion de galletas',
    label: 'Elaboracion de galletas',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Elaboracion de otros productos de panaderia , excepto elaboracion de galletas',
    label:
      'Elaboracion de otros productos de panaderia , excepto elaboracion de galletas',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Elaboracion de azucar y otros productos derivados de la cana de azucar',
    label:
      'Elaboracion de azucar y otros productos derivados de la cana de azucar',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion de cacao, chocolate y productos de confiteria',
    label: 'Elaboracion de cacao, chocolate y productos de confiteria',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Elaboracion de macarrones,  fideos, alcuzcuz y productos farinaceos  similares',
    label:
      'Elaboracion de macarrones,  fideos, alcuzcuz y productos farinaceos  similares',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion de comidas y platos preparados',
    label: 'Elaboracion de comidas y platos preparados',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Tostado y molienda del cafe',
    label: 'Tostado y molienda del cafe',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion de otros productos alimenticios n.c.p.',
    label: 'Elaboracion de otros productos alimenticios n.c.p.',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion de alimentos preparados para animales',
    label: 'Elaboracion de alimentos preparados para animales',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Destilacion, rectificacion y mezcla de bebidas alcoholicas',
    label: 'Destilacion, rectificacion y mezcla de bebidas alcoholicas',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion de vinos',
    label: 'Elaboracion de vinos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion de bebidas malteadas y de malta',
    label: 'Elaboracion de bebidas malteadas y de malta',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Elaboracion de bebidas no alcoholicas; produccion de aguas minerales y otras aguas embotelladas',
    label:
      'Elaboracion de bebidas no alcoholicas; produccion de aguas minerales y otras aguas embotelladas',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Elaboracion de productos de tabaco',
    label: 'Elaboracion de productos de tabaco',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Hilatura, tejedura y acabados de productos textiles',
    label: 'Hilatura, tejedura y acabados de productos textiles',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Preparacion e hiladura de fibras textiles',
    label: 'Preparacion e hiladura de fibras textiles',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Tejedura de productos textiles',
    label: 'Tejedura de productos textiles',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Acabado de productos  textiles',
    label: 'Acabado de productos  textiles',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de tejidos de punto y ganchillo',
    label: 'Fabricacion de tejidos de punto y ganchillo',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de articulos confeccionados con materiales textiles, excepto prendas de vestir',
    label:
      'Fabricacion de articulos confeccionados con materiales textiles, excepto prendas de vestir',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de tapices y alfombras',
    label: 'Fabricacion de tapices y alfombras',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de cuerdas, cordeles, bramantes y redes',
    label: 'Fabricacion de cuerdas, cordeles, bramantes y redes',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de otros productos textiles n.c.p.',
    label: 'Fabricacion de otros productos textiles n.c.p.',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de ropa interior',
    label: 'Fabricacion de ropa interior',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de otras prendas de vestir, excepto ropa interior y prendas de piel',
    label:
      'Fabricacion de otras prendas de vestir, excepto ropa interior y prendas de piel',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de articulos de piel',
    label: 'Fabricacion de articulos de piel',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de articulos de punto y ganchillo',
    label: 'Fabricacion de articulos de punto y ganchillo',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Curtido y adobo de cueros; adobo y tenido de pieles',
    label: 'Curtido y adobo de cueros; adobo y tenido de pieles',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de maletas, bolsos de mano y de articulos similares, y articulos de talabarteria o guarnicioneria',
    label:
      'Fabricacion de maletas, bolsos de mano y de articulos similares, y articulos de talabarteria o guarnicioneria',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de calzado',
    label: 'Fabricacion de calzado',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Aserrado y acepilladura de madera',
    label: 'Aserrado y acepilladura de madera',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de hojas de madera para enchapado y tableros a base de madera',
    label:
      'Fabricacion de hojas de madera para enchapado y tableros a base de madera',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de partes y piezas de carpimteria para edificios y construcciones',
    label:
      'Fabricacion de partes y piezas de carpimteria para edificios y construcciones',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de recipientes de madera',
    label: 'Fabricacion de recipientes de madera',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de otros productos de madera; fabricacion de articulos de corcho, paja y materiales trenzables.',
    label:
      'Fabricacion de otros productos de madera; fabricacion de articulos de corcho, paja y materiales trenzables.',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de pasta de madera , papel y carton',
    label: 'Fabricacion de pasta de madera , papel y carton',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion del papel y carton ondulado y de envases de papel y carton',
    label:
      'Fabricacion del papel y carton ondulado y de envases de papel y carton',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de otros articulos del papel y carton',
    label: 'Fabricacion de otros articulos del papel y carton',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Impresion',
    label: 'Impresion',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Servicios relacionadas con la impresion',
    label: 'Servicios relacionadas con la impresion',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Reproduccion de grabaciones',
    label: 'Reproduccion de grabaciones',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de productos de horno de coque',
    label: 'Fabricacion de productos de horno de coque',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de los productos de la refinacion del petroleo',
    label: 'Fabricacion de los productos de la refinacion del petroleo',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de sustancias quimicas basicas',
    label: 'Fabricacion de sustancias quimicas basicas',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion abonos y compuestos de nitrogeno',
    label: 'Fabricacion abonos y compuestos de nitrogeno',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de plasticos y de caucho sintetico en formas primarias',
    label: 'Fabricacion de plasticos y de caucho sintetico en formas primarias',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de plaguicidas  y de otros productos quimicos de uso agropecuario',
    label:
      'Fabricacion de plaguicidas  y de otros productos quimicos de uso agropecuario',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de pinturas, barnices y productos de revestimiento similares, tintas de imprenta y masillas',
    label:
      'Fabricacion de pinturas, barnices y productos de revestimiento similares, tintas de imprenta y masillas',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de jabones y detergentes, preparados para limpiar y pulir',
    label:
      'Fabricacion de jabones y detergentes, preparados para limpiar y pulir',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de perfumes y preparados de tocador',
    label: 'Fabricacion de perfumes y preparados de tocador',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de otros productos quimicos n.c.p.',
    label: 'Fabricacion de otros productos quimicos n.c.p.',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de fibras artificiales',
    label: 'Fabricacion de fibras artificiales',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de productos farmaceuticos, sustancias quimicas medicinales y de productos botanicos de uso farmaceutico',
    label:
      'Fabricacion de productos farmaceuticos, sustancias quimicas medicinales y de productos botanicos de uso farmaceutico',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de cubiertas y camaras de caucho; recauchutado y renovacion de cubiertas de caucho',
    label:
      'Fabricacion de cubiertas y camaras de caucho; recauchutado y renovacion de cubiertas de caucho',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de otros productos de caucho',
    label: 'Fabricacion de otros productos de caucho',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de productos de plasticos para la construccion',
    label: 'Fabricacion de productos de plasticos para la construccion',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de productos de plasticos para envases industriales',
    label: 'Fabricacion de productos de plasticos para envases industriales',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de vidrio y de productos de vidrio',
    label: 'Fabricacion de vidrio y de productos de vidrio',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de productos refractarios',
    label: 'Fabricacion de productos refractarios',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion material de arcilla para la construccion',
    label: 'Fabricacion material de arcilla para la construccion',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de otros productos de ceramica y porcelana',
    label: 'Fabricacion de otros productos de ceramica y porcelana',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de cemento, de la cal y del yeso',
    label: 'Fabricacion de cemento, de la cal y del yeso',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de articulos de hormigon, de cemento y de yeso',
    label: 'Fabricacion de articulos de hormigon, de cemento y de yeso',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Corte, tallado y acabado de la piedra',
    label: 'Corte, tallado y acabado de la piedra',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de otros productos minerales no metalicos n.c.p.',
    label: 'Fabricacion de otros productos minerales no metalicos n.c.p.',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Industrias basicas de hierro y acero',
    label: 'Industrias basicas de hierro y acero',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de productos primarios de metales preciosos y metales no ferrosos',
    label:
      'Fabricacion de productos primarios de metales preciosos y metales no ferrosos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fundicion de hierro y acero',
    label: 'Fundicion de hierro y acero',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fundicion de metales no ferrosos',
    label: 'Fundicion de metales no ferrosos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de productos metalicos para uso estructural',
    label: 'Fabricacion de productos metalicos para uso estructural',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de tanques, depositos y recipientes de metal',
    label: 'Fabricacion de tanques, depositos y recipientes de metal',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de los generadores del vapor, excepto calderas de agua caliente para calefaccion central',
    label:
      'Fabricacion de los generadores del vapor, excepto calderas de agua caliente para calefaccion central',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de armas y municiones',
    label: 'Fabricacion de armas y municiones',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Forja, prensado, estampado y laminado de metales; pulvimetalurgia',
    label: 'Forja, prensado, estampado y laminado de metales; pulvimetalurgia',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Tratamiento y revestimiento de metales',
    label: 'Tratamiento y revestimiento de metales',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de articulos de cuchilleria, herramientas de mano y articulos de ferreteria',
    label:
      'Fabricacion de articulos de cuchilleria, herramientas de mano y articulos de ferreteria',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de otros productos de metal n.c.p.',
    label: 'Fabricacion de otros productos de metal n.c.p.',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de componentes y tableros electronicos',
    label: 'Fabricacion de componentes y tableros electronicos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de computadoras y equipo periferico',
    label: 'Fabricacion de computadoras y equipo periferico',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de equipos de comunicaciones',
    label: 'Fabricacion de equipos de comunicaciones',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de aparatos electronicos de consumo',
    label: 'Fabricacion de aparatos electronicos de consumo',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de equipos de medicion, prueba, verificacion, navegacion y de equipo de control',
    label:
      'Fabricacion de equipos de medicion, prueba, verificacion, navegacion y de equipo de control',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de relojes',
    label: 'Fabricacion de relojes',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de equipos de irradiacion y de equipo electrico de uso medico',
    label:
      'Fabricacion de equipos de irradiacion y de equipo electrico de uso medico',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de instrumentos opticos de equipo fotografico',
    label: 'Fabricacion de instrumentos opticos de equipo fotografico',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de soportes magneticos y opticos',
    label: 'Fabricacion de soportes magneticos y opticos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de componentes y tableros electronicos',
    label: 'Fabricacion de componentes y tableros electronicos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de computadoras y equipo periferico',
    label: 'Fabricacion de computadoras y equipo periferico',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de equipos de comunicaciones',
    label: 'Fabricacion de equipos de comunicaciones',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de aparatos electronicos de consumo',
    label: 'Fabricacion de aparatos electronicos de consumo',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de equipos de medicion, prueba, verificacion, navegacion y de equipo de control',
    label:
      'Fabricacion de equipos de medicion, prueba, verificacion, navegacion y de equipo de control',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de relojes',
    label: 'Fabricacion de relojes',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de equipos de irradiacion y de equipo electrico de uso medico',
    label:
      'Fabricacion de equipos de irradiacion y de equipo electrico de uso medico',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de instrumentos opticos de equipo fotografico',
    label: 'Fabricacion de instrumentos opticos de equipo fotografico',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de soportes magneticos y opticos',
    label: 'Fabricacion de soportes magneticos y opticos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de motores electricos, generadores, transformadores electricos y aparatos  de distribucion y control de la energia electrica',
    label:
      'Fabricacion de motores electricos, generadores, transformadores electricos y aparatos  de distribucion y control de la energia electrica',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de baterias y acumuladores',
    label: 'Fabricacion de baterias y acumuladores',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de cables de fibra optica',
    label: 'Fabricacion de cables de fibra optica',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de otros hilos y cables electricos',
    label: 'Fabricacion de otros hilos y cables electricos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de dispositivos de cableado',
    label: 'Fabricacion de dispositivos de cableado',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de equipos de iluminacion electricos',
    label: 'Fabricacion de equipos de iluminacion electricos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de aparatos de uso domestico',
    label: 'Fabricacion de aparatos de uso domestico',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de otro equipo electrico',
    label: 'Fabricacion de otro equipo electrico',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de motores y turbinas, excepto motores para aeronaves, vehiculos automotores y motocicletas',
    label:
      'Fabricacion de motores y turbinas, excepto motores para aeronaves, vehiculos automotores y motocicletas',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de equipo de propulsion de fluidos',
    label: 'Fabricacion de equipo de propulsion de fluidos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de otras bombas, compresores, grifos y valvulas',
    label: 'Fabricacion de otras bombas, compresores, grifos y valvulas',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de cojinetes, engranajes, trenes de engranajes y piezas de transmision',
    label:
      'Fabricacion de cojinetes, engranajes, trenes de engranajes y piezas de transmision',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de hornos, hogueras  y quemadores',
    label: 'Fabricacion de hornos, hogueras  y quemadores',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de equipo de elevacion y manipulacion',
    label: 'Fabricacion de equipo de elevacion y manipulacion',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de maquinaria y equipo de oficina (excepto computadoras y equipos perifericos)',
    label:
      'Fabricacion de maquinaria y equipo de oficina (excepto computadoras y equipos perifericos)',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de herramientas manuales motorizadas',
    label: 'Fabricacion de herramientas manuales motorizadas',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de otros tipos de maquinaria de uso general',
    label: 'Fabricacion de otros tipos de maquinaria de uso general',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de maquinaria agropecuaria y forestal',
    label: 'Fabricacion de maquinaria agropecuaria y forestal',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de maquinarias para la conformacion de metales y de maquinas herramienstas',
    label:
      'Fabricacion de maquinarias para la conformacion de metales y de maquinas herramienstas',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de maquinaria para metalurgica',
    label: 'Fabricacion de maquinaria para metalurgica',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de maquinaria para explotacion de minas y canteras y para obras de construccion',
    label:
      'Fabricacion de maquinaria para explotacion de minas y canteras y para obras de construccion',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de maquinaria para la elaboracion de alimentos, bebidas y tabaco',
    label:
      'Fabricacion de maquinaria para la elaboracion de alimentos, bebidas y tabaco',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de maquinaria para la elaboracion de productos textiles, prendas de vestir y cueros',
    label:
      'Fabricacion de maquinaria para la elaboracion de productos textiles, prendas de vestir y cueros',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de otros tipos de maquinaria de uso especial',
    label: 'Fabricacion de otros tipos de maquinaria de uso especial',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de vehiculos automotores',
    label: 'Fabricacion de vehiculos automotores',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de carrocerias para vehiculos automotores; fabricacion de remolques y semiremolques',
    label:
      'Fabricacion de carrocerias para vehiculos automotores; fabricacion de remolques y semiremolques',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de partes y accesorios para motores de vehiculos',
    label: 'Fabricacion de partes y accesorios para motores de vehiculos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Construccion de buques y estructuras flotantes',
    label: 'Construccion de buques y estructuras flotantes',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Construccion de embarcaciones de recreo y deporte',
    label: 'Construccion de embarcaciones de recreo y deporte',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de locomotoras y de material rodante',
    label: 'Fabricacion de locomotoras y de material rodante',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de vehiculos militares de combate',
    label: 'Fabricacion de vehiculos militares de combate',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de motocicletas',
    label: 'Fabricacion de motocicletas',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de bicicletas y sillas de ruedas para personas con discapacidad',
    label:
      'Fabricacion de bicicletas y sillas de ruedas para personas con discapacidad',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de otros tipos de equipo de transporte n.c.p.',
    label: 'Fabricacion de otros tipos de equipo de transporte n.c.p.',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de muebles de madera',
    label: 'Fabricacion de muebles de madera',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Fabricacion de muebles de otro tipo de material (excepto de madera, piedra, cemento o ceramica)',
    label:
      'Fabricacion de muebles de otro tipo de material (excepto de madera, piedra, cemento o ceramica)',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de joyas y articulos conexos',
    label: 'Fabricacion de joyas y articulos conexos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de bisuteria y articulos conexos',
    label: 'Fabricacion de bisuteria y articulos conexos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de Instrumentos musicales',
    label: 'Fabricacion de Instrumentos musicales',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de articulos de deporte',
    label: 'Fabricacion de articulos de deporte',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de juegos y juguetes',
    label: 'Fabricacion de juegos y juguetes',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Fabricacion de instrumentos y suministros medicos y odontologicos',
    label: 'Fabricacion de instrumentos y suministros medicos y odontologicos',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Otras industrias manufactureras n.c.p.',
    label: 'Otras industrias manufactureras n.c.p.',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Reparacion de productos elaborados de metal',
    label: 'Reparacion de productos elaborados de metal',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Reparacion de maquinaria',
    label: 'Reparacion de maquinaria',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Reparacion de equipo electronico y optico',
    label: 'Reparacion de equipo electronico y optico',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Reparacion de equipo electrico',
    label: 'Reparacion de equipo electrico',
  },
  {
    actividad: 'Industrias Manufactureras',
    value:
      'Reparacion de equipo de transporte,  excepto los vehiculos automotores',
    label:
      'Reparacion de equipo de transporte,  excepto los vehiculos automotores',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Reparacion de equipo de otro tipo',
    label: 'Reparacion de equipo de otro tipo',
  },
  {
    actividad: 'Industrias Manufactureras',
    value: 'Instalacion de maquinaria y equipo industrial',
    label: 'Instalacion de maquinaria y equipo industrial',
  },
  {
    actividad: 'Suministro de electricidad, gas, vapor y aire acondicionado',
    value: 'Generacion , transmision  y distribucion de energia electrica',
    label: 'Generacion , transmision  y distribucion de energia electrica',
  },
  {
    actividad: 'Suministro de electricidad, gas, vapor y aire acondicionado',
    value:
      'Fabricacion de gas; distribucion de combustibles gaseosos por tuberias',
    label:
      'Fabricacion de gas; distribucion de combustibles gaseosos por tuberias',
  },
  {
    actividad: 'Suministro de electricidad, gas, vapor y aire acondicionado',
    value: 'Suministro de vapor y aire acondicionado',
    label: 'Suministro de vapor y aire acondicionado',
  },
  {
    actividad:
      'Suministro de agua; evacuacion de agua residuales, gestion de desechos y descontaminacion',
    value: 'Captacion, tratamiento y distribucion de agua',
    label: 'Captacion, tratamiento y distribucion de agua',
  },
  {
    actividad:
      'Suministro de agua; evacuacion de agua residuales, gestion de desechos y descontaminacion',
    value: 'Evacuacion de aguas residuales',
    label: 'Evacuacion de aguas residuales',
  },
  {
    actividad:
      'Suministro de agua; evacuacion de agua residuales, gestion de desechos y descontaminacion',
    value: 'Recoleccion de desechos no peligrosos',
    label: 'Recoleccion de desechos no peligrosos',
  },
  {
    actividad:
      'Suministro de agua; evacuacion de agua residuales, gestion de desechos y descontaminacion',
    value: 'Recoleccion de desechos peligrosos',
    label: 'Recoleccion de desechos peligrosos',
  },
  {
    actividad:
      'Suministro de agua; evacuacion de agua residuales, gestion de desechos y descontaminacion',
    value: 'Tratamiento y eliminacion de desechos no peligrosos',
    label: 'Tratamiento y eliminacion de desechos no peligrosos',
  },
  {
    actividad:
      'Suministro de agua; evacuacion de agua residuales, gestion de desechos y descontaminacion',
    value: 'Tratamiento y eliminacion de desechos peligrosos',
    label: 'Tratamiento y eliminacion de desechos peligrosos',
  },
  {
    actividad:
      'Suministro de agua; evacuacion de agua residuales, gestion de desechos y descontaminacion',
    value: 'Tratamiento y eliminacion de desechos peligrosos',
    label: 'Tratamiento y eliminacion de desechos peligrosos',
  },
  {
    actividad:
      'Suministro de agua; evacuacion de agua residuales, gestion de desechos y descontaminacion',
    value: 'Recuperacion de materiales',
    label: 'Recuperacion de materiales',
  },
  {
    actividad:
      'Suministro de agua; evacuacion de agua residuales, gestion de desechos y descontaminacion',
    value:
      'Actividades de descontaminacion y otros servicios de gestion de desechos',
    label:
      'Actividades de descontaminacion y otros servicios de gestion de desechos',
  },
  {
    actividad: 'Construccion',
    value: 'Construccion de edificios',
    label: 'Construccion de edificios',
  },
  {
    actividad: 'Construccion',
    value: 'Construccion de carreteras y vias ferreas',
    label: 'Construccion de carreteras y vias ferreas',
  },
  {
    actividad: 'Construccion',
    value:
      'Construccion de obras para el tratamiento,  distribucion y suministro de agua y drenaje y construccion de sistemas de riego agricola.',
    label:
      'Construccion de obras para el tratamiento,  distribucion y suministro de agua y drenaje y construccion de sistemas de riego agricola.',
  },
  {
    actividad: 'Construccion',
    value:
      'Construccion de obras de generacion y conduccion de energia electrica.',
    label:
      'Construccion de obras de generacion y conduccion de energia electrica.',
  },
  {
    actividad: 'Construccion',
    value: 'Construccion de otros proyectos de ingenieria civil',
    label: 'Construccion de otros proyectos de ingenieria civil',
  },
  { actividad: 'Construccion', value: 'Demolicion', label: 'Demolicion' },
  {
    actividad: 'Construccion',
    value: 'Preparacion del terreno',
    label: 'Preparacion del terreno',
  },
  {
    actividad: 'Construccion',
    value: 'Instalacion electrica y de telecomunicaciones',
    label: 'Instalacion electrica y de telecomunicaciones',
  },
  {
    actividad: 'Construccion',
    value:
      'Instacion de fontaneria e instalacion de calefaccion y aire acondicionado',
    label:
      'Instacion de fontaneria e instalacion de calefaccion y aire acondicionado',
  },
  {
    actividad: 'Construccion',
    value: 'Otro tipo de instalaciones de construccion',
    label: 'Otro tipo de instalaciones de construccion',
  },
  {
    actividad: 'Construccion',
    value: 'Terminacion y acabados de edificios',
    label: 'Terminacion y acabados de edificios',
  },
  {
    actividad: 'Construccion',
    value: 'Otras actividades especializadas de la construccion',
    label: 'Otras actividades especializadas de la construccion',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta de vehiculos automotores',
    label: 'Venta de vehiculos automotores',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Mantenimiento y reparacion de vehiculos automotores',
    label: 'Mantenimiento y reparacion de vehiculos automotores',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta de partes, piezas y accesorios de vehiculos automotores',
    label: 'Venta de partes, piezas y accesorios de vehiculos automotores',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta, mantenimiento y reparacion de motocicletas y de sus partes, piezas y accesorios.',
    label:
      'Venta, mantenimiento y reparacion de motocicletas y de sus partes, piezas y accesorios.',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor a cambio de una retribucion o por contrato',
    label: 'Venta al por mayor a cambio de una retribucion o por contrato',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por mayor de materias primas agropecuarias y animales vivos',
    label:
      'Venta al por mayor de materias primas agropecuarias y animales vivos',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor de carne y productos carnicos',
    label: 'Venta al por mayor de carne y productos carnicos',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor de pescados y mariscos',
    label: 'Venta al por mayor de pescados y mariscos',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor de frutas y verduras frescas',
    label: 'Venta al por mayor de frutas y verduras frescas',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor de licores, bebidas y tabaco',
    label: 'Venta al por mayor de licores, bebidas y tabaco',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor de otros alimentos',
    label: 'Venta al por mayor de otros alimentos',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor de productos textiles y prendas de vestir',
    label: 'Venta al por mayor de productos textiles y prendas de vestir',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor de calzado',
    label: 'Venta al por mayor de calzado',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por mayor de aparatos, articulos y equipo de uso domestico',
    label:
      'Venta al por mayor de aparatos, articulos y equipo de uso domestico',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por mayor de productos farmaceuticos, veterinarios y articulos de tocador',
    label:
      'Venta al por mayor de productos farmaceuticos, veterinarios y articulos de tocador',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por mayor de juegos, juguetes, articulos deportivos y de esparcimiento',
    label:
      'Venta al por mayor de juegos, juguetes, articulos deportivos y de esparcimiento',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor de otros enseres domesticos n.c.p',
    label: 'Venta al por mayor de otros enseres domesticos n.c.p',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por mayor de computadoras, equipo informatico periferico y programas informaticos.',
    label:
      'Venta al por mayor de computadoras, equipo informatico periferico y programas informaticos.',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por mayor de equipo, partes y piezas electronicas y de telecomunicaciones',
    label:
      'Venta al por mayor de equipo, partes y piezas electronicas y de telecomunicaciones',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por mayor de maquinaria, equipo y materiales agropecuarios',
    label:
      'Venta al por mayor de maquinaria, equipo y materiales agropecuarios',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor de otro tipo de maquinaria y equipo.',
    label: 'Venta al por mayor de otro tipo de maquinaria y equipo.',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por mayor de combustibles solidos, liquidos y gaseosos y de productos conexos.',
    label:
      'Venta al por mayor de combustibles solidos, liquidos y gaseosos y de productos conexos.',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor de metales y de minerales metaliferos.',
    label: 'Venta al por mayor de metales y de minerales metaliferos.',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por mayor de materiales de construccion, articulos de ferreteria  y equipo y materiales de fontaneria y calefaccion.',
    label:
      'Venta al por mayor de materiales de construccion, articulos de ferreteria  y equipo y materiales de fontaneria y calefaccion.',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor de fertilizantes y productos agroquimicos',
    label: 'Venta al por mayor de fertilizantes y productos agroquimicos',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor de basura, desechos y otros productos n.c.p',
    label: 'Venta al por mayor de basura, desechos y otros productos n.c.p',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por mayor de otros productos no especializada.',
    label: 'Venta al por mayor de otros productos no especializada.',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por menor en supermercados, almacenes y similares',
    label: 'Venta al por menor en supermercados, almacenes y similares',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por menor en minisuper , pulperias y similares',
    label: 'Venta al por menor en minisuper , pulperias y similares',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por menor en tiendas por departamentos',
    label: 'Venta al por menor en tiendas por departamentos',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por menor en bazares y otros establecimientos no especializados n.c.p.',
    label:
      'Venta al por menor en bazares y otros establecimientos no especializados n.c.p.',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por menor de carne de bovino, porcino y sus derivados',
    label: 'Venta al por menor de carne de bovino, porcino y sus derivados',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por menor de carne de aves',
    label: 'Venta al por menor de carne de aves',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por menor de pescados y mariscos',
    label: 'Venta al por menor de pescados y mariscos',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por menor de frutas y verduras frescas',
    label: 'Venta al por menor de frutas y verduras frescas',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por menor de otros alimentos',
    label: 'Venta al por menor de otros alimentos',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por menor de bebidas en comercios especializados',
    label: 'Venta al por menor de bebidas en comercios especializados',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por menor de tabaco en comercios especializados',
    label: 'Venta al por menor de tabaco en comercios especializados',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por menor de libros, periodicos y articulos de papeleria en comercios especializados',
    label:
      'Venta al por menor de libros, periodicos y articulos de papeleria en comercios especializados',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por menor de grabaciones musicales y videograficas en comercios especializados',
    label:
      'Venta al por menor de grabaciones musicales y videograficas en comercios especializados',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por menor de articulos de deporte en comercios especializados',
    label:
      'Venta al por menor de articulos de deporte en comercios especializados',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por menor de juegos y de juguetes en almacenes especializados',
    label:
      'Venta al por menor de juegos y de juguetes en almacenes especializados',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por menor de prendas de vestir y articulos de cuero en almacenes especializados',
    label:
      'Venta al por menor de prendas de vestir y articulos de cuero en almacenes especializados',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por menor de zapatos  en comercios especializados',
    label: 'Venta al por menor de zapatos  en comercios especializados',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por menor de productos farmaceuticos y medicinales, cosmeticos y articulos de tocador en comercios especializados',
    label:
      'Venta al por menor de productos farmaceuticos y medicinales, cosmeticos y articulos de tocador en comercios especializados',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Otra venta al por menor de otros productos nuevos en comercios especializados',
    label:
      'Otra venta al por menor de otros productos nuevos en comercios especializados',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por menor de articulos de segunda mano',
    label: 'Venta al por menor de articulos de segunda mano',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por menor en puestos de venta y mercados de: alimentos, bebidas y de productos del tabaco.',
    label:
      'Venta al por menor en puestos de venta y mercados de: alimentos, bebidas y de productos del tabaco.',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por menor de productos textiles ,prendas de vestir y calzado, en puestos de venta y mercados',
    label:
      'Venta al por menor de productos textiles ,prendas de vestir y calzado, en puestos de venta y mercados',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Venta al por menor en puestos de venta y mercados de otras mercancias',
    label:
      'Venta al por menor en puestos de venta y mercados de otras mercancias',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value: 'Venta al por menor por correo y Internet',
    label: 'Venta al por menor por correo y Internet',
  },
  {
    actividad:
      'Comercio al por mayor y al por menor; reparacion de los vehiculos de motor y de las motocicletas',
    value:
      'Otros tipos de venta al por menor no realizada en comercios, puestos de venta o mercados.',
    label:
      'Otros tipos de venta al por menor no realizada en comercios, puestos de venta o mercados.',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Transporte de pasajeros por ferrocarril',
    label: 'Transporte de pasajeros por ferrocarril',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Transporte de carga por ferrocarril',
    label: 'Transporte de carga por ferrocarril',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value:
      'Transporte terrestre de pasajeros del area urbana, suburbana por via terrestre',
    label:
      'Transporte terrestre de pasajeros del area urbana, suburbana por via terrestre',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Transporte terrestre  de estudiantes y de empleados',
    label: 'Transporte terrestre  de estudiantes y de empleados',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Servicios de taxi',
    label: 'Servicios de taxi',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Transporte terrestre no regular de pasajeros, en buses',
    label: 'Transporte terrestre no regular de pasajeros, en buses',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Transporte terrestre regular en larga distancia de pasajeros',
    label: 'Transporte terrestre regular en larga distancia de pasajeros',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Otro transporte terrestre de pasajeros n.c.p',
    label: 'Otro transporte terrestre de pasajeros n.c.p',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Transporte terrestre  local e internacional de contenedores',
    label: 'Transporte terrestre  local e internacional de contenedores',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Otro transpote  de carga n.c.p.',
    label: 'Otro transpote  de carga n.c.p.',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Transporte por tuberias.',
    label: 'Transporte por tuberias.',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Transporte maritimo y de cabotaje de pasajeros',
    label: 'Transporte maritimo y de cabotaje de pasajeros',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Transporte maritimo y de cabotaje de carga.',
    label: 'Transporte maritimo y de cabotaje de carga.',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Transporte de pasajeros por vias de navegacion interiores',
    label: 'Transporte de pasajeros por vias de navegacion interiores',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Transporte de carga, por vias de navegacion interiores.',
    label: 'Transporte de carga, por vias de navegacion interiores.',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Transporte de pasajeros por via aerea',
    label: 'Transporte de pasajeros por via aerea',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Transporte de carga por via aerea',
    label: 'Transporte de carga por via aerea',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Almacenamiento y deposito',
    label: 'Almacenamiento y deposito',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value:
      'Remolque y asistencia  para vehiculo destinado al transporte terrestre',
    label:
      'Remolque y asistencia  para vehiculo destinado al transporte terrestre',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Servicios de estacionamientos  y garajes',
    label: 'Servicios de estacionamientos  y garajes',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value:
      'Servicios de administracion de carreteras,  puentes y otros servicios auxiliares',
    label:
      'Servicios de administracion de carreteras,  puentes y otros servicios auxiliares',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Actividades de servicio vinculadas al transporte acuatico',
    label: 'Actividades de servicio vinculadas al transporte acuatico',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Actividades de servicio secundario de transporte por via aerea.',
    label: 'Actividades de servicio secundario de transporte por via aerea.',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Manipulacion de carga',
    label: 'Manipulacion de carga',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Otras actividades de apoyo al transporte',
    label: 'Otras actividades de apoyo al transporte',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Servicios postales (Correo)',
    label: 'Servicios postales (Correo)',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Servicio de mensajeria',
    label: 'Servicio de mensajeria',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value:
      'Actividades de alojamiento para estancias  cortas en hoteles y moteles',
    label:
      'Actividades de alojamiento para estancias  cortas en hoteles y moteles',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value:
      'Actividades de alojamiento para estancias  cortas en cabanas, villas y similares',
    label:
      'Actividades de alojamiento para estancias  cortas en cabanas, villas y similares',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Otras actividades de alojamiento para estancias cortas n.c.p',
    label: 'Otras actividades de alojamiento para estancias cortas n.c.p',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value:
      'Actividades de campamentos , parques recreativos  de vehiculos  ( camping) y parques de caravanas',
    label:
      'Actividades de campamentos , parques recreativos  de vehiculos  ( camping) y parques de caravanas',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Otras actividades de alojamientos.',
    label: 'Otras actividades de alojamientos.',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Actividades de restaurantes y de servicios moviles de comidas',
    label: 'Actividades de restaurantes y de servicios moviles de comidas',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Suministros de comidas por encargo',
    label: 'Suministros de comidas por encargo',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Otras actividades del servicio de alimentacion',
    label: 'Otras actividades del servicio de alimentacion',
  },
  {
    actividad: 'Transporte y almacenamiento',
    value: 'Actividades de servicio de bebidas',
    label: 'Actividades de servicio de bebidas',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value: 'Edicion de librosEdicion de libros',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Edicion de directorios y de listas de correosEdicion de directorios y de listas de correos',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Edicion de periodicos, diarios y revistasEdicion de periodicos, diarios y revistas',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value: 'Otras actividades de edicionOtras actividades de edicion',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Edicion de programas informaticos.Edicion de programas informaticos.',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Actividades de produccion de peliculas cinematograficas, videos y programas de television.Actividades de produccion de peliculas cinematograficas, videos y programas de television.',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Actividades posteriores de la produccion de peliculas, videocintas y programas de television.Actividades posteriores de la produccion de peliculas, videocintas y programas de television.',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Actividades de distribucion de peliculas, videocintas y programas de television.Actividades de distribucion de peliculas, videocintas y programas de television.',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Actividades de proyeccion de peliculas.Actividades de proyeccion de peliculas.',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Actividades de grabacion y publicacion de grabaciones sonorasActividades de grabacion y publicacion de grabaciones sonoras',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value: 'Transmisiones de radioTransmisiones de radio',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Programacion y transmision de televisionProgramacion y transmision de television',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value: 'TelecomunicacionesTelecomunicaciones',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Actividades de telecomunicaciones alambricasActividades de telecomunicaciones alambricas',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Actividades de telecomunicaciones alambricasActividades de telecomunicaciones alambricas',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Actividades de telecomunicaciones inalambricasActividades de telecomunicaciones inalambricas',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Actividades de telecomunicaciones inalambricasActividades de telecomunicaciones inalambricas',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Actividades de telecomunicaciones por sateliteActividades de telecomunicaciones por satelite',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Actividades de telecomunicaciones por sateliteActividades de telecomunicaciones por satelite',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value: 'Servicios de cafe internetServicios de cafe internet',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Otras actividades de telecomunicacion.Otras actividades de telecomunicacion.',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Actividades de programacion informaticaActividades de programacion informatica',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Actividades de consultoria informatica y gestion de instalaciones informaticasActividades de consultoria informatica y gestion de instalaciones informaticas',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Otras actividades de la tecnologia de informacion y servicio informaticosOtras actividades de la tecnologia de informacion y servicio informaticos',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Procesamiento de datos, hospedaje y actividades conexasProcesamiento de datos, hospedaje y actividades conexas',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value: 'Portales WebPortales Web',
  },
  {
    actividad: 'Informacion y comunicaciones.',
    value:
      'Otros servicios de informacion n.c.p.Otros servicios de informacion n.c.p.',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Banca Central',
    label: 'Banca Central',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Otros tipos de intermediacion monetaria',
    label: 'Otros tipos de intermediacion monetaria',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Actividades de sociedades de cartera',
    label: 'Actividades de sociedades de cartera',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Fondo y sociedades de Inversion y entidades financieras similares',
    label: 'Fondo y sociedades de Inversion y entidades financieras similares',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Arriendamiento financiero',
    label: 'Arriendamiento financiero',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Otros tipos de credito.',
    label: 'Otros tipos de credito.',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value:
      'Otras actividades de servicios financiero, excepto las de seguros y fondos de penciones n.c.p',
    label:
      'Otras actividades de servicios financiero, excepto las de seguros y fondos de penciones n.c.p',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Seguros de vida',
    label: 'Seguros de vida',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Seguros generales',
    label: 'Seguros generales',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Reaseguros',
    label: 'Reaseguros',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Fondos de pension',
    label: 'Fondos de pension',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Administracion de mercados financieros',
    label: 'Administracion de mercados financieros',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Corretaje de valores y de contratos de productos basicos',
    label: 'Corretaje de valores y de contratos de productos basicos',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value:
      'Otras actividades auxiliares a las actividades de servicios financieros.',
    label:
      'Otras actividades auxiliares a las actividades de servicios financieros.',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Evaluacion de riesgos  y danos.',
    label: 'Evaluacion de riesgos  y danos.',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Actividades de agentes  y corredores de seguro',
    label: 'Actividades de agentes  y corredores de seguro',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Otras actividades auxiliares de seguros y fondos de pensiones',
    label: 'Otras actividades auxiliares de seguros y fondos de pensiones',
  },
  {
    actividad: 'Actividades financieras y de seguros.',
    value: 'Actividades de administracion  de fondos.',
    label: 'Actividades de administracion  de fondos.',
  },
  {
    actividad: 'Actividades inmobiliarias',
    value: 'Actividades inmobiliarias con bienes propios o arrendados',
    label: 'Actividades inmobiliarias con bienes propios o arrendados',
  },
  {
    actividad: 'Actividades inmobiliarias',
    value:
      'Actividades inmobiliarias realizadas  a cambio de una retribucion o por contrata.',
    label:
      'Actividades inmobiliarias realizadas  a cambio de una retribucion o por contrata.',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value: 'Actividades juridicas.',
    label: 'Actividades juridicas.',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value:
      'Actividades de contabilidad, teneduria de libros y auditorias; consultoria fiscal',
    label:
      'Actividades de contabilidad, teneduria de libros y auditorias; consultoria fiscal',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value: 'Actividades de oficinas principales',
    label: 'Actividades de oficinas principales',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value: 'Actividades de consultoria en gestion',
    label: 'Actividades de consultoria en gestion',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value:
      'Actividades de arquitectura  e ingenieria y actividades conexas de consultoria tecnica',
    label:
      'Actividades de arquitectura  e ingenieria y actividades conexas de consultoria tecnica',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value: 'Ensayos y analisis tecnicos.',
    label: 'Ensayos y analisis tecnicos.',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value:
      'Investigaciones  y desarrollo experimental en el campo de las ciencias naturales y la ingenieria.',
    label:
      'Investigaciones  y desarrollo experimental en el campo de las ciencias naturales y la ingenieria.',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value:
      'Investigacion y desarrollo experimental en el campo de las ciencias sociales y las humanidades.',
    label:
      'Investigacion y desarrollo experimental en el campo de las ciencias sociales y las humanidades.',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value: 'Publicidad',
    label: 'Publicidad',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value: 'Estudios de mercados y encuestas de opinion publica',
    label: 'Estudios de mercados y encuestas de opinion publica',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value: 'Actividades especializadas  de diseno',
    label: 'Actividades especializadas  de diseno',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value: 'Actividades de fotografia.',
    label: 'Actividades de fotografia.',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value: 'Otras actividades profesionales, cientificas y tecnicas n.c.p.',
    label: 'Otras actividades profesionales, cientificas y tecnicas n.c.p.',
  },
  {
    actividad: 'Actividades profesionales,  cientificas y tecnicas',
    value: 'Actividades veterinarias',
    label: 'Actividades veterinarias',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Alquiler de automoviles sin conductor',
    label: 'Alquiler de automoviles sin conductor',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Alquiler de camiones de carga, autobuses y remolques sin conductor',
    label: 'Alquiler de camiones de carga, autobuses y remolques sin conductor',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Alquiler y arrendamiento de equipo recreativo y deportivo.',
    label: 'Alquiler y arrendamiento de equipo recreativo y deportivo.',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Alquiler de cintas de video y discos',
    label: 'Alquiler de cintas de video y discos',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value:
      'Alquiler y arrendamiento de otros efectos personales  y enseres domesticos',
    label:
      'Alquiler y arrendamiento de otros efectos personales  y enseres domesticos',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value:
      'Alquiler y arrendamiento de otros tipo de maquinaria, equipo y bienes tangibles',
    label:
      'Alquiler y arrendamiento de otros tipo de maquinaria, equipo y bienes tangibles',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value:
      'Arrendamiento de propiedad intelectual y productos similares excepto obras protegidas por derechos de autor',
    label:
      'Arrendamiento de propiedad intelectual y productos similares excepto obras protegidas por derechos de autor',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Actividades de agencias  empleos',
    label: 'Actividades de agencias  empleos',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Actividades de agencias de empleo temporal',
    label: 'Actividades de agencias de empleo temporal',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Otras actividades de dotacion de recursos humanos.',
    label: 'Otras actividades de dotacion de recursos humanos.',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Actividades de agencias de viajes',
    label: 'Actividades de agencias de viajes',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Actividades de operadores  turisticos',
    label: 'Actividades de operadores  turisticos',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Otros servicios de reservacion y actividades relacionadas',
    label: 'Otros servicios de reservacion y actividades relacionadas',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Actividades de seguridad  privada',
    label: 'Actividades de seguridad  privada',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Actividades de servicios de sistemas  de seguridad',
    label: 'Actividades de servicios de sistemas  de seguridad',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Actividades de investigacion',
    label: 'Actividades de investigacion',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Actividades combinadas de apoyo a instalaciones',
    label: 'Actividades combinadas de apoyo a instalaciones',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Limpieza general  de edificios',
    label: 'Limpieza general  de edificios',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value:
      'Otras actividades de limpieza de edificios e instalaciones  industriales',
    label:
      'Otras actividades de limpieza de edificios e instalaciones  industriales',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Actividades de paisajismo y servicios de mantenimiento conexos',
    label: 'Actividades de paisajismo y servicios de mantenimiento conexos',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Actividades combinadas de servicios administrativos de oficina',
    label: 'Actividades combinadas de servicios administrativos de oficina',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value:
      'Fotocopiado, preparacion de documentos y otras actividades especializadas de apoyo de oficina',
    label:
      'Fotocopiado, preparacion de documentos y otras actividades especializadas de apoyo de oficina',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Actividades de centros de llamadas',
    label: 'Actividades de centros de llamadas',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Organizacion de convenciones y eventos comerciales',
    label: 'Organizacion de convenciones y eventos comerciales',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value:
      'Actividades de agencias de cobro y agencias de calificacion crediticia',
    label:
      'Actividades de agencias de cobro y agencias de calificacion crediticia',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Actividades de envasado y empacado',
    label: 'Actividades de envasado y empacado',
  },
  {
    actividad: 'Actividades de servicios administrativas y de apoyo',
    value: 'Otras actividades de servicios de apoyo a las empresas n.c.p.',
    label: 'Otras actividades de servicios de apoyo a las empresas n.c.p.',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Actividades de la administracion  publica en general',
    label: 'Actividades de la administracion  publica en general',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value:
      'Regulacion de las actividades de organismos que prestan  servicios sanitarios, educativos, culturales y otros servicios sociales, excepto servicios de seguridad social.',
    label:
      'Regulacion de las actividades de organismos que prestan  servicios sanitarios, educativos, culturales y otros servicios sociales, excepto servicios de seguridad social.',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Regulacion y facilitacion de la actividad economica.',
    label: 'Regulacion y facilitacion de la actividad economica.',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Relaciones exteriores.',
    label: 'Relaciones exteriores.',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Actividades de defensa.',
    label: 'Actividades de defensa.',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Actividades de mantenimiento del orden publico y de seguridad.',
    label: 'Actividades de mantenimiento del orden publico y de seguridad.',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value:
      'Actividades de planes de seguridad social de afiliacion obligatoria.',
    label:
      'Actividades de planes de seguridad social de afiliacion obligatoria.',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Ensenanza preescolar y primaria',
    label: 'Ensenanza preescolar y primaria',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Ensenanza secundaria de formacion general',
    label: 'Ensenanza secundaria de formacion general',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Ensenanza de formacion tecnica y profesional',
    label: 'Ensenanza de formacion tecnica y profesional',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Ensenanza universitaria',
    label: 'Ensenanza universitaria',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Ensenanza parauniversitaria',
    label: 'Ensenanza parauniversitaria',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Educacion deportiva  y recreativa',
    label: 'Educacion deportiva  y recreativa',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Educacion cultural',
    label: 'Educacion cultural',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Otros tipos de ensenanza n.c.p.',
    label: 'Otros tipos de ensenanza n.c.p.',
  },
  {
    actividad:
      'Administracion publica y defensa; planes de seguridad social de afiliacion obligatoria.',
    value: 'Actividades de apoyo a la ensenanza',
    label: 'Actividades de apoyo a la ensenanza',
  },
  {
    actividad: 'Actividades de atencion de la salud humana y asistencia social',
    value: 'Actividades de hospitales',
    label: 'Actividades de hospitales',
  },
  {
    actividad: 'Actividades de atencion de la salud humana y asistencia social',
    value: 'Actividades de medicos',
    label: 'Actividades de medicos',
  },
  {
    actividad: 'Actividades de atencion de la salud humana y asistencia social',
    value: 'Actividades de odontologos',
    label: 'Actividades de odontologos',
  },
  {
    actividad: 'Actividades de atencion de la salud humana y asistencia social',
    value: 'Laboratorios medicos y de diagnostico',
    label: 'Laboratorios medicos y de diagnostico',
  },
  {
    actividad: 'Actividades de atencion de la salud humana y asistencia social',
    value: 'Otras actividades relacionadas con la salud humana',
    label: 'Otras actividades relacionadas con la salud humana',
  },
  {
    actividad: 'Actividades de atencion de la salud humana y asistencia social',
    value: 'Actividades de atencion de enfermeria en instituciones',
    label: 'Actividades de atencion de enfermeria en instituciones',
  },
  {
    actividad: 'Actividades de atencion de la salud humana y asistencia social',
    value: 'Actividades de atencion de enfermeria en instituciones',
    label: 'Actividades de atencion de enfermeria en instituciones',
  },
  {
    actividad: 'Actividades de atencion de la salud humana y asistencia social',
    value:
      'Actividades de atencion en instituciones para personas con retraso mental, enfermos mentales y toxicomanos',
    label:
      'Actividades de atencion en instituciones para personas con retraso mental, enfermos mentales y toxicomanos',
  },
  {
    actividad: 'Actividades de atencion de la salud humana y asistencia social',
    value:
      'Actividades en instituciones dedicadas al cuidado del adulto mayor y personas con discapacidad',
    label:
      'Actividades en instituciones dedicadas al cuidado del adulto mayor y personas con discapacidad',
  },
  {
    actividad: 'Actividades de atencion de la salud humana y asistencia social',
    value: 'Otras actividades de atencion en instituciones n.c.p.',
    label: 'Otras actividades de atencion en instituciones n.c.p.',
  },
  {
    actividad: 'Actividades de atencion de la salud humana y asistencia social',
    value:
      'Asistencia social sin alojamiento para el adulto mayor y personas con discapacidad',
    label:
      'Asistencia social sin alojamiento para el adulto mayor y personas con discapacidad',
  },
  {
    actividad: 'Actividades de atencion de la salud humana y asistencia social',
    value: 'Otras actividades de asistencia  social sin alojamiento n.c.p.',
    label: 'Otras actividades de asistencia  social sin alojamiento n.c.p.',
  },
  {
    actividad: 'Actividades artisticas, de entretenimiento  y recreativas',
    value: 'Actividades creativas, artisticas y de entretenimiento',
    label: 'Actividades creativas, artisticas y de entretenimiento',
  },
  {
    actividad: 'Actividades artisticas, de entretenimiento  y recreativas',
    value: 'Actividades de Bibliotecas y archivos',
    label: 'Actividades de Bibliotecas y archivos',
  },
  {
    actividad: 'Actividades artisticas, de entretenimiento  y recreativas',
    value: 'Actividades de museos y preservacion de lugares historicos',
    label: 'Actividades de museos y preservacion de lugares historicos',
  },
  {
    actividad: 'Actividades artisticas, de entretenimiento  y recreativas',
    value: 'Actividades de jardines botanicos, zoologicos y parques naturales',
    label: 'Actividades de jardines botanicos, zoologicos y parques naturales',
  },
  {
    actividad: 'Actividades artisticas, de entretenimiento  y recreativas',
    value: 'Actividades de juego de azar y apuestas',
    label: 'Actividades de juego de azar y apuestas',
  },
  {
    actividad: 'Actividades artisticas, de entretenimiento  y recreativas',
    value: 'Gestion de instalaciones deportivas',
    label: 'Gestion de instalaciones deportivas',
  },
  {
    actividad: 'Actividades artisticas, de entretenimiento  y recreativas',
    value: 'Actividades de clubes deportivos',
    label: 'Actividades de clubes deportivos',
  },
  {
    actividad: 'Actividades artisticas, de entretenimiento  y recreativas',
    value: 'Otras actividades deportivas',
    label: 'Otras actividades deportivas',
  },
  {
    actividad: 'Actividades artisticas, de entretenimiento  y recreativas',
    value: 'Actividades de parques de atracciones y parques tematicos',
    label: 'Actividades de parques de atracciones y parques tematicos',
  },
  {
    actividad: 'Actividades artisticas, de entretenimiento  y recreativas',
    value: 'Otras actividades de esparcimiento y recreativas  n.c.p.',
    label: 'Otras actividades de esparcimiento y recreativas  n.c.p.',
  },
  {
    actividad: 'Otras actividades de servicios',
    value: 'Actividades de asociaciones empresariales y de empleadores',
    label: 'Actividades de asociaciones empresariales y de empleadores',
  },
  {
    actividad: 'Otras actividades de servicios',
    value: 'Actividades de asociaciones  profesionales',
    label: 'Actividades de asociaciones  profesionales',
  },
  {
    actividad: 'Otras actividades de servicios',
    value: 'Actividades de sindicatos',
    label: 'Actividades de sindicatos',
  },
  {
    actividad: 'Otras actividades de servicios',
    value: 'Actividades de organizaciones religiosas',
    label: 'Actividades de organizaciones religiosas',
  },
  {
    actividad: 'Otras actividades de servicios',
    value: 'Actividades de organizaciones politicas',
    label: 'Actividades de organizaciones politicas',
  },
  {
    actividad: 'Otras actividades de servicios',
    value: 'Actividades de otras asociaciones n.c.p.',
    label: 'Actividades de otras asociaciones n.c.p.',
  },
  {
    actividad: 'Otras actividades de servicios',
    value: 'Reparacion de computadoras y equipo periferico',
    label: 'Reparacion de computadoras y equipo periferico',
  },
  {
    actividad: 'Otras actividades de servicios',
    value: 'Reparacion de equipos de comunicaciones',
    label: 'Reparacion de equipos de comunicaciones',
  },
  {
    actividad: 'Otras actividades de servicios',
    value:
      'Reparacion de aparatos  de uso domestico y equipo domestico y de jardineria',
    label:
      'Reparacion de aparatos  de uso domestico y equipo domestico y de jardineria',
  },
  {
    actividad: 'Otras actividades de servicios',
    value: 'Reparacion de calzado y articulos de cuero',
    label: 'Reparacion de calzado y articulos de cuero',
  },
  {
    actividad: 'Otras actividades de servicios',
    value: 'Reparacion de muebles y accesorios domesticos',
    label: 'Reparacion de muebles y accesorios domesticos',
  },
  {
    actividad: 'Otras actividades de servicios',
    value: 'Reparacion de otros bienes personales y enseres  domesticos n.c.p.',
    label: 'Reparacion de otros bienes personales y enseres  domesticos n.c.p.',
  },
  {
    actividad: 'Otras actividades de servicios',
    value:
      'Lavado y limpieza, incluida la limpieza en seco, de productos  textiles  y piel',
    label:
      'Lavado y limpieza, incluida la limpieza en seco, de productos  textiles  y piel',
  },
  {
    actividad: 'Otras actividades de servicios',
    value: 'Actividades de peluqueria y otros tratamientos de belleza',
    label: 'Actividades de peluqueria y otros tratamientos de belleza',
  },
  {
    actividad: 'Otras actividades de servicios',
    value: 'Funerales y actividades conexas',
    label: 'Funerales y actividades conexas',
  },
];

export const NACIONALIDAD = [
  { value: 'Afgano', label: 'Afgano' },
  { value: 'Albano', label: 'Albano' },
  { value: 'Aleman', label: 'Aleman' },
  { value: 'Andorrano', label: 'Andorrano' },
  { value: 'Angolano', label: 'Angolano' },
  { value: 'Antiguano', label: 'Antiguano' },
  { value: 'Saudi', label: 'Saudi' },
  { value: 'Argelino', label: 'Argelino' },
  { value: 'Argentino', label: 'Argentino' },
  { value: 'Armenio', label: 'Armenio' },
  { value: 'Australiano', label: 'Australiano' },
  { value: 'Austriaco', label: 'Austriaco' },
  { value: 'Azerbaiyana', label: 'Azerbaiyana' },
  { value: 'Bahameño', label: 'Bahameño' },
  { value: 'Bangladesi', label: 'Bangladesi' },
  { value: 'Barbadense', label: 'Barbadense' },
  { value: 'Bareini', label: 'Bareini' },
  { value: 'Belga', label: 'Belga' },
  { value: 'Beliceño', label: 'Beliceño' },
  { value: 'Benines', label: 'Benines' },
  { value: 'Birmano', label: 'Birmano' },
  { value: 'Boliviano', label: 'Boliviano' },
  { value: 'Bosnia', label: 'Bosnia' },
  { value: 'Botsuano', label: 'Botsuano' },
  { value: 'Brasileño', label: 'Brasileño' },
  { value: 'Bruneano', label: 'Bruneano' },
  { value: 'Bulgaro', label: 'Bulgaro' },
  { value: 'Burkines', label: 'Burkines' },
  { value: 'Burundes', label: 'Burundes' },
  { value: 'Butanes', label: 'Butanes' },
  { value: 'Caboverdiano', label: 'Caboverdiano' },
  { value: 'Camboyano', label: 'Camboyano' },
  { value: 'Camerunes', label: 'Camerunes' },
  { value: 'Canadiense', label: 'Canadiense' },
  { value: 'Catari', label: 'Catari' },
  { value: 'Centroafricano', label: 'Centroafricano' },
  { value: 'Chadiano', label: 'Chadiano' },
  { value: 'Checo', label: 'Checo' },
  { value: 'Chileno', label: 'Chileno' },
  { value: 'Chino', label: 'Chino' },
  { value: 'Chipriota', label: 'Chipriota' },
  { value: 'Colombiano', label: 'Colombiano' },
  { value: 'Comorense', label: 'Comorense' },
  { value: 'Congoleño', label: 'Congoleño' },
  { value: 'Norcoreano', label: 'Norcoreano' },
  { value: 'Surcoreano', label: 'Surcoreano' },
  { value: 'Marfileño', label: 'Marfileño' },
  { value: 'Costarricense', label: 'Costarricense' },
  { value: 'Croata', label: 'Croata' },
  { value: 'Cubano', label: 'Cubano' },
  { value: 'Danes', label: 'Danes' },
  { value: 'Dominiques', label: 'Dominiques' },
  { value: 'Dominicano', label: 'Dominicano' },
  { value: 'Ecuatoriano', label: 'Ecuatoriano' },
  { value: 'Egipcio', label: 'Egipcio' },
  { value: 'Salvadoreño', label: 'Salvadoreño' },
  { value: 'Emirati', label: 'Emirati' },
  { value: 'Eritreo', label: 'Eritreo' },
  { value: 'Eslovaco', label: 'Eslovaco' },
  { value: 'Esloveno', label: 'Esloveno' },
  { value: 'Español', label: 'Español' },
  { value: 'Estadounidense', label: 'Estadounidense' },
  { value: 'Estonio', label: 'Estonio' },
  { value: 'Etiope', label: 'Etiope' },
  { value: 'Filipino', label: 'Filipino' },
  { value: 'Fines', label: 'Fines' },
  { value: 'Fiyiano', label: 'Fiyiano' },
  { value: 'Frances', label: 'Frances' },
  { value: 'Gabones', label: 'Gabones' },
  { value: 'Gambiano', label: 'Gambiano' },
  { value: 'Georgiano', label: 'Georgiano' },
  { value: 'Ghanes ', label: 'Ghanes ' },
  { value: 'Granadino', label: 'Granadino' },
  { value: 'Griego', label: 'Griego' },
  { value: 'Guatemalteco', label: 'Guatemalteco' },
  { value: 'Guineano', label: 'Guineano' },
  { value: 'Bisauguineano', label: 'Bisauguineano' },
  { value: 'Ecuatoguineano ', label: 'Ecuatoguineano ' },
  { value: 'Guyanes', label: 'Guyanes' },
  { value: 'Haitiano', label: 'Haitiano' },
  { value: 'Hondureño', label: 'Hondureño' },
  { value: 'Hungaro', label: 'Hungaro' },
  { value: 'Indio', label: 'Indio' },
  { value: 'Indonesio ', label: 'Indonesio ' },
  { value: 'Iraki', label: 'Iraki' },
  { value: 'Irani', label: 'Irani' },
  { value: 'Irlandes', label: 'Irlandes' },
  { value: 'Islandes', label: 'Islandes' },
  { value: 'Israeli ', label: 'Israeli ' },
  { value: 'Italiano', label: 'Italiano' },
  { value: 'Jamaicano', label: 'Jamaicano' },
  { value: 'Japones', label: 'Japones' },
  { value: 'Jordano', label: 'Jordano' },
  { value: 'Kazajo', label: 'Kazajo' },
  { value: 'Keniata', label: 'Keniata' },
  { value: 'Kirguis', label: 'Kirguis' },
  { value: 'Kiribatiano', label: 'Kiribatiano' },
  { value: 'Kuwaiti', label: 'Kuwaiti' },
  { value: 'Laosiano', label: 'Laosiano' },
  { value: 'Lesotense', label: 'Lesotense' },
  { value: 'Letonio', label: 'Letonio' },
  { value: 'Libanes', label: 'Libanes' },
  { value: 'Liberiano', label: 'Liberiano' },
  { value: 'Libio', label: 'Libio' },
  { value: 'Liechtensteiniano ', label: 'Liechtensteiniano ' },
  { value: 'Lituano', label: 'Lituano' },
  { value: 'Luxemburgueses', label: 'Luxemburgueses' },
  { value: 'Macedonio', label: 'Macedonio' },
  { value: 'Malgache', label: 'Malgache' },
  { value: 'Malayo', label: 'Malayo' },
  { value: 'Malaui', label: 'Malaui' },
  { value: 'Maltes', label: 'Maltes' },
  { value: 'Marroqui', label: 'Marroqui' },
  { value: 'Mexicano', label: 'Mexicano' },
  { value: 'Moldavo', label: 'Moldavo' },
  { value: 'Nepali', label: 'Nepali' },
  { value: 'Nicaragüense', label: 'Nicaragüense' },
  { value: 'Nigeriano', label: 'Nigeriano' },
  { value: 'Noruego', label: 'Noruego' },
  { value: 'Neozelandes', label: 'Neozelandes' },
  { value: 'Omani', label: 'Omani' },
  { value: 'Neerlandes', label: 'Neerlandes' },
  { value: 'Pakistani', label: 'Pakistani' },
  { value: 'Panameño', label: 'Panameño' },
  { value: 'Paraguayo', label: 'Paraguayo' },
  { value: 'Peruano', label: 'Peruano' },
  { value: 'Polaco', label: 'Polaco' },
  { value: 'Portugues', label: 'Portugues' },
  { value: 'Britanico', label: 'Britanico' },
  { value: 'Ruandes', label: 'Ruandes' },
  { value: 'Rumano', label: 'Rumano' },
  { value: 'Ruso', label: 'Ruso' },
  { value: 'Salomonense', label: 'Salomonense' },
  { value: 'Serbio', label: 'Serbio' },
  { value: 'Singapurense', label: 'Singapurense' },
  { value: 'Sueco', label: 'Sueco' },
  { value: 'Suizo', label: 'Suizo' },
  { value: 'Surinames', label: 'Surinames' },
  { value: 'Tailandes', label: 'Tailandes' },
  { value: 'Trinitense', label: 'Trinitense' },
  { value: 'Turco', label: 'Turco' },
  { value: 'Uruguayo', label: 'Uruguayo' },
  { value: 'Uzbeco/Uzbeko', label: 'Uzbeco/Uzbeko' },
  { value: 'Vanuatuense', label: 'Vanuatuense' },
  { value: 'Venezolano', label: 'Venezolano' },
  { value: 'Vietnamita', label: 'Vietnamita' },
  { value: 'Yemeni', label: 'Yemeni' },
];

export const PEP = [
  { value: 'Diputados', label: 'Diputados' },
  { value: 'Presidente', label: 'Presidente' },
  { value: 'Vice Presidentes', label: 'Vice Presidentes' },
  { value: 'Ministros', label: 'Ministros' },
  { value: 'Vice Ministros', label: 'Vice Ministros' },
  {
    value: 'Magistrados propietarios y suplentes del Poder Judicial ',
    label: 'Magistrados propietarios y suplentes del Poder Judicial ',
  },
  {
    value: 'Magistrados propietarios y suplentes del TSE',
    label: 'Magistrados propietarios y suplentes del TSE',
  },
  {
    value: 'Contralor y Sub Contralor generales de la Republica ',
    label: 'Contralor y Sub Contralor generales de la Republica ',
  },
  {
    value: 'Defensor y Defensor Adjunto de los Habitantes ',
    label: 'Defensor y Defensor Adjunto de los Habitantes ',
  },
  {
    value: 'Procurador y Procurador Adjunto General de la Republica',
    label: 'Procurador y Procurador Adjunto General de la Republica',
  },
  {
    value: 'Fiscal General de la Republica',
    label: 'Fiscal General de la Republica',
  },
  { value: 'Rectores y Vicerrectores', label: 'Rectores y Vicerrectores' },
  {
    value: 'Regulador General de la Republica',
    label: 'Regulador General de la Republica',
  },
  {
    value:
      'Superintendentes de Instituciones Publicas y de Servicios Publicos, asi como los intendentes',
    label:
      'Superintendentes de Instituciones Publicas y de Servicios Publicos, asi como los intendentes',
  },
  { value: 'Contador Nacional', label: 'Contador Nacional' },
  { value: 'Tesorero Nacional ', label: 'Tesorero Nacional ' },
  {
    value: 'Oficiales mayores de los ministerios ',
    label: 'Oficiales mayores de los ministerios ',
  },
  { value: 'Presidentes ejecutivos ', label: 'Presidentes ejecutivos ' },
  {
    value: 'Regidores propietarios y suplentes',
    label: 'Regidores propietarios y suplentes',
  },
  { value: 'Alcaldes municipales', label: 'Alcaldes municipales' },
  { value: 'Alcaldes suplentes ', label: 'Alcaldes suplentes ' },
  { value: 'Vice Alcaldes', label: 'Vice Alcaldes' },
  {
    value: 'Jefes de misiones diplomaticas',
    label: 'Jefes de misiones diplomaticas',
  },
];

export const ESTRUCTURA_PROPIEDAD = [
  {
    value:
      'Sociedad de capital con estructura simple (menos de tres niveles de sociedades para llegar a persona fisica).',
    label:
      'Sociedad de capital con estructura simple (menos de tres niveles de sociedades para llegar a persona fisica).',
  },
  { value: 'Administracion Publica', label: 'Administracion Publica' },
  { value: 'Fideicomiso', label: 'Fideicomiso' },
  { value: 'Asociaciones', label: 'FideAsociacionesicomiso' },
  {
    value:
      'Sociedad de capital con estructura compleja (igual o mas de tres niveles de sociedades para llegar a persona fisica)',
    label:
      'Sociedad de capital con estructura compleja (igual o mas de tres niveles de sociedades para llegar a persona fisica)',
  },
  { value: 'Fundaciones', label: 'Fundaciones' },
  {
    value: 'Cotiza en alguna bolsa o mercado organizado',
    label: 'Cotiza en alguna bolsa o mercado organizado',
  },
];

export const TIPO_PERSONA = [
  { value: 'Persona fisica', label: 'Persona fisica' },
  {
    value: 'Sociedad de capital simple ',
    label: 'Sociedad de capital simple ',
  },
  { value: 'Fideicomiso', label: 'Fideicomiso' },
  { value: 'Asociaciones', label: 'Asociaciones' },
  { value: 'Fundaciones', label: 'Fundaciones' },
  { value: 'Cotiza en bolsa', label: 'Cotiza en bolsa' },
  { value: 'Administracion Publica', label: 'Administracion Publica' },
];

export const TIPO_FIRMA = [
  { value: 'Individual', label: 'Individual' },
  { value: 'Mancomunada', label: 'Mancomunada' },
];

export const TIPOS_DNI = [
  { value: 'Fisico', label: 'Fisico' },
  { value: 'Juridico', label: 'Juridico' },
  { value: 'Nite', label: 'Nite' },
  { value: 'Cedula Residencia', label: 'Residencia' },
  { value: 'Dimex', label: 'Dimex' },
];
