import React, { useEffect, useState } from 'react';
import './Slider.scss';
import SliderLib from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const CustomSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#BB9947',
    boxShadow: iOSBoxShadow,
    borderColor: '#937734',
    borderWidth: 3,
    borderStyle: 'solid',
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 5px)',
    top: -20,
    '& *': {
      background: 'transparent',
      color: '#BB9947',
      fontWeight: 'bold',
      fontSize: 13,
    },
  },
  track: {
    height: 6,
    backgroundColor: '#000000',
  },
  rail: {
    height: 6,
    opacity: 0.5,
    backgroundColor: '#F1EBDA',
  },
  mark: {
    backgroundColor: '#BB9947',
    height: 12,
    width: 1,
  },
  markLabel: {
    fontSize: 13,
    marginTop: 5,
  },
  markActive: {
    opacity: 1,
    backgroundColor: '#BB9947',
  },
})(SliderLib);

const Slider = ({
  label,
  marks,
  value,
  onChange,
  min,
  max,
  expandable,
  maxLimit,
  minLimit,
  valueLabelFormat,
}) => {
  const [localMarks, setLocalMarks] = useState(marks);
  const [modifiedMarksLocally, setModifiedMarksLocally] = useState(false);
  const [localMax, setLocalMax] = useState(max);
  const [localMin, setLocalMin] = useState(min);

  useEffect(() => {
    if (!modifiedMarksLocally) {
      setLocalMarks(marks);
    }
  }, [marks, modifiedMarksLocally]);

  useEffect(() => {
    setLocalMin(min);
  }, [min]);

  useEffect(() => {
    setLocalMax(max);
  }, [max]);

  const showLeft = minLimit == null || localMin > minLimit;
  const leftButton = expandable && showLeft && (
    <button
      className="slider__button slider__button--left"
      onClick={(e) => {
        setModifiedMarksLocally(true);
        setLocalMarks(
          localMarks.map(mark => ({
            value: mark.value - 1,
            label: mark.value - 1,
          }))
        );
        setLocalMin(localMin - 1);
        setLocalMax(localMax - 1);
        e.preventDefault();
      }}
    />
  );

  const showRight = maxLimit == null || localMax < maxLimit;
  const rightButton = expandable && showRight && (
    <button
      className="slider__button slider__button--right"
      onClick={(e) => {
        setModifiedMarksLocally(true);
        setLocalMarks(
          localMarks.map(mark => ({
            value: mark.value + 1,
            label: mark.value + 1,
          }))
        );
        setLocalMin(localMin + 1);
        setLocalMax(localMax + 1);
        e.preventDefault();
      }}
    />
  );
  return (
    <div className="slider">
      <div className="slider__label">
        <label>{label}</label>
      </div>
      <div className="slider__control">
        <CustomSlider
          aria-label="custom thumb label"
          value={value}
          marks={localMarks}
          valueLabelDisplay="on"
          step={null}
          min={localMin}
          max={localMax}
          onChange={onChange}
          valueLabelFormat={valueLabelFormat}
        />
        {leftButton}
        {rightButton}
      </div>
    </div>
  );
};
Slider.defaultProps = {
  value: 0,
};

export default Slider;
