import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useSingleBlogCategoriy } from '../../ducks/blog/hooks';
import BlogSidebar from '../BlogSidebar';
import EmptyComponent from '../Empty/EmptyComponent';
import './BlogCategory.scss';

export const BlogWrapper = ({ className, children, isPost, title, subtitle }) => (
  <div className={`${className} blog-category`}>
    <BlogSidebar isPost={isPost}>
      <div className='blog-category__head'>
        <h1>{title || <>Bienvenidos a nuestras<br></br>Cápsulas Capital</>}</h1>
        <p>{subtitle || <>Compartiremos con vos las mejores soluciones financieras.<br />
        ¡Estamos aquí para ayudarte! Aprendé, planificá, gestioná bien tus deudas y ahorrá.</>}</p>
      </div>
      <div className='blog-category__content'>
        {children}
      </div>
    </BlogSidebar>
  </div>
);

export const BlogCategoryEmpty = ({ blogTitle, blogSubtitle, children, className = '', data, isLoading, message, title, subtitle, grid = true }) => {
  return (
    <BlogWrapper className={className} title={blogTitle} subtitle={blogSubtitle}>
      <h2>{title}</h2>
      <h3 dangerouslySetInnerHTML={{ __html: subtitle }}/>
      <EmptyComponent data={data} isLoading={isLoading} message={message}>
        <div className={classNames('blog-category__content-posts', {
          'blog-category__content-posts--grid': !!grid,
        })}>
          {children}
        </div>
      </EmptyComponent>
    </BlogWrapper>
  )
};

const BlogCategoryWrapper = ({ children, className = '', data, isLoading, message, category: cat, grid = true }) => {
  const params = useParams();
  const category = useSingleBlogCategoriy(cat || params?.category);
  
  return (
    <BlogCategoryEmpty 
      className={className} 
      data={data} 
      isLoading={isLoading} 
      message={"Esta categoría todavía no tiene posts"} 
      title={category?.name}
      grid={grid}
      subtitle={category?.acf?.subtitulo}>
      {children}
    </BlogCategoryEmpty>
  )
};

export default BlogCategoryWrapper;