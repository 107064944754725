import React from 'react';
import './ProfileHeader.scss';

const ProfileHeader = ({ title, asset }) => (
  <div className="profile-header">
    <div className="profile-header__title">{title}</div>
    <div className="profile-header__asset">{asset}</div>
  </div>
);

export default ProfileHeader;
