import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { signOutRequest } from '../../ducks/actions';
import { useHistory } from 'react-router';
import './ProfileMenu.scss';
import useHubLink from '../../hooks/useHubLink.hook';

const ProfileMenu = ({ closeMenu }) => {
  const dispatch = useDispatch();
  const ageLink = useHubLink();
  const signOut = () => dispatch(signOutRequest());
  const history = useHistory();
  return (
    <ul className="profile-menu">
      <li>
        <Link className="profile-menu__link" to="/perfil/mis-datos">
          Mi perfil
        </Link>
      </li>
      <li>
        <Link className="profile-menu__link" to="/dashboard">
          Dashboard
        </Link>
      </li>
      <li>
        <a
          href={ageLink}
          target="_blank"
          className="profile-menu__link"
          rel="noreferrer"
        >
          Ir a Veinsa Hub
        </a>
      </li>
      <li>
        <button
          onClick={() => {
            history.push('/solicitar');
            signOut();
            closeMenu();
          }}
          className="profile-menu__close-session"
        >
          Cerrar sesión
        </button>
      </li>
    </ul>
  );
};

export default ProfileMenu;
