import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import BlogHeaderSvg from '../../assets/images/blogHeader.svg';
import './BlogHeader.scss';


const BlogHeader = ({ isLoading, backgroundImage = BlogHeaderSvg }) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    history.push(`/blog/buscar/${data.search}`);
  };
  const background = isLoading ? {
    'background': 'white'
  } : {
    'backgroundImage': `url(${backgroundImage})`
  };

  return (
    <div className='blog-header' style={background}>
      <h2>Publicaciones del blog</h2>
      <form className='blog-header__input' onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register('search', { required: true })}
          type="text"
          placeholder='Buscar'
        />
        <input type='submit' value="" />
      </form>
    </div>
  )
};

export default BlogHeader;