import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import TopBar from '../TopBar';
import UserBadge from '../UserBadge';
import './Profile.scss';
import MisDatosIcon from '../../assets/images/mis_datos_icon.svg';
import MiSolicitudIcon from '../../assets/images/mi_solicitud_icon.svg';
import RealizarPagoIcon from '../../assets/images/realizar_pago_icon.svg';
import MisOperacionesIcon from '../../assets/images/mis_operaciones_icon.svg';
import SeguridadIcon from '../../assets/images/seguridad.svg';
import AgeHubIcon from '../../assets/images/ir_a_age_hub_icon.svg';
import { NavLink, Route, Switch } from 'react-router-dom';
import MisDatos from '../MisDatos';
import MiSolicitud from '../MiSolicitud';
import MetodoDePago from '../MetodoDePago';
import MisOperaciones from '../MisOperaciones/MisOperaciones';
import { useState } from 'react';
import classNames from 'classnames';
import useClickOutside from '../../hooks/useClickOutside.hook';
import EditarSolicitud from '../EditarSolicitud';
import Seguridad from '../Seguridad';
import useHubLink from '../../hooks/useHubLink.hook';
import { setCurrencyRequest } from '../../ducks/actions';
import { currencies } from '../Currency/Currency';

const Profile = () => {
  // Resetting currency 
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrencyRequest(currencies.DOLLAR));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const ref = useRef(null);
  const buttonRef = useRef(null);
  const { name } = useSelector(state => ({
    name: state.user.data?.name || '',
  }));

  const ageLink = useHubLink();
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = () => {
    setMenuOpen(false);
  };
  useClickOutside(ref, buttonRef, closeMenu);
  return (
    <div className="profile">
      <TopBar />
      <div className="profile__bar">
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="profile__mobile-menu-button"
          ref={buttonRef}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div className="profile__badge">
          <UserBadge name={name} />
          <div className="profile__name">{name}</div>
        </div>
      </div>
      <div className="profile__content">
        <div
          className={classNames('profile__menu', {
            'profile__menu--open': menuOpen,
          })}
          ref={ref}
        >
          <NavLink
            onClick={() => closeMenu()}
            to="/perfil/mis-datos"
            activeClassName="profile__button--active"
            className="profile__button"
          >
            <div className="profile__button-icon-container">
              <img
                src={MisDatosIcon}
                className="profile__button-icon"
                alt="Icon"
              />
            </div>
            <p className="profile__button-text">Mis datos</p>
          </NavLink>
          <NavLink
            onClick={() => closeMenu()}
            to="/perfil/mi-solicitud"
            activeClassName="profile__button--active"
            className="profile__button"
          >
            <div className="profile__button-icon-container">
              <img
                src={MiSolicitudIcon}
                className="profile__button-icon"
                alt="Icon"
              />
            </div>
            <p className="profile__button-text">Mis solicitudes de crédito</p>
          </NavLink>
          <NavLink
            onClick={() => closeMenu()}
            to="/perfil/realizar-pago"
            activeClassName="profile__button--active"
            className="profile__button"
          >
            <div className="profile__button-icon-container">
              <img
                src={RealizarPagoIcon}
                className="profile__button-icon"
                alt="Icon"
              />
            </div>
            <p className="profile__button-text">Realizar pago</p>
          </NavLink>
          <NavLink
            onClick={() => closeMenu()}
            to="/perfil/mis-operaciones"
            activeClassName="profile__button--active"
            className="profile__button"
          >
            <div className="profile__button-icon-container">
              <img
                src={MisOperacionesIcon}
                className="profile__button-icon"
                alt="Icon"
              />
            </div>
            <p className="profile__button-text">Mis operaciones</p>
          </NavLink>
          <NavLink
            onClick={() => closeMenu()}
            to="/perfil/seguridad"
            activeClassName="profile__button--active"
            className="profile__button"
          >
            <div className="profile__button-icon-container">
              <img
                src={SeguridadIcon}
                className="profile__button-icon"
                alt="Icon"
              />
            </div>
            <p className="profile__button-text">Seguridad</p>
          </NavLink>
          <a
            href={ageLink}
            target="_blank"
            className="profile__button"
            rel="noreferrer"
          >
            <div className="profile__button-icon-container">
              <img
                src={AgeHubIcon}
                className="profile__button-icon"
                alt="Icon"
              />
            </div>
            <p className="profile__button-text">Ir a Veinsa Hub</p>
          </a>
        </div>
        <div className="profile__viewport">
          <Switch>
            <Route path="/perfil/mis-datos">
              <MisDatos />
            </Route>
            <Route path="/perfil/mi-solicitud/:idSolicitud">
              <EditarSolicitud />
            </Route>
            <Route path="/perfil/mi-solicitud">
              <MiSolicitud />
            </Route>
            <Route path="/perfil/realizar-pago/:numeroOperacion/:tipoPago">
              <MetodoDePago />
            </Route>
            <Route path="/perfil/realizar-pago">
              <MetodoDePago />
            </Route>
            <Route path="/perfil/mis-operaciones">
              <MisOperaciones />
            </Route>
            <Route path="/perfil/seguridad">
              <Seguridad />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Profile;
