import { createReducer } from '../../utils/helpers';
import ActionTypes from './actionTypes';

export const initialState = {
  data: [],
  isLoading: false,
  error: null,
  isReady: false,
};

const reducer = {
  faq: createReducer(initialState, {
    [ActionTypes.FETCH_FAQ_REQUEST](state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    [ActionTypes.FETCH_FAQ_SUCCESS](state, action) {
      return {
        ...state,
        data: [
          {
            id: 0,
            topic: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
            questions: action.payload.faq.data.map((question, i) => ({
              ...question,
              title: question.pregunta,
              content: question.respuesta,
              id: i,
            })),
          },
        ],
        isReady: true,
        isLoading: false,
      };
    },
    [ActionTypes.FETCH_FAQ_FAILURE](state) {
      return {
        ...state,
        error: true,
        isReady: true,
        isLoading: false,
      };
    },
  }),
};

export default reducer;
