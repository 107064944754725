import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomepageRequest } from "./actions";

export const useHomePage = () => {
  const dispatch = useDispatch();
  
  const { homepage } = useSelector(state => ({
    homepage: state.app.homepage,
  }));

  useEffect(() => {
    if (!homepage.data && !homepage.isLoading) {
      dispatch(fetchHomepageRequest());
    }
  }, [dispatch, homepage]);
  return [homepage.data, homepage.isLoading];
};