import { useEffect, useState } from 'react';
import { CURRENCIES } from '../utils/constants';
import { cleanString } from '../utils/helpers';

const useMoneda = (currency) => {
  const [currentCurrency, setCurrentCurrency] = useState();

  useEffect(() => {
    if (!currency && !currentCurrency) {
        return;
    }
    if (!currency) {
      setCurrentCurrency(null);
        return;
    }
    const cleancurrency = cleanString(currency)?.toLocaleLowerCase();
    const occurency = CURRENCIES.find(curr => 
      curr.labels.indexOf(cleancurrency) >= 0);

    setCurrentCurrency(occurency);
  }, [currency, currentCurrency]);
  return currentCurrency;
};

export default useMoneda;
