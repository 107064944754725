/**
 * @module Services/UserPreferencesApiService
 * @desc UserPreferencesApiService
 */

import { throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import axios from 'axios';

import { BLOG_URL, ENDPOINT_URL } from '../utils/constants';

export class BaseApiService {
  static blogGet(url, token, addAuth, responseType = 'json') {
    return BaseApiService.ajax(
      'GET',
      url,
      token,
      'json',
      undefined,
      undefined,
      BLOG_URL,
      addAuth
    );
  }

  static get(url, token, addAuth, responseType = 'json', endpoint = ENDPOINT_URL) {
    return BaseApiService.ajax(
      'GET',
      url,
      token,
      responseType,
      undefined,
      undefined,
      endpoint,
      addAuth
    );
  }

  static put(
    url,
    body,
    token,
    headers = { 'Content-Type': 'application/json' },
    addAuth,
    responseType = 'json',
    endpoint = ENDPOINT_URL
  ) {
    return BaseApiService.ajax(
      'PUT',
      url,
      token,
      responseType,
      body,
      {
        ...headers,
      },
      endpoint,
      addAuth
    );
  }

  static delete(
    url,
    body,
    token,
    headers = { 'Content-Type': 'application/json' },
    addAuth,
    responseType = 'json',
    endpoint = ENDPOINT_URL
  ) {
    return BaseApiService.ajax(
      'DELETE',
      url,
      token,
      responseType,
      body,
      headers,
      endpoint,
      addAuth
    );
  }

  static post(
    url,
    body,
    token,
    headers = { 'Content-Type': 'application/json' },
    addAuth,
    responseType = 'json',
    endpoint = ENDPOINT_URL
  ) {
    return BaseApiService.ajax(
      'POST',
      url,
      token,
      responseType,
      body,
      {
        ...headers,
      },
      endpoint,
      addAuth
    );
  }

  static ajax(
    method,
    url,
    token,
    responseType,
    body,
    headers = { 'Content-Type': 'application/json' },
    endpointUrl,
    addAuth = true
  ) {
    // const bearer = token ?? getUserInLocalStorage()?.accessToken;
    // if (addAuth && bearer) {
    //   headers.authorization = `Bearer ${bearer}`;
    // }
    const endpoint = url.indexOf('http') === -1 ?
      `${endpointUrl}${url}` :
      url;

    return ajax({
      url: `${endpoint}`,
      method,
      withCredentials: true,
      responseType,
      headers,
      body,
      crossDomain: true,
    });
  }

  static axiosClient() {
    axios.defaults.withCredentials = true;
    return axios.create({
      baseURL: ENDPOINT_URL
    });
  }

  static getErrorHandler(className, methodName, action) {
    return error => {
      const actions = [];
      // if (error.status === 401) {
      //   actions.push(refreshTokenRequest(action));
      // }

      /* eslint-disable no-console */
      console.error(className, methodName, error);
      /* eslint-enable no-console */

      return throwError({
        error,
        actions,
      });
    };
  }
}
