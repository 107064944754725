import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TopBar from '../TopBar';
import UserBadge from '../UserBadge/UserBadge';
import './Dashboard.scss';
import MyRequestIcon from '../../assets/images/my_request_icon.svg';
import PaymentIcon from '../../assets/images/payment_icon.svg';
import OperationsIcon from '../../assets/images/operations_icon.svg';
import RequestNewIcon from '../../assets/images/new_request_icon.svg';
import Operation from '../Operation';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { fetchOperationsRequest } from '../../ducks/actions';
import { Link } from 'react-router-dom';
import { getOperationSelectors } from '../../ducks/selectors';
import EmptyComponent from '../Empty/EmptyComponent';
import AgeHubIcon from '../../assets/images/age_hub.svg';
// import { AGE_HUB } from '../../utils/constants';
import useHubLink from '../../hooks/useHubLink.hook';


const Dashboard = () => {
  const { name } = useSelector(state => ({
    name: state.user.data?.name || '',
    operationObject: state.user.operations,
  }));

  const ageLink = useHubLink();

  const operationObject = useSelector(getOperationSelectors);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOperationsRequest());
  }, [dispatch]);

  const history = useHistory();
  return (
    <div className="dashboard">
      <TopBar />
      <div className="dashboard__bar">
        <div className="dashboard__badge">
          <UserBadge name={name} />
          <div className="dashboard__name">{name}</div>
        </div>
      </div>
      <h1 className="dashboard__title">¿Qué trámites querés hacer?</h1>
      <hr className="dashboard__hr" />
      <div className="dashboard__options">
        <button className="option" onClick={() => history.push('/perfil/mi-solicitud')}>
          <div className="option__icon">
            <img src={MyRequestIcon} alt="Icon" className="option__image" />
          </div>
          <Link className="option__text" to="">
            Mis solicitudes de crédito
          </Link>
        </button>
        <button className="option" onClick={() => history.push("/perfil/realizar-pago")}>
          <div className="option__icon">
            <img src={PaymentIcon} alt="Icon" className="option__image" />
          </div>
          <Link className="option__text" to="">
            Realizar pago
          </Link>
        </button>
        <button className="option" onClick={() => history.push("/perfil/mis-operaciones")}>
          <div className="option__icon">
            <img src={OperationsIcon} alt="Icon" className="option__image" />
          </div>
          <Link className="option__text" to="">
            Mis operaciones
          </Link>
        </button>
        <button onClick={() => history.push('/solicitar')} className="option">
          <div className="option__icon">
            <img src={RequestNewIcon} alt="Icon" className="option__image" />
          </div>
          <p className="option__text">Solicitar nuevo crédito</p>
        </button>
        <button className="option" onClick={() => {
          const win = window.open(ageLink);
          win.focus();
        }}>
          <div className="option__icon">
            <img src={AgeHubIcon} alt="Icon" className="option__image" />
          </div>
          <a className="option__text" 
          href={ageLink}
          target="_blank"
          rel="noreferrer">
            Ir a Veinsa Hub
          </a>
        </button>
      </div>
      <hr className="dashboard__hr" />
      <h1 className="dashboard__title">Mis operaciones activas</h1>
      <div className="dashboard__operations">
        <EmptyComponent
          message="No tienes operaciones activas"
          isLoading={operationObject.isLoading}
          data={operationObject.data}
        >
          {operationObject.data
            .filter(operation => operation.dashboard !== false)
            .map((operation, index) => (
              <div className="dashboard__operation" key={operation.numero}>
                <Operation operation={operation} index={index} />
              </div>
            ))}
        </EmptyComponent>
      </div>
    </div>
  );
};

export default Dashboard;
