import classNames from 'classnames';
import React from 'react';
import './Checkbox.scss';

import Check from '../../assets/images/checkbox__check.png';

const Checkbox = ({ setChecked, checked, gold }) => (
  <button
    onClick={() => setChecked(!checked)}
    className={classNames('checkbox', { 'checkbox--checked': checked, 'checkbox--gold': gold })}
  >
    {checked && <img src={Check} alt="Check" />}
  </button>
);

export default Checkbox;
