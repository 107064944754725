import classNames from 'classnames';
import React from 'react';
import './FinancialDataIcon.scss';

const FinancialDataIconActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
    <g id="Paso_2" data-name="Paso 2" transform="translate(-0.268)">
      <g id="Ellipse_215" data-name="Ellipse 215" transform="translate(0.268)" fill="#fff" stroke="#bb9a47" strokeWidth="1">
        <circle cx="19" cy="19" r="19" stroke="none"/>
        <circle cx="19" cy="19" r="18.5" fill="none"/>
      </g>
      <circle id="Ellipse_245" data-name="Ellipse 245" cx="15" cy="15" r="15" transform="translate(4.268 4)" fill="#bb9947"/>
      <path id="Path_12388" data-name="Path 12388" d="M8.1,9.7H5.927a1.084,1.084,0,0,1,0-2.169H8.1A1.082,1.082,0,0,1,9.18,8.618h2.168A3.254,3.254,0,0,0,8.1,5.365v-1.1H5.927V5.346a3.262,3.262,0,0,0,0,6.525H8.1A1.084,1.084,0,0,1,8.1,14.04H5.927a1.082,1.082,0,0,1-1.082-1.084H2.658a3.254,3.254,0,0,0,3.253,3.253v1.084l2.185.019V16.227A3.262,3.262,0,1,0,8.1,9.7" transform="translate(12.154 8.473)" fill="#fff"/>
    </g>
  </svg>
);
const FinancialDataIcon = ({ active }) => active ? <FinancialDataIconActive /> : (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    className={classNames('financial-data-icon', {
      'financial-data-icon--active': active,
    })}
  >
    <g
      id="Ellipse_215"
      data-name="Ellipse 215"
      fill="#fff"
      stroke="#bb9a47"
      strokeWidth="1"
    >
      <circle cx="21" cy="21" r="20" stroke="none" />
      <circle cx="21" cy="21" r="19.5" fill="none" />
    </g>
    <path
      id="Path_12388"
      data-name="Path 12388"
      d="M8.344,9.952H6.077a1.134,1.134,0,0,1,0-2.268H8.344A1.132,1.132,0,0,1,9.478,8.818h2.268a3.4,3.4,0,0,0-3.4-3.4V4.262H6.077V5.4a3.412,3.412,0,0,0,0,6.824H8.344a1.134,1.134,0,0,1,.006,2.268H6.077a1.131,1.131,0,0,1-1.131-1.134H2.658a3.4,3.4,0,0,0,3.4,3.4v1.134l2.285.02V16.775a3.412,3.412,0,1,0,0-6.824"
      transform="translate(13.963 10.056)"
      fill="#bb9947"
    />
  </svg>
);

export default FinancialDataIcon;
