import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Arrow from '../../assets/images/arrow-left.png';
import ArrowDown from '../../assets/images/arrow_down.svg';
import AlreadySignedIn from '../../assets/images/icon-already-registered.svg';
import logo from '../../assets/images/logo.png';
import User from '../../assets/images/user_icon.svg';
import { signInRequest } from '../../ducks/user/actions';
import useClickOutside from '../../hooks/useClickOutside.hook';
import Button from '../Button';
import CommonDialog from '../CommonDialog';
import AppContext from '../Context/AppContext';
import Input from '../Input';
import ProfileMenu from '../ProfileMenu';
import UserBadge from '../UserBadge/UserBadge';
import './UserMenu.scss';

const UserMenu = () => {
  const { setOpenRegister, setOpenRecuperar, login2, showBlocked, setShowBlocked } = useContext(AppContext);

  const history = useHistory();
  const ref = useRef(null);
  const triggerRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const dispatch = useDispatch();
  const signIn = (email, password, callback) => dispatch(signInRequest(email, password, callback, login2));
  const { isSignedIn, name, error, errorPayload } = useSelector(state => ({
    isSignedIn: !!state.user.data,
    name: state.user.data?.name,
    error: state.user.error,
    errorPayload: state.user.errorPayload,
  }));

  useEffect(() => {
    if (isLoggingIn && isSignedIn) {
      closeDropdown();
    }
  }, [isLoggingIn, isSignedIn]);

  useEffect(() => {
    return history.listen((location) => {
      closeDropdown();
    })
  }, [history]);

  useEffect(() => {
    if (errorPayload && !showBlocked) {
      if (!errorPayload?.error?.response?.data?.tieneCobro) {
        closeDropdown();
      }

      setShowBlocked(errorPayload?.error?.response?.data?.tieneCobro);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorPayload]);

  const {
    register,
    handleSubmit,
    // watch,
    // control,
    // formState: { errors },
    // setValue,
  } = useForm();
  const onSubmit = data => {
    signIn(data.emailDD, data.passwordDD, () => {
      history.push('/dashboard/');
    });
    setIsLoggingIn(true);
  };
  const openDropdown = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setShowDropdown(true);
  };
  const closeDropdown = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setShowDropdown(false);
  };
  useClickOutside(ref, triggerRef, () => closeDropdown());

  const dropdown = (
    <div className="user-menu__dropdown">
      {isSignedIn ? (
        <ProfileMenu closeMenu={() => closeDropdown()} />
      ) : (
        <form className="user-menu__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="user-menu__title">Iniciar sesión</div>
          <div className="user-menu__input">
            <Input
              type2
              placeholder="Correo electrónico"
              {...register('emailDD')}
            />
          </div>
          <div className="user-menu__input">
            <Input
              type2
              type="password"
              placeholder="Contraseña"
              {...register('passwordDD')}
            />
          </div>
          <div className="user-menu__forgot-container">
            <a onClick={() => {
              setOpenRecuperar(true);
            }} className="user-menu__forgot">
              ¿Olvidaste tu contraseña?
            </a>
          </div>
          {error && (
            <div className="user-menu__error">
              Usuario o contraseña incorrecto.
            </div>
          )}
          <Button
            className="user-menu__submit"
            value="Ingresar"
            suffix={<img src={Arrow} alt="Arrow" />}
            type="submit"
          />
          <div className="user-menu__register-container">
            <div className="user-menu__o">ó</div>
            <button
              type="button"
              onClick={() => {
                setOpenRegister(true);
              }}
              className="user-menu__register"
            >
              Registrá tu usuario
            </button>
          </div>
        </form>
      )}
    </div>
  );

  return (
    <>
      <CommonDialog
        icon={AlreadySignedIn}
        open={showBlocked}
        onClose={() => setShowBlocked(false)}
        title="Ingreso no permitido"
        body="Tenemos una situación con tu usuario, podes ponerte en contacto con nosotros al teléfono 4000-8020."
        buttons={<Button black onClick={() => setShowBlocked(false)} value="Cerrar" />}
      />
      <div className="user-menu" ref={ref}>
        <div className="user-menu__toggle">
          <div className="logo mobile">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <span className="user-menu__label user-menu__blog">
            <Link to="/blog">Blog</Link>
          </span>
          {!isSignedIn && (
            <span className="user-menu__label">
              Iniciá sesión o registrá tu usuario
            </span>
          )}
          <button
            ref={triggerRef}
            className="user-menu__button"
            onClick={() => {
              if (showDropdown) {
                closeDropdown();
              } else {
                openDropdown();
              }
            }}
          >
            {isSignedIn ? (
              <UserBadge name={name} />
            ) : (
              <img className="user-menu__img" src={User} alt="User" />
            )}
            <img src={ArrowDown} alt="Arrow" />
          </button>
        </div>
        {showDropdown && dropdown}
      </div>
    </>
  );
};

export default UserMenu;
