import React from 'react';
import './CardDetector.scss';
import AmexIcon from '../../assets/images/amex_icon.svg';
import VisaIcon from '../../assets/images/visa_icon.svg';
import MasterCardIcon from '../../assets/images/master_card_icon.svg';
import classNames from 'classnames';

const getCardType = (number = '') => {
  // visa
  let re = new RegExp('^4');
  if (number.match(re) != null) return 'VISA';

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (
    /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/.test(
      number
    )
  )
    return 'MASTER_CARD';

  // AMEX
  re = new RegExp('^3[47]');
  if (number.match(re) != null) return 'AMEX';

  // Discover
  re = new RegExp(
    '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)'
  );
  if (number.match(re) != null) return 'DISCOVER';

  // Diners
  re = new RegExp('^36');
  if (number.match(re) != null) return 'DINERS';

  // Diners - Carte Blanche
  re = new RegExp('^30[0-5]');
  if (number.match(re) != null) return 'DINERS_CARTE_BLANCHE';

  // JCB
  re = new RegExp('^35(2[89]|[3-8][0-9])');
  if (number.match(re) != null) return 'JCB';

  // Visa Electron
  re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
  if (number.match(re) != null) return 'VISA_ELECTRON';

  return '';
};

const cardType = {
  AMEX: AmexIcon,
  MASTER_CARD: MasterCardIcon,
  VISA: VisaIcon,
};

const CardDetector = ({ value, showAll }) => {
  const type = getCardType(value);
  const image = cardType[type];
  return (
    <div className={classNames('card-detector', {
      'card-detector--all': showAll
    })}>
      {showAll ? (
        Object.keys(cardType).map(card => (
          <img src={cardType[card]} alt="Card Icon" />
        ))
      ): (
        image && <img src={image} alt="Card Icon" />
      )}
      
    </div>
  );
};

export default CardDetector;
