import { createReducer } from '../../utils/helpers';
import { initialState } from '../app/reducers';
import ActionTypes from './actionTypes';

export const userState = {
  data: null,
  isLoading: false,
  error: null,
  errorPayload: null,
  isReady: false,
  isSigning: false,
  isAuthenticated: false,
  operations: {
    data: [],
    isLoading: false,
    isSaving: false,
    error: false,
    saved: false,
  },
  operationsToPay: {
    data: [],
    isLoading: false,
    isSaving: false,
    error: false,
    saved: false,
  },
  registering: {
    data: {},
    isLoading: false,
    error: false,
    saved: false,
  },
  recovering: {
    data: {},
    isLoading: false,
    loaded: false,
    error: false,
    saved: false,
  },
  token: {
    data: null,
    error: false,
    isLoading: false
  }
};

const reducer = {
  user: createReducer(userState, {
    [ActionTypes.SIGN_IN_REQUEST](state) {
      return {
        ...state,
        isSigning: true,
        error: false,
        errorPayload: null,
        isReady: false,
      };
    },
    [ActionTypes.SIGN_IN_SUCCESS](state, action) {
      return {
        ...state,
        data: {
          ...action.payload.user,
          ...action.payload.token,
          dni_type: action.payload?.user?.dni_type?.toUpperCase(),
        },
        isSigning: false,
        isReady: true,
        isAuthenticated: true,
        error: false,
      };
    },
    [ActionTypes.SIGN_IN_FAILURE](state, action) {
      return {
        ...state,
        error: true,
        errorPayload: action.payload,
        isSigning: false,
        isReady: true,
      };
    },
    [ActionTypes.SIGN_OUT_REQUEST]() {
      return userState;
    },
    [ActionTypes.SIGN_IN_RESET](state) {
      return { ...state, isSigning: false, error: false };
    },
    [ActionTypes.CHECK_USER_LOGGED_IN_REQUEST](state) {
      return {
        ...state,
        isSigning: true,
      };
    },
    [ActionTypes.CHECK_USER_LOGGED_IN_SUCCESS](state, action) {
      return {
        ...state,
        data: {
          ...action.payload.user,
          ...action.payload.token,
          dni_type: action.payload?.user?.dni_type?.toUpperCase(),
        },
        isSigning: false,
        isReady: true,
        isAuthenticated: true,
      };
    },
    [ActionTypes.CHECK_USER_LOGGED_IN_FAILURE](state) {
      return {
        ...state,
        error: false,
        errorPayload: null,
        isSigning: false,
        isReady: true,
      };
    },
    [ActionTypes.SAVE_EXTRA_FIELDS](state, action) {
      const { data } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          email2: data.email,
          phone2: data.phone,
        }
      };
    },
    [ActionTypes.GET_OPERATIONS_REQUEST](state) {
      return {
        ...state,
        operations: {
          ...userState.operations,
          isLoading: true,
        },
      };
    },
    [ActionTypes.GET_OPERATIONS_SUCCESS](state, action) {
      return {
        ...state,
        operations: {
          ...state.operations,
          data: action.payload.data,
          isLoading: false,
        },
      };
    },
    [ActionTypes.GET_OPERATIONS_TOPAY_REQUEST](state) {
      return {
        ...state,
        operationsToPay: {
          ...userState.operations,
          isLoading: true,
        },
      };
    },
    [ActionTypes.GET_OPERATIONS_TOPAY_SUCCESS](state, action) {
      return {
        ...state,
        operationsToPay: {
          ...state.operationsToPay,
          data: action.payload.data,
          isLoading: false,
        },
      };
    },
    [ActionTypes.REGISTER_AGE_USER](state) {
      return {
        ...state,
        registering: {
          ...userState.registering,
          isLoading: true,
        }
      }
    },
    [ActionTypes.REGISTER_AGE_USER_SUCCESS](state, action) {
      return {
        ...state,
        registering: {
          ...userState.registering,
          isLoading: false,
          data: action.payload.data
        }
      }
    },
    [ActionTypes.REGISTER_AGE_USER_RESET](state) {
      return {
        ...state,
        registering: {
          ...userState.registering,
        }
      }
    },
    [ActionTypes.RECOVER_USER_PASS](state) {
      return {
        ...state,
        recovering: {
          ...userState.registering,
          isLoading: true,
        }
      }
    },
    [ActionTypes.RECOVER_USER_PASS_SUCCESS](state, action) {
      return {
        ...state,
        recovering: {
          ...userState.recovering,
          isLoading: false,
          loaded: true,
          data: action.payload.data
        }
      }
    },
    [ActionTypes.RECOVER_USER_PASS_RESET](state) {
      return {
        ...state,
        recovering: {
          ...userState.recovering,
        }
      }
    },
    [ActionTypes.GET_USER_BY_TOKEN](state) {
      return {
        ...state,
        token: {
          ...initialState.token,
          isLoading: true,
        }
      }
    },
    [ActionTypes.GET_USER_BY_TOKEN_SUCCESS](state, action) {
      return {
        ...state,
        token: {
          ...state.token,
          isLoading: false,
          data: action.payload.data
        }
      }
    },
    [ActionTypes.GET_USER_BY_TOKEN_FAILURE](state, action) {
      return {
        ...state,
        token: {
          ...state.token,
          data: null,
          error: true,
          isLoading: false,
        }
      }
    }
  }),
};

export default reducer;
