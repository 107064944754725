import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOperationsRequest } from '../../ducks/actions';
import { getOperationSelectors } from '../../ducks/selectors';
import EmptyComponent from '../Empty/EmptyComponent';
import ProfileSection from '../ProfileSection';
import Tabs from '../Tabs';
import './MisOperaciones.scss';

const MisOperaciones = () => {
  const operationObject = useSelector(getOperationSelectors);

  const dispatch = useDispatch();

  useEffect(() => {
    if (operationObject?.data?.length > 0) {
      return;
    }
    dispatch(fetchOperationsRequest());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <div className="mis-operaciones">
      <ProfileSection title="Mis operaciones">
        <p className="mis-operaciones__text">Su cuenta/operación activa:</p>
        <div className="mis-operaciones__tabs">
          <EmptyComponent
            message="No tienes operaciones activas"
            isLoading={operationObject.isLoading}
            data={operationObject.data}
          >
            <Tabs data={operationObject?.data} />
          </EmptyComponent>
        </div>
      </ProfileSection>
    </div>
  );
};

export default MisOperaciones;
