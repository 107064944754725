import React, { useEffect, useState } from 'react';
import './TipoFinanciamiento.scss';
import { useForm, Controller } from 'react-hook-form';
import Slider from '../Slider';
import VehicleType from '../VehicleType';
import CurrencyFormat from '../CurrencyFormat';
import Button from '../Button/Button';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import { obtenerCuotaMensual, scrollTo } from '../../utils/helpers';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Currency from '../Currency/Currency';

const primes = [
  {
    value: 20,
    label: '20%',
  },
  {
    value: 30,
    label: '30%',
  },
  {
    value: 40,
    label: '40%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 60,
    label: '60%',
  },
  {
    value: 70,
    label: '70%',
  },
  {
    value: 80,
    label: '80%',
  },
];

const terms = [
  {
    value: 12,
    label: '12M',
  },
  {
    value: 24,
    label: '24M',
  },
  {
    value: 36,
    label: '36M',
  },
  {
    value: 48,
    label: '48M',
  },
  {
    value: 60,
    label: '60M',
  },
  {
    value: 72,
    label: '72M',
  },
  {
    value: 84,
    label: '84M',
  },
  {
    value: 96,
    label: '96M',
  },
];

const RATE_KEYS = {
  Nuevo: 'Nuevo',
  Usado: 'Usado',
  dollar: 'USD',
  colon: 'CRC',
};

export const VEHICLE_TYPES = {
  Nuevo: 'Nuevo',
  Usado: 'Usado',
};

const FinancingSummary = ({
  vehicleValue,
  prime,
  term,
  vehicleType,
  currency,
  useExtendida,
}) => {
  const {
    segurosFactorVida,
    segurosFactorITP,
    segurosFactorDesempleo,
    rates,
  } = useSelector(state => ({
    segurosFactorVida: state.app.rates.data?.segurosFactorVida || 0,
    segurosFactorITP: state.app.rates.data?.segurosFactorITP || 0,
    segurosFactorDesempleo: state.app.rates.data?.segurosFactorDesempleo || 0,
    rates: {
      administracionNuevoCRC: state.app.rates.data?.administracionNuevoCRC,
      administracionNuevoUSD: state.app.rates.data?.administracionNuevoUSD,
      administracionUsadoCRC: state.app.rates.data?.administracionUsadoCRC,
      administracionUsadoUSD: state.app.rates.data?.administracionUsadoUSD,
      comisionNuevoTasaCRC: state.app.rates.data?.comisionNuevoTasaCRC,
      comisionNuevoTasaUSD: state.app.rates.data?.comisionNuevoTasaUSD,
      comisionUsadoTasaCRC: state.app.rates.data?.comisionUsadoTasaCRC,
      comisionUsadoTasaUSD: state.app.rates.data?.comisionUsadoTasaUSD,
      gastoNuevoGPSCRC: state.app.rates.data?.gastoNuevoGPSCRC,
      gastoNuevoGPSUSD: state.app.rates.data?.gastoNuevoGPSUSD,
      gastoUsadoGPSCRC: state.app.rates.data?.gastoUsadoGPSCRC,
      gastoUsadoGPSUSD: state.app.rates.data?.gastoUsadoGPSUSD,
      tasaNuevoCRC: state.app.rates.data?.tasaNuevoCRC,
      tasaNuevoUSD: state.app.rates.data?.tasaNuevoUSD,
      segurosFactorDesempleo: state.app.rates.data?.segurosFactorDesempleo,
      segurosFactorITP: state.app.rates.data?.segurosFactorITP,
      segurosFactorVida: state.app.rates.data?.segurosFactorVida,
      totalGastosAdministrativosNuevoCRC:
        state.app.rates.data?.totalGastosAdministrativosNuevoCRC,
      totalGastosAdministrativosNuevoUSD:
        state.app.rates.data?.totalGastosAdministrativosNuevoUSD,
      totalGastosAdministrativosUsadoCRC:
        state.app.rates.data?.totalGastosAdministrativosUsadoCRC,
      totalGastosAdministrativosUsadoUSD:
        state.app.rates.data?.totalGastosAdministrativosUsadoUSD,
      tasaUsadoCRC: state.app.rates.data?.tasaUsadoCRC,
      tasaUsadoUSD: state.app.rates.data?.tasaUsadoUSD,
    },
  }));
  const IVA = 1.13;
  const GPSInstalation = 39.55;
  const rate = `tasa${vehicleType}${RATE_KEYS[currency]}`;
  // const administracion = `administracion${vehicleType}${RATE_KEYS[currency]}`;
  // const administracionRate = rate[administracion];
  const comision = `comision${vehicleType}Tasa${RATE_KEYS[currency]}`;
  const gastosAdministrativos = `totalGastosAdministrativos${vehicleType}${RATE_KEYS[currency]}`;
  const finalPrimeAmount = vehicleValue * (prime / 100);
  const financeAmount = vehicleValue - finalPrimeAmount;
  const comission = financeAmount * (rates[comision] / 100 || 0) * IVA;
  
  
  const carInsurance = (vehicleValue / 1000) * 1.72 * IVA;
  const administrativeExpense = financeAmount ? (rates[gastosAdministrativos] || 0) : 0;
  const lifeInsurance = ((vehicleValue * segurosFactorVida) / 1000) * 1.02;
  const itpInsurance = ((vehicleValue * segurosFactorITP) / 1000) * 1.02;
  
  const allInsurances = carInsurance + lifeInsurance + itpInsurance;
  const financeAmountWithInsurances = financeAmount ? financeAmount + allInsurances + GPSInstalation: 0;
  const total = financeAmountWithInsurances + comission;
  const quote = obtenerCuotaMensual(rates[rate], term, total);

  const unemploymentInsurance =
  (((carInsurance + lifeInsurance + itpInsurance + quote + administrativeExpense) *
    segurosFactorDesempleo) /
    1000) * IVA;

  const finalQuote = quote + carInsurance + lifeInsurance + itpInsurance + administrativeExpense + unemploymentInsurance;

  if (useExtendida) {
    return (
      <div className="summary">
        <div className="summary__bottom">
          <div className="summary__bottom-label">Cuota mensual aproximada:</div>
          <div className="summary__bottom-value">
            <CurrencyFormat
              // value={
              //   quote +
              //     carInsurance +
              //     lifeInsurance +
              //     itpInsurance +
              //     unemploymentInsurance || 0
              // }
              value={finalQuote || 0}
            />
          </div>
        </div>
      </div>
    )
  };
  
  return (
    <div className="summary">
      <div className="summary__group">
        <h3 className="summary__title">Detalle del financiamiento</h3>
        <div className="summary__row">
          <div className="summary__label">Valor a financiar:</div>
          <div className="summary__value">
            <CurrencyFormat value={financeAmountWithInsurances} />
          </div>
        </div>
        <div className="summary__row">
          <div className="summary__label">Comisión de formalización:</div>
          <div className="summary__value">
            <CurrencyFormat value={comission} />
          </div>
        </div>
        <div className="summary__row">
          <div className="summary__label">Total a financiar:</div>
          <div className="summary__value">
            <CurrencyFormat value={total} />
          </div>
        </div>
      </div>
      <div className="summary__group">
        <h3 className="summary__title">Cuota mensual total</h3>
        <div className="summary__row">
          <div className="summary__label">Cuota financiera:</div>
          <div className="summary__value">
            <CurrencyFormat value={quote || 0} />
          </div>
        </div>
        <div className="summary__row">
          <div className="summary__label">Gastos administrativos:</div>
          <div className="summary__value">
            <CurrencyFormat value={administrativeExpense} />
          </div>
        </div>
      </div>
      <div className="summary__group">
        <h3 className="summary__title">Seguros</h3>
        <div className="summary__row">
          <div className="summary__label">Seguro del vehículo:</div>
          <div className="summary__value">
            <CurrencyFormat value={carInsurance} />
          </div>
        </div>
        <div className="summary__row">
          <div className="summary__label">Seguro de vida:</div>
          <div className="summary__value">
            <CurrencyFormat value={lifeInsurance} />
          </div>
        </div>
        <div className="summary__row">
          <div className="summary__label">Seguro de incapacidad:</div>
          <div className="summary__value">
            <CurrencyFormat value={itpInsurance} />
          </div>
        </div>
        <div className="summary__row">
          <div className="summary__label">Seguro de desempleo:</div>
          <div className="summary__value">
            <CurrencyFormat value={unemploymentInsurance} />
          </div>
        </div>
      </div>
      <div className="summary__bottom">
        <div className="summary__bottom-label">Cuota mensual aproximada:</div>
        <div className="summary__bottom-value">
          <CurrencyFormat
            // value={
            //   quote +
            //     carInsurance +
            //     lifeInsurance +
            //     itpInsurance +
            //     unemploymentInsurance || 0
            // }
            value={finalQuote || 0}
          />
        </div>
      </div>
      <div className="summary__disclaimer">
        La prima de seguro indicada es una estimación y puede variar según el
        valor de mercado, uso del vehículo, tipo de vehículo, año o cualquier
        otra condición que establezca el ente asegurador o la autoridad
        reguladora. Además, la póliza de protección crediticia (vida, desempleo
        e incapacidad), puede variar si es asalariado o trabajador independiente
        y/o extranjero.
      </div>
    </div>
  );
};

const TipoFinanciamiento = ({ currency, setCurrency, next, amount }) => {
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [reset, setReset] = useState(false);
  useEffect(() => {
    if (errors.valor) {
      scrollTo('.currency-input');
    }
  }, [errors.valor]);

  const onSubmit = data => {
    if (!data.valor) {
      return;
    }
    next({
      ...data,
      //valor: (data.valor || 0) - (data.valor || 0) * (data.prima / 100),
      valor: (data.valor || 0),
    });
  };
  useEffect(() => {
    if (amount === 0) {
      setValue('valor', 0);
    }
  }, [amount, setValue]);

  // const { isSignedIn } = useSelector(state => ({
  //   isSignedIn: !!state.user.data,
  // }));

  useEffect(() => {
    if (reset) {
      setTimeout(() => {
        setReset(false);  
      }, 100);
    }
  }, [reset]);
  const today = new Date();
  const currentYear = today.getFullYear();
  const years = [
    ...(watch('type') === VEHICLE_TYPES.Usado
      ? [
          {
            value: currentYear - 4,
            label: currentYear - 4,
          },
        ]
      : []),
    ...(watch('type') === VEHICLE_TYPES.Usado
      ? [
          {
            value: currentYear - 3,
            label: currentYear - 3,
          },
        ]
      : []),
    ...(watch('type') === VEHICLE_TYPES.Usado
      ? [
          {
            value: currentYear - 2,
            label: currentYear - 2,
          },
        ]
      : []),
    {
      value: currentYear - 1,
      label: currentYear - 1,
    },
    ...(watch('type') === VEHICLE_TYPES.Nuevo
      ? [
          {
            value: currentYear,
            label: currentYear,
          },
        ]
      : []),
    ...(watch('type') === VEHICLE_TYPES.Nuevo
      ? [
          {
            value: currentYear + 1,
            label: currentYear + 1,
          },
        ]
      : []),
  ];

  const {
    calculatorSettings,
    isReady,
  } = useSelector(state => ({
    calculatorSettings: state.app?.rates?.calculatorSettings || {},
    isReady: state.app.rates.isReady,
  }));

  const maxYear = years[years.length - 1].value;
  const minYear =
    watch('type') === VEHICLE_TYPES.Nuevo ? years[0].value : years[0].value - 1;
  
  const valorCar = (
    <div className="tf-form__column tf-form__column--value">
      <Controller
        name="valor"
        control={control}
        defaultValue={''}
        rules={{
          required: true,
          validate: {
            positive: v => parseInt(v) > 0,
          },
        }}
        render={({ field }) => (
          <CurrencyInput
            onChange={({ value }) => setValue('valor', value)}
            value={field.value}
            label="Valor del vehículo"
            placeholder="ej. 20000"
            hasError={!!errors.valor}
            // currency={currency?.name}
          />
        )}
      />
    </div>
  );

  const summary = (
    <FinancingSummary
      vehicleValue={Number(watch('valor'))}
      prime={watch('prima')}
      term={watch('plazo')}
      vehicleType={watch('type')}
      currency={currency?.name}
      useExtendida={calculatorSettings.useExtendida}
    />
  );

  return (
    <div className="tf">
      <p className="tf__intro">
        Tenemos para vos una herramienta fácil y útil para calcular la cuota
        aproximada a tu financiamiento.<br /> 
        <strong>
          Completá los siguientes pasos y conocé a detalle tus opciones:
        </strong>
      </p>

      <div className={classNames('tf__content', {
        'tf__content--compress': calculatorSettings?.useExtendida,
        'tf__content--loaded': isReady,
      })}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tf__title-bar">
            <div className="tf__title">Tipo financiamiento</div>
            {!!calculatorSettings.useCR && !!calculatorSettings.useUSD ? (
              <Currency currency={currency} setCurrency={setCurrency} />
            ): (
              <Currency singleCurrencySign={!!calculatorSettings.useCR ? '₡': '$'} />
            )}
            
          </div>
          <div className="tf__columns">
            <div className="tf__form">
              <div className="tf-form">
                <div className="tf-form__row tf-form__row--vehicle">
                  <Controller
                    name="type"
                    control={control}
                    defaultValue={VEHICLE_TYPES.Nuevo}
                    render={({ field }) => (
                      <VehicleType
                        type={field.value}
                        setType={type => {
                          if (watch('type') === type) {
                            return;
                          }
                          setReset(true);
                          setValue('type', type)
                        }}
                      />
                    )}
                  />
                  {calculatorSettings?.useExtendida ? (
                    <>{valorCar}</>
                  ): null}
                </div>
                {!calculatorSettings?.useExtendida ? (
                  <div className="tf-form__row">
                    {valorCar}
                  </div>
                ): null}

                <div className="tf-form__row tf-form__row--anio-vehiculo">
                {!reset && (
                  <Controller
                    name="anio"
                    control={control}
                    defaultValue={currentYear}
                    render={({ field }) => (
                      <Slider
                        label="Año del vehículo"
                        marks={years}
                        min={minYear}
                        max={maxYear}
                        expandable={watch('type') === VEHICLE_TYPES.Usado}
                        maxLimit={maxYear}
                        onChange={(event, newValue) => {
                          field.onChange({
                            ...event,
                            target: { ...event.target, value: newValue },
                          });
                        }}
                        value={field.value}
                      />
                    )}
                  />
                  )}
                </div>
                <div className="tf-form__row">
                  <Controller
                    name="prima"
                    control={control}
                    defaultValue={20}
                    render={({ field }) => (
                      <Slider
                        label="Prima"
                        marks={primes}
                        min={primes[0].value - 1}
                        max={primes[primes.length - 1].value}
                        onChange={(event, newValue) => {
                          field.onChange({
                            ...event,
                            target: { ...event.target, value: newValue },
                          });
                        }}
                        value={field.value}
                        valueLabelFormat={value => `${value}%`}
                      />
                    )}
                  />
                </div>
                <div className="tf-form__row">
                  <Controller
                    name="plazo"
                    control={control}
                    defaultValue={96}
                    render={({ field }) => (
                      <Slider
                        label="Plazo del financiamiento"
                        marks={terms}
                        min={terms[0].value - 1}
                        max={terms[terms.length - 1].value + 1}
                        onChange={(event, newValue) => {
                          field.onChange({
                            ...event,
                            target: { ...event.target, value: newValue },
                          });
                        }}
                        value={field.value}
                        valueLabelFormat={value => `${value}M`}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            {!calculatorSettings.useExtendida ? <div className="tf__summary">
              {summary}
            </div> : null}
          </div>
          {calculatorSettings.useExtendida ? (
            <div className="tf__summary tf__summary--extendida">
              {summary}
            </div>
          ): null}
          <div className="tf__bottom">
            El cálculo aquí reflejado constituye únicamente una referencia y no
            representa ningún compromiso ni obligación por parte de AGE Capital.
            Todo cálculo está sujeto a una cotización formal, que debe ser
            realizada por un ejecutivo de AGE Capital. El uso de esta herramienta
            no garantiza la aprobación de un crédito o condiciones similares en
            el financiamiento. Queda sujeto a las politicas de financiamiento de
            AGE Capital y que el cliente cumpla con la presentación de todos los
            documentos solicitados para validar la información acá indicada.
          </div>
          <div className="tf__submit-container">
            <Button type="submit" value="Solicitar financiamiento" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default TipoFinanciamiento;
