import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { approveOffer, changeSolicitudToQuote, fetchSolicitudesRequest, getDocuments } from '../../ducks/actions';
import EmptyComponent from '../Empty/EmptyComponent';
import Requirements from '../Requirements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import './EditarSolicitud.scss';
import usePrevious from '../../hooks/usePrevious';

const EditarSolicitud = () => {
  const dispatch = useDispatch();

  const [ loaded, setLoaded ] = useState(false);
  const { idSolicitud: solicitudId } = useParams();
  const { solicitudes, quote, mainQuote } = useSelector(state => ({
    solicitudes: state.app.solicitudes,
    quote: state.app.quote.data,
    mainQuote: state.app.quote,
  }));

  const previousQuoteId = usePrevious(quote?.id);

  const quoteS = solicitudes?.data?.find(
    solicitud => solicitud.id === solicitudId
  );

  useEffect(() => {
    if (quoteS) {
      dispatch(changeSolicitudToQuote(quoteS));
      setTimeout(() => setLoaded(true), 100);
    }
    
  }, [quoteS, dispatch]);

  useEffect(() => {
 
    if (!quote || quote?.id !== previousQuoteId || quote?.id !== solicitudId) {
      return;
    }

    //dispatch(changeQuote(quoteS));
    if (quote?.status === '1-Preaprobado') {
      dispatch(approveOffer(quote?.id));
    }

    if (!mainQuote.hasDownloadedDocuments) {
      dispatch(getDocuments(quote.id));
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote, previousQuoteId]);

  useEffect(() => {
    if (!solicitudes.fetched) {
      dispatch(fetchSolicitudesRequest());
    }
  }, [dispatch, solicitudes.fetched]);
  
  return (
    <div className="editar-solicitud">
      <EmptyComponent
        data={solicitudes.data}
        message="No hay solicitudes"
        isLoading={solicitudes.isLoading || !loaded || !mainQuote.hasDownloadedDocuments}
      >
        <Requirements
          back={
            <Link className="editar-solicitud__back" to="/perfil/mi-solicitud">
              <FontAwesomeIcon icon={faArrowCircleLeft} /> Atrás
            </Link>
          }
          documents={quote?.documentos}
          state= {quote?.status}
          isProfile
        />
      </EmptyComponent>
    </div>
  );
};

export default EditarSolicitud;
