import React from 'react';
import './MaskedInput.scss';
import CurrencyFormatLib from 'react-currency-format';
import classNames from 'classnames';

const MaskedInput = ({
  name,
  label,
  value,
  placeholder,
  onBlur,
  onChange,
  format,
  hasError,
  mask
}) => {
  return (
    <div className="masked-input">
      <div className="masked-input__label">
        <label htmlFor={name}>{label}</label>
      </div>
      <div
        className={classNames('masked-input__control-container', {
          'masked-input__control-container--error': hasError,
        })}
      >
        <CurrencyFormatLib
          id={name}
          name={name}
          className="masked-input__control"
          onValueChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          value={value}
          displayType="input"
          format={format}
          mask={mask}
        />
      </div>
    </div>
  );
};

export default MaskedInput;
