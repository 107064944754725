import React from 'react';

const CameraIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26.045" height="22.789" viewBox="0 0 26.045 22.789">
      <path id="Icon_awesome-camera" data-name="Icon awesome-camera" d="M26.045,7.947V22.6A2.442,2.442,0,0,1,23.6,25.039H2.442A2.442,2.442,0,0,1,0,22.6V7.947A2.442,2.442,0,0,1,2.442,5.506H6.918l.626-1.674A2.438,2.438,0,0,1,9.828,2.25h6.384A2.438,2.438,0,0,1,18.5,3.832l.631,1.674H23.6A2.442,2.442,0,0,1,26.045,7.947Zm-6.918,7.325a6.1,6.1,0,1,0-6.1,6.1A6.109,6.109,0,0,0,19.127,15.272Zm-1.628,0A4.476,4.476,0,1,1,13.022,10.8,4.483,4.483,0,0,1,17.5,15.272Z" transform="translate(0 -2.25)" fill="#bb9947"/>
    </svg>
  );
};

export default CameraIcon;