import classNames from 'classnames';
import React from 'react';
import Modal from '../Modal';
import './Dialog.scss';

const Dialog = ({ children, open, scroll, classname = '' }) => {
  if (!open) {
    return null;
  }
  return (
    <Modal>
      <div className={`${classNames('dialog', {
        'dialog--scroll': !!scroll
      })} ${classname}`}>{children}</div>
    </Modal>
  );
};

export default Dialog;