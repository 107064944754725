import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSolicitudesRequest } from '../../ducks/actions';
import EmptyComponent from '../Empty/EmptyComponent';
import Result from '../Result/Result';
import './MiSolicitud.scss';
import ProfileSection from '../ProfileSection';

const MiSolicitud = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSolicitudesRequest());
  }, [dispatch]);

  const { solicitudes, user } = useSelector(state => ({
    solicitudes: state.app.solicitudes,
    quote: state.app.quote.data,
    user: state.user.data,
  }));

  return (
    <div className="mi-solicitud">
      <EmptyComponent
        data={solicitudes.data}
        message="No hay solicitudes"
        isLoading={solicitudes.isLoading}
      >
        <ProfileSection
          title="Mis solicitudes de crédito"
        >
          {solicitudes.data?.map(solicitud => (
            <div className="mi-solicitud__solicitud" key={solicitud.id}>
              <Result quote={solicitud} user={user} />
            </div>
          ))}
        </ProfileSection>
      </EmptyComponent>
    </div>
  );
};

export default MiSolicitud;
