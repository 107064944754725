import classNames from 'classnames';
import React from 'react';
import FinancialDataIcon from '../FinancialDataIcon/FinancialDataIcon';
import PersonalDataIcon from '../PersonalDataIcon/PersonalDataIcon';
import RequestStatusIcon from '../RequestStatusIcon/RequestStatusIcon';
import FormalizationIcon from '../FormalizationIcon/FormalizationIcon';
import './RequirementsTimeline.scss';
import Check from '../../assets/images/check_circle.svg';

const RequirementsTimeline = ({ currentStep, sent, data }) => {
  const step1CompletedDocuments = data?.step1?.completedDocuments || 0;
  const step1RequiredDocuments = data?.step1?.requiredDocuments || 0;
  const step2CompletedDocuments = data?.step2?.completedDocuments || 0;
  const step2RequiredDocuments = data?.step2?.requiredDocuments || 0;
  const step4CompletedDocuments = data?.step4?.completedDocuments || 0;
  const step4RequiredDocuments = data?.step4?.requiredDocuments || 0;
  const step1Complete = step1CompletedDocuments === step1RequiredDocuments;
  const step2Complete = step2CompletedDocuments === step2RequiredDocuments;
  // const step4Complete = step4CompletedDocuments === step4RequiredDocuments;
  

  return (
    <div className="requirements-timeline">
      <div className="requirements-timeline__timeline">
        <div
          className={classNames('requirements-timeline__step', {
            'requirements-timeline__step--active': currentStep === 1,
          })}
        >
          {step1Complete ? (
            <img src={Check} alt="Check" />
          ) : (
            <PersonalDataIcon active={currentStep === 1} />
          )}
          <div className="requirements-timeline__label">
            <p className="requirements-timeline__title">Datos personales</p>
            <p className="requirements-timeline__sub-text">
              {step1CompletedDocuments || 0}/{step1RequiredDocuments || 0}{' '}
              Archivos subidos
            </p>
          </div>
        </div>
        <div className={classNames("requirements-timeline__separator", {
          'requirements-timeline__separator--active': currentStep > 1
        })} />
        <div
          className={classNames('requirements-timeline__step', {
            'requirements-timeline__step--active': currentStep === 2,
          })}
        >
          {(step2Complete) ? (
            <img src={Check} alt="Check" />
          ) : (
            <FinancialDataIcon active={currentStep === 2} />
          )}
          <div className="requirements-timeline__label">
            <p className="requirements-timeline__title">Datos financieros</p>
            <p className="requirements-timeline__sub-text">
              {step2CompletedDocuments || 0}/{step2RequiredDocuments || 0}{' '}
              Archivos subidos
            </p>
          </div>
        </div>
        <div className={classNames("requirements-timeline__separator", {
          'requirements-timeline__separator--active': currentStep > 2
        })} />        <div
          className={classNames('requirements-timeline__step', {
            'requirements-timeline__step--active': currentStep === 3,
          })}
        >
          {currentStep > 3 ? (
            <img src={Check} alt="Check" />
            // <img
            //   className="requirements-timeline__clock"
            //   src={Clock}
            //   alt="Clock"
            // />
          ) : (
            <RequestStatusIcon active={currentStep === 3} />
          )}
          <div className="requirements-timeline__label">
            <p className="requirements-timeline__title">
              Estado de la solicitud
            </p>
            <p className="requirements-timeline__sub-text"></p>
          </div>
        </div>

        {/* TODO C: cambiar lo de step1completed doc a step4completed */}
        <div className={classNames("requirements-timeline__separator", {
          'requirements-timeline__separator--active': currentStep > 3
        })} />
        <div
          className={classNames('requirements-timeline__step', {
            'requirements-timeline__step--active': currentStep === 4,
          })}
        >
          {sent && currentStep === 5? (
            <img src={Check} alt="Check" />
          ) : (
            <FormalizationIcon active={currentStep === 4} />
          )}
          <div className="requirements-timeline__label">
            <p className="requirements-timeline__title">Formalización</p>
            <p className="requirements-timeline__sub-text">
              {step4CompletedDocuments || 0}/{step4RequiredDocuments || 0}{' '}
              Archivos subidos
            </p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default RequirementsTimeline;
