import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import Angle_blackMark from '../../assets/images/angle-black.svg';
import Arrow_downMark from '../../assets/images/arrow_down.svg';
import HeaderBg from '../../assets/images/faq_header.svg';
import Glass from '../../assets/images/glass.svg';
import GrayGlass from '../../assets/images/grey_glass.svg';
import QuestionMark from '../../assets/images/question_circle.svg';
import Whatsapp from '../../assets/images/whatsapp.png';
import { fetchFaqRequest } from '../../ducks/actions';
import { WHATSAPP_LINK } from '../../utils/constants';
import { removeAccents } from '../../utils/helpers';
import Button from '../Button';
import Input from '../Input';
import Loader from '../Loader/Loader';
import TopBar from '../TopBar';
import './Faq.scss';


// const faqs = [
//   {
//     id: 0,
//     topic: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
//     questions: [
//       {
//         id: 0,
//         title: '¿Ofrecen crédito prendario o Leasing Financiero?',
//         content: 'Únicamente Leasing Financiero.',
//       },
//       {
//         id: 1,
//         title: '¿Qué es un Leasing Financiero?',
//         content:
//           'Es un acuerdo que establece el pago de cuotas en un plazo y tasa establecida, donde al final y en cumplimiento de los pagos, el bien se inscribe a nombre del cliente sin costo adicional a excepción del traspaso.',
//       },
//       {
//         id: 2,
//         title: '¿En qué moneda puedo obtener mi financiamiento?',
//         content: 'Únicamente en dólares.',
//       },
//       {
//         id: 3,
//         title: '¿Cuáles marcas financian?',
//         content:
//           'Mitsubishi · Ssang Yong · Citroen · Peugeot · Maserati · Geely · Fuso · JMC · Mahindra, entre otras.',
//       },
//       {
//         id: 4,
//         title:
//           '¿Qué requisitos se deben remitir para optar por un financiamiento?',
//         content: `<p>Para el análisis de aprobación, debe remitir los siguientes documentos legibles:</p>

//           <p>Físicos Asalariados: Copia de la cédula de identidad o DIMEX, Orden patronal o Constancia Salarial y Solicitud de Crédito.</p>

//           <p>Físicos Independientes:  Copia de la cédula de identidad o DIMEX, Certificado CPA o estados de cuenta bancarios (Últimos 3 meses) y Solicitud de Crédito</p>

//           <p>Jurídicos: Personería Jurídica, Copia de la cédula de Identidad o DIMEX del Representante Lega, Estados Financieros (últimos 2 años) y Solicitud de Crédito.</p>`,
//       },
//       {
//         id: 5,
//         title:
//           '¿Qué pasa si en el momento de ingresar mi solicitud no tengo los documentos completos?',
//         content:
//           'Puedes guardar el avance de tu solicitud de crédito e ingresar nuevamente cuando tengas la información completa para terminar el proceso de aplicación.',
//       },
//       {
//         id: 6,
//         title: '¿La tasa de interés es fija o variable?',
//         content: 'La tasa de interés es variable.',
//       },
//       {
//         id: 7,
//         title: '¿Se genera penalidad por cancelación anticipada?',
//         content:
//           'No, puede realizar pagos extraordinarios o cancelación total sin ningún costo adicional',
//       },
//       {
//         id: 8,
//         title: '¿En caso de atraso de mi pago, se genera multa por morosidad?',
//         content:
//           'En caso de que exista un atraso mayor de 5 días posterior a la fecha de pago, podrá cargarse en su operación una multa por concepto de mora.',
//       },
//       {
//         id: 9,
//         title: '¿Dónde puedo ver mi estado de cuenta?',
//         content: 'Ingrese a XXX (link), con su usuario y contraseña.',
//       },
//       {
//         id: 10,
//         title: '¿Dónde puedo realizar mis pagos?',
//         content:
//           'Puede realizar su pago en la dirección XXX, con su usuario y contraseña, en la sección XXX. También lo puede realizar por medio de la página BN Internet Banking, BN Servicios, Cajas del Banco Nacional, Plataforma PAYBAC y en todas las sucursales de VEINSA. Para mayor información comuníquese al 4000-8000, con el área de Cobro de AGE Capital.',
//       },
//       {
//         id: 11,
//         title: '¿A quién debo llamar en caso de tener un accidente?',
//         content:
//           'Si usted es cliente de AGE Futura Seguros debe comunicarse al teléfono 4000-8000 y al Instituto Nacional de Seguros 800-800-8000',
//       },
//       {
//         id: 12,
//         title:
//           '¿Dónde debo llamar para solicitar un análisis de acuerdo de pago?',
//         content:
//           'Debe remitir información al correo creditoycobro@agecapital.cr y comunicarse al teléfono 4000-8000 con el Depto. Cobro AGE Capital',
//       },
//       {
//         id: 13,
//         title: '¿Puedo utilizar mi propio abogado para liberar mi prenda?',
//         content:
//           'Sí, debe comunicarse al teléfono 4000-8000 y brindar los datos de la persona que realizará los trámites, teléfono y correo electrónico para brindarle las instrucciones a seguir.',
//       },
//     ],
//   },
//   // {
//   //   id: 1,
//   //   topic: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
//   //   questions: [
//   //     {
//   //       id: 0,
//   //       title: '¿Cuáles marcas financian?',
//   //       content:
//   //         'Es un acuerdo que establece el pago de cuotas en un plazo y tasa establecida, donde al final y en cumplimiento de los pagos, el bien se inscribe a nombre del cliente sin costo adicional a excepción del traspaso.',
//   //     },
//   //     {
//   //       id: 1,
//   //       title:
//   //         '¿Qué requisitos se deben remitir para optar por un financiamiento?',
//   //       content:
//   //         'Es un acuerdo que establece el pago de cuotas en un plazo y tasa establecida, donde al final y en cumplimiento de los pagos, el bien se inscribe a nombre del cliente sin costo adicional a excepción del traspaso.',
//   //     },
//   //     {
//   //       id: 2,
//   //       title:
//   //         '¿Qué pasa si en el momento de ingresar mi solicitud no tengo los documentos completos?',
//   //       content:
//   //         'Es un acuerdo que establece el pago de cuotas en un plazo y tasa establecida, donde al final y en cumplimiento de los pagos, el bien se inscribe a nombre del cliente sin costo adicional a excepción del traspaso.',
//   //     },
//   //   ],
//   // },
//   // {
//   //   id: 2,
//   //   topic: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
//   //   questions: [
//   //     {
//   //       id: 0,
//   //       title: '¿La tasa de interés es fija o variable?',
//   //       content:
//   //         'Es un acuerdo que establece el pago de cuotas en un plazo y tasa establecida, donde al final y en cumplimiento de los pagos, el bien se inscribe a nombre del cliente sin costo adicional a excepción del traspaso.',
//   //     },
//   //     {
//   //       id: 1,
//   //       title: '¿Se genera penalidad por cancelación anticipada?',
//   //       content:
//   //         'Es un acuerdo que establece el pago de cuotas en un plazo y tasa establecida, donde al final y en cumplimiento de los pagos, el bien se inscribe a nombre del cliente sin costo adicional a excepción del traspaso.',
//   //     },
//   //     {
//   //       id: 2,
//   //       title: '¿En caso de atraso de mi pago, se genera multa por morosidad?',
//   //       content:
//   //         'Es un acuerdo que establece el pago de cuotas en un plazo y tasa establecida, donde al final y en cumplimiento de los pagos, el bien se inscribe a nombre del cliente sin costo adicional a excepción del traspaso.',
//   //     },
//   //     {
//   //       id: 3,
//   //       title: '¿Dónde puedo ver mi estado de cuenta?',
//   //       content:
//   //         'Es un acuerdo que establece el pago de cuotas en un plazo y tasa establecida, donde al final y en cumplimiento de los pagos, el bien se inscribe a nombre del cliente sin costo adicional a excepción del traspaso.',
//   //     },
//   //     {
//   //       id: 4,
//   //       title: '¿Dónde puedo realizar mis pagos? ',
//   //       content:
//   //         'Es un acuerdo que establece el pago de cuotas en un plazo y tasa establecida, donde al final y en cumplimiento de los pagos, el bien se inscribe a nombre del cliente sin costo adicional a excepción del traspaso.',
//   //     },
//   //     {
//   //       id: 5,
//   //       title: '¿A quién debo llamar en caso de tener un accidente?',
//   //       content:
//   //         'Es un acuerdo que establece el pago de cuotas en un plazo y tasa establecida, donde al final y en cumplimiento de los pagos, el bien se inscribe a nombre del cliente sin costo adicional a excepción del traspaso.',
//   //     },
//   //   ],
//   // },
// ];

const Header = ({ onSearch }) => {
  const [searchText, setSearchText] = useState('');
  return (
    <div className="header">
      <div className="header__top-bar">
        <TopBar />
      </div>
      <div className="header__content">
        <div className="header__image">
          <img src={HeaderBg} alt="Background" />
        </div>
        <div className="header__text">
          <h1 className="header__title">Preguntas frecuentes</h1>
          <p className="header__subheader">
           ¿Tenés alguna duda? Aquí podés consultar las preguntas más frecuentes que recibimos a diario.
          </p>
          <p className="header__subheader">
           Para una atención más personalizada, también podés contactarnos a nuestro Whatsapp de servicio 
           al cliente de AGE Capital.{' '}
            <a
              target="_blank"
              className="requirements__footer-link"
              href={WHATSAPP_LINK}
              rel="noreferrer"
            >
              Hacé click aquí.
              <img
                src={Whatsapp}
                className="requirements__whatsapp"
                alt="Whatsapp"
              />
            </a>
          </p>
          <div className="header__input-container">
            <Input
              pre={<img src={GrayGlass} alt="Glass" />}
              className="header__input"
              whiteBackground
              placeholder="¿Cómo podemos ayudar?"
              value={searchText}
              onChange={({ target: { value } }) => setSearchText(value)}
              onKeyUp={event => {
                if (event.which === 13) {
                  onSearch(searchText);
                }
              }}
            />
            <Button
              className="header__buton"
              value={<img src={Glass} alt="Glass" />}
              sm
              onClick={() => onSearch(searchText)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/*const Topic = ({ topic, setSelectedTopic, selected }) => (
  <button
    onClick={() => setSelectedTopic(topic)}
    className={classNames('topic', { 'topic--selected': selected })}
  >
    {topic.topic}
  </button>
);*/

const Question = ({ question, isSelected, setSelectedQuestion }) => (
  <div className={classNames('question', { 'question--selected': isSelected })}>
    <button
      onClick={() => setSelectedQuestion(question.id)}
      className="question__title"
    >
      <img src={QuestionMark} alt="Question" className="question__icon" />
      <span dangerouslySetInnerHTML={{ __html: question.title }} />

      {isSelected
        ? <img src={Angle_blackMark} alt="Icon" className="question__ArrowDirection" />
        : <img src={Arrow_downMark} alt="Icon" className="question__ArrowDirection" />
      }
    </button>
    {isSelected && (
      <div
        className="question__content"
        dangerouslySetInnerHTML={{ __html: question.content }}
      />
    )}
  </div>
);

const Questions = ({ questions, searchText, setSearchText }) => {
  const [selectedQuestion, setSelectedQuestion] = useState();
  useEffect(() => {
    if (searchText) {
      const question = questions.find(
        _question =>
          removeAccents(_question.title).toLowerCase().includes(removeAccents(searchText.toLowerCase())) ||
          removeAccents(_question.content).toLowerCase().includes(removeAccents(searchText.toLowerCase()))
      );
      if (question) {
        setSelectedQuestion(question.id);
      }
    }
  }, [searchText, questions]);
  return (
    <div className="questions">
      {questions?.filter(_question =>
          removeAccents(_question.title).toLowerCase().includes(removeAccents(searchText.toLowerCase())) ||
          removeAccents(_question.content).toLowerCase().includes(removeAccents(searchText.toLowerCase())) ||
          !searchText
          ).map(question => (
        <Question
          key={question.id}
          question={question}
          isSelected={selectedQuestion === question.id}
          setSelectedQuestion={questionId => {
            setSearchText(searchText.toLowerCase());
            setSelectedQuestion(
              questionId === selectedQuestion ? null : questionId
            );
          }}
        />
      ))}
    </div>
  );
};

const Faq = () => {
  const { faq } = useSelector(state => ({ faq: state.faq }));
  const faqs = useMemo(() => faq.data || [], [faq]);
  const [selectedTopic, setSelectedTopic] = useState(faqs[0]);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFaqRequest());
  }, [dispatch]);
  useEffect(() => {
    setSelectedTopic(faqs[0]);
  }, [faqs]);
  return (
    <div className="faq">
      <Helmet>
        <title>Preguntas Frecuentes - Age Capital Costa Rica</title>
        <meta name='keywords' content='preguntas frecuentes age capital' />
        <meta name='description' content='Encontrá la respuesta a todas las preguntas que tengás con respecto a nuestros créditos y condiciones aquí.' />
      </Helmet>
      <Header
        setSearchText={setSearchText}
        searchText={searchText}
        onSearch={text => {
          setSearchText(text);
          const topic = faqs.find(
            faq =>
              faq.title?.includes(text) ||
              faq.content?.includes(text) ||
              faq.questions?.some(
                question =>
                  question.title?.includes(text) ||
                  question.content?.includes(text)
              )
          );
          if (topic) {
            setSelectedTopic(topic);
          }
        }}
      />
      {faq.isLoading ? (
        <Loader />
      ) : (
        <div className="faq__content">
          <div>
            <div className="faq__topics">
              <div className="topic topic--title">
                Encontrá aquí las respuestas a tus preguntas relacionadas a AGE Capital:
              </div>
              {/* {faqs.map(topic => (
                <Topic
                  key={topic.id}
                  topic={topic}
                  setSelectedTopic={setSelectedTopic}
                  selected={selectedTopic?.id === topic.id}
                />
              ))} */}
            </div>
          </div>
          <div className="faq__questions">
            <Questions
              questions={selectedTopic?.questions}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Faq;
