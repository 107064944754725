import React from 'react';
import './CurrencyInput.scss';
import CurrencyFormatLib from 'react-currency-format';
import useCurrency from '../../hooks/useCurrency.hook';
import classNames from 'classnames';
import useMoneda from '../../hooks/useMoneda';

const CurrencyInput = ({
  name,
  label,
  value,
  placeholder,
  onBlur,
  onChange,
  disabled,
  hasError,
  maxValue,
  disabledForm,
  currency: moneda,
}) => {
  const currency = useCurrency();
  const currencySign = useMoneda(moneda);
  const pre = currencySign?.sign || currency?.sign;
  return (
    <div className="currency-input">
      <div className="currency-input__label">
        <label htmlFor={name}>{label}</label>
      </div>
      <div
        className={classNames('currency-input__control-container', {
          'currency-input__control-container--has-pre': !!pre,
          'currency-input__control-container--error': hasError,
          'currency-input__control-container--disabled': disabledForm,
        })}
      >
        {pre && <div className="currency-input__pre">{pre}</div>}
        <CurrencyFormatLib
          id={name}
          name={name}
          className="currency-input__control"
          onValueChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          value={value}
          thousandSeparator={true}
          displayType="input"
          disabled={disabled}
          isAllowed={(values) => {
            if (!maxValue) {
              return true;
            }
            const { value } = values;
            if (value && parseInt(value) > maxValue) {
              return false;
            }
            return true;
          }}
        />
      </div>
    </div>
  );
};

export default CurrencyInput;
