import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import UserMenu from '../UserMenu';
import './TopBar.scss';


const TopBar = () => (
  <div className="top-bar">
    <div className="logo desktop">
      <Link to="/">
        <img src={logo} alt="Logo" />
      </Link>
    </div>
    <UserMenu />
  </div>
);

export default TopBar;
