import classNames from 'classnames';
import React from 'react';
import './Button.scss';

const Button = ({
  type,
  className,
  value,
  onClick,
  suffix,
  prefix,
  black,
  gray,
  sm,
  disabled,
  component: Component,
  fullwidth,
  ...props
}) => (
  <Component
    {...props}
    onClick={onClick}
    type={type || 'button'}
    className={classNames(`button ${className || ''}`, {
      'button--black': !!black,
      'button--gray': !!gray,
      'button--sm': !!sm,
      'button--disabled': disabled,
      'button--fullwidth': fullwidth,
    })}
    disabled={disabled}
  >
    <div className="button__prefix">{prefix}</div>
    <div className="button__text">{value}</div>
    <div className="button__suffix">{suffix}</div>
  </Component>
);

Button.defaultProps = {
  component: 'button',
};

export default Button;
