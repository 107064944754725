import React, { useState } from 'react';
import './Operation.scss';
import CurrencyFormat from '../CurrencyFormat';
import DollarIcon from '../../assets/images/dollar_icon.svg';
import CalendarIcon from '../../assets/images/calendar_icon.svg';
import FileIcon from '../../assets/images/file_icon.svg';
import Arrow from '../../assets/images/arrow_down.svg';
import { geDaysBetweenDates } from '../../utils/helpers';
import classNames from 'classnames';
import Angle from '../../assets/images/angle-black.svg';

const Operation = ({ operation, index }) => {
  const [open, setOpen] = useState(index === 0);
  const days = operation?.diasAtraso; //geDaysBetweenDates(operation?.proximoPago);
  const [plate, setPlate] = useState(false);
  return (
    <div className={classNames('operation', { 'operation--open': open })}>
      <div className="operation__top-bar">
        <button
          className="operation__top-content"
          onClick={() => setOpen(!open)}
        >
          <div className="operation__label">Número de operación</div>
          <div className="operation__number">{operation?.numero}</div>
          <div className="operation__control">
            <img src={Arrow} alt="Arrow" className="operation__arrow" />
          </div>
        </button>
        <hr />
      </div>
      {open && (
        <>
          <div className="operation__content">
            <div className="operation__plate operation__plate--inner">
              <button onClick={() => setPlate(!plate)} className={classNames('', {
                'operation__plate--active': plate,
              })}>
                Placas ligadas a esta operación: <img src={Angle} className="panel__toggle-image" alt="Arrow" />
              </button>
            </div>
            <hr />
            {plate && <>
              <div className="operation__plate operation__plate--inner" style={{'margin-top': '1rem'}}>
                {operation?.placa?.split(';')?.map(x => x.trim()).join(', ')}
              </div>
              <hr />
            </>}

            <div className="operation__details">
              <div className="operation__box operation__box--quote">
                <div className="operation__box-left operation__dollar">
                  <img
                    src={DollarIcon}
                    alt="Dollar"
                    className="operation__dollar-icon"
                  />
                </div>
                <div className="operation__box-right">
                  <div className="operation__quote">
                    <CurrencyFormat value={operation?.consultaGeneral?.ValorCuota} currentCurrency={operation?.moneda} />
                  </div>
                  <div className="operation__box-label">Cuota mensual</div>
                </div>
              </div>
              <div className="operation__box operation__box--days">
                <div className="operation__box-left operation__calendar">
                  <img
                    src={CalendarIcon}
                    alt="Dollar"
                    className="operation__calendar-icon"
                  />
                </div>
                <div className="operation__box-right">
                  <div className="operation__days-container">

                    {days !== 0 && <div className="operation__days">{Math.abs(days)}</div>}
                    <div className="operation__box-label">
                      {'Días en atraso'}
                      {/* {days === 0 && 'Hoy es día de pago'}
                      {days < 0 && 'Días en atraso'}
                      {days > 0 && 'Días para el próximo pago'} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="operation__box operation__box--number">
                <div className="operation__box-left operation__file">
                  <img
                    src={FileIcon}
                    alt="Dollar"
                    className="operation__file-icon"
                  />
                </div>
                <div className="operation__box-right">
                  <div className="operation__quote-number">
                    {operation?.consultaGeneral?.CuotasPagadas}
                    {/* {operation?.cuotasPagas}/{operation?.plazo} */}
                  </div>
                  <div className="operation__box-label">Número de cuota</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Operation;
