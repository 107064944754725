import React from 'react';
import { Helmet } from "react-helmet";
import Flow from '../Flow';
import Header from '../Header';


const Solicitar = () => {
  return (
    <>
      <Helmet>
        <meta name='description' content='Encontrá el crédito de tus sueños, solicitá tu financiamiento con Age Capital Costa Rica. Ofrecemos financimiento y   leasing interno para Veinsa y en general.' />
        <meta name='keywords' content='veinsa leasing age capital costa rica financiamiento age capital costa rica financiamiento interno veinsa credito mitsubishi leasing financiero costa rica Financiamiento en Veinsa' />
        <title>Leasing Financiero en Costa Rica - Age Capital Costa Rica</title>
      </Helmet>
      <Header />
      <Flow />
    </> 
  );
};

export default Solicitar;


