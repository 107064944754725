// getTokenSelector

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTokenSelector } from '../ducks/selectors';
import { AGE_HUB } from '../utils/constants';

const useHubLink = () => {
  const jwt = useSelector(getTokenSelector);
  const getLink = useCallback(() => {
    return `${AGE_HUB}${jwt ? 
      `/api/sso.php?method=internal&token=${jwt}`: '/'
    }`;
  }, [jwt]);

  return getLink();
};

export default useHubLink;
