import React from 'react';
import './FormalizationIcon.scss';

const FormalizationIconActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectángulo_752" data-name="Rectángulo 752" width="15.153" height="21.531" fill="#fff"/>
      </clipPath>
    </defs>
    <g id="Grupo_19697" data-name="Grupo 19697" transform="translate(-220 -471)">
      <g id="Elipse_215" data-name="Elipse 215" transform="translate(220 471)" fill="#fff" stroke="#bb9947" strokeWidth="1">
        <circle cx="20" cy="20" r="20" stroke="none"/>
        <circle cx="20" cy="20" r="19.5" fill="none"/>
      </g>
      <circle id="Elipse_214" data-name="Elipse 214" cx="16" cy="16" r="16" transform="translate(224 475)" fill="#bb9947"/>
      <g id="Grupo_19698" data-name="Grupo 19698" transform="translate(231.999 481)">
        <g id="Grupo_16422" data-name="Grupo 16422" transform="translate(0 0)" clipPath="url(#clip-path)">
          <path id="Trazado_12421" data-name="Trazado 12421" d="M13.624,0H1.529A1.534,1.534,0,0,0,0,1.529V19.967A1.566,1.566,0,0,0,1.564,21.53H13.589a1.566,1.566,0,0,0,1.564-1.563V1.529A1.533,1.533,0,0,0,13.624,0m.214,1.529V19.966a.25.25,0,0,1-.249.25H1.565a.251.251,0,0,1-.25-.249V1.53a.217.217,0,0,1,.216-.216H13.624a.215.215,0,0,1,.214.214" transform="translate(0 0)" fill="#fff"/>
          <path id="Trazado_12422" data-name="Trazado 12422" d="M4.285,8.918H6.332a.657.657,0,0,0,.657-.656A.647.647,0,0,0,6.8,7.8.659.659,0,0,0,6.331,7.6H4.285a.658.658,0,0,0,0,1.315" transform="translate(-0.841 -2.763)" fill="#fff"/>
          <path id="Trazado_12423" data-name="Trazado 12423" d="M6.332,21.663H4.286a.658.658,0,1,0,0,1.315H6.332a.658.658,0,0,0,0-1.315" transform="translate(-0.841 -6.022)" fill="#fff"/>
          <path id="Trazado_12424" data-name="Trazado 12424" d="M4.286,11.261h8.539a.656.656,0,0,0,.657-.657.658.658,0,0,0-.657-.657H4.285a.657.657,0,0,0,0,1.314" transform="translate(-0.841 -3.306)" fill="#fff"/>
          <path id="Trazado_12425" data-name="Trazado 12425" d="M4.285,13.606h8.541a.657.657,0,0,0,0-1.314H4.286a.657.657,0,0,0,0,1.314" transform="translate(-0.841 -3.85)" fill="#fff"/>
          <path id="Trazado_12426" data-name="Trazado 12426" d="M4.286,15.953h8.539a.657.657,0,0,0,0-1.314H4.285a.657.657,0,0,0,0,1.314" transform="translate(-0.841 -4.394)" fill="#fff"/>
          <path id="Trazado_12427" data-name="Trazado 12427" d="M4.285,18.3h8.541a.657.657,0,0,0,0-1.314H4.286a.657.657,0,0,0,0,1.314" transform="translate(-0.841 -4.937)" fill="#fff"/>
          <path id="Trazado_12428" data-name="Trazado 12428" d="M4.286,20.645h8.539a.657.657,0,1,0,0-1.314H4.286a.657.657,0,0,0,0,1.314" transform="translate(-0.841 -5.481)" fill="#fff"/>
          <path id="Trazado_12429" data-name="Trazado 12429" d="M14.615,22.833l-.89,0a1.454,1.454,0,0,0-.746.205,2.375,2.375,0,0,0-.479-1.185.779.779,0,0,0-.807-.21c-.367.122-.589.469-.767,1.2-.075.315-.122.611-.139.727a.657.657,0,0,0,.572.732.645.645,0,0,0,.5-.153c.109.469.244.759.621.854a.673.673,0,0,0,.172.022h0a.7.7,0,0,0,.536-.259c.091-.111.226-.292.409-.554a.146.146,0,0,1,.12-.061h.89a.658.658,0,0,0,0-1.315" transform="translate(-2.5 -5.009)" fill="#fff"/>
        </g>
      </g>
    </g>
  </svg>
);

const FormalizationIcon = ({ active }) => active ? <FormalizationIconActive /> : (
  <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectángulo_752" data-name="Rectángulo 752" width="15.153" height="21.531" fill="#707070"/>
      </clipPath>
    </defs>
    <g id="Trazado_14033" data-name="Trazado 14033" fill="#fff">
      <path d="M 19 37.5 C 16.50230026245117 37.5 14.07956981658936 37.01092910766602 11.79911994934082 36.04637908935547 C 9.596209526062012 35.11463165283203 7.617690086364746 33.7806396484375 5.918519973754883 32.08147811889648 C 4.219359874725342 30.38231086730957 2.885370016098022 28.40378952026367 1.953619956970215 26.20088005065918 C 0.9890699982643127 23.92042922973633 0.5 21.49769973754883 0.5 19 C 0.5 16.50230026245117 0.9890699982643127 14.07956981658936 1.953619956970215 11.79911994934082 C 2.885370016098022 9.596209526062012 4.219359874725342 7.617690086364746 5.918519973754883 5.918519973754883 C 7.617690086364746 4.219359874725342 9.596209526062012 2.885370016098022 11.79911994934082 1.953619956970215 C 14.07956981658936 0.9890699982643127 16.50230026245117 0.5 19 0.5 C 21.49769973754883 0.5 23.92042922973633 0.9890699982643127 26.20088005065918 1.953619956970215 C 28.40378952026367 2.885370016098022 30.38231086730957 4.219359874725342 32.08147811889648 5.918519973754883 C 33.7806396484375 7.617690086364746 35.11463165283203 9.596209526062012 36.04637908935547 11.79911994934082 C 37.01092910766602 14.07956981658936 37.5 16.50230026245117 37.5 19 C 37.5 21.49769973754883 37.01092910766602 23.92042922973633 36.04637908935547 26.20088005065918 C 35.11463165283203 28.40378952026367 33.7806396484375 30.38231086730957 32.08147811889648 32.08147811889648 C 30.38231086730957 33.7806396484375 28.40378952026367 35.11463165283203 26.20088005065918 36.04637908935547 C 23.92042922973633 37.01092910766602 21.49769973754883 37.5 19 37.5 Z" stroke="none"/>
      <path d="M 19 1 C 14.19202995300293 1 9.671829223632812 2.872329711914062 6.272079467773438 6.272079467773438 C 2.872329711914062 9.671829223632812 1 14.19202995300293 1 19 C 1 23.80797004699707 2.872329711914062 28.32817077636719 6.272079467773438 31.72792053222656 C 9.671829223632812 35.12767028808594 14.19202995300293 37 19 37 C 23.80797004699707 37 28.32817077636719 35.12767028808594 31.72792053222656 31.72792053222656 C 35.12767028808594 28.32817077636719 37 23.80797004699707 37 19 C 37 14.19202995300293 35.12767028808594 9.671829223632812 31.72792053222656 6.272079467773438 C 28.32817077636719 2.872329711914062 23.80797004699707 1 19 1 M 19 0 C 29.49341011047363 0 38 8.506589889526367 38 19 C 38 29.49341011047363 29.49341011047363 38 19 38 C 8.506589889526367 38 0 29.49341011047363 0 19 C 0 8.506589889526367 8.506589889526367 0 19 0 Z" stroke="none" fill="#707070"/>
    </g>
    <g id="Grupo_19658" data-name="Grupo 19658" transform="translate(11 8)">
      <g id="Grupo_16422" data-name="Grupo 16422" transform="translate(0 0)" clipPath="url(#clip-path)">
        <path id="Trazado_12421" data-name="Trazado 12421" d="M13.624,0H1.529A1.534,1.534,0,0,0,0,1.529V19.967A1.566,1.566,0,0,0,1.564,21.53H13.589a1.566,1.566,0,0,0,1.564-1.563V1.529A1.533,1.533,0,0,0,13.624,0m.214,1.529V19.966a.25.25,0,0,1-.249.25H1.565a.251.251,0,0,1-.25-.249V1.53a.217.217,0,0,1,.216-.216H13.624a.215.215,0,0,1,.214.214" transform="translate(0 0)" fill="#707070"/>
        <path id="Trazado_12422" data-name="Trazado 12422" d="M4.285,8.918H6.332a.657.657,0,0,0,.657-.656A.647.647,0,0,0,6.8,7.8.659.659,0,0,0,6.331,7.6H4.285a.658.658,0,0,0,0,1.315" transform="translate(-0.841 -2.763)" fill="#707070"/>
        <path id="Trazado_12423" data-name="Trazado 12423" d="M6.332,21.663H4.286a.658.658,0,1,0,0,1.315H6.332a.658.658,0,0,0,0-1.315" transform="translate(-0.841 -6.022)" fill="#707070"/>
        <path id="Trazado_12424" data-name="Trazado 12424" d="M4.286,11.261h8.539a.656.656,0,0,0,.657-.657.658.658,0,0,0-.657-.657H4.285a.657.657,0,0,0,0,1.314" transform="translate(-0.841 -3.306)" fill="#707070"/>
        <path id="Trazado_12425" data-name="Trazado 12425" d="M4.285,13.606h8.541a.657.657,0,0,0,0-1.314H4.286a.657.657,0,0,0,0,1.314" transform="translate(-0.841 -3.85)" fill="#707070"/>
        <path id="Trazado_12426" data-name="Trazado 12426" d="M4.286,15.953h8.539a.657.657,0,0,0,0-1.314H4.285a.657.657,0,0,0,0,1.314" transform="translate(-0.841 -4.394)" fill="#707070"/>
        <path id="Trazado_12427" data-name="Trazado 12427" d="M4.285,18.3h8.541a.657.657,0,0,0,0-1.314H4.286a.657.657,0,0,0,0,1.314" transform="translate(-0.841 -4.937)" fill="#707070"/>
        <path id="Trazado_12428" data-name="Trazado 12428" d="M4.286,20.645h8.539a.657.657,0,1,0,0-1.314H4.286a.657.657,0,0,0,0,1.314" transform="translate(-0.841 -5.481)" fill="#707070"/>
        <path id="Trazado_12429" data-name="Trazado 12429" d="M14.615,22.833l-.89,0a1.454,1.454,0,0,0-.746.205,2.375,2.375,0,0,0-.479-1.185.779.779,0,0,0-.807-.21c-.367.122-.589.469-.767,1.2-.075.315-.122.611-.139.727a.657.657,0,0,0,.572.732.645.645,0,0,0,.5-.153c.109.469.244.759.621.854a.673.673,0,0,0,.172.022h0a.7.7,0,0,0,.536-.259c.091-.111.226-.292.409-.554a.146.146,0,0,1,.12-.061h.89a.658.658,0,0,0,0-1.315" transform="translate(-2.5 -5.009)" fill="#707070"/>
      </g>
    </g>
  </svg>
);
export default FormalizationIcon;



