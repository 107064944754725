// @flow
/**
 * @module Actions/BLOG
 * @desc Actions for Faq
 */
 import ActionTypes from './actionTypes';

 /**
  * Fetch BLOG request
  * @returns {Object}
  */
 export function fetchHomePageBlogsRequest(email, password) {
   return {
     type: ActionTypes.FETCH_HOMEPAGE_POSTS,
   };
 }
 
 /**
  * Fetch blog success
  *
  * @param {object} blogs
  * @returns {Object}
  */
 export function fetchHomePageBlogsSuccess(blogs) {
   return {
     type: ActionTypes.FETCH_HOMEPAGE_POSTS_SUCCESS,
     payload: { blogs },
   };
 }
 
 /**
  * Fetch faq failure
  *
  * @returns {Object}
  */
 export function fetchHomePageBlogsFailure() {
   return {
     type: ActionTypes.FETCH_HOMEPAGE_POSTS_FAILURE,
   };
 }
 
export function fetchCategories() {
  return {
    type: ActionTypes.FETCH_CATEGORIES,
    };
}

export function fetchCategoriesSuccess(categories) {
  return {
    type: ActionTypes.FETCH_CATEGORIES_SUCCESS,
    payload: {
      categories
    }
  };
}

export function fetchCategoriesFailure() {
  return {
    type: ActionTypes.FETCH_CATEGORIES_FAILURE,
  };
}

export function fetchSearch(search) {
  return {
    type: ActionTypes.FETCH_SEARCH,
    payload: { search }
  };
}

export function fetchSearchSuccess(posts, search) {
  return {
    type: ActionTypes.FETCH_SEARCH_SUCCESS,
    payload: {
      posts,
      search
    }
  };
}

export function fetchSearchFailure() {
  return {
    type: ActionTypes.FETCH_SEARCH_FAILURE,
  };
}

// CAPSULAS
export function fetchCapsulas() {
  return {
    type: ActionTypes.FETCH_CAPSULAS,
  };
}

export function fetchCapsulasSuccess(posts) {
  return {
    type: ActionTypes.FETCH_CAPSULAS_SUCCESS,
    payload: {
      posts,
    }
  };
}

export function fetchCapsulasFailure() {
  return {
    type: ActionTypes.FETCH_CAPSULAS_FAILURE,
  };
}

// Categoria Blog
export function fetchCategoryBlog(slug) {
  return {
    type: ActionTypes.FETCH_CATEGORIES_BY_SLUG,
    payload: { slug }
  };
}

export function fetchCategoryBlogSuccess(posts, slug) {
  return {
    type: ActionTypes.FETCH_CATEGORIES_BY_SLUG_SUCCESS,
    payload: {
      posts,
      slug,
    }
  };
}

export function fetchCategoryBlogFailure() {
  return {
    type: ActionTypes.FETCH_CATEGORIES_BY_SLUG_FAILURE,
  };
}

// Blog Post
export function fetchBlogPost(id) {
  return {
    type: ActionTypes.FETCH_BLOG_POST,
    payload: { id }
  };
}

export function fetchBlogPostSuccess(post) {
  return {
    type: ActionTypes.FETCH_BLOG_POST_SUCCESS,
    payload: {
      post,
    }
  };
}

export function fetchBlogPostFailure() {
  return {
    type: ActionTypes.FETCH_BLOG_POST_FAILURE,
  };
}

// ALL Blogs
export function fetchAllBlogs() {
  return {
    type: ActionTypes.FETCH_ALL_BLOG
  };
}

export function fetchAllBlogsSuccess(posts) {
  return {
    type: ActionTypes.FETCH_ALL_BLOG_SUCCESS,
    payload: {
      posts,
    }
  };
}

export function fetchAllBlogsFailure() {
  return {
    type: ActionTypes.FETCH_ALL_BLOG_FAILURE,
  };
}