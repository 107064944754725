const ActionTypes = {
  SET_CURRENCY_REQUEST: 'SET_CURRENCY_REQUEST',
  SET_CURRENCY_SUCCESS: 'SET_CURRENCY_SUCCESS',
  SET_CURRENCY_FAILURE: 'SET_CURRENCY_FAILURE',
  SET_CURRENCY_RESET: 'SET_CURRENCY_RESET',
  FETCH_HOMEPAGE_REQUEST: 'FETCH_HOMEPAGE_REQUEST',
  FETCH_HOMEPAGE_SUCCESS: 'FETCH_HOMEPAGE_SUCCESS',
  FETCH_HOMEPAGE_FAILURE: 'FETCH_HOMEPAGE_FAILURE',
  FETCH_HOMEPAGE_RESET: 'FETCH_HOMEPAGE_RESET',
  FETCH_RATES_REQUEST: 'FETCH_RATES_REQUEST',
  FETCH_RATES_SUCCESS: 'FETCH_RATES_SUCCESS',
  FETCH_RATES_FAILURE: 'FETCH_RATES_FAILURE',
  FETCH_RATES_RESET: 'FETCH_RATES_RESET',
  SUBMIT_QUOTE_REQUEST: 'SUBMIT_QUOTE_REQUEST',
  SUBMIT_QUOTE_SUCCESS: 'SUBMIT_QUOTE_SUCCESS',
  SUBMIT_QUOTE_FAILURE: 'SUBMIT_QUOTE_FAILURE',
  CHANGE_QUOTE: 'CHANGE_QUOTE',
  SUBMIT_QUOTE_RESET: 'SUBMIT_QUOTE_RESET',
  SAVE_FILES_REQUEST: 'SAVE_FILES_REQUEST',
  SAVE_FILES_SUCCESS: 'SAVE_FILES_SUCCESS',
  SAVE_FILES_FAILURE: 'SAVE_FILES_FAILURE',
  SAVE_FILES_RESET: 'SAVE_FILES_RESET',
  FETCH_SOLICITUDES_REQUEST: 'FETCH_SOLICITUDES_REQUEST',
  FETCH_SOLICITUDES_SUCCESS: 'FETCH_SOLICITUDES_SUCCESS',
  FETCH_SOLICITUDES_FAILURE: 'FETCH_SOLICITUDES_FAILURE',
  FETCH_SOLICITUDES_RESET: 'FETCH_SOLICITUDES_RESET',

  FETCH_PAQUETES_REQUEST: 'FETCH_PAQUETES_REQUEST',
  FETCH_PAQUETES_SUCCESS: 'FETCH_PAQUETES_SUCCESS',
  FETCH_PAQUETES_FAILURE: 'FETCH_PAQUETES_FAILURE',

  SAVE_PAQUETES_REQUEST: 'SAVE_PAQUETES_REQUEST',
  SAVE_PAQUETES_SUCCESS: 'SAVE_PAQUETES_SUCCESS',
  SAVE_PAQUETES_FAILURE: 'SAVE_PAQUETES_FAILURE',

  DELETE_FILE_REQUEST: 'DELETE_FILE_REQUEST',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  DELETE_FILE_FAILURE: 'DELETE_FILE_FAILURE',
  DELETE_FILE_RESET: 'DELETE_FILE_RESET',
  SAVE_RESPUESTAS_REQUEST: 'SAVE_RESPUESTAS_REQUEST',
  SAVE_RESPUESTAS_SUCCESS: 'SAVE_RESPUESTAS_SUCCESS',
  SAVE_RESPUESTAS_FAILURE: 'SAVE_RESPUESTAS_FAILURE',

  SAVE_CUSTOMER_FORM_REQUEST: 'SAVE_CUSTOMER_FORM_REQUEST',
  SAVE_CUSTOMER_FORM_SUCCESS: 'SAVE_CUSTOMER_FORM_SUCCESS',
  SAVE_CUSTOMER_FORM_FAILURE: 'SAVE_CUSTOMER_FORM_FAILURE',
  SAVE_CUSTOMER_FORM_RESET: 'SAVE_CUSTOMER_FORM_RESET',

  DOWNLOAD_DTC_REQUEST: 'DOWNLOAD_DTC_REQUEST',
  DOWNLOAD_DTC_SUCCESS: 'DOWNLOAD_DTC_SUCCESS',

  APPROVE_OFFER: 'APPROVE_OFFER',
  APPROVE_OFFER_SUCCESS: 'APPROVE_OFFER_SUCCESS',

  REQUEST_DOCUMENTO_REQUEST: 'REQUEST_DOCUMENTO_REQUEST',
  REQUEST_DOCUMENTO_SUCCESS: 'REQUEST_DOCUMENTO_SUCCESS',

  APPROVE_TYC: 'APPROVE_TYC', 
  APPROVE_TYC_SUCCESS: 'APPROVE_TYC_SUCCESS',

  CHANGE_FILE: 'CHANGE_FILE',
  CHANGE_SOLICITUD_TO_QUOTE: 'CHANGE_SOLICITUD_TO_QUOTE',
  RESET_QUOTE: 'RESET_QUOTE',

  FETCH_INFO_CEDULA: 'FETCH_INFO_CEDULA',
  FETCH_INFO_CEDULA_SUCCESS: 'FETCH_INFO_CEDULA_SUCCESS',
  FETCH_INFO_CEDULA_FAILURE: 'FETCH_INFO_CEDULA_FAILURE',

  FORMALIZATION_REQUEST: 'FORMALIZATION_REQUEST',
  FORMALIZATION_SUCCESS: 'FORMALIZATION_SUCCESS',
  FORMALIZATION_FAILURE: 'FORMALIZATION_FAILURE',

  CANCELATION_REQUEST: 'CANCELATION_REQUEST',
  CANCELATION_SUCCESS: 'CANCELATION_SUCCESS',
  CANCELATION_FAILURE: 'CANCELATION_FAILURE',

  DOWNLOAD_LETTER_REQUEST: 'DOWNLOAD_LETTER_REQUEST',
  DOWNLOAD_LETTER_SUCCESS: 'DOWNLOAD_LETTER_SUCCESS',
  DOWNLOAD_LETTER_FAILURE: 'DOWNLOAD_LETTER_FAILURE',

  DOWNLOAD_APPROVED_LETTER_REQUEST: 'DOWNLOAD_APPROVED_LETTER_REQUEST',
  DOWNLOAD_APPROVED_LETTER_SUCCESS: 'DOWNLOAD_APPROVED_LETTER_SUCCESS',
  DOWNLOAD_APPROVED_LETTER_FAILURE: 'DOWNLOAD_APPROVED_LETTER_FAILURE',

  GET_SOLICITUD_DOCUMENTS: 'GET_SOLICITUD_DOCUMENTS',
  GET_SOLICITUD_DOCUMENTS_SUCCESS: 'GET_SOLICITUD_DOCUMENTS_SUCCESS',
  GET_SOLICITUD_DOCUMENTS_FAILURE: 'GET_SOLICITUD_DOCUMENTS_FAILURE',

  CHANGE_QUOTE_STATUS: 'CHANGE_QUOTE_STATUS',
};

export default ActionTypes;
