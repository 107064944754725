import classNames from 'classnames';
import React, { forwardRef, useState } from 'react';
import './Input.scss';

const Input = forwardRef(
  (
    {
      onChange,
      onBlur,
      name,
      label,
      pre,
      placeholder,
      defaultValue,
      value,
      size2,
      disabled,
      type2,
      type,
      className,
      whiteBackground,
      onKeyUp,
      hasError,
      disclaimer,
      error,
      maxLength = 1000,
      circular,
    },
    ref
  ) => {
    const [orType, setOrType] = useState(type);
    return (
      <div
        className={classNames(`input ${className || ''}`, {
          'input--size2': size2,
          'input--disabled': disabled,
          'input--type2': type2,
          'input--background': whiteBackground,
        })}
      >
        {!type2 && label && (
          <div className="input__label">
            <label htmlFor={name}>{label}</label>
          </div>
        )}
        {disclaimer && (
          <div className="input__disclaimer">
            <p>{disclaimer}</p>
          </div>
        )}
        <div
          className={classNames('input__control-container', {
            'input__control-container--has-pre': !!pre,
            'input__control-container--error': hasError,
            'input__control-container--circular': circular,
          })}
        >
          {pre && <div className="input__pre">{pre}</div>}
          <input
            ref={ref}
            id={name}
            name={name}
            className="input__control"
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            disabled={disabled}
            type={orType || 'text'}
            onKeyUp={onKeyUp}
            maxLength={maxLength}
          />
          {type === 'password' && (
            <div className='input__control-eye'>
              <button onClick={(ev) => {
                ev.preventDefault()
                ev.stopPropagation();
                ev.nativeEvent.stopImmediatePropagation();
                setOrType(orType === 'password' ? 'text' : 'password');
              }} className={classNames('input__control-eye-button', {
                'input__control-eye-button--active': orType === 'password'
              })} />
            </div>
          )}
        </div>
        {error && (
          <div className="input__error">
            <p>{error}</p>
          </div>
        )}
      </div>
    );
  }
);

export default Input;
