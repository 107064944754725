/**
 * @desc Root Epics
 * @module Epics/Root
 */

import { combineEpics } from 'redux-observable';

import * as userEpics from './user/epics';
import * as appEpics from './app/epics';
import * as faqEpics from './faq/epics';
import * as blogEpics from './blog/epics';

const epics = {
  ...userEpics,
  ...appEpics,
  ...faqEpics,
  ...blogEpics,
};

export default combineEpics(...Object.values(epics));
