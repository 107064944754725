import React from 'react';
import classNames from 'classnames';

import './VehicleType.scss';
import Used from '../Used';
import New from '../New';
import { VEHICLE_TYPES } from '../TipoFinanciamiento/TipoFinanciamiento';

const VehicleType = ({ type, setType }) => (
  <div className="vehicle-type">
    <div className="vehicle-type__label">Vehículo:</div>
    <button
      className={classNames('vehicle-type__button vehicle-type__button--new', {
        'vehicle-type__button--active': type === VEHICLE_TYPES.Nuevo,
      })}
      onClick={() => setType(VEHICLE_TYPES.Nuevo)}
      type="button"
    >
      <span className="vehicle-type__icon">
        <New white={type === VEHICLE_TYPES.Nuevo} />
      </span>
      Nuevo
    </button>
    <button
      className={classNames('vehicle-type__button', {
        'vehicle-type__button--active': type === VEHICLE_TYPES.Usado,
      })}
      onClick={() => setType(VEHICLE_TYPES.Usado)}
      type="button"
    >
      <span className="vehicle-type__icon">
        <Used white={type === VEHICLE_TYPES.Usado} />
      </span>
      Usado
    </button>
  </div>
);

export default VehicleType;
