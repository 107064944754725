import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

import './Requirements.scss';

import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RequirementsTimeline from '../RequirementsTimeline/RequirementsTimeline';
import DeleteIcon from '../DeleteIcon';
import Whatsapp from '../../assets/images/whatsapp.png';
import Button from '../Button';
import Save from '../../assets/images/save.svg';
import Arrow from '../../assets/images/arrow-left.png';
import Download from '../../assets/images/download.png';
import TooltipIcon from '../../assets/images/tooltip.png';
import FormSection from '../FormSection';

import { getRemainingDocs, results, WHATSAPP_LINK } from '../../utils/constants';

import CommonDialog from '../CommonDialog';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteFileRequest,
  saveFilesRequest,
  saveCustomerFormRequest,
  downloadDtc,
  changeFile,
  changeQuoteStatus,
} from '../../ducks/actions';
import Result from '../Result/Result';

import { useForm } from 'react-hook-form';
import DatePicker from '../DatePicker/DatePicker';
import Input from '../Input';

import Select from '../Select';
import {
  PAISES,
  ESTADOS_CIVILES,
  GRADOS_ACADEMICOS,
  ORIGENES_DE_FONDOS,
  PROFESIONES,
  TIPOS_IDENTIFICACION,
  PROVINCIAS,
  CANTONES,
  DISTRITOS,
  SI_NO,
  ACTIVIDAD_ECONOMICA,
  NACIONALIDAD,
  MONEDAS,
  PEP,
  ESTRUCTURA_PROPIEDAD,
  TIPO_PERSONA,
  TIPO_FIRMA,
  SUBACTIVIDAD_ECONOMICA,
} from './data';
import { getFieldStatus, getQuoteStatus, scrollTo, toArray, toUTCTime, canDelete, cannotMakeChanges } from '../../utils/helpers';
import usePrevious from '../../hooks/usePrevious';
import ViewIcon from '../ViewIcon/ViewIcon';
import CameraIcon from '../CameraIcon/CameraIcon';
import { ALLOW_CAMARA, FORMALIZATION_DOCUMENTS, ICON_BY_STATUS, PERSONAL_DOCUMENTS, STATUS } from '../../utils/constants';

const EMAIL_REGEX = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

const PersonalForm = ({ register, watch, quote, getValues, errors }) => {
  return (
    <div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Input
            label="Primer apellido"
            placeholder="Digite su apellido"
            {...register('primerApellido', {
              required: true,
            })}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Segundo apellido"
            placeholder="Digite su apellido"
            {...register('segundoApellido', {
              required: true,
            })}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Nombre completo"
            placeholder="Digite su nombre"
            {...register('nombre', {
              required: true,
            })}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="País de nacimiento"
            {...register('paisNacimiento', {
              required: true,
            })}
            // hasError={!!errors.nombre}
            options={PAISES}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Nacionalidad "
            options={NACIONALIDAD}
            {...register('nacionalidad', {
              required: true,
            })}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <DatePicker
            bigger={true}
            label="Fecha de nacimiento"
            // placeholder="Ingrese su nombre completo"
            {...register('fechaNacimiento', {
              required: true,
            })}
            // hasError={!!errors.nombre}
            value={getValues('fechaNacimiento')}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="Sexo"
            {...register('sexo', {
              required: true,
            })}
            // hasError={!!errors.nombre}
            options={[
              { value: 1, label: 'Masculino' },
              { value: 2, label: 'Femenino' },
            ]}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Tipo de identificación"
            {...register('tipoIdentificacion', {
              required: true,
            })}
            // hasError={!!errors.nombre}
            options={TIPOS_IDENTIFICACION}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Número de identificación"
            placeholder="Digite"
            {...register('numeroIdentificacion', {
              required: true,
            })}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <DatePicker
            bigger={true}
            label="Fecha de vencimiento"
            // placeholder="Ingrese su nombre completo"
            {...register('fechaVencimiento')}
            // hasError={!!errors.nombre}
            value={getValues('fechaVencimiento', {
              required: true,
            })}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Estado civil"
            {...register('estadoCivil', {
              required: true,
            })}
            // hasError={!!errors.nombre}
            options={ESTADOS_CIVILES}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Grado académico"
            {...register('gradoAcademico', {
              required: true,
            })}
            options={GRADOS_ACADEMICOS}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="Profesión / Ocupación"
            options={PROFESIONES}
            {...register('profesion', {
              required: true,
            })}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Actividad económica"
            options={ACTIVIDAD_ECONOMICA}
            {...register('actividadEconomica', {
              required: true,
            })}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Sub actividad económica"
            options={SUBACTIVIDAD_ECONOMICA.filter(
              sub =>
                sub.actividad ===
                (watch('actividadEconomica') ||
                  quote?.cliente?.actividadEconomica)
            )}
            {...register('subActividadEconomica', {
              required: true,
            })}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Origen de fondos"
            options={ORIGENES_DE_FONDOS}
            {...register('origenFondos', {
              required: true,
            })}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column form-section__column--span2">
          <Input
            label="Dirección exacta"
            placeholder="Digite su dirección exacta"
            {...register('direccion', {
              required: true,
            })}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="País *"
            {...register('pais', {
              required: true,
            })}
            hasError={!!errors.pais}
            // hasError={!!errors.nombre}
            options={PAISES}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Provincia *"
            {...register('provincia', {
              required: true,
            })}
            hasError={!!errors.provincia}
            // hasError={!!errors.nombre}
            options={PROVINCIAS}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Cantón *"
            // placeholder="Ingrese su nombre completo"
            {...register('canton', {
              required: true,
            })}
            hasError={!!errors.canton}
            options={CANTONES.filter(
              canton => canton.provincia === watch('provincia')
            )}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="Distrito *"
            {...register('distrito', {
              required: true,
            })}
            // hasError={!!errors.nombre}
            options={DISTRITOS.filter(
              distrito =>
                distrito.canton === (watch('canton') || quote?.cliente?.canton)
            )}
          />
        </div>
        <div className="form-section__column">
          <Input
            maxLength={8}
            label="Teléfono de residencia"
            placeholder="Digite"
            {...register('telefono')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Celular"
            placeholder="Digite"
            {...register('celular', {
              required: true,
            })}
            hasError={!!errors.celular}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Input
            label="Correo electrónico"
            placeholder="Digite"
            {...register('email', {
              pattern: EMAIL_REGEX,
              required: true,
            })}
            hasError={!!errors.email}
          />
        </div>
        <div className="form-section__column"></div>
        <div className="form-section__column"></div>
      </div>
    </div>
  );
};

const PersonalJuridicoForm = ({
  register,
  watch,
  quote,
  getValues,
  errors,
}) => {
  return (
    <div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Input
            label="Razón Social"
            placeholder="Digite su razón social"
            {...register('razonSocial')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Tipo de identificación"
            {...register('tipoIdentificacion')}
            // hasError={!!errors.nombre}
            options={TIPOS_IDENTIFICACION}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Número de identificación"
            placeholder="Digite"
            {...register('numeroIdentificacion')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Input
            label="Nombre Comercial"
            placeholder="Digite"
            {...register('nombreComercial')}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Estructura de la propiedad"
            options={ESTRUCTURA_PROPIEDAD}
            {...register('estructuraPropiedad')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="País de Constitución"
            options={PAISES}
            {...register('paisConstitucion')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <DatePicker
            bigger={true}
            label="Fecha de Constitución"
            // placeholder="Ingrese su nombre completo"
            {...register('fechaConstitucion')}
            // hasError={!!errors.nombre}
            value={getValues('fechaConstitucion')}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Tipo de Persona"
            {...register('tipoPersona')}
            // hasError={!!errors.nombre}
            options={TIPO_PERSONA}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Origen de fondos"
            options={ORIGENES_DE_FONDOS}
            {...register('origenFondos')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="Actividad económica"
            options={ACTIVIDAD_ECONOMICA}
            {...register('actividadEconomica')}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="País de Actividad Económica"
            {...register('paisTrabajo')}
            // hasError={!!errors.nombre}
            options={PAISES}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Provincia"
            {...register('provincia')}
            // hasError={!!errors.nombre}
            options={PROVINCIAS}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="Cantón"
            // placeholder="Ingrese su nombre completo"
            {...register('canton')}
            options={CANTONES.filter(
              canton => canton.provincia === watch('provincia')
            )}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Distrito"
            {...register('distrito')}
            options={DISTRITOS.filter(
              distrito =>
                distrito.canton === (watch('canton') || quote?.cliente?.canton)
            )}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Dirección exacta"
            placeholder="Digite su dirección exacta"
            {...register('direccion')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Input
            maxLength={8}
            label="Teléfono de residencia"
            placeholder="Digite"
            {...register('telefono')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Correo electrónico"
            placeholder="Digite"
            {...register('email', {
              pattern: EMAIL_REGEX,
            })}
            hasError={!!errors.email}
          />
        </div>
        <div className="form-section__column"></div>
      </div>
    </div>
  );
};

const AccionistasForm = ({ register, watch, quote, errors }) => {
  const accionistasLength = quote?.cliente?.accionistas?.length ?? 0;
  const [accionistas, setAccionistas] = useState(
    accionistasLength === 0 ? 1 : accionistasLength
  );

  const accionistaError = x => (errors?.accionistas?.at(x) || {});

  return (
    <div>
      {toArray(accionistas).map((x, index) => (
        <div key={x}>
          <div className="form-section__row">
            <div className="form-section__column">
              <Input
                label="Nombre Completo"
                placeholder="Digite su nombre completo"
                {...register(`accionistas.${x}.accionistaNombre`, {
                  required: true,
                })}
                name={`accionistas[${x}].accionistaNombre`}
                hasError={!!accionistaError(x).accionistaNombre}
              />
            </div>
            <div className="form-section__column">
              <Select
                label="Tipo de identificación"
                {...register(`accionistas.${x}.accionistaTipoId`, {
                  required: true,
                })}
                name={`accionistas[${x}].accionistaTipoId`}
                hasError={!!accionistaError(x).accionistaTipoId}
                options={TIPOS_IDENTIFICACION}
              />
            </div>
            <div className="form-section__column">
              <Input
                label="Número de identificación"
                placeholder="Digite"
                {...register(`accionistas.${x}.accionistaId`, {
                  required: true,
                })}
                hasError={!!accionistaError(x).accionistaId}
                name={`accionistas[${x}].accionistaId`}
                // hasError={!!errors.nombre}
              />
            </div>
          </div>
          <div className="form-section__row">
            <div className="form-section__column">
              <Select
                label="Nacionalidad"
                {...register(`accionistas.${x}.accionistaNacionalidad`, {
                  required: true,
                })}
                name={`accionistas[${x}].accionistaNacionalidad`}
                hasError={!!accionistaError(x).accionistaNacionalidad}
                options={PAISES}
              />
            </div>
            <div className="form-section__column">
              <Input
                label="Porcentaje"
                placeholder="Digite"
                {...register(`accionistas.${x}.accionistaPorcentaje`, {
                  required: true,
                })}
                hasError={!!accionistaError(x).accionistaPorcentaje}
                name={`accionistas[${x}].accionistaPorcentaje`}
                // hasError={!!errors.nombre}
              />
            </div>
          </div>
          <div className="form-buttons">
            <button
              type="button"
              className="form-plus"
              onClick={() => setAccionistas(accionistas + 1)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
            {index !== 0 && (
              <button
                type="button"
                className="form-plus"
                onClick={() => setAccionistas(accionistas - 1)}
              >
                <FontAwesomeIcon icon={faMinus} />
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const RepresentanteLegal = ({ register, watch, quote }) => {
  return (
    <div>
      <input type="hidden" {...register('representanteRId')} />
      <div className="form-section__row">
        <div className="form-section__column">
          <Input
            label="Nombre"
            placeholder="Digite su nombre"
            {...register('representanteNombre')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Primer Apellido"
            placeholder="Digite su primer apellido"
            {...register('representanteApellido')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Segundo Apellido"
            placeholder="Digite su segundo apellido"
            {...register('representanteSegApellido')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="Tipo de identificación"
            {...register('representanteTipoId')}
            // hasError={!!errors.nombre}
            options={TIPOS_IDENTIFICACION}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Número de identificación"
            placeholder="Digite"
            {...register('representanteId')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Nacionalidad"
            options={NACIONALIDAD}
            {...register('representanteNacionalidad')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="Profesión / Ocupación"
            options={PROFESIONES}
            {...register('representanteProfesion')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Email"
            placeholder="Digite"
            {...register('representanteEmail')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Télefono Residencia"
            placeholder="Digite"
            {...register('representanteTelResidencia')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Input
            label="Télefono Oficina"
            placeholder="Digite"
            {...register('representanteTelOficina')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Télefono Celular"
            placeholder="Digite"
            {...register('representanteTelCel')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Tipo Firma"
            {...register('representanteTipoFirma')}
            options={TIPO_FIRMA}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="Pais"
            {...register('representantePais')}
            options={PAISES}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Provincia"
            {...register('representanteProvincia')}
            // hasError={!!errors.nombre}
            options={PROVINCIAS}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Cantón"
            {...register('representanteCanton')}
            options={CANTONES.filter(
              canton =>
                canton.provincia ===
                (watch('representanteProvincia') ||
                  quote?.cliente?.representanteProvincia)
            )}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="Distrito"
            // placeholder="Ingrese su nombre completo"
            {...register('representanteDistrito')}
            // hasError={!!errors.nombre}
            options={DISTRITOS.filter(
              distrito =>
                distrito.canton ===
                (watch('representanteCanton') ||
                  quote?.cliente?.representanteCanton)
            )}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Dirección"
            placeholder="Digite"
            {...register('representanteDireccion')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
    </div>
  );
};

const LaboralForm = ({ register, watch, quote, getValues, errors }) => {
  return (
    <div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Input
            label="Nombre de la empresa"
            placeholder="Digite el nombre de la empresa"
            {...register('nombreEmpresa')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Empresa propia"
            {...register('empresaPropia')}
            options={SI_NO}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Puesto / Ocupación"
            placeholder="Digite su puesto / ocupación"
            {...register('puesto')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <DatePicker
            bigger={true}
            label="Fecha de ingreso"
            {...register('fechaIngreso')}
            // hasError={!!errors.nombre}
            value={getValues('fechaIngreso')}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Ingresos mensuales"
            placeholder="Digite sus ingresos mensuales"
            {...register('ingresosMensuales')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Moneda"
            options={MONEDAS}
            {...register('ingresosMensualesMoneda')}
            // hasError={!!errors.nombre}
          />
        </div>
        {(watch('ingresosMensualesMoneda') ||
          quote?.cliente?.ingresosMensualesMoneda) === MONEDAS[2].value && (
          <div className="form-section__column">
            <Input
              label="Indique Moneda"
              placeholder="Digite"
              {...register('otraMoneda')}
              // hasError={!!errors.nombre}
            />
          </div>
        )}

        <div className="form-section__column">
          <Input
            label="Otros ingresos"
            placeholder="Digite otros ingresos"
            {...register('otrosIngresos')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="País donde se ubica su trabajo"
            {...register('paisTrabajo')}
            // hasError={!!errors.nombre}
            options={PAISES}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Provincia"
            {...register('provinciaTrabajo')}
            // hasError={!!errors.nombre}
            options={PROVINCIAS}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Cantón"
            {...register('cantonTrabajo')}
            options={CANTONES.filter(
              canton =>
                canton.provincia ===
                (watch('provinciaTrabajo') || quote?.cliente?.provinciaTrabajo)
            )}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="Distrito"
            // placeholder="Ingrese su nombre completo"
            {...register('distritoTrabajo')}
            // hasError={!!errors.nombre}
            options={DISTRITOS.filter(
              distrito =>
                distrito.canton ===
                (watch('cantonTrabajo') || quote?.cliente?.cantonTrabajo)
            )}
          />
        </div>
        <div className="form-section__column form-section__column--span2">
          <Input
            label="Dirección exacta"
            placeholder="Digite su dirección exacta"
            {...register('direccionTrabajo')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Input
            maxLength={8}
            label="Teléfono de oficina"
            placeholder="Digite"
            {...register('telefonoTrabajo', { required: false })}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Extensión"
            placeholder="Digite"
            {...register('extensionTrabajo', { required: false })}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Correo electrónico"
            placeholder="Digite"
            {...register('correoTrabajo', {
              pattern: EMAIL_REGEX,
              required: false,
            })}
            hasError={!!errors.correoTrabajo}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="Actividad comercial de la empresa"
            placeholder="Digite la actividad comercial de su empresa"
            options={ACTIVIDAD_ECONOMICA}
            {...register('actividadEmpresa')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
    </div>
  );
};

const ExpuestasForm = ({ register, watch, quote, getValues, errors }) => {
  const relactionPersonaExpuesta = watch('relactionPersonaExpuesta');
  return (
    <div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="Indique si usted califica como persona expuesta políticamente"
            {...register('personaExpuesta')}
            options={SI_NO}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Tiene relación de primer o segundo grado de consanguinidad con una persona expuesta políticamente"
            {...register('relactionPersonaExpuesta')}
            options={SI_NO}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Input
            label="Indique su nombre*"
            placeholder="Digite"
            {...register('personaExpuestaNombre', {
              required: parseInt(relactionPersonaExpuesta) === 1 ? 'Requerido' : false,
            })}
            hasError={!!errors.personaExpuestaNombre}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Select
            label="Puesto*"
            placeholder="Digite"
            options={PEP}
            {...register('personaExpuestaPuesto', {
              required: parseInt(relactionPersonaExpuesta) === 1 ? 'Requerido' : false,
            })}
            hasError={!!errors.personaExpuestaPuesto}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Entidad donde labora*"
            placeholder="Digite"
            {...register('personaExpuestaEntidad', {
              required: parseInt(relactionPersonaExpuesta) === 1 ? 'Requerido' : false,
            })}
            hasError={!!errors.personaExpuestaEntidad}

            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <DatePicker
            bigger={true}
            label="Fecha de vencimiento del cargo*"
            {...register('personaExpuestaFechaVencimiento', {
              required: parseInt(relactionPersonaExpuesta) === 1 ? 'Requerido' : false,
            })}
            hasError={!!errors.personaExpuestaFechaVencimiento}
            value={getValues('personaExpuestaFechaVencimiento')}
          />
        </div>
      </div>
    </div>
  );
};

const AdicionalForm = ({ register, watch, errors, getValues }) => {
  const administraFondosTerceros = watch('administraFondosTerceros');
  const actividadesFinancierasNoFinancieras = watch('actividadesFinancierasNoFinancieras');
  const tieneRelacionesComercialesDeRiesgo = watch('tieneRelacionesComercialesDeRiesgo');
  return (
    <div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="¿Administra usted fondos de terceros?"
            {...register('administraFondosTerceros')}
            options={SI_NO}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label={`Indique*`}
            placeholder="Digite"
            {...register('fondosTercerosComentario', {
              required: parseInt(administraFondosTerceros) === 1 ? 'Requerido' : false,
            })}
            hasError={!!errors.fondosTercerosComentario}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="¿Realiza usted alguna de las actividades descritas como actividades profesionales no financieras designadas?"
            {...register('actividadesFinancierasNoFinancieras')}
            options={SI_NO}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Indique*"
            placeholder="Digite"
            {...register('actividadesFinancierasNoFinancierasComentario', {
              required: parseInt(actividadesFinancierasNoFinancieras) === 1 ? 'Requerido' : false,
            })}
            hasError={!!errors.actividadesFinancierasNoFinancierasComentario}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Select
            label="Mantiene relaciones comerciales y transacciones con jurisdicciones catalogadas de riesgo por organismos internacionales como GAFI,  OFAC u ONU"
            {...register('tieneRelacionesComercialesDeRiesgo')}
            options={SI_NO}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Indique*"
            placeholder="Digite"
            {...register('tieneRelacionesComercialesDeRiesgoComentario', {
              required: parseInt(tieneRelacionesComercialesDeRiesgo) === 1 ? 'Requerido' : false,
            })}
            hasError={!!errors.tieneRelacionesComercialesDeRiesgoComentario}

            // hasError={!!errors.nombre}
          />
        </div>
      </div>
    </div>
  );
};

const ReferenciasForm = ({ register, errors }) => {
  return (
    <div>
      <div className="form-section__row">
        <div className="form-section__column">
          <input type="hidden" {...register('referenciaId1')} />
          <Input
            label="Nombre completo"
            placeholder="Digite"
            {...register('referenciaNombre1')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Input
            label="Relación"
            placeholder="Digite"
            {...register('referenciaRelacion1')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            maxLength={8}
            label="Teléfono"
            placeholder="Digite"
            {...register('referenciaTelefono1')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Correo"
            placeholder="Digite"
            {...register('correoRelacion1', {
              pattern: EMAIL_REGEX,
            })}
            hasError={!!errors.correoRelacion1}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <input type="hidden" {...register('referenciaId2')} />
          <Input
            label="Nombre completo"
            placeholder="Digite"
            {...register('referenciaNombre2')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Input
            label="Relación"
            placeholder="Digite"
            {...register('referenciaRelacion2')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            maxLength={8}
            label="Teléfono"
            placeholder="Digite"
            {...register('referenciaTelefono2')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Correo"
            placeholder="Digite"
            {...register('correoRelacion2', {
              pattern: EMAIL_REGEX,
            })}
            hasError={!!errors.correoRelacion2}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <input type="hidden" {...register('referenciaId3')} />
          <Input
            label="Nombre completo"
            placeholder="Digite"
            {...register('referenciaNombre3')}
            // hasError={!!errors.nombre}
          />
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <Input
            label="Relación"
            placeholder="Digite"
            {...register('referenciaRelacion3')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            maxLength={8}
            label="Teléfono"
            placeholder="Digite"
            {...register('referenciaTelefono3')}
            // hasError={!!errors.nombre}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Correo"
            placeholder="Digite"
            {...register('correoRelacion3', {
              pattern: EMAIL_REGEX,
            })}
            hasError={!!errors.correoRelacion3}
          />
        </div>
      </div>
    </div>
  );
};

const NotaLegalForm = ({ register, watch, errors, getValues }) => {
  return (
    <div>
      <div className="form-section__row">
        <div className="form-section__column">
          <ol className="form-section__disclaimer">
            <li>
              El cliente declara bajo juramento que la información consignada en
              este formulario es en todo verídica, exacta y suministrada con la
              intención de que cualquiera de las empresas del grupo empresarial
              AGE Holding evalúe la otorgación de un servicio y que no he hecho
              ninguna omisión que pudiera inducir al error a la empresa o a sus
              funcionarios, por lo tanto acepto que cualquier omisión o falsedad
              causaría la negación de la admisión y de los servicios
              solicitados.
            </li>
            <li>
              Declaro que los ingresos reportados no provienen de actividades
              ilícitas y que mis operaciones son propias de la actividad que
              detallo en este formulario.
            </li>
            <li>
              Así mismo autorizo en forma expresa a el grupo empresarial AGE
              Holding para que recopile, almacene y utilice mis datos personales
              suministrados por mi persona, para que sean usados en los análisis
              respectivos de las solicitudes y trámites que realice con
              cualquiera de las empresas del grupo empresarial AGE Holding, así
              mismo en campañas de mercadeo, estudios estadísticos y afines y
              cualquier otro fin lícito dentro de cualquiera de las
              actividades propias del grupo empresarial, sin usos externos de
              los mismos. Entendido de que si no otorgo mi consentimiento
              informado para estos fines no podré acceder a los servicios que
              presta cualquiera de las empresas de AGE Holding.
            </li>
            <li>
              Expresamente autoriza a las empresas del grupo empresarial AGE
              Holding y a sus funcionarios a obtener, verificar y utilizar la
              información general y de crédito sobre mi persona.
            </li>
            <li>
              En relación con la presente solicitud, para los efectos de lo
              dispuesto en los artículos 5 inciso 2 de la ley 8968 protección
              de la persona frente al tratamiento de sus datos personales y el
              artículo 196 del código penal (Ley 4573), expresamente autorizo a
              cualquiera de las empresas del grupo empresarial AGE Holding para
              que directa o indirectamente consulte bases de datos privadas y
              públicas y tenga acceso a mis datos personales de acceso
              irrestricto o de acceso restringido, con el propósito de verificar
              o confirmar la información brindada en esta oportunidad y en
              cualquier otra que se considere necesaria para la tramitación de
              un servicio. Esta autorización comprende también los estudios
              correspondientes de las sociedades donde yo figure como
              representante legal. La presente autorización se concede en el
              entendido de que toda la información recopilada será administrada
              por cualquiera de las empresas del grupo empresarial AGE Holding
              con el carácter confidencial que la constitución política y la ley
              le reconocen.
            </li>
          </ol>
        </div>
      </div>
      <div className="form-section__row">
        <div className="form-section__column">
          <DatePicker
            bigger={true}
            label="Fecha"
            {...register('fechaLegal', { required: false } )}
            // hasError={!!errors.nombre}
            value={getValues('fechaLegal')}
          />
        </div>
        <div className="form-section__column">
          <Input
            label="Nombre completo"
            placeholder="Digite"
            {...register('nombreLegal', { required: false } )}
            hasError={!!errors.nombre}
          />
        </div>
        {/* <div className="form-section__column">
          <Input
            label="Firma"
            // placeholder="Digite"
            {...register('firmaLegal')}
            // hasError={!!errors.nombre}
          />
        </div> */}
      </div>
    </div>
  );
};

const Forms = ({ register, watch, getValues, errors, quote, esJuridico }) => (
  <div className="forms">
    <div className="form__subtitle">
      <div className="form__title">
      Para tu facilidad te ofrecemos otras dos opciones en las que podés completar este formulario:
      </div>
      <div className="form__title">
      <span className="form__title-text">presentándote a una de nuestras sucursales&nbsp;</span>o bien por medio de nuestro canal de   
      <span className="form__title-text">&nbsp;servicio al cliente de&nbsp;
        <a
          style={{color: '#D6C18F', 'text-decoration': 'none'}}
          target="_blank"
          className="requirements__footer-link"
          href="https://wa.link/j7l5wu"
          rel="noreferrer"
        >
          Whatsapp
          <img
            src={Whatsapp}
            className="requirements__whatsapp"
            alt="Whatsapp"
          />
        </a></span>
      </div>
    </div>
    <div
      className={classNames('field', {
        'field--rejected': true,
      })}
    >
      <div className="field__status">
        <img src={ICON_BY_STATUS['APPROVED']} alt="Check" />
      </div>
      <div className="field__content">
        <div className="field__title">
          Formulario conozca a su cliente persona {esJuridico ? 'jurídica' : 'física'}
        </div>
        <div className="field__requirements">
          Debés completar el siguiente formulario en línea.
        </div>
      </div>
    </div>
    {!esJuridico && (
      <div className="form-container">
        <FormSection
          title="Información personal"
          content={
            <PersonalForm
              register={register}
              watch={watch}
              quote={quote}
              getValues={getValues}
              errors={errors}
            />
          }
        />
      </div>
    )}
    {esJuridico && (
      <div className="form-container">
        <FormSection
          title="Información personal"
          content={
            <PersonalJuridicoForm
              register={register}
              watch={watch}
              quote={quote}
              getValues={getValues}
              errors={errors}
            />
          }
        />
      </div>
    )}

    {esJuridico && (
      <div className="form-container">
        <FormSection
          title="Accionistas con más del 10% de Participación"
          content={
            <AccionistasForm
              register={register}
              watch={watch}
              quote={quote}
              getValues={getValues}
              errors={errors}
            />
          }
        />
      </div>
    )}

    {!esJuridico && (
      <div className="form-container">
        <FormSection
          title="Datos laborales"
          content={
            <LaboralForm
              register={register}
              watch={watch}
              quote={quote}
              getValues={getValues}
              errors={errors}
            />
          }
        />
      </div>
    )}

    {esJuridico && (
      <div className="form-container">
        <FormSection
          title="Información del Representante legal"
          content={
            <RepresentanteLegal
              register={register}
              watch={watch}
              quote={quote}
              getValues={getValues}
            />
          }
        />
      </div>
    )}

    <div className="form-container">
      <FormSection
        title="Personas expuestas políticamente"
        content={
          <ExpuestasForm
            register={register}
            watch={watch}
            quote={quote}
            getValues={getValues}
            errors={errors}
          />
        }
        disclaimer={
          <p className="requirements__forms--disclaimer">
            * Campos Obligatorios  
          </p>
        }
      />
    </div>
    <div className="form-container">
      <FormSection
        title="Información adicional"
        disclaimer={
          <p className="requirements__forms--disclaimer">
            * Campos Obligatorios  
          </p>
        }
        content={
          <AdicionalForm
            register={register}
            watch={watch}
            quote={quote}
            getValues={getValues}
            errors={errors}
          />
        }
      />
    </div>
    <div className="form-container">
      <FormSection
        title="Referencias"
        content={
          <ReferenciasForm
            register={register}
            watch={watch}
            quote={quote}
            getValues={getValues}
            errors={errors}
          />
        }
      />
    </div>
    <div className="form-container">
      <FormSection
        title="Nota legal"
        content={
          <NotaLegalForm
            register={register}
            watch={watch}
            errors={errors}
            quote={quote}
            getValues={getValues}
          />
        }
      />
    </div>
  </div>
);



const RequirementsInput = ({ value, disabled }) => (
  <input
    className={classNames('requirements-input', {
      'requirements-input--disabled': disabled,
    })}
    value={value}
    disabled
  />
);

const Field = ({ field, onFileChange, fileInfo, quote, onDelete, step, loadingDtc }) => {
  const status = getFieldStatus(field);
  const quoteStatus = getQuoteStatus(quote.status);
  const uploadRef = useRef(null);

  const dispatch = useDispatch();
  const deleteFile = () => {
    onDelete(field?.id);
    dispatch(deleteFileRequest(field?.id));
  };

  const downloadDtcRequest = id => dispatch(downloadDtc(id));

  const to = useMemo(() => {
    if (!field?.id) {
      return null;
    }
    const blob = fileInfo[field.id];
    if (blob) {
      return URL.createObjectURL(blob);
    }
    return field.url;
  }, [fileInfo, field]);

  const hasCamera = () => {
    return isMobile && ALLOW_CAMARA.reduce((acc, curr) => {
      if (acc) return acc;
      
      if (curr.indexOf(field.tipoDocumento) !== -1) {
        return true;
      }
      return acc;
    }, false);
  };

  return (
    <div
      className={classNames('field', {
        'field--approved': status === STATUS.APPROVED,
        'field--rejected': status === STATUS.REJECTED,
      })}
    >
      <div className="field__status">
        <img src={ICON_BY_STATUS[status]} alt="Check" />
      </div>
      <div className="field__content">
        <div className="field__title">
          {field.tipoDocumento}{' '}
          {!field.requerido && (
            <span className="field__optional">(Opcional)</span>
          )}
          {field.tooltip && (
            <div className="field__tooltip-icon">
              <img
                src={TooltipIcon}
                alt="Tooltip icon"
                className="field__tooltip-img"
              />
              <div className="field__tooltip-content">
                Información de la situación crediticia de los deudores
              </div>
            </div>
          )}
        </div>
        <div className="field__requirements">{field.requerimientos}</div>
        <div className={classNames('field__input-container', {
          'field__input-container--camera': hasCamera(),
        })}>
          <div className="field__input">
            <RequirementsInput
              value={field.archivo || fileInfo[field.id]?.name || ''}
              disabled={status === STATUS.APPROVED}
            />
            {status === STATUS.PENDING && hasCamera() && (
              <button
                onClick={() => uploadRef?.current?.click()}
                type="button"
                className="field__camera"
              >
                <CameraIcon />
              </button>
            )}
          </div>
          <div className="field__actions">
            <input
              ref={uploadRef}
              type="file"
              accept={`image/*;${isMobile ? 'capture=camera': ''}`}
              style={{ display: 'none' }}
              onChange={() => {
                const content = uploadRef?.current?.files[0];
                onFileChange({
                  [field.id]: content,
                });
                uploadRef.current.value = '';
              }}
            />
            {(quoteStatus === results.PENDING_DOCS || quoteStatus === results.PREAPPROVED) && status === STATUS.PENDING ? (
              <>
                <button
                  onClick={() => uploadRef?.current?.click()}
                  type="button"
                  className="field__upload"
                >
                  Subir archivo
                </button>
              </>
            ) : (
              <div className='field__actions-buttons'>
                {(field.url || fileInfo[field.id]) && (
                  <a href={to} target='_blank' rel='noreferrer'>
                    <ViewIcon />
                  </a>
                )}
                {canDelete(quoteStatus) || step > 3 ? (
                  <button
                    type="button"
                    onClick={deleteFile}
                    className="field__delete"
                  >
                    <DeleteIcon />
                  </button>
                ): null}
              </div>
            )}
          </div>
        </div>
        {field.tipoDocumento.indexOf('DTR') >= 0 && (
          <button
            type="button"
            onClick={() => {
              if (loadingDtc) return;
              downloadDtcRequest(quote.id);
            }}
            className="field__download-link"
          >
            <img
              src={Download}
              alt="Download"
              className="field__download-icon"
            />{' '}
            {loadingDtc ? 'Descargando...' : 'Descargar formulario'}
          </button>
        )}
        {field.motivoActualizacion && (
          <div className="field__result">
            {field.motivoActualizacion}{' '}
            {field.observacion && `- ${field.observacion}`}
          </div>
        )}
      </div>
    </div>
  );
};

const FillDocuments = ({
  loadingDtc,
  documents = [],
  onFileChange,
  fileInfo = [],
  quote,
  onDelete,
  status,
  step,
}) =>
  documents.map(field => (
    <div className="requirements__requirement" key={field.id}>
      <Field
        onDelete={onDelete}
        quote={quote}
        field={field}
        onFileChange={onFileChange}
        fileInfo={fileInfo?.find(file => Object.keys(file)[0] === field.id)}
        status={status}
        step={step}
        loadingDtc={loadingDtc}
      />
    </div>
  ));

const Requirements = ({ isProfile, documents = [], state = '', back, home }) => {
  const formatStatus = getQuoteStatus(state);
  const [sendingFromHome, setSendingFromHome] = useState(false);
  const [step, setStep] = useState(
    formatStatus === results.APPROVED || formatStatus === results.APPROVED_CONDITIONS ? 3 : 1 );
  const [showSavedDialog, setShowSavedDialog] = useState(false);
  const [showSentDialog, setShowSentDialog] = useState(false);
  const [sent, setSent] = useState(false);
  const [fileInfo, setFileInfo] = useState(
    documents.map(document => ({ [document.id]: null }))
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { quote, user, mainQuote, loadingDtc } = useSelector(state => ({
    quote: state.app.quote.data,
    mainQuote: state.app.quote,
    user: state.user.data,
    loadingDtc: state.app.dtc.isLoading,
  }));
  const cannotChangeInlined = cannotMakeChanges(formatStatus);

  const cannotChange = useMemo(() => {
    return cannotChangeInlined && step <= 3 && !home;
  }, [cannotChangeInlined, step, home]);

  const wasUploadingFiles = usePrevious(mainQuote.isUploadingFiles);
  const wasUploadingForm = usePrevious(mainQuote.isUploadingForm);
  
  useEffect(() => {
    if (home) scrollTo('.requirements__title');
  }, [home]);

  useEffect(() => {
    if (wasUploadingFiles || wasUploadingForm) {
      if (
        !mainQuote.isUploadingForm &&
        !mainQuote.isUploadingFiles &&
        step !== 3 && 
        step !== 5
      ) {
        setShowSavedDialog(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mainQuote.isUploadingFiles,
    mainQuote.isUploadingForm,
    wasUploadingFiles,
    wasUploadingForm,
  ]);

  const saveFiles = () => {
    console.log(fileInfo);
    dispatch(saveFilesRequest(fileInfo));
  };

  const step1Documents =
    documents?.filter(document =>
      PERSONAL_DOCUMENTS.includes(document.tipoDocumento)
    ) || [];
  const step2Documents = getRemainingDocs(documents);
  const step4Documents =
    documents?.filter(
      document => FORMALIZATION_DOCUMENTS.includes(document.tipoDocumento)
  ) || [];
  const step1CompletedDocuments = step1Documents?.filter(
    document => !!document.archivo
  );
  const step2CompletedDocuments = step2Documents?.filter(
    document => !!document.archivo
  );
  const step4CompletedDocuments = step4Documents?.filter(
    document => !!document.archivo
  );
  const step1CompletedDocumentsLength = step1CompletedDocuments?.length || 0;
  const step2CompletedDocumentsLength = step2CompletedDocuments?.length || 0;
  const step4CompletedDocumentsLength = step4CompletedDocuments?.length || 0;
  const step1RequiredDocumentsLength = step1Documents?.length || 0;
  const step2RequiredDocumentsLength = step2Documents?.length || 0;
  const step4RequiredDocumentsLength = step4Documents?.length || 0;
  const timelineData = {
    step1: {
      completedDocuments: step1CompletedDocumentsLength,
      requiredDocuments: step1RequiredDocumentsLength,
    },
    step2: {
      completedDocuments: step2CompletedDocumentsLength,
      requiredDocuments: step2RequiredDocumentsLength,
    },
    step4: {
      completedDocuments: step4CompletedDocumentsLength,
      requiredDocuments: step4RequiredDocumentsLength,
    }
  };
  const totalCompleted =
    step1CompletedDocumentsLength + step2CompletedDocumentsLength;
  const totalRequired =
    step1RequiredDocumentsLength + step2RequiredDocumentsLength;

    

  const saveForm = values => dispatch(saveCustomerFormRequest(values));

  const {
    register: registerOriginal,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'all',
    defaultValues: {
      primerApellido: quote?.cliente?.primerApellido,
      segundoApellido: quote?.cliente?.segundoApellido,
      nombre: quote?.cliente?.nombre,
      nacionalidad: quote?.cliente?.nacionalidad,
      paisNacimiento: quote?.cliente?.paisNacimiento,
      actividadEmpresa: quote?.cliente?.actividadEmpresa,
      fechaNacimiento: toUTCTime(quote?.cliente?.fechaNacimiento),
      fechaVencimiento: toUTCTime(quote?.cliente?.fechaVencimiento),
      fechaIngreso: toUTCTime(quote?.cliente?.fechaIngreso),
      fechaConstitucion: toUTCTime(quote?.cliente?.fechaConstitucion),
      personaExpuestaFechaVencimiento: toUTCTime(
        quote?.cliente?.personaExpuestaFechaVencimiento
      ),
      correoTrabajo: quote?.cliente?.correoTrabajo,
      extensionTrabajo: quote?.cliente?.extensionTrabajo,
      telefonoTrabajo: quote?.cliente?.telefonoTrabajo,
      direccionTrabajo: quote?.cliente?.direccionTrabajo,
      distritoTrabajo: quote?.cliente?.distritoTrabajo,
      cantonTrabajo: quote?.cliente?.cantonTrabajo,
      provinciaTrabajo: quote?.cliente?.provinciaTrabajo,
      paisTrabajo: quote?.cliente?.paisTrabajo,
      otrosIngresos: quote?.cliente?.otrosIngresos,
      otraMoneda: quote?.cliente?.otraMoneda,
      ingresosMensualesMoneda: quote?.cliente?.ingresosMensualesMoneda,
      ingresosMensuales: quote?.cliente?.ingresosMensuales,
      puesto: quote?.cliente?.puesto,
      empresaPropia: quote?.cliente?.empresaPropia,
      nombreEmpresa: quote?.cliente?.nombreEmpresa,
      email: quote?.cliente?.email,
      celular: quote?.cliente?.celular,
      telefono: quote?.cliente?.telefono,
      distrito: quote?.cliente?.distrito || '1',
      canton: quote?.cliente?.canton,
      provincia: quote?.cliente?.provincia,
      pais: quote?.cliente?.pais,
      direccion: quote?.cliente?.direccion,
      origenFondos: quote?.cliente?.origenFondos,
      actividadEconomica: quote?.cliente?.actividadEconomica,
      profesion: quote?.cliente?.profesion,
      gradoAcademico: quote?.cliente?.gradoAcademico,
      estadoCivil: quote?.cliente?.estadoCivil,
      numeroIdentificacion: quote?.cliente?.numeroIdentificacion,
      tipoIdentificacion: quote?.cliente?.tipoIdentificacion,
      sexo: quote?.cliente?.sexo,
      personaExpuesta: quote?.cliente?.personaExpuesta,
      relactionPersonaExpuesta: quote?.cliente?.relactionPersonaExpuesta,
      personaExpuestaNombre: quote?.cliente?.personaExpuestaNombre,
      personaExpuestaPuesto: quote?.cliente?.personaExpuestaPuesto,
      personaExpuestaEntidad: quote?.cliente?.personaExpuestaEntidad,
      administraFondosTerceros: quote?.cliente?.administraFondosTerceros,
      fondosTercerosComentario: quote?.cliente?.fondosTercerosComentario,
      actividadesFinancierasNoFinancieras:
        quote?.cliente?.actividadesFinancierasNoFinancieras,
      actividadesFinancierasNoFinancierasComentario:
        quote?.cliente?.actividadesFinancierasNoFinancierasComentario,
      tieneRelacionesComercialesDeRiesgo:
        quote?.cliente?.tieneRelacionesComercialesDeRiesgo,
      tieneRelacionesComercialesDeRiesgoComentario:
        quote?.cliente?.tieneRelacionesComercialesDeRiesgoComentario,
      referenciaNombre1: quote?.cliente?.referenciaNombre1,
      referenciaNombre2: quote?.cliente?.referenciaNombre2,
      referenciaNombre3: quote?.cliente?.referenciaNombre3,
      referenciaRelacion1: quote?.cliente?.referenciaRelacion1,
      referenciaRelacion2: quote?.cliente?.referenciaRelacion2,
      referenciaRelacion3: quote?.cliente?.referenciaRelacion3,
      referenciaTelefono1: quote?.cliente?.referenciaTelefono1,
      referenciaTelefono2: quote?.cliente?.referenciaTelefono2,
      referenciaTelefono3: quote?.cliente?.referenciaTelefono3,
      correoRelacion1: quote?.cliente?.correoRelacion1,
      correoRelacion2: quote?.cliente?.correoRelacion2,
      correoRelacion3: quote?.cliente?.correoRelacion3,
      referenciaId1: quote?.cliente?.referenciaId1,
      referenciaId2: quote?.cliente?.referenciaId2,
      referenciaId3: quote?.cliente?.referenciaId3,
      accionistas: quote?.cliente?.accionistas || [],

      razonSocial: quote?.cliente?.razonSocial,
      nombreComercial: quote?.cliente?.nombreComercial,
      estructuraPropiedad: quote?.cliente?.estructuraPropiedad,
      paisConstitucion: quote?.cliente?.paisConstitucion,
      tipoPersona: quote?.cliente?.tipoPersona,
      representanteNombre: quote?.cliente?.representanteNombre,
      representanteApellido: quote?.cliente?.representanteApellido,
      representanteSegApellido: quote?.cliente?.representanteSegApellido,
      representanteTipoId: quote?.cliente?.representanteTipoId,
      representanteId: quote?.cliente?.representanteId,
      representanteRId: quote?.cliente?.representanteRId,
      representanteNacionalidad: quote?.cliente?.representanteNacionalidad,
      representanteProfesion: quote?.cliente?.representanteProfesion,
      representanteEmail: quote?.cliente?.representanteEmail,
      representanteTelResidencia: quote?.cliente?.representanteTelResidencia,
      representanteTelOficina: quote?.cliente?.representanteTelOficina,
      representanteTelCel: quote?.cliente?.representanteTelCel,
      representanteTipoFirma: quote?.cliente?.representanteTipoFirma,
      representantePais: quote?.cliente?.representantePais,
      representanteProvincia: quote?.cliente?.representanteProvincia,
      representanteCanton: quote?.cliente?.representanteCanton,
      representanteDistrito: quote?.cliente?.representanteDistrito,
      representanteDireccion: quote?.cliente?.representanteDireccion,
      subActividadEconomica: quote?.cliente?.subActividadEconomica,
    },
  });

  const register = (id, options = null) => {
    if (!options) {
      options = {
        required: true,
      };
    }
    return {
      ...registerOriginal(id, options),
      hasError: !!errors[id]
    };
  }

  const onSubmit = values => {
    if (step === 4) {
      saveForm(values);
    }
    saveFiles();
  };

  const saveDraftForm = values => {
    saveForm(values);
    saveFiles();
  }

  const enviar = () => {
    if (sendingFromHome) return;
    
    if (((step + 1) === 3) && 
      (formatStatus === results.PENDING_DOCS || formatStatus === results.PREAPPROVED)) {
      dispatch(changeQuoteStatus('3-Documentacion en Revision'));
    }

    setStep(step + 1);
    setTimeout(() => {
      if (step === 2) {
        saveFiles();
        if (home) {
          setSendingFromHome(true);
          history.push('/perfil/mi-solicitud/');
          return;
        }
        setSent(true);
        setShowSentDialog(true);
      }
      if(step === 4){
        saveForm(getValues());
        saveFiles();
        if (home) {
          setSendingFromHome(true);
          history.push('/perfil/mi-solicitud/');
          return;
        }
        setSent(true);
        setShowSentDialog(true);
      }

      scrollTo('.requirements__title');
    }, 10);
  }

  const disableContinue =
    mainQuote.isUploadingFiles || mainQuote.isUploadingForm;

  return (
    <div
      className={classNames('requirements', {
        'requirements--profile': isProfile,
      })}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {back && <div className="requirements__back">{back}</div>}
        <div className="requirements__title">
          <div className="requirements__title-text">
            Requisitos de aprobación{' '}
            {isProfile && (
              <span className="requirements__title-sub">
                (Completá los datos pendientes)
              </span>
            )}
          </div>
          {isProfile && (
            <div className='requirements__solicitar-content'>
              <Button
                onClick={() => history.push('/')}
                value="Solicitá nuevo crédito"
                sm
              />
            </div>
          )}
        </div>
        <div className="requirements__content">
          <RequirementsTimeline
            currentStep={step}
            sent={sent}
            data={timelineData}
          />
          {step === 4 && (
            <div className="requirements__forms">
              <Forms
                register={register}
                watch={watch}
                errors={errors}
                quote={quote}
                esJuridico={user?.dni_type === 'JURIDICO'}
                getValues={getValues}
              />
            </div>
          )}
          <div className="requirements__list">
            {(step === 1 || step === 2 || step === 4) && (
              <FillDocuments
                loadingDtc={loadingDtc}
                step={step}
                quote={quote}
                documents={(step === 1 && step1Documents) || (step === 2 && step2Documents) || step4Documents}
                onDelete={id => {
                  setFileInfo(
                    fileInfo.map(file => {
                      if (Object.keys(file)[0] === id) {
                        return { [id]: null };
                      }
                      return file;
                    })
                  );
                }}
                onFileChange={updatedFile => {
                  const key = Object.keys(updatedFile)[0];
                  dispatch(changeFile(updatedFile[key]?.name, key));
                  setFileInfo(
                    fileInfo.map(file => {
                      if (
                        Object.keys(file)[0] === Object.keys(updatedFile)[0]
                      ) {
                        return updatedFile;
                      }
                      return file;
                    })
                  );
                }}
                fileInfo={fileInfo}
                status={formatStatus}
              />
            )}
            {step === 3 && (
              <Result quote={quote} oneStepFoward={setStep} step={step}/>
            )}
            {step === 5 &&  (
               <Result quote={quote} oneStepFoward={setStep} step={step}/>
               )}
          </div>
          <div className="requirements__footer">
            {step <= 3 && (
              <>
                <div className="requirements__legend">
                  También podés enviar tus datos a nuestro WhatsApp de servicio al
                  cliente de AGE Capital.{' '}
                  <a
                    target="_blank"
                    className="requirements__footer-link"
                    href={WHATSAPP_LINK}
                    rel="noreferrer"
                  >
                    Hacé click aquí.
                    <img
                      src={Whatsapp}
                      className="requirements__whatsapp"
                      alt="Whatsapp"
                    />
                  </a>
                </div>
                <div className="requirements__footer-text">
                  Recordá que podés guardar tu formulario y continuar el proceso
                  después. Tu información se mantendrá vigente por 3 meses. Además,
                  podés completar este formulario en el orden que más te convenga.
                </div>
              </>
            )}
            {step !== 5  && (
              <div className="requirements__buttons">
                  <div className="requirements__buttons-button">
                    {step !== 3 && step !== 4 && !cannotChange && (
                      <Button
                        disabled={disableContinue}
                        prefix={<img src={Save} alt="Save" />}
                        value={disableContinue ? 'Guardando' : 'Guardar formulario'}
                        type="submit"
                        fullwidth
                      />
                    )}
                    {step === 4 && !cannotChange && (
                      <Button
                        disabled={disableContinue}
                        prefix={<img src={Save} alt="Save" />}
                        value={disableContinue ? 'Guardando' : 'Guardar formulario'}
                        type="button"
                        fullwidth
                        onClick={(ev) => {
                          ev.preventDefault()
                          ev.stopPropagation();
                          ev.nativeEvent.stopImmediatePropagation();
                          saveDraftForm(getValues());
                        }}
                      />
                    )}
                  </div>
                <div className="requirements__buttons-right">
                  {step > 1 && (
                    <div className="requirements__buttons-button">
                      <Button
                        gray
                        value="Paso anterior"
                        type="button"
                        fullwidth
                        prefix={
                          <img
                            src={Arrow}
                            alt="Arrow"
                            className="requirements__rotate"
                          />
                        }
                        onClick={() => setStep(step - 1)}
                      />
                    </div>
                  )}
                  {(step === 2 || step === 1) && cannotChange && (
                    <div className="requirements__buttons-button 1">
                      <Button
                        black
                        type="button"
                        fullwidth
                        value={'Siguiente paso'}
                        suffix={<img src={Arrow} alt="Arrow" />}
                        onClick={() => {
                          setStep(step + 1);
                        }}
                      />
                    </div>
                  )}
                  {step !== 3 && !cannotChange && (
                    <div className="requirements__buttons-button 2">
                      <Button
                        black
                        type="button"
                        fullwidth
                        disabled={
                          disableContinue ||
                          (step === 2 && totalCompleted !== totalRequired)
                        }
                        value={step === 2 || step === 4 ? `${sendingFromHome ? 'Enviando...': 'Enviar'}` : 'Siguiente paso'}
                        suffix={<img src={Arrow} alt="Arrow" />}
                        onClick={(ev) => {
                          ev.preventDefault()
                          ev.stopPropagation();
                          ev.nativeEvent.stopImmediatePropagation();
                          // console.log(errors);
                          // return;
                          handleSubmit(enviar)();
                        }}
                      />
                    </div>
                    )}
                  
                </div>
              </div>
            )}
          </div>
        </div>
        <CommonDialog
          open={showSavedDialog}
          onClose={() => setShowSavedDialog(false)}
          title="¡Formulario guardado!"
          body="Los documentos enviados serán revisados por un ejecutivo para asegurar que cumplen con todos los requisitos. Recordá que podés cargar tu información pendiente aquí o en el Veinsa Hub para terminar con el proceso."
          buttons={
            <>
              <Button
                black
                onClick={() => setShowSavedDialog(false)}
                value="Cerrar"
              />
              {/* <Button onClick={() => {}} value="Continuar en Age Hub" /> */}
            </>
          }
        />
        <CommonDialog
          open={showSentDialog}
          onClose={() => setShowSentDialog(false)}
          title="¡Formulario enviado!"
          body="Tu información ha sido enviada con éxito. Podés cerrar esta ventana para ver el estado de tu solicitud."
          buttons={
            <>
              <Button
                black
                onClick={() => setShowSentDialog(false)}
                value="Cerrar"
              />
            </>
          }
        />
      </form>
    </div>
  );
};

export default Requirements;
