import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useCurrency = () => {
  const [currency, setCurrency] = useState();
  const { currencyInState } = useSelector(state => ({
    currencyInState: state.app.data.currency,
  }));

  useEffect(() => {
    setCurrency(currencyInState);
  }, [currencyInState]);

  return currency;
};

export default useCurrency;
