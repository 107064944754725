import React from 'react';
import Dialog from '../Dialog';
import './CommonDialog.scss';
import Sent from '../../assets/images/sent_icon.svg';
import Close from '../../assets/images/close.png';

const CommonDialog = ({
  open,
  onClose,
  body,
  title,
  buttons,
  icon,
  iconMargin,
  className = '',
}) => (
  <Dialog open={open}>
    <div className={`response-sent ${className}`}>
      <div
        className="response-sent__image"
        // style={{ marginTop: iconMargin ? -iconMargin : '-15%' }}
      >
        <img
          className="response-sent__image"
          src={icon || Sent}
          alt="Loading"
        />
      </div>
      <div className="response-sent__close-container">
        <button onClick={onClose} type="button">
          <img src={Close} alt="Close" />
        </button>
      </div>
      <h1 className="response-sent__title">{title}</h1>
      <div className="response-sent__content">
        <p className="response-sent__text">{body}</p>
        <div className="response-sent__buttons">{buttons}</div>
      </div>
    </div>
  </Dialog>
);

export default CommonDialog;
