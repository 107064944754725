import React from 'react';
import Slider from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import TopBar from '../TopBar';
import './Header.scss';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
    partialVisibilityGutter: 329,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Header = () => {
  return (
    <div className="header">
      <TopBar />
      <div className="header__content">
        <Slider
          responsive={responsive}
          center
          arrows={false}
          dotListClass="header__dots"
          showDots={false}
        >
          <div className="header__slide header__slide--1" />
          {/* {fixRaiamsCostume.map(banner => (
            <div
              key={banner.id}
              className="header__slide"
              style={{
                backgroundImage: `url(${banner.imagen?.url})`,
              }}
            >
              <h1 className="header__title">{banner.titulo}</h1>
              <p className="header__subheader">{banner.contenido}</p>
            </div>
          ))} */}
        </Slider>
      </div>
    </div>
  );
};

export default Header;
