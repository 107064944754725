import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../Button';
import './SignInForm.scss';
import Arrow from '../../assets/images/arrow-left.png';
import Dialog from '../Dialog';
import Close from '../../assets/images/close.png';
import { useDispatch } from 'react-redux';
import { signInRequest } from '../../ducks/user/actions';
import { AGE_HUB } from '../../utils/constants';
import { useSelector } from 'react-redux';
import AppContext from '../Context/AppContext';
import Input from '../Input';

const SignInForm = ({ open, onClose, onSignIn, openRegister, canSkip }) => {
  const {
    register,
    handleSubmit,
    // watch,
    // control,
    // formState: { errors },
    // setValue,
  } = useForm();
  const appContext = useContext(AppContext);
  const { login2 } = appContext;
  const dispatch = useDispatch();
  const signIn = (email, password) => dispatch(signInRequest(email, password, null, login2));
  const onSubmit = data => {
    signIn(data.email, data.password);
    onSignIn();
  };
  const { error } = useSelector(state => ({ error: state.user.error }));
  return (
    <Dialog open={open} medium scroll>
      <div className="sign-in-form">
        <div className="sign-in-form__close-container">
          <button onClick={onClose} type="button">
            <img src={Close} alt="Close" />
          </button>
        </div>
        <h1 className="sign-in-form__title">¡Iniciá sesión!</h1>
        <p className="sign-in-form__text">
          Si ya sos parte de AGE Capital, iniciá sesión para que tu <br />
          experiencia sea más eficiente. <br />
          Si aún no lo sos, ¡sólo te tomará unos minutos!
        </p>
        <form className="sign-in-form__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="sign-in-form__input-container">
            <Input 
              className="sign-in-form__input"
              placeholder="Correo electrónico"
              circular
              {...register('email')}
            />
          </div>
          <div className="sign-in-form__input-container">
            <Input 
              type="password"
              className="sign-in-form__input"
              placeholder="Contraseña"
              circular
              {...register('password')}
            />
          </div>
          {error && (
            <div className="sign-in-form__error">
              Usuario o contraseña incorrectos
            </div>
          )}
          <Button
            className="sign-in-form__submit"
            value="Ingresar"
            suffix={<img src={Arrow} alt="Arrow" />}
            type="submit"
          />
          <div className="sign-in-form__forgot-container">
            <a href={`${AGE_HUB}/login`} className="sign-in-form__forgot">
              ¿Olvidaste tu contraseña?
            </a>
          </div>
          {canSkip && (
            <div className="sign-in-form__register-container">
              <Button
                className="sign-in-form__submit"
                value="Continuar sin registrarse"
                suffix={<img src={Arrow} alt="Arrow" />}
                type="button"
                onClick={onClose}
              />
            </div>
          )}
          <div className="sign-in-form__register-container">
            <button
              onClick={openRegister}
              type="button"
              className="sign-in-form__forgot"
            >
              Registrá tu usuario
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default SignInForm;
