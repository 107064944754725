import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BlogAll from '../BlogAll';
import BlogCapsula from '../BlogCapsula';
import BlogCategory from '../BlogCategory';
import BlogSearch from '../BlogSearch';
import Post from '../Post';
import TopBar from '../TopBar';
import './Blog.scss';

const Blog = () => {
  return (
    <div className="blog">
      <div className='header'>
        <TopBar />
      </div>
      <div className='blog-content'>
        <Switch>
          <Route path="/blog/post/:postId">
            <Post />
          </Route>
          <Route path="/blog/categoria/capsulas-financieras/:postId" exact={true}>
            <BlogCapsula />
          </Route>
          <Route path="/blog/categoria/capsulas-financieras/">
            <BlogCapsula />
          </Route>
          <Route path="/blog/categoria/:category">
            <BlogCategory />
          </Route>
          <Route path="/blog/buscar/:search">
            <BlogSearch />
          </Route>
          <Route path="/blog">
            <BlogAll />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Blog;