import classNames from 'classnames';
import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsiveCarousel } from '../../utils/constants';
import './Testimonial.scss';

export const CustomDot = ({
  index,
  onClick,
  active
}) => {
  return (
    <button
      onClick={e => {
        onClick();
        e.preventDefault();
      }}
      className={classNames("testimonial-dot mobile", {
        "testimonial-dot--active": active
      })} />
  );
};

const Testimonial = ({ testimonios }) => {
  if (!testimonios?.length) {
    return null;
  }
  return (
    <div className='testimonial'>
      <h3>Lo que dicen <span className='gold'>nuestros clientes</span></h3>
      <Carousel className='testimonial-carousel' responsive={responsiveCarousel} showDots={true} customDot={<CustomDot />} arrows={false}>
        {testimonios.map((testimonio, i) => (
          <div key={i} className="testimonial-item">
            <span className='testimonial-item__content'>
              {testimonio.testimonio}
            </span>
            <span className='testimonial-item__author'>
              {testimonio.nombre}
            </span>
          </div>
        ))}
      </Carousel>
    </div>
  )
};

export default Testimonial;