import React from 'react';
import AlreadySignedIn from '../assets/images/icon-already-registered.svg';
import RegisterIcon from '../assets/images/icon-register.svg';
import PendingIcon from '../assets/images/process_icon.svg';
import RejectedIcon from '../assets/images/rejected_circle.svg';
import ApprovedIcon from '../assets/images/success@2.svg';
import { results, STATUS } from './constants';

/**
 * Generate URL Query params
 * @param {Object} params
 * @returns {string}
 */
export function buildUrlQueryParams(params: any) {
  return Object.getOwnPropertyNames(params)
    .map((key, i) => `${i === 0 ? '?' : ''}${key}=${params[key]}`)
    .join('&');
}

/**
 * Generate reducer.
 *
 * @param {Object} initialState
 * @param {Object} handlers
 * @returns {function}
 */
export function createReducer(initialState: Object, handlers: Object) {
  return function reducer(state: Object = initialState, action: Object) {
    if ({}.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}

/**
 * Helper to display formik state
 * @param {Object} props
 * @returns {Object}
 */
export const DisplayFormikState = props => (
  <div style={{ margin: '1rem 0' }}>
    <pre
      style={{
        background: '#f6f8fa',
        fontSize: '.65rem',
        padding: '.5rem',
      }}
    >
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);

/**
 * String replace all
 * @param {String} text
 * @param {String} phraseToReplace
 * @param {String} newPhrase
 */
export const replaceAll = (text, phraseToReplace, newPhrase) => {
  const re = new RegExp(phraseToReplace, 'g');
  return text.replace(re, newPhrase);
};

/**
 * Build value from label
 * @param {String} label
 * @returns {String}
 */
export const buildValueFromLabel = label => {
  if (!label) {
    return '';
  }
  return label.toLowerCase().replace(/ /g, '-');
};

/**
 * Convert base64 to blob
 * @param {String} b64Data
 * @param {String} contentType
 * @param {number} sliceSize
 */
export const createObjectURL = (b64Data, contentType = '', sliceSize = 512) => {
  try {
    if (!b64Data) {
      return '';
    }
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    const fileUrl = URL.createObjectURL(blob);
    return fileUrl;
  } catch (error) {
    return '';
  }
};

/**
 * Convert to integer
 * @param {string} dirtyNumber
 * @param {number} hours
 * @returns {number}
 */
export const toInteger = dirtyNumber => {
  if (dirtyNumber === null || dirtyNumber === true || dirtyNumber === false) {
    return NaN;
  }

  var number = Number(dirtyNumber);

  if (isNaN(number)) {
    return number;
  }

  return number < 0 ? Math.ceil(number) : Math.floor(number);
};

/**
 * Convert to date
 * @param {date} dirtyDate
 * @param {number} hours
 */
export function toDate(argument) {
  if (arguments.length < 1) {
    throw new TypeError(
      '1 argument required, but only ' + arguments.length + ' present'
    );
  }

  var argStr = Object.prototype.toString.call(argument); // Clone the date

  if (
    argument instanceof Date ||
    (typeof argument === 'object' && argStr === '[object Date]')
  ) {
    // Prevent the date to lose the milliseconds when passed to new Date() in IE10
    return new Date(argument.getTime());
  } else if (typeof argument === 'number' || argStr === '[object Number]') {
    return new Date(argument);
  } else {
    if (
      (typeof argument === 'string' || argStr === '[object String]') &&
      typeof console !== 'undefined'
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        "Starting with v2.0.0-beta.1 date-fns doesn't accept strings as arguments. Please use `parseISO` to parse strings. See: https://git.io/fjule"
      ); // eslint-disable-next-line no-console

      console.warn(new Error().stack);
    }

    return new Date(NaN);
  }
}

/**
 * Gets days between today and the paydate
 * @param {date} nextPay
 * @param {number} Difference
 */
export function geDaysBetweenDates(nextPay) {
  const current = new Date();
  const payDay = new Date(nextPay * 1000);

  // this is to put it in todays date
  payDay.setHours(25, 1);
  payDay.setHours(23, 59);

  const Difference_In_Time = payDay.getTime() - current.getTime();

  // To calculate the no. of days between two dates
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return parseFloat(Difference_In_Days.toFixed());
}

export function toUTCTime(date) {
  try {
    var dateObj = toDate(parseFloat(date));
    dateObj.setHours(dateObj.getHours() + 6);
    // dateObj.setHours(23, 59);
    if (dateObj instanceof Date && isFinite(dateObj)) {
      return dateObj;
    }
    return undefined;
  } catch {
    return undefined;
  }
}

/**
 * Gets days between today and the paydate
 * @param {date} nextPay
 * @param {number} Difference
 */
export function ticoDate(d, extraDays = 0) {
  const dateObj = new Date(d * 1000);

  // this is to put it in todays date
  dateObj.setHours(25, 1);
  dateObj.setHours(23, 59);

  if (extraDays) {
    dateObj.setDate(dateObj.getDate() + extraDays);
  }

  const monthNames = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'Mayo',
    'Jun',
    'Jul',
    'Agos',
    'Set',
    'Oct',
    'Nov',
    'Dic',
  ];
  const month = monthNames[dateObj.getMonth()];
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  return `${day}/${month}/${year}`;
}
/**
 * Set hours of date
 * @param {date} dirtyDate
 * @param {number} dirtyHours
 * @returns {date}
 */
export function setHours(dirtyDate, dirtyHours) {
  if (arguments.length < 2) {
    throw new TypeError(
      '2 arguments required, but only ' + arguments.length + ' present'
    );
  }

  var date = toDate(dirtyDate);
  var hours = toInteger(dirtyHours);
  date.setHours(hours);
  return date;
}

/**
 * Set minutes of date
 * @param {date} dirtyDate
 * @param {number} dirtyMinutes
 * @returns {date}
 */
export function setMinutes(dirtyDate, dirtyMinutes) {
  if (arguments.length < 2) {
    throw new TypeError(
      '2 arguments required, but only ' + arguments.length + ' present'
    );
  }

  var date = toDate(dirtyDate);
  var minutes = toInteger(dirtyMinutes);
  date.setMinutes(minutes);
  return date;
}


/**
 * Verify if token is already expired
 * @returns {Boolean}
 */
export const isTokenExpired = () => {
  const expiration = localStorage.getItem('expiration');
  const now = new Date();
  const expirationDate = new Date(0);
  expirationDate.setUTCSeconds(expiration);
  if (expiration && now > expirationDate) {
    // if (true) {
    return true;
  }
  return false;
};

/**
 * Get param from query string
 * @param {String} paramName
 * @param {String} queryString
 */
export const getQueryParam = (params, url) => {
  //this expression is to get the query strings
  const reg = new RegExp('[?&]' + params + '=([^&#]*)', 'i');
  const queryString = reg.exec(url);
  return queryString ? queryString[1] : null;
};

/**
 * Capitalize
 * @param {String} text
 *
 * @returns {String}
 */
export const capitalize = (text = '') => {
  return text.replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
};

/**
 * Store user in local storage
 * @param {Object} user
 */
export const storeUserInLocalStorage = user => {
  localStorage.setItem('user', JSON.stringify(user));
};

/**
 * Store user in local storage
 */
export const deleteUserInLocalStorage = () => {
  localStorage.removeItem('user');
};

/**
 * Get user in local storage
 * @returns {Object}
 */
export const getUserInLocalStorage = () =>
  JSON.parse(localStorage.getItem('user'));

/**
 * Get Url parameter
 * @returns {Object}
 */
export function getUrlParameter(name) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  let results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

/**
 * Build CI payload
 * @returns {Object}
 */
export const buildCIPayload = ci => {
  switch (ci?.TipoId) {
    case 1:
      return {
        Titulo: ci.Titulo,
        LanguageId: ci.LanguageId,
        Active: ci.Active,
        TipoId: ci.TipoId,
      };
    case 2:
      return {
        Titulo: ci.Titulo,
        Contenido: ci.Contenido,
        LanguageId: ci.LanguageId,
        Active: ci.Active,
        TipoId: ci.TipoId,
        MediaUrl: ci.MediaUrl,
      };
    case 3: {
      // const formData = new FormData();

      // formData.append('TipoId', ci.TipoId);
      // formData.append('LanguageId', ci.LanguageId);
      // formData.append('Titulo', ci.Titulo);
      // formData.append('Contenido', ci.Contenido);
      // formData.append('Active', ci.Active);
      // formData.append('File', ci.File);

      // return formData;
      return {
        Titulo: ci.Titulo,
        Contenido: ci.Contenido,
        LanguageId: ci.LanguageId,
        Active: ci.Active,
        TipoId: ci.TipoId,
        MediaUrl: ci.MediaUrl,
      };
    }
    default:
      return {
        Titulo: ci.Titulo,
        Contenido: ci.Contenido,
        LanguageId: ci.LanguageId,
        Active: ci.Active,
        TipoId: ci.TipoId,
        MediaUrl: ci.MediaUrl,
      };
  }
};

/**
 * Remove underscores from string
 * @param {String} text
 * @returns {String}
 */
export const removeUnderscores = text => {
  const result = (text || '').replace(/_/g, ' ');
  return result;
};

/**
 * Calculates monthly quote
 * @param {Number} calcInteres
 * @param {Number} calcPlazo
 * @param {Number} financingAmount
 * @returns {Number}
 */
export const obtenerCuotaMensual = (
  calcInteres,
  calcPlazo,
  financingAmount
) => {
  const factorInteresNeto = calcInteres / 100;

  /* Use the PMT Excel function - This is the equivalent

  P = (Pv*R) / [1 - (1 + R)^(-n)]

  P = Monthly Payment
  Pv = Present Value (starting value of the loan)
  APR = Annual Percentage Rate
  R = Periodic Interest Rate = APR/number of interest periods per year
  n = Total number of interest periods (interest periods per year * number of years)

  */
  const cuotaMensual =
    (financingAmount * (factorInteresNeto / 12)) /
    (1 - Math.pow(1 + factorInteresNeto / 12, -1 * calcPlazo));

  return cuotaMensual;
};

/**
 * Build acronym
 * @param {String} text
 * @returns {String}
 */
export const buildAcronym = (text = '') => {
  const matches = text.match(/\b(\w)/g) || [];
  return matches.join('');
};

/**
 * Get email from a string
 * @param {String} text
 */
export const getEmailFromString = (text) => {
  //this expression is to get the email in the string
  const reg = new RegExp(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
  const email = reg.exec(text);
  return email ? email[1] : null;
};

/**
 * Return a register message according to the data option type
 * @returns {Object}
 */
export function getRegisterMessage(data) {
  switch (data?.flag) {
    case 'check-email':
      return {
        image: RegisterIcon,
        Titulo: 'Solicitud de registro enviada.',
        Contenido: 'Se envió la información a tu correo electrónico, revisalo con el fin de activar tu cuenta.',
      };
    case 'check-email-existing':
      let email = data?.email || {};
      return {
        image: AlreadySignedIn,
        Titulo: 'Solicitud de registro denegada.',
        Contenido: `La identificación ingresada ya se encuentra en nuestra base de datos bajo el correo electrónico ${email[0]}.\n
        Te hemos enviado un correo con el fin de que podás activar la cuenta.`,
        Footer: 'Si no podés accesar a este correo por favor <br> contáctanos al <a href="tel:4000-8000">4000-8000</a>'
      };
    case 'redirect-login':
      return {
        image: AlreadySignedIn,
        Titulo: 'Solicitud de registro denegada.',
        Contenido: `La identificación ingresada ya se encuentra en nuestra base de datos bajo el correo electrónico ${data?.email}.\n
          Ingresá los datos correctos para iniciar sesión.`,
        Footer: 'Si no podés accesar a este correo por favor <br> contáctanos al <a href="tel:4000-8000">4000-8000</a>'
      };
    case 'fill-data':
      return {
        Titulo: 'Ingresá todos tus datos',
        Contenido: 'Completá los siguientes datos para crear tu cuenta',
      };
    default:
      return null;
  }
};

/**
 * Return a register message according to the data option type
 * @returns {Object}
 */
export function getRecoverMessage(data) {
  switch (data?.flag) {
    case 'no-user':
      return {
        image: AlreadySignedIn,
        Titulo: 'El usuario no existe',
        Contenido: 'Esta cédula no se encuentra registrada en la base de datos',
      };
    case 'recover':
      let email = data?.email || {};
      return {
        image: RegisterIcon,
        Titulo: 'Revisá la bandeja de tu correo electrónico',
        Contenido: `Hemos enviado un mensaje con tus datos al correo electrónico ${email}.\n`,
        Footer: 'Si no podés accesar a este usuario por favor <br> contáctanos al <a href="tel:4000-8000">4000-8000</a>',
        Email: `${email}`
      };
    default:
      return null;
  }
};

export const isDev = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return true;
  }
  return false;
}

export const toArray = (amount) => {
  let arr = [];
  for (let x = 0; x < amount; x++) {
    arr.push(x);
  }
  return arr;
};

export const scrollTo = (element, plus = 0) => {
  const domElement = document.querySelector(element);

  if (!domElement) return;

  const top = domElement.offsetTop;
  window.scroll({
    top: top + plus,
    behavior: 'smooth'  // 
  });
};

export const getTyCText = (name, lastname, dni) => {
  return `${name} ${lastname}, cédula de identidad ${dni} aceptó términos y condiciones de la página www.agecapital.cr, el día ${new Date().getDate()} de ${MONTHS[new Date().getMonth()]} a las ${new Date().getHours()}:${(new Date().getMinutes() < 10 ? '0' : '') + '' + (new Date().getMinutes())
    } del año ${new Date().getFullYear()}`;
}

export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Setiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
];


export const DownloadFile = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const getResultData = result => {
  if (result === results.APPROVED) {
    return {
      text: 'Aprobado',
      icon: ApprovedIcon,
      view: true,
      downloadConditions: true,
      subtext:
        'Felicidades, fuiste aprobado. Coordiná tu cita de la firma del contrato con nuestras ejecutivas de crédito al 4000-8000 o al correo vehiculoveinsa@agecapital.cr',
    };
  }
  if (result === results.PREAPPROVED) {
    return {
      text: 'Preaprobado',
      icon: ApprovedIcon,
      edit: true,
      //needTerms: true,
      downloadConditions: true,
      subtext:
        'Gracias por ingresar tu información.  Estamos procesando tu solicitud.',
    };
  }
  if (result === results.PENDING_DOCS) {
    return {
      text: 'Documentos pendientes',
      icon: PendingIcon,
      edit: true,
      subtext:
        'Tu solicitud se encuentra incompleta. Por favor ingresá a terminar la documentación pendiente.',
    };
  }
  if (result === results.CHECKING_DOCS) {
    return {
      text: 'Documentación en revisión',
      icon: PendingIcon,
      view: true,
      subtext:
        'Tu solicitud se encuentra bajo revisión de los documentos adjuntados.',
    };
  }
  if (result === results.CHECKING_CREDIT) {
    return {
      text: 'En análisis de credito',
      icon: PendingIcon,
      view: true,
      subtext: 'Tu solicitud se encuentra bajo aprobación.',
    };
  }
  if (result === results.APPROVED_CONDITIONS) {
    return {
      text: 'Aprobado con condiciones',
      icon: ApprovedIcon,
      view: true,
      downloadConditions: true,
      subtext:
        'Felicidades, fuiste aprobado con algunas condiciones. Coordiná tu cita de la firma del contrato con nuestras ejecutivas de crédito al 4000-8000 o al correo vehiculoveinsa@agecapital.cr',
    };
  }
  if (result === results.FORMALIZATION) {
    return {
      text: 'Gestión de formalización',
      icon: ApprovedIcon,
      view: true,
      subtext:
        'Tu solicitud se encuentra bajo revisión de contratos en formalización',
    };
  }
  if (result === results.READY) {
    return {
      text: 'Listo para entrega',
      icon: ApprovedIcon,
      view: true,
      subtext:
        'Tu solicitud se encuentra lista. Los documentos de formalización se encuentran listos para tu firma.',
    };
  }
  if (result === results.FORMALIZED) {
    return {
      text: 'Formalizado',
      icon: ApprovedIcon,
      view: true,
      subtext:
        'Tu solicitud se encuentra lista. Los documentos de formalización se encuentran listos para tu firma.',
    };
  }
  if (result === results.REJECTED) {
    return {
      text: 'Rechazado',
      icon: RejectedIcon,
      view: true,
      subtext:
        'Tu solicitud ha sido declinada. Te invitamos a analizar nuevas opciones con nuestros asesores crediticios.',
    };
  }
  if (result === results.CLOSED) {
    return {
      text: 'Cerrado',
      icon: RejectedIcon,
      view: true,
      subtext: 'Lamentamos que este proceso no cumpliera con tus expectativas. Te invitamos a volver a utilizar nuestra calculadora o bien contactarte con un asesor al 4000-8000.',
    };
  }
  return null;
};

export const getQuoteStatus = status => {
  if (status === '1-Preaprobado') {
    return results.PREAPPROVED;
  }
  if (status === '2-Documentos Pendientes') {
    return results.PENDING_DOCS;
  }
  if (status === '3-Documentacion en Revision') {
    return results.CHECKING_DOCS;
  }
  if (status === '4-En Analisis de Credito') {
    return results.CHECKING_CREDIT;
  }
  if (status === '5-Aprobada') {
    return results.APPROVED;
  }
  if (status === '5-Aprobado con Condiciones') {
    return results.APPROVED_CONDITIONS;
  }
  if (status === '5-Rechazado') {
    return results.REJECTED;
  }
  if (status === '6-Gestion de Formalizacion') {
    return results.FORMALIZATION;
  }
  if (status === '7-Listo para entrega') {
    return results.READY;
  }
  if (status === '7-Formalizado') {
    return results.FORMALIZED;
  }
  if (status === '8-Cerrado') {
    return results.CLOSED;
  }

  return status;
};

export const getFieldStatus = field => {
  if (field?.debeActualizar) {
    return STATUS.REJECTED;
  }
  if (field?.verificado) {
    return STATUS.APPROVED;
  }
  if (field?.archivo) {
    return STATUS.REVISION;
  }
  return STATUS.PENDING;
};

export const canDelete = (status) => {
  return status === results.PREAPPROVED ||
    status === results.PENDING_DOCS;
};

export const cannotMakeChanges = (status) => {
  return status !== results.PREAPPROVED &&
    status !== results.PENDING_DOCS;
};


export const cleanString = (txt) => {
  return txt.replace(/[^a-zA-Z ]/g, '');
}

export const removeAccents = (txt) => txt.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const postData = (path, params, method) => {
  // Create form
  const hidden_form = document.createElement('form');
  // Set method to post by default
  hidden_form.method = method || 'post';
  // Set path
  hidden_form.action = path;
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hidden_input = document.createElement
          ('input');
      hidden_input.type = 'hidden';
      hidden_input.name = key;
      hidden_input.value = params[key];
      hidden_form.appendChild(hidden_input);
    }
  }
  document.body.appendChild(hidden_form);
  hidden_form.submit();
}
