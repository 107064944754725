import React from 'react';
import './CurrencyFormat.scss';
import CurrencyFormatLib from 'react-currency-format';
import useCurrency from '../../hooks/useCurrency.hook';
import useMoneda from '../../hooks/useMoneda';

const CurrencyFormat = ({ value, currentCurrency: curr }) => {
  // Use system currency
  const currency = useCurrency();
  // In case we send the currency, try to parse it.
  const currentCurrency = useMoneda(curr);
  return (
    <CurrencyFormatLib
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={currentCurrency?.sign || currency?.sign}
      decimalScale={2}
    />
  );
};
export default CurrencyFormat;
