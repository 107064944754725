import React from 'react';
import './ResultBadge.scss';

import classNames from 'classnames';
import ApprovedIcon from '../../assets/images/approved_icon.png';
import MoreIcon from '../../assets/images/rejected_icon.png';
import { results } from '../../utils/constants';

const getData = result => {
  if (result === results.APPROVED) {
    return {
      text: 'Crédito enviado a revisión',
      icon: (
        <img className="result-badge__icon" src={ApprovedIcon} alt="Approved" />
      ),
    };
  }
  if (result === results.MORE) {
    return {
      text:
        'Requerimos más información tuya. Intenta de nuevo o comunícate con servicio al cliente.',
      icon: (
        <img
          className="result-badge__icon"
          src={MoreIcon}
          alt="More information"
        />
      ),
    };
  }
  return null;
};

const ResultBadge = ({ result }) => {
  const data = getData(result);
  return (
    <div
      className={classNames('result-badge', {
        'result-badge--approved': result === results.APPROVED,
        'result-badge--more': result === results.MORE,
      })}
    >
      {data?.icon}
      <span>{data?.text}</span>
    </div>
  );
};

export default ResultBadge;
