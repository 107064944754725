import classNames from 'classnames';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './FormSection.scss';

const FormSection = ({ title, content, disclaimer }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={classNames('form-section', {
        'form-section--expanded': isOpen,
      })}
    >
      <div className="form-section__title">
        <button
          className="form-section__toggle"
          onClick={() => setIsOpen(!isOpen)}
          type="button"
        >
          {title} <FontAwesomeIcon icon={faAngleDown} />
        </button>
      </div>
      <div
        className={classNames('form-section__content', {
          'form-section__content--is-open': isOpen,
        })}
      >
        {content}
        {disclaimer}
      </div>
    </div>
  );
};

export default FormSection;
