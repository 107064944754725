import { createReducer } from '../../utils/helpers';
import ActionTypes from './actionTypes';

export const InitialDataState = {
  data: [],
  isLoading: false,
  error: null,
  isReady: false,
};

export const initialState = {
  homepage: InitialDataState,
  categories: InitialDataState,
  capsulas: InitialDataState,
  search: {...InitialDataState, data: {}},
  categoryPosts: {...InitialDataState, data: {}},
  posts: {...InitialDataState, data: {}},
  allPosts: InitialDataState,
};

// const commonSuccessAction = (action) => {
//   return [ActionTypes.FETCH_HOMEPAGE_POSTS](state) {
//     return {
//       ...state
//     }
//   };
// };

const reducer = {
  blog: createReducer(initialState, {
    [ActionTypes.FETCH_HOMEPAGE_POSTS](state) {
      return {
        ...state,
        homepage: {
          ...state.homepage,
          isLoading: true,
        },
      };
    },
    [ActionTypes.FETCH_CATEGORIES](state) {
      return {
        ...state,
        categories: {
          ...state.categories,
          isLoading: true,
        },
      };
    },
    
    [ActionTypes.FETCH_HOMEPAGE_POSTS_SUCCESS](state, action) {
      const { blogs } = action.payload;
      return {
        ...state,
        homepage: {
          ...state.homepage,
          data: blogs,
          isReady: true,
          isLoading: false,
        },
      };
    },
    [ActionTypes.FETCH_CATEGORIES_SUCCESS](state, action) {
      const { categories } = action.payload;
      return {
        ...state,
        categories: {
          ...state.categories,
          data: categories,
          isReady: true,
          isLoading: false,
        },
      };
    },
    [ActionTypes.FETCH_HOMEPAGE_POSTS_FAILURE](state) {
      return {
        ...state,
        homepage: {
          ...state.homepage,
          error: true,
          isReady: true,
          isLoading: false,  
        },
      };
    },
    [ActionTypes.FETCH_SEARCH](state, action) {
      const { search: term } = action.payload;
      return {
        ...state,
        search: {
          ...state.search,
          data: {
            ...state.search.data,
            [term]: [],
          },
          isLoading: true,
        },
      };
    },
    [ActionTypes.FETCH_SEARCH_SUCCESS](state, action) {
      const { posts, search: term } = action.payload;
      return {
        ...state,
        search: {
          ...state.search,
          data: {
            ...state.search.data,
            [term]: posts,
          },
          isLoading: false,
        },
      };
    },
    [ActionTypes.FETCH_CATEGORIES_BY_SLUG](state, action) {
      const { slug } = action.payload;
      return {
        ...state,
        categoryPosts: {
          ...state.categoryPosts,
          slug: {
            ...state.categoryPosts.data,
            [slug]: [],
          },
          isLoading: true,
        },
      };
    },
    [ActionTypes.FETCH_CATEGORIES_BY_SLUG_SUCCESS](state, action) {
      const { posts, slug } = action.payload;
      return {
        ...state,
        categoryPosts: {
          ...state.categoryPosts,
          data: {
            ...state.categoryPosts.data,
            [slug]: posts,
          },
          isLoading: false,
        },
      };
    },
    [ActionTypes.FETCH_BLOG_POST](state, action) {
      const { id } = action.payload;
      return {
        ...state,
        posts: {
          ...state.posts,
          data: {
            ...state.posts.data,
            [id]: [],
          },
          isLoading: true,
        },
      };
    },
    [ActionTypes.FETCH_BLOG_POST_SUCCESS](state, action) {
      const { post: posts } = action.payload;
      const data = !!posts?.length ? {
        ...state.posts,
        data: {
          ...state.posts.data,
          [posts[0]?.id]: posts[0],
        },
        isLoading: false,
      } : { ...state.posts, isLoading: false,};

      return {
        ...state,
        posts: { ...data }
      };
    },
    [ActionTypes.FETCH_ALL_BLOG](state) {
      return {
        ...state,
        allPosts: {
          ...state.allPosts,
          isLoading: true,
        },
      };
    },
    [ActionTypes.FETCH_ALL_BLOG_SUCCESS](state, action) {
      const { posts } = action.payload;
      return {
        ...state,
        allPosts: {
          ...state.allPosts,
          data: posts,
          isReady: true,
          isLoading: false,
        },
      };
    },
  }),
};

export default reducer;
