import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Loader.scss';

const Loader = ({ pulse, preloader, light }) => {
  let html;
  if (preloader) {
    html = <div className="app__preloader" />;
  } else if (pulse) {
    html = (
      <span
        className={cx('app__loader app__loader--pulse', {
          'app__loader-light': light
        })}
      >
        <span className="pulse" />
      </span>
    );
  } else {
    html = (
      <span className="app__loader app__loader--rotate">
        <svg className="loader__svg">
          <circle
            className="loader__circle"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
          />
        </svg>
      </span>
    );
  }

  return html;
};

Loader.propTypes = {
  light: PropTypes.bool,
  preloader: PropTypes.bool,
  pulse: PropTypes.bool.isRequired
};

Loader.defaultProps = {
  pulse: true
};

export default Loader;
