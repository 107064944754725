import classNames from 'classnames';
import React, { useState } from 'react';
import Button from '../Button';
import CurrencyFormat from '../CurrencyFormat';
import './Tabs.scss';
import Arrow from '../../assets/images/arrow-left.png';
import Checkbox2 from '../Checkbox2';
import Angle from '../../assets/images/angle-black.svg';
import { ticoDate } from '../../utils/helpers';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { requestDocument } from '../../ducks/actions';

const Tab = ({ tab, isActive, setSelectedItem }) => (
  <button
    onClick={() => setSelectedItem(tab.numero)}
    className={classNames('tab', { 'tab--active': isActive })}
  >
    <span className="tab__label">Operación:</span> {tab.numero}
  </button>
);

const Panel = ({ loan }) => {
  const initialState = {
    'Historial de pago': false,
    'Estado de cuenta': false,
    'Recibo de pago': false,
  };
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(initialState);
  const [enviado, setEnviado] = useState(false);
  const [plate, setPlate] = useState(false);

  const requestDocs = () => {
    const documentos = Object.keys(options).reduce((acc, curr) => {
      if (options[curr]) {
        acc = acc + curr + ', ';
      }
      return acc;
    }, '');
    if (!documentos) {
      return;
    }
    setEnviado(true);
    setTimeout(() => {
      setEnviado(false);
    }, 3000);
    setOptions(initialState);
    dispatch(
      requestDocument(
        loan?.numero,
        options,
        loan?.moneda
      )
    );
  };

  const objectKeys = Object.keys(options);
  const history = useHistory();
  return (
    <div className={classNames('panel', { 'panel--open': open })}>
      <div className="panel__title">
        <button
          onClick={() => setPlate(!plate)}
          className={classNames('panel__toggle--plate', {
            'panel__toggle--plate--active': plate,
          })}
        >
          Placas ligadas a esta operación:{' '}
          <img src={Angle} className="panel__toggle-image" alt="Arrow" />
        </button>
      </div>
      {plate && (
        <div className="panel__title panel__title--plate">
          <span style={{ 'font-weight': 'normal' }}>
            {(loan.placa?.split(';') || []).map(x => x.trim()).join(', ')}
          </span>
        </div>
      )}
      <div className="panel__detail">
        <div className="panel__detail-col">
          <div className="panel__detail-title">
            Pago de cuotas - {loan.cuotasPagas + 1}
            <button onClick={() => setOpen(!open)} className="panel__toggle">
              <img src={Angle} className="panel__toggle-image" alt="Arrow" />
            </button>
          </div>
          {open && (
            <>
              <div className="panel__due-date">
                Fecha de vencimiento:{' '}
                <span>{ticoDate(loan.fechaFinal)}</span>
              </div>
              <div className="panel__valid-until">
                Fecha de próximo pago: <span>{ticoDate(loan.proximoPago)}</span>
              </div>
            </>
          )}
        </div>
        <div className="panel__detail-col">
          <div className="panel__detail-value">
            Cuota
          </div>
          {open && <div className="panel__detail-label"><CurrencyFormat value={loan.cuota} currentCurrency={loan?.moneda} /></div>}
        </div>
        <div className="panel__detail-col">
          <div className="panel__detail-value">Plazo</div>
          {open && <div className="panel__detail-label">{loan.plazo} meses</div>}
        </div>
        <div className="panel__detail-col">
          <div className="panel__detail-value">Estado</div>
          {open && <div className="panel__detail-label">{loan.categoriaMora}</div>}
        </div>
        <div className="panel__detail-col">
          <div className="panel__detail-value">Cuotas Vencidas</div>
          {open && <div className="panel__detail-label">{loan.cuotasVencidas}</div>}
        </div>
        <div className="panel__detail-col">
          <div className="panel__detail-value"># Cuota</div>
          {open && (
            <div className="panel__detail-label">
              {loan.consultaGeneral.CuotasPagadas}
            </div>
          )}
        </div>
      </div>
      <div className="panel__payments">
        <div className="panel__payment-type">
          Seleccioná que tipo de pago querés realizar
        </div>
        <div className="panel__button">
          <Button
            sm
            gray
            value="Pago parcial"
            suffix={<img src={Arrow} alt="Icon" />}
            onClick={() => {
              history.push(`/perfil/realizar-pago/${loan.numero}/parcial`);
            }}
            fullwidth
          />
        </div>
        <div className="panel__button">
          <Button
            sm
            value="Extraordinario"
            suffix={<img src={Arrow} alt="Icon" />}
            onClick={() => {
              history.push(
                `/perfil/realizar-pago/${loan.numero}/extraordinario`
              );
            }}
            fullwidth
          />
        </div>
        <div className="panel__button">
          <Button
            sm
            black
            value="Pagar cuota"
            suffix={<img src={Arrow} alt="Icon" />}
            onClick={() => {
              history.push(`/perfil/realizar-pago/${loan.numero}/cuota`);
            }}
            fullwidth
          />
        </div>
      </div>
      <div className="panel__additional">
        <div className="panel__additional-text">
          Podés solicitar documento adicional
        </div>
        {objectKeys.map(key => {
          return (
            <div className="panel__additional-check" key={key}>
              <Checkbox2
                setChecked={status => {
                  setOptions({
                    ...options,
                    [key]: status,
                  });
                }}
                checked={options[key]}
              />{' '}
              <div className="panel__additional-check-text">{key}</div>
            </div>
          );
        })}

        <div className="panel__additional-button">
          {enviado ? (
            <span className="panel__additional-button--requested">
              Solicitado!
            </span>
          ):(
            <Button
              onClick={requestDocs}
              sm
              black
              value={'Solicitar'}
              suffix={<img src={Arrow} alt="Icon" />}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const Tabs = ({ data }) => {
  const [selectedItem, setSelectedItem] = useState(data[0]?.numero);
  return (
    <div className="tabs">
      <div className="tabs__tabs">
        {data?.map(tab => (
          <Tab
            key={tab.numero}
            tab={tab}
            isActive={selectedItem === tab.numero}
            setSelectedItem={setSelectedItem}
          />
        ))}
      </div>
      <div className="tabs__panels">
        {data
          ?.filter(tab => {
            return selectedItem === tab.numero;
          })
          ?.map(loan => (
            <Panel key={loan.placa} loan={loan} />
          ))}
      </div>
    </div>
  );
};

export default Tabs;
