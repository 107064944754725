import serialize from 'form-serialize';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BadIcon from '../../assets/images/Pago_No_realizado.png';
import PayIcon from '../../assets/images/Pago_realizado.png';
import Afiliado from '../../assets/images/afiliado.svg';
import Arrow from '../../assets/images/arrow-left.png';
import circleXIcon from '../../assets/images/circleXIcon.svg';
import {
  fetchOperationsRequest
} from '../../ducks/actions';
import {
  approveTYC
} from '../../ducks/app/actions';
import {
  getOperationSelectors
} from '../../ducks/selectors';
import { ENDPOINT_URL } from '../../utils/constants';
import { geDaysBetweenDates, getTyCText, getUrlParameter, postData } from '../../utils/helpers';
import Button from '../Button';
import CardDetector from '../CardDetector/CardDetector';
import Checkbox2 from '../Checkbox2';
import CommonDialog from '../CommonDialog';
import CurrencyInput from '../CurrencyInput';
import EmptyComponent from '../Empty/EmptyComponent';
import Input from '../Input';
import MaskedInput from '../MaskedInput';
import ProcessingDialog from '../ProcessingDialog';
import ProfileSection from '../ProfileSection';
import RadioButtons from '../RadioButtons';
import Select from '../Select';
import './MetodoDePago.scss';

const PAYMENT_TYPES = [
  {
    label: 'Pago cuota',
    value: 0,
  },
  {
    label: 'Pago parcial',
    value: 1,
  },
  {
    label: 'Abono extraordinario',
    value: 2,
  },
];

const EXTRAORDINARIO_MODE = [
  {
    label: 'Disminución de cuota',
    value: 'Disminución de cuota',
  },
  {
    label: 'Disminución de tiempo',
    value: 'Disminución de tiempo',
  },
];

const TYPES_MAP = {
  parcial: 1,
  cuota: 0,
  extraordinario: 2,
};

const FORMAS_PAGO = [
  {
    label: 'Tarjeta',
    value: 0,
  },
  // {
  //   label: 'Transferencia IBAN',
  //   value: 1,
  // },
];

const getMaxValue = (tipoPago, montoCuota, saldo) => {
  if (tipoPago === 1) {
    return montoCuota
  }
  if (tipoPago === 2) {
    return saldo;
  }
  return null;
}

const MetodoDePago = () => {
  const succeded = getUrlParameter('succeded');
  const cargo = getUrlParameter('cargo');
  const error = getUrlParameter('error');
  const { userData } = useSelector(state => ({
    userData: state.user.data,
  }));

  const [showError, setShowError] = useState(!!error ?? false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [showDone, setShowDone] = useState((!!succeded && !cargo) ?? false);
  const [showCargoAutomatico, setShowCargoAutomatico] = useState(false);
  const [showNewCargoAutomatico, setShowNewCargoAutomatico] = useState((!!succeded && !!cargo) ?? false);
  const [operationNumbers, setOperationNumbers] = useState([]);
  const [placasNumbers, setPlacasNumbers] = useState([]);
  const [accept, setAccept] = useState(false);
  const [showTYCError, setShowTYCError] = useState(false);
  const { numeroOperacion: numOperacion, tipoPago } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm();
  const formRef = useRef();
  const onSubmit = async data => {
    if (!accept) {
      setShowTYCError(true);
      return false;
    }
    saveTYCchoice(userData);
    setShowProcessing(true);

    const removeFrame = () => {
      const iframes = document.querySelectorAll('iframe');
      iframes.forEach((frame) => {
        if (frame.src.indexOf('credomatic') >= 0) {
          frame.remove();
        }
      });
    };
    // Initialize the ThreeDSService
    const gateway = window.processor;
    const threeDS = gateway.get3DSecure();

    // Create a 3DS Frame
    // This will start out 0px x 0px during fingerprinting.
    // If the customer is prompted to complete a challenge, it will resize automatically.


    const form = formRef.current;
    const serializedForm = serialize(form, { hash: true });
    console.log(data, userData, serializedForm);

    const options = {
      cardNumber: data.numeroTarjeta,
      cardExpMonth: data.vencimiento.substring(0, 2),
      cardExpYear: data.vencimiento.substring(2, 4),
      currency: operation?.moneda?.toLowerCase().indexOf('colon') != -1 ? 'CRC' : 'USD',
      amount: data.monto,
      email: userData.email,
      phone: '',
      city: 'San Jose',
      state: 'SJ',
      address1: '',
      country: 'CR',
      firstName: userData.name || 'GENERICO',
      lastName: userData.lastname || 'GENERICO',
      postalCode: '10101'
    };
    try {
      const threeDSecureInterface  = threeDS.createUI(options);
      // Mount the threeDSecureInterface to the DOM
      // This begins the collection of 3DS data.
      threeDSecureInterface.start('body');
  
      // Listen for the threeDSecureInterface to ask the user for a password
      threeDSecureInterface.on('challenge', function(e) {
        setShowProcessing(false);
        console.log('Challenged');
      });
  
      // Listen for the threeDSecureInterface to provide all the needed 3DS data
      threeDSecureInterface.on('complete', function(e) {
        setShowProcessing(true);
        threeDSecureInterface.unmount();
        postData(`${ENDPOINT_URL}/pago/sale/`, {
          ...serializedForm,
          ...data,
          ...options,
          cavv: e.cavv,
          xid: e.xid,
          eci: e.eci,
          cardHolderAuth: e.cardHolderAuth,
          threeDsVersion: e.threeDsVersion,
          directoryServerId: e.directoryServerId,
          cardHolderInfo: e.cardHolderInfo,
        }, 'POST');
      });
  
      // Listen for the threeDSecureInterface to indicate that the customer
      // has failed to authenticate
      threeDSecureInterface.on('failure', function(e) {
        console.log('failure');
        console.log(e);
        threeDSecureInterface.unmount();
        setShowError(true);
        setShowProcessing(false);
        // history.push('./?error=Hubo un error al procesar la reserva');
      });
  
      // Listen for any errors that might occur.
      gateway.on('error', function (e) {
        setShowError(true);
        setShowProcessing(false);
        console.error(e);
        threeDSecureInterface.unmount();
      })
    } catch(e) {
      console.log(e);
      setShowError(true);
      setShowProcessing(false);
    }
  };


  const saveTYCchoice = (user_data) => {
    var current = new Date();
    dispatch(approveTYC({
      cedula: user_data?.dni,
      fecha: current.getTime(),
      texto: getTyCText(user_data?.name, user_data?.lastname, user_data?.dni),
    }))
  };

  const operationObject = useSelector(getOperationSelectors);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOperationsRequest());
  }, [dispatch]);

  useEffect(() => {
    setOperationNumbers(
      (operationObject.data || []).map(x => ({
        label: x.numero,
        value: x.numero,
      }))
    );
    setPlacasNumbers(
      (operationObject.data || []).filter(x => x.placa).map(x => ({
        label: x.placa,
        value: x.placa,
      }))
    );
  }, [operationObject]);

  const selectedNumOperacion = watch('numeroOperacion');

  const operation = useMemo(() => operationObject.data?.find(
    _operation => _operation.numero === selectedNumOperacion
  ), [operationObject, selectedNumOperacion]);

  const tipo = watch('tipoPago');

  useEffect(() => {
    if (operation && tipo === 0) {
      setValue('monto', operation?.cuota);
    }
  }, [operation, setValue, tipo]);

  useEffect(() => {
    if (operation) {
      setValue('placa', operation?.placa);
    }
  }, [operation, setValue]);

  useEffect(() => {
    if (numOperacion) {
      setValue('numeroOperacion', numOperacion);
    }
  }, [numOperacion, operationNumbers, setValue]);

  useEffect(() => {
    if (tipoPago) {
      setValue('tipoPago', TYPES_MAP[tipoPago]);
    } else {
      setValue('tipoPago', PAYMENT_TYPES[0]?.value);
    }
  }, [setValue, tipoPago]);

  useEffect(() => {
    if (operation?.cargoAutomatico === 1 && !numOperacion) {
      setShowCargoAutomatico(true);
    }
  }, [operation, numOperacion]);

  // const TYCaccepted = () => {
  //   return !accept;
  // };

  return (
    <div className="metodo-de-pago">
      <ProfileSection
        title="Método de pago"
        extraTitle="Realizá tu pago al día "
      >
        <EmptyComponent
          isLoading={operationObject.isLoading}
          data={operationObject.data}
          message="No tienes operaciones activas por pagar."
        >
          <p className="metodo-de-pago__text">
            Queremos facilitarte el proceso, podés buscar por número de
            operación o número de placa y hacer tu pago, de manera fácil.
          </p>
          <form
            ref={formRef}
            className="metodo-de-pago__form"
            onSubmit={handleSubmit(onSubmit)}
            action={`${ENDPOINT_URL}/pago/sale/`}
            method="POST"
          >
            <input
              type="hidden"
              name="tipoPagoMonto"
              value={PAYMENT_TYPES[tipo]?.label}
            />
            <input
              type="hidden"
              name="moneda"
              value={operation?.moneda}
            />
            <div className="metodo-de-pago__row metodo-de-pago__row--initial">
              <div className="metodo-de-pago__column">
                <Controller
                  name="numeroOperacion"
                  control={control}
                  defaultValue={numOperacion}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      onChange={({ target }) => {
                        setValue('numeroOperacion', target?.value);
                        const placasOperacion = operationObject.data?.find(
                          operacion => operacion.numero === target?.value
                        )?.placa;
                        // setPlacasNumbers(placasOperacion || []);
                        // const placas = operationObject.data?.find(
                        //   operacion => operacion.numero === target?.value
                        // )?.placa;
                        // setPlacasNumbers([{ value: placas, label: placas }]);
                        setValue('placa', placasOperacion);
                        if (errors.numeroOperacion && !!target?.value) {
                          clearErrors('numeroOperacion');
                        }
                      }}
                      value={field.value}
                      label="Número de operación"
                      options={operationNumbers}
                      name="numeroOperacion"
                      hasError={!!errors.numeroOperacion}
                      disabledForm={!!tipoPago}
                    />
                  )}
                />
              </div>
              <div className="metodo-de-pago__column">
                <Controller
                  name="placa"
                  control={control}
                  //defaultValue={numOperacion || operationNumbers[0]?.value}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      onChange={({ target }) => {
                        setValue('placa', target?.value);
                        const operacion = operationObject.data?.find(
                          operacion => operacion.placa === target?.value
                        )?.numero;
                        setValue('numeroOperacion', operacion);
                        if (errors.placa && !!target?.value) {
                          clearErrors('placa');
                        }
                      }}
                      value={field.value}
                      label="Placas"
                      options={placasNumbers}
                      name="placa"
                      hasError={!!errors.placa}
                      disabledForm={!!tipoPago}
                    />
                  )}
                />
              </div>
              <div className="metodo-de-pago__column">
                <Controller
                  name="tipoPago"
                  control={control}
                  defaultValue={TYPES_MAP[tipoPago]}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      onChange={({ target }) => {
                        setValue('tipoPago', Number(target.value));
                        if (errors.tipoPago && !!target?.value) {
                          clearErrors('tipoPago');
                          clearErrors('parcialModo');
                        }
                      }}
                      value={field.value}
                      label="Tipo de pago"
                      options={PAYMENT_TYPES}
                      name="tipoPago"
                      hasError={!!errors.tipoPago}
                      disabledForm={!!tipoPago}
                    />
                  )}
                />
              </div>


              <div className="metodo-de-pago__column">
                <Controller
                  name="monto"
                  control={control}
                  defaultValue={
                    (watch('tipoPago') === 0 && operation?.cuota) || ''
                  }
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CurrencyInput
                      currency={operation?.moneda}
                      onChange={({ value }) => {
                        setValue('monto', value);
                        if (errors.monto && !!value) {
                          clearErrors('monto');
                        }
                      }}
                      value={
                        // watch('tipoPago') === 0 ? operation?.cuota : field.value
                        field.value
                      }
                      label="Monto a pagar"
                      placeholder={watch('tipoPago') === PAYMENT_TYPES[0]?.value ? "Ej. 238.00" : "Ej. 0.00"}
                      disabledForm={watch('tipoPago') === 0}
                      name="monto"
                      hasError={!!errors.monto}
                      maxValue={getMaxValue(watch('tipoPago'), operation?.cuota, operation?.saldo)}
                    />
                  )}
                />
              </div>
            </div>
            {operation && geDaysBetweenDates(operation?.proximoPago) > 0 && (
              <span className="metodo-de-pago__al-dia">
                El pago se encuentra al día. Recordá que podés hacer abonos extraordinarios en cualquier momento.
              </span>
            )}
            <hr className="metodo-de-pago__hr" />
            {/* {watch('tipoPago') === 2 && (
                <div className="metodo-de-pago__row">
                  <Controller
                    name="parcialModo"
                    control={control}
                    //defaultValue={numOperacion || operationNumbers[0]?.value}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        onChange={({ target }) => {
                          setValue('parcialModo', target?.value);
                          if (errors.parcialModo && !!target?.value) {
                            clearErrors('parcialModo');
                      
                          }
                        }}
                        value={field.value}
                        label="Aplicar pago a:"
                        options={EXTRAORDINARIO_MODE}
                        name="parcialModo"
                        hasError={!!errors.parcialModo}
                        disabledForm={!!tipoPago}
                      />
                      
                    )}
                  />
                </div>
              )} */}
            {watch('tipoPago') === 2 && (
              <div className="metodo-de-pago__row metodo-de-pago__row--pago-parcial">
                <RadioButtons
                  options={EXTRAORDINARIO_MODE}
                  label="Aplicar pago a:"
                  {...register('parcialModo', { required: 'Requerido' })}
                  onChange={value => {
                    setValue('parcialModo', value);
                    if (errors.parcialModo && !!value) {
                      clearErrors('parcialModo');
                    }
                  }}
                  value={watch('parcialModo')}
                  hasError={!!errors.parcialModo}
                />
              </div>
            )}
            <hr className="metodo-de-pago__hr" />
            <p className="metodo-de-pago__text">
              Por favor completá tu método de pago de preferencia
            </p>
            <div className="metodo-de-pago__row">
              <div className="metodo-de-pago__forma">
                <Controller
                  name="formaPago"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <RadioButtons
                      name="formaPago"
                      label="Forma de pago disponible"
                      onChange={value => {
                        setValue('formaPago', value);
                        if (errors.formaPago && !!value) {
                          clearErrors('formaPago');
                        }
                      }}
                      value={field.value}
                      options={FORMAS_PAGO}
                      black
                    />
                  )}
                />
              </div>
              {watch('formaPago') === 0 ? (
                <div className="metodo-de-pago__extra">
                  <div className="metodo-de-pago__column">
                    {/* {errors.nombre} */}
                    <Input
                      label="Nombre del titular de la tarjeta"
                      placeholder="Ingrese su nombre completo"
                      {...register('nombre', { required: 'Requerido' })}
                      hasError={!!errors.nombre}
                    />
                  </div>
                  <div className="metodo-de-pago__card-details">
                    <div className="metodo-de-pago__column">
                      <Controller
                        name="numeroTarjeta"
                        control={control}
                        rules={{ required: 'Requerido' }}
                        render={({ field }) => (
                          <MaskedInput
                            label="Número de la tarjeta"
                            placeholder="5555 0000 0000 8888"
                            onChange={({ value }) => {
                              setValue('numeroTarjeta', value);
                              if (errors.numeroTarjeta && !!value) {
                                clearErrors('numeroTarjeta');
                              }
                            }}
                            value={field.value}
                            format="#### #### #### ####"
                            name="numeroTarjeta"
                            hasError={!!errors.numeroTarjeta}
                          />
                        )}
                      />
                      <div className="metodo-de-pago__card-detector">
                        <CardDetector
                          value={watch('numeroTarjeta')}
                          showAll={true}
                        />
                      </div>
                    </div>
                    <div className="metodo-de-pago__column">
                      <Controller
                        name="vencimiento"
                        control={control}
                        rules={{ required: 'Requerido' }}
                        render={({ field }) => (
                          <MaskedInput
                            label="Fecha de vencimiento"
                            placeholder="MM/AA"
                            onChange={({ value }) => {
                              setValue('vencimiento', value);
                              if (errors.vencimiento && !!value) {
                                clearErrors('vencimiento');
                              }
                            }}
                            value={field.value}
                            format="##/##"
                            name="vencimiento"
                            hasError={!!errors.vencimiento}
                          />
                        )}
                      />
                    </div>
                    <div className="metodo-de-pago__column">
                      <Controller
                        name="cvc"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MaskedInput
                            label="CVC"
                            placeholder="0000"
                            onChange={({ value }) => {
                              setValue('cvc', value);
                              if (errors.cvc && !!value) {
                                clearErrors('cvc');
                              }
                            }}
                            value={field.value}
                            format="####"
                            name="cvc"
                            hasError={!!errors.cvc}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="metodo-de-pago__paybac">
                  PAYBAC de BAC Credomatic es un servicio fácil y rápido que te
                  permite hacer pagos con tu cuenta IBAN de cualquier banco y
                  sin cargos adicionales.
                </div>
              )}
            </div>
          </form>
          {/* HACE LO DE CX SEGUN FORMA DE PAGO PARA CAMBIAR EL ESTILO O CON STYLE VAR */}
          <div className="metodo-de-pago__submit-container"
            style={{ '--var-jc': (watch('formaPago') === 0) ? ('space-between') : ('flex-end') }}>
            {
              watch('formaPago') === 0 ? (
                <>
                  <div className="metodo-de-pago__submit-container-left">
                    <Checkbox2
                      name="checkbox"
                      checked={accept}
                      setChecked={status => {
                        setAccept(status);
                        setShowTYCError(!status);
                      }}
                    />{' '}
                    <a
                      className="metodo-de-pago__submit-container-left-link"
                      href="/wp-content/uploads/docs/Terminos y Condiciones AGE Capital PAGOS.pdf"
                      target="_blank"
                    >
                      He leído los términos y condiciones
                    </a>
                  </div>
                  <div className="metodo-de-pago__submit-container-right">
                    {showTYCError ? (
                      <div className="metodo-de-pago__acceptTYC">
                        <span className="metodo-de-pago__acceptTYC-text">
                          Para continuar aceptá nuestros términos.
                        </span>
                        <img className="metodo-de-pago__acceptTYC-img" src={circleXIcon} alt="Circle" />
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        value="Pagar ahora"
                        black
                        onClick={handleSubmit(onSubmit)}
                        suffix={
                          <img
                            className="metodo-de-pago__arrow"
                            src={Arrow}
                            alt="Arrow"
                          />
                        }
                      />
                    )}
                  </div>
                </>
              ) : (
                <Button
                  href="https://paybac.cr/paybac/login"
                  target="_blank"
                  component="a"
                  value="Pagar con PAYBAC"
                  black
                  suffix={
                    <img
                      className="metodo-de-pago__arrow"
                      src={Arrow}
                      alt="Arrow"
                    />
                  }
                />
              )
            }
          </div>
        </EmptyComponent>
      </ProfileSection>

      <ProcessingDialog
        open={showProcessing}
        title="Estamos procesando tu pago, ¡No cerrés la ventana!"
        body="Estamos procesando tus datos, esto tomará solo unos segundos."
      />
      <CommonDialog
        className="pay-modal"
        open={showError}
        icon={BadIcon}
        iconMargin={180}
        onClose={() => setShowError(false)}
        title="¡El pago no se ha realizado satisfactoriamente!"
        body={
          error ??
          'Estamos procesando tus datos, esto tomará solo unos segundos.'
        }
      />
      <CommonDialog
        className="pay-modal"
        iconMargin={180}
        icon={PayIcon}
        open={showDone}
        onClose={() => setShowDone(false)}
        title="¡El pago se ha realizado satisfactoriamente!"
        body="El comprobante de pago llegará a tu correo electrónico en un plazo de 24 horas."
        buttons={
          <>
            <Button black onClick={() => setShowDone(false)} value="Cerrar" />
          </>
        }
      />
      <CommonDialog
        icon={Afiliado}
        open={showCargoAutomatico}
        onClose={() => setShowCargoAutomatico(false)}
        title="¡Estás afiliado a cargo automático!"
        body="Recordá que también podés hacer pagos parciales o abonos extraordinarios."
      />
      <CommonDialog
        className='nueva-afiliacion'
        icon={Afiliado}
        open={showNewCargoAutomatico}
        onClose={() => {
          setShowNewCargoAutomatico(false);
          setShowDone(true);
        }}
        title="¡Quedaste afiliado a cargo automático,
        queremos facilitarte tu trámite!"
        body={
          <>Para más información podés contactarnos, llamando al <a href='tel:+50640008190'>4000-8190</a> o escribiendo a: <a href='mailto:vehiculoveinsa@agecapital.cr'>vehiculoveinsa@agecapital.cr</a></>
        }
      />
    </div>
  );
};

export default MetodoDePago;
