import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import { createEpicMiddleware } from 'redux-observable';

import rootEpic from '../ducks/epics';
import reducers from '../ducks/reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const epicMiddleware = createEpicMiddleware();

function configureStore() {
  const store =
    process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test'
      ? createStore(rootReducer, applyMiddleware(epicMiddleware))
      : createStore(
          rootReducer,
          composeEnhancers(applyMiddleware(epicMiddleware))
        );

  epicMiddleware.run(rootEpic);

  return store;
}

const store = configureStore();

export default store;
