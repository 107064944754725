import React from 'react';

import carKeys from '../../assets/images/car-keys.png';
import clock from '../../assets/images/clock.png';
import customerService from '../../assets/images/customer-service.png';
import store from '../../assets/images/store.png';
import './Benefits.scss';

const Benefits = () => (
  <div className="benefits">
    <div className="benefits__nocall"> {/* Esta clase es para agregar el call action sin margen */}
      <div className="benefits__banner">
        <div>
          <div className="benefits__title">
            ¡Con nosotros, tenés estos <span className="gold">beneficios</span>!
          </div>
        </div>
        <div className="benefits__sub-title">
          Trámites sencillos y en línea para el financiamiento de tu vehículo, rápido y hecho a tu medida.
        </div>
      </div>
      <div className="benefits__boxes">
        <div className="benefits__box">
          <div className="benefits__icon-container">
            <img className="benefits__icon" src={clock} alt="clock" />
          </div>
          <div className="benefits__box-text">Financiamientos aprobados en tiempo récord</div>
        </div>
        <div className="benefits__box">
          <div className="benefits__icon-container">
            <img
              className="benefits__icon"
              src={customerService}
              alt="customer service"
            />
          </div>
          <div className="benefits__box-text">
            Servicio personalizado y trámites simples
          </div>
        </div>
        <div className="benefits__box">
          <div className="benefits__icon-container">
            <img className="benefits__icon" src={store} alt="store" />
          </div>
          <div className="benefits__box-text">
            ¿Pymes o independientes? ¡Acá te apoyamos!
          </div>
        </div>
      </div>
    </div>
    <div className="callActionHub">
      <div className="w-half">
        <img src={"https://prod-agecapital-cr.azurewebsites.net/wp-content/uploads/2023/04/logoWhite-1.webp"} alt="Veinsa Hub" title="Veinsa Hub" />
      </div>
      <div className="w-half">
        <h6>¡Cotizá tu carro, agendá tu mantenimiento, obtené un seguro, revisá tu financiamiento y hacé muchas cosas más!</h6><a href="https://www.veinsahub.com" target="_blank" rel="noreferrer" className="btn-hub">IR AL HUB</a></div>
    </div>
    <div className="benefits__nocall">
      <div className="benefits__helper">
        <div className="benefits__helper-left">
          <div className="benefits__helper-line">
            Nuestras soluciones de {' '}
            <span className="gold">financiamiento son hechas a </span>
            tu medida.
          </div>
          <div className="benefits__helper-line">
            Vamos de la mano con  <br />
            <span className="gold">el desarrollo <br />
              empresarial y comercial</span> <br />
            de nuestros clientes.
          </div>
        </div>
        <div className="benefits__helper-right">
          <div className="benefits__helper-right-content">
            <img
              className="benefits__helper-car-keys"
              src={carKeys}
              alt="car keys"
            />
            <div className="benefits__helper-right-box">
              <div className="benefits__helper-right-box-title">
                Beneficios que solo vos disfrutarás:
              </div>
              <p className="benefits__helper-right-box-subtitle">Financiamiento en:</p>
              <ul className="benefits__helper-right-box-list">
                <li>Servicios de taller</li>
                <li>Pago de marchamo</li>
              </ul>
              <p className="benefits__helper-right-box-subtitle">Descuentos en:</p>
              <ul className="benefits__helper-right-box-list">
                <li>SIXT rent-a-car</li>
                <li>Repuestos y servicios de taller</li>
              </ul>
              <div className="benefits__helper-right-disclaimer">
                Aplican restricciones
              </div>
            </div>
          </div>
          <div className="benefits__helper-right-goals">
            ¡Somos tu mejor aliado para el{' '}
            <span className="gold">financiamiento de tu vehículo!</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Benefits;
