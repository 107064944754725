import { currencies } from '../../components/Currency/Currency';
import { createReducer } from '../../utils/helpers';
import ActionTypes from './actionTypes';
import UserActionTypes from '../user/actionTypes';

export const initialState = {
  data: {
    currency: currencies.DOLLAR,
  },
  isLoading: false,
  error: null,
  isReady: false,
  homepage: {
    data: null,
    isLoading: false,
    error: null,
    isReady: false,
  },
  rates: {
    data: null,
    isLoading: false,
    error: null,
    isReady: false,
    calculatorSettings: null,
  },
  quote: {
    data: null,
    isLoading: false,
    error: null,
    isReady: false,
    isSending: false,
    sent: false,
    isUploadingFiles: false,
    isUploadingForm: false,
    hasDownloadedDocuments: false,
  },
  solicitudes: {
    data: [],
    isLoading: false,
    error: null,
    isReady: false,
    fetched: false,
  },
  paquetes: {
    data: [],
    isLoading: false,
    error: null,
    isReady: false,
  },
  customerForm: {
    data: [],
    isLoading: false,
    error: null,
    isReady: false,
    isSent: false,
  },
  users: {
    data: [],
    isLoading: false,
  },
  users2: {
    data: [],
    isLoading: false,
  },
  users3: {
    data: [],
    isLoading: false,
  },
  dtc: {
    isLoading: false,
  },
  approval: {
    isLoading: false,
  },
};

const reducer = {
  app: createReducer(initialState, {
    [ActionTypes.SET_CURRENCY_REQUEST](state, action) {
      return {
        ...state,
        data: {
          ...state.data,
          currency: action.payload.currency,
        },
      };
    },
    [ActionTypes.FETCH_HOMEPAGE_REQUEST](state) {
      return {
        ...state,
        homepage: {
          ...state.homepage,
          isLoading: true,
        },
      };
    },
    [ActionTypes.FETCH_HOMEPAGE_SUCCESS](state, action) {
      return {
        ...state,
        homepage: {
          ...state.homepage,
          data: action.payload.data,
          isLoading: false,
        },
      };
    },
    [ActionTypes.FETCH_HOMEPAGE_FAILURE](state) {
      return {
        ...state,
        homepage: {
          ...state.homepage,
          isLoading: false,
          error: true,
        },
      };
    },
    [ActionTypes.FETCH_HOMEPAGE_RESET](state) {
      return {
        ...state,
        homepage: initialState.homepage,
      };
    },
    [ActionTypes.FETCH_RATES_REQUEST](state) {
      return {
        ...state,
        rates: {
          ...state.rates,
          isLoading: true,
          isReady: false,
        },
      };
    },
    [ActionTypes.FETCH_RATES_SUCCESS](state, action) {
      return {
        ...state,
        rates: {
          ...state.rates,
          calculatorSettings: action.payload.data.additional,
          data: action.payload.data.data,
          isLoading: false,
          isReady: true,
        },
      };
    },
    [ActionTypes.FETCH_RATES_FAILURE](state) {
      return {
        ...state,
        rates: {
          ...state.rates,
          isLoading: false,
          error: true,
          isReady: true,
        },
      };
    },
    [ActionTypes.SUBMIT_QUOTE_REQUEST](state) {
      return {
        ...state,
        quote: {
          ...state.quote,
          isSending: true,
        },
      };
    },
    [ActionTypes.SUBMIT_QUOTE_SUCCESS](state, action) {
      return {
        ...state,
        quote: {
          ...state.quote,
          data: action.payload.data,
          isSending: false,
          sent: true,
        },
      };
    },
    [ActionTypes.CHANGE_SOLICITUD_TO_QUOTE](state, action) {
      return {
        ...state,
        quote: {
          ...initialState.quote,
          data: action.payload.quote,
        },
      };
    },
    [ActionTypes.CHANGE_QUOTE](state, action) {
      return {
        ...state,
        quote: {
          ...initialState.quote,
          data: action.payload.quote,
        }
      };
    },
    [ActionTypes.SUBMIT_QUOTE_FAILURE](state) {
      return {
        ...state,
        quote: {
          ...state.quote,
          isSending: false,
          sent: false,
        },
      };
    },
    [ActionTypes.RESET_QUOTE](state) {
      return {
        ...state,
        quote: {
          ...initialState.quote
        }
      }
    },
    [ActionTypes.CHANGE_FILE](state, action) {
      return {
        ...state,
        quote: {
          ...state.quote,
          data: {
            ...state.quote.data,
            documentos: state.quote.data?.documentos?.map(document => {
              const id = action.payload.id;
              if (document.id === id) {
                return {
                  ...document,
                  debeActualizar: 0,
                  archivo: action.payload.file,
                };
              }
              return document;
            }),
          },
        },
      }
    },
    [ActionTypes.SUBMIT_QUOTE_RESET](state) {
      return {
        ...state,
        quote: initialState.quote,
      };
    },
    [ActionTypes.SAVE_FILES_SUCCESS](state) {
      return {
        ...state,
        quote: {
          ...state.quote,
          isUploadingFiles: false,
        }
      }
    },
    [ActionTypes.SAVE_FILES_FAILURE](state) {
      return {
        ...state,
        quote: {
          ...state.quote,
          isUploadingFiles: false,
        }
      }
    },
    [ActionTypes.SUBMIT_QUOTE_RESET](state) {
      return {
        ...state,
        quote: initialState.quote,
      };
    },
    [ActionTypes.SAVE_CUSTOMER_FORM_REQUEST](state) {
      return {
        ...state,
        quote: {
          ...state.quote,
          isUploadingForm: true,
        }
      }
    },
    [ActionTypes.SAVE_CUSTOMER_FORM_FAILURE](state) {
      return {
        ...state,
        quote: {
          ...state.quote,
          isUploadingForm: false,
        }
      }
    },
    [ActionTypes.CHANGE_QUOTE_STATUS](state, action) {
      return {
        ...state,
        quote: {
          ...state.quote,
          data: {
            ...state.quote.data,
            status: action.payload.status,
          }
        }
      }
    },
    [ActionTypes.SAVE_FILES_REQUEST](state, action) {
      const docIds = action.payload.files
        .filter(file => !!file[Object.keys(file)[0]])
        .map(file => {
          const id = Object.keys(file)[0];
          const archivo = file[id].name;
          return { id, archivo };
        });
      return {
        ...state,
        quote: {
          ...state.quote,
          isUploadingFiles: true,
          data: {
            ...state.quote.data,
            documentos: state.quote.data?.documentos?.map(document => {
              const docToUpload = docIds.find(
                fileToUpload => fileToUpload.id === document.id
              );
              if (docToUpload) {
                return {
                  ...document,
                  debeActualizar: 0,
                  archivo: docToUpload.archivo,
                };
              }
              return document;
            }),
          },
        },
      };
    },
    [ActionTypes.FETCH_SOLICITUDES_REQUEST](state) {
      return {
        ...state,
        solicitudes: {
          ...state.solicitudes,
          isLoading: true,
        },
      };
    },
    [ActionTypes.FETCH_SOLICITUDES_SUCCESS](state, action) {
      return {
        ...state,
        solicitudes: {
          ...state.solicitudes,
          data: action.payload.data,
          isLoading: false,
          fetched: true,
        },
        quote: {
          ...state.quote,
          data: action.payload.data[0] || null,
        },
      };
    },
    [ActionTypes.FETCH_SOLICITUDES_FAILURE](state) {
      return {
        ...state,
        solicitudes: {
          ...state.solicitudes,
          isLoading: false,
          error: true,
        },
      };
    },
    [ActionTypes.FETCH_SOLICITUDES_RESET](state) {
      return {
        ...state,
        solicitudes: initialState.solicitudes,
      };
    },
    [ActionTypes.FETCH_PAQUETES_REQUEST](state) {
      return {
        ...state,
        paquetes: {
          isLoading: true,
          data: [],
          isError: false,
          isReady: false,
        },
      };
    },
    [ActionTypes.FETCH_PAQUETES_SUCCESS](state, action) {
      return {
        ...state,
        paquetes: {
          isLoading: false,
          data: action.payload?.data,
          isError: false,
          isReady: true,
        },
      };
    },
    [ActionTypes.FETCH_PAQUETES_FAILURE](state) {
      return {
        ...state,
        paquetes: {
          isLoading: false,
          data: [],
          isError: true,
          isReady: false,
        },
      };
    },
    [ActionTypes.DELETE_FILE_REQUEST](state, action) {
      const { fileId } = action.payload;
      return {
        ...state,
        quote: {
          ...state.quote,
          data: {
            ...state.quote.data,
            documentos: state.quote.data?.documentos?.map(document => {
              if (fileId === document.id) {
                return {
                  ...document,
                  archivo: '',
                  verificado: 0,
                  motivoActualizacion: '',
                  url: null,
                };
              }
              return document;
            }),
          },
        },
      };
    },
    [ActionTypes.SAVE_CUSTOMER_FORM_SUCCESS](state) {
      return {
        ...state,
        customerForm: {
          ...state.customerForm,
          isSent: true,
        },
        quote: {
          ...state.quote,
          isUploadingForm: false,
        }
      };
    },
    [ActionTypes.SAVE_CUSTOMER_FORM_RESET](state) {
      return {
        ...state,
        customerForm: {
          ...state.customerForm,
          isSent: false,
        },
      };
    },
    [UserActionTypes.SIGN_OUT_REQUEST](state) {
      return {
        ...initialState,
        rates: {
          ...state.rates,
        }
      };
    },
    [ActionTypes.FETCH_INFO_CEDULA](state, action) {
      const { isSecond } = action.payload;
      if (isSecond) {
        return {
          ...state,
          users2: {
            ...state.users2,
            isLoading: true,
          },
        };  
      }
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
        },
      };
    },
    [ActionTypes.FETCH_INFO_CEDULA_SUCCESS](state, action) {
      const { isSecond, isThird } = action.payload;
      if (isThird) {
        return {
          ...state,
          users3: {
            ...state.users3,
            data: action.payload.data,
            isLoading: false,
          },
        };
      }
      if (isSecond) {
        return {
          ...state,
          users2: {
            ...state.users2,
            data: action.payload.data,
            isLoading: false,
          },
        };
      }
      return {
        ...state,
        users: {
          ...state.users,
          data: action.payload.data,
          isLoading: false,
        },
      };
    },
    [ActionTypes.FETCH_INFO_CEDULA_FAILURE](state) {
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          error: true,
        },
      };
    },
    [ActionTypes.DOWNLOAD_DTC_REQUEST](state) {
      return {
        ...state,
        dtc: {
          isLoading: true,
        }
      }
    },
    [ActionTypes.DOWNLOAD_DTC_SUCCESS](state) {
      return {
        ...state,
        dtc: {
          isLoading: false,
        }
      }
    },
    [ActionTypes.DOWNLOAD_LETTER_REQUEST](state) {
      return {
        ...state,
        approval: {
          isLoading: true,
        }
      }
    },
    [ActionTypes.DOWNLOAD_APPROVED_LETTER_REQUEST](state) {
      return {
        ...state,
        approval: {
          isLoading: true,
        }
      }
    },
    [ActionTypes.DOWNLOAD_LETTER_SUCCESS](state) {
      return {
        ...state,
        approval: {
          isLoading: false,
        }
      }
    },
    [ActionTypes.DOWNLOAD_APPROVED_LETTER_SUCCESS](state) {
      return {
        ...state,
        approval: {
          isLoading: false,
        }
      }
    },
    [ActionTypes.GET_SOLICITUD_DOCUMENTS](state) {
      return {
        ...state,
        quote: {
          ...state.quote,
          hasDownloadedDocuments: false,
        }
      }
    },
    [ActionTypes.GET_SOLICITUD_DOCUMENTS_SUCCESS](state, action) {
      return {
        ...state,
        quote: {
          ...state.quote,
          hasDownloadedDocuments: true,
          data: {
            ...state.quote.data,
            documentos: action.payload.documents || [],
          }
        }
      }
    }
  }),
};

export default reducer;
