import React from 'react';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import Date from '../../assets/images/date.svg';
import { useBlogData } from '../../ducks/blog/hooks';
import EmptyComponent from '../Empty/EmptyComponent';
import { CustomDot } from '../Testimonial/Testimonial';
import './HomepageBlogs.scss';


const BLOCK = 'homepage-blogs';
export const responsiveCarousel = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 965 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 964, min: 0 },
    items: 1,
  }
};

export const HomepageBlog = ({ blog, blogUrl }) => {
  const { date, featuredImage, categories } = useBlogData(blog);
  
  return (
    <div className={`${BLOCK}__item-container`}>
      <Link to={blogUrl || `/blog/categoria/capsulas-financieras/${blog.id}`} className={`${BLOCK}__item`}>
        {featuredImage && (
          <div style={{ backgroundImage: featuredImage && `url(${featuredImage.source_url})` }} className={`${BLOCK}__item--image`} />
        )}
        <div className={`${BLOCK}__item-content`}>
          <div className={`${BLOCK}__item-date`}>
            <img src={Date} alt="Date" />
            <span>{date}</span>
          </div>
          <div className={`${BLOCK}__item-text`}>
            {categories?.length > 0 && <h4>{categories[0].name}</h4>}
            <h5>{blog?.title?.rendered}</h5>
          </div>
        </div>
      </Link>
    </div>
  );
};

const HomepageBlogs = ({ isLoading, blogs}) => {
  
  return (
    <EmptyComponent isLoading={isLoading} data={blogs} dontShow={true}>
      <div className={BLOCK}>
        <h3>Microcápsulas <span className='gold'>con Daniel Suchar</span></h3>
        <Carousel className={`${BLOCK}__carousel`} responsive={responsiveCarousel} showDots={blogs?.length > 1} customDot={<CustomDot />} arrows={false}>
          {blogs.map(blog => {
            return (
              <HomepageBlog blog={blog} key={blog.id} />
            );
          })}
        </Carousel>
        <Link className={`${BLOCK}__see-more`} to="/blog">Ver más</Link>
      </div>
    </EmptyComponent>
  );
};

export default HomepageBlogs;