// @flow
/**
 * @module Actions/User
 * @desc Actions for User
 */
import ActionTypes from './actionTypes';

/**
 * Set currency request
 * @param {string} currency
 * @returns {Object}
 */
export function setCurrencyRequest(currency) {
  return {
    type: ActionTypes.SET_CURRENCY_REQUEST,
    payload: { currency },
  };
}

/**
 * Set currency success
 *
 * @param {object} user
 * @returns {Object}
 */
export function setCurrencySuccess(user) {
  return {
    type: ActionTypes.SET_CURRENCY_SUCCESS,
    payload: { user },
  };
}

/**
 * Set currency failure
 *
 * @returns {Object}
 */
export function setCurrencyFailure() {
  return {
    type: ActionTypes.SET_CURRENCY_FAILURE,
  };
}

/**
 * Set currency reset
 *
 * @returns {Object}
 */
export function setCurrencyReset() {
  return {
    type: ActionTypes.SET_CURRENCY_RESET,
  };
}

/**
 * Fetch homepage data request
 * @returns {Object}
 */
export function fetchHomepageRequest(currency) {
  return {
    type: ActionTypes.FETCH_HOMEPAGE_REQUEST,
    payload: { currency },
  };
}

/**
 * Fetch homepage data success
 *
 * @param {object} data
 * @returns {Object}
 */
export function fetchHomepageSuccess(data) {
  return {
    type: ActionTypes.FETCH_HOMEPAGE_SUCCESS,
    payload: { data },
  };
}

/**
 * Fetch homepage data failure
 *
 * @returns {Object}
 */
export function fetchHomepageFailure() {
  return {
    type: ActionTypes.FETCH_HOMEPAGE_FAILURE,
  };
}

/**
 * Fetch homepage data reset
 *
 * @returns {Object}
 */
export function fetchHomepageReset() {
  return {
    type: ActionTypes.FETCH_HOMEPAGE_RESET,
  };
}

/**
 * Fetch rates request
 * @returns {Object}
 */
export function fetchRatesRequest() {
  return {
    type: ActionTypes.FETCH_RATES_REQUEST,
  };
}

/**
 * Fetch rates success
 *
 * @param {object} data
 * @returns {Object}
 */
export function fetchRatesSuccess(data) {
  return {
    type: ActionTypes.FETCH_RATES_SUCCESS,
    payload: { data },
  };
}

/**
 * Fetch rates failure
 *
 * @returns {Object}
 */
export function fetchRatesFailure() {
  return {
    type: ActionTypes.FETCH_RATES_FAILURE,
  };
}

/**
 * Fetch rates reset
 *
 * @returns {Object}
 */
export function fetchRatesReset() {
  return {
    type: ActionTypes.FETCH_RATES_RESET,
  };
}

/**
 * Submit quote request
 * @returns {Object}
 */
export function submitQuoteRequest(quote) {
  return {
    type: ActionTypes.SUBMIT_QUOTE_REQUEST,
    payload: { quote },
  };
}

/**
 * Change quote object
 * @returns {Object}
 */
 export function changeQuote(quote) {
  return {
    type: ActionTypes.CHANGE_QUOTE,
    payload: { quote },
  };
}

/**
 * Change quote object
 * @returns {Object}
 */
 export function approveOffer(id) {
  return {
    type: ActionTypes.APPROVE_OFFER,
    payload: { id },
  };
}


/**
 * Change quote object
 * @returns {Object}
 */
 export function approveOfferSuccess() {
  return {
    type: ActionTypes.APPROVE_OFFER_SUCCESS,
  };
}

/**
 * Change quote object
 * @returns {Object}
 */
 export function approveTYC(data) {
  return {
    type: ActionTypes.APPROVE_TYC,
    payload: { ...data },
  };
}


/**
 * Change quote object
 * @returns {Object}
 */
 export function approveTYCSuccess() {
  return {
    type: ActionTypes.APPROVE_TYC_SUCCESS,
  };
}

/**
 * Submit quote success
 *
 * @param {object} data
 * @returns {Object}
 */
export function submitQuoteSuccess(data) {
  return {
    type: ActionTypes.SUBMIT_QUOTE_SUCCESS,
    payload: { data },
  };
}

/**
 * Submit quote failure
 *
 * @returns {Object}
 */
export function submitQuoteFailure() {
  return {
    type: ActionTypes.SUBMIT_QUOTE_FAILURE,
  };
}

/**
 * Submit quote failure
 *
 * @returns {Object}
 */
 export function resetQuote() {
  return {
    type: ActionTypes.RESET_QUOTE,
  };
}

/**
 * Submit quote reset
 *
 * @returns {Object}
 */
export function submitQuoteReset() {
  return {
    type: ActionTypes.SUBMIT_QUOTE_RESET,
  };
}

/**
 * Save files request
 * @returns {Object}
 */
export function saveFilesRequest(files) {
  return {
    type: ActionTypes.SAVE_FILES_REQUEST,
    payload: { files },
  };
}

/**
 * Save files success
 *
 * @param {object} data
 * @returns {Object}
 */
export function saveFilesSuccess(data) {
  return {
    type: ActionTypes.SAVE_FILES_SUCCESS,
    payload: { data },
  };
}

/**
 * Save files failure
 *
 * @returns {Object}
 */
export function saveFilesFailure() {
  return {
    type: ActionTypes.SAVE_FILES_FAILURE,
  };
}

/**
 * Save files reset
 *
 * @returns {Object}
 */
export function saveFilesReset() {
  return {
    type: ActionTypes.SAVE_FILES_RESET,
  };
}

/**
 * Fetch solicitudes request
 * @returns {Object}
 */
export function fetchSolicitudesRequest(files) {
  return {
    type: ActionTypes.FETCH_SOLICITUDES_REQUEST,
    payload: { files },
  };
}

/**
 * Fetch solicitudes success
 *
 * @param {object} data
 * @returns {Object}
 */
export function fetchSolicitudesSuccess(data) {
  return {
    type: ActionTypes.FETCH_SOLICITUDES_SUCCESS,
    payload: { data },
  };
}

/**
 * Fetch solicitudes failure
 *
 * @returns {Object}
 */
export function fetchSolicitudesFailure() {
  return {
    type: ActionTypes.FETCH_SOLICITUDES_FAILURE,
  };
}

/**
 * Fetch operations
 *
 * @returns {Object}
 */
 export function continueFormalization(id) {
  return {
    type: ActionTypes.FORMALIZATION_REQUEST,
    payload: { id }
  };
}

/**
 * Fetch operations
 *
 * @returns {Object}
 */
 export function continueFormalizationSuccess(id) {
  return {
    type: ActionTypes.FORMALIZATION_SUCCESS,
  };
}


/**
 * Fetch operations
 *
 * @returns {Object}
 */
 export function cancelationRequest(id, reason, extra, callback) {
  return {
    type: ActionTypes.CANCELATION_REQUEST,
    payload: { id, reason, extra, callback }
  };
}

/**
 * Fetch operations
 *
 * @returns {Object}
 */
 export function cancelationRequestSuccess(id) {
  return {
    type: ActionTypes.CANCELATION_SUCCESS
  };
}

/**
 * Fetch solicitudes reset
 *
 * @returns {Object}
 */
export function fetchSolicitudesReset() {
  return {
    type: ActionTypes.FETCH_SOLICITUDES_RESET,
  };
}

/**
 * Fetch paquetes request
 * @returns {Object}
 */
export function fetchPaquetesRequest() {
  return {
    type: ActionTypes.FETCH_PAQUETES_REQUEST,
  };
}

/**
 * Fetch paquetes success
 *
 * @param {object} data
 * @returns {Object}
 */
export function fetchPaquetesSuccess(data) {
  return {
    type: ActionTypes.FETCH_PAQUETES_SUCCESS,
    payload: { data },
  };
}

/**
 * Fetch paquetes failure
 *
 * @returns {Object}
 */
export function fetchPaquetesFailure() {
  return {
    type: ActionTypes.FETCH_PAQUETES_FAILURE,
  };
}

/**
 * Fetch paquetes request
 * @returns {Object}
 */
export function savePaquetesRequest(data) {
  return {
    type: ActionTypes.SAVE_PAQUETES_REQUEST,
    payload: { data },
  };
}

/**
 * DOWNLOAD DTR form
 * @returns {Object}
 */
 export function downloadDtc(solicitudId) {
  return {
    type: ActionTypes.DOWNLOAD_DTC_REQUEST,
    payload: { solicitudId },
  };
}

/**
 * DOWNLOAD DTR form
 * @returns {Object}
 */
 export function downloadDtcSuccess() {
  return {
    type: ActionTypes.DOWNLOAD_DTC_SUCCESS,
  };
}


/**
 * Fetch paquetes success
 *
 * @param {object} data
 * @returns {Object}
 */
export function savePaquetesSuccess() {
  return {
    type: ActionTypes.SAVE_PAQUETES_SUCCESS,
  };
}

/**
 * Delete file request
 * @param {String} fileId
 * @returns {Object}
 */
export function deleteFileRequest(fileId) {
  return {
    type: ActionTypes.DELETE_FILE_REQUEST,
    payload: { fileId },
  };
}

/**
 * Delete file success
 *
 * @returns {Object}
 */
export function deleteFileSuccess() {
  return {
    type: ActionTypes.DELETE_FILE_SUCCESS,
  };
}

/**
 * Delete file failure
 *
 * @returns {Object}
 */
export function deleteFileFailure() {
  return {
    type: ActionTypes.DELETE_FILE_FAILURE,
  };
}

/**
 * Delete file reset
 *
 * @returns {Object}
 */
export function deleteFileReset() {
  return {
    type: ActionTypes.DELETE_FILE_RESET,
  };
}
/**
 * Fetch paquetes failure
 *
 * @returns {Object}
 */
export function saveRespuestasRequest(data) {
  return {
    type: ActionTypes.SAVE_RESPUESTAS_REQUEST,
    payload: { data },
  };
}

/**
 * Fetch paquetes request
 * @returns {Object}
 */
export function saveRespuestasSuccess() {
  return {
    type: ActionTypes.SAVE_PAQUETES_SUCCESS,
  };
}

/**
 * Save customer form request
 * @param {object} data
 * @returns {Object}
 */
export function saveCustomerFormRequest(data) {
  return {
    type: ActionTypes.SAVE_CUSTOMER_FORM_REQUEST,
    payload: { data },
  };
}

/**
 * Save customer form success
 *
 * @param {object} data
 * @returns {Object}
 */
export function saveCustomerFormSuccess(data) {
  return {
    type: ActionTypes.SAVE_CUSTOMER_FORM_SUCCESS,
    payload: { data },
  };
}

/**
 * Save customer form failure
 *
 * @returns {Object}
 */
export function saveCustomerFormFailure() {
  return {
    type: ActionTypes.SAVE_CUSTOMER_FORM_FAILURE,
  };
}

/**
 * Save customer form reset
 *
 * @returns {Object}
 */
export function saveCustomerFormReset() {
  return {
    type: ActionTypes.SAVE_CUSTOMER_FORM_RESET,
  };
}


/**
 * Save customer form reset
 *
 * @returns {Object}
 */
 export function requestDocument(operacion, documentos, moneda) {
  return {
    type: ActionTypes.REQUEST_DOCUMENTO_REQUEST,
    payload: {
      id: operacion,
      documentos,
      moneda
    }
  };
}

/**
 * Save customer form reset
 *
 * @returns {Object}
 */
 export function requestDocumentSuccess() {
  return {
    type: ActionTypes.REQUEST_DOCUMENTO_SUCCESS,
  };
}


/**
 * Save customer form reset
 *
 * @returns {Object}
 */
 export function changeFile(file, id) {
  return {
    type: ActionTypes.CHANGE_FILE,
    payload: { file, id },
  };
}

/**
 * Save customer form reset
 *
 * @returns {Object}
 */
 export function changeSolicitudToQuote(quote) {
  return {
    type: ActionTypes.CHANGE_SOLICITUD_TO_QUOTE,
    payload: { quote },
  };
}

  /**
 * Get Info of Cedula
 *
 * @returns {Object}
 */
 export function fetchCedula(data, isSecond = false, isThird = false) {
  return {
    type: ActionTypes.FETCH_INFO_CEDULA,
    payload: { data, isSecond, isThird },
  };
}

  /**
 *Get response of Info DNI
 *
 * @returns {Object}
 */
   export function fetchCedulaSuccess(data, isSecond = false, isThird = false) {
    return {
      type: ActionTypes.FETCH_INFO_CEDULA_SUCCESS,
      payload: { data, isSecond, isThird },
    };
  }
  /**
 * Get Failure of Fetch Cedulas
 *
 * @returns {Object}
 */
export function fetchCedulaFailure(data) {
  return {
    type: ActionTypes.FETCH_INFO_CEDULA_FAILURE,
    payload:{data},
  };
}


/**
 * Fetch operations
 *
 * @returns {Object}
 */
 export function downloadApprovedLetterRequest(id, moneda) {
  return {
    type: ActionTypes.DOWNLOAD_APPROVED_LETTER_REQUEST,
    payload: { id, moneda }
  };
}

/**
 * Fetch operations
 *
 * @returns {Object}
 */
 export function downloadApprovedLetterSuccess() {
  return {
    type: ActionTypes.DOWNLOAD_APPROVED_LETTER_SUCCESS,
  };
}

/**
 * Fetch operations
 *
 * @returns {Object}
 */
 export function downloadLetterRequest({ id, moneda }) {
  return {
    type: ActionTypes.DOWNLOAD_LETTER_REQUEST,
    payload: { id, moneda }
  };
}

/**
 * Fetch operations
 *
 * @returns {Object}
 */
 export function downloadLetterSuccess() {
  return {
    type: ActionTypes.DOWNLOAD_LETTER_SUCCESS,
  };
}

/**
 * Fetch DOCS
 *
 * @returns {Object}
 */
 export function getDocuments(id) {
  return {
    type: ActionTypes.GET_SOLICITUD_DOCUMENTS,
    payload: { id }
  };
}

/**
 * Fetch DOCS success
 *
 * @returns {Object}
 */
 export function getDocumentsSuccess(documents) {
  return {
    type: ActionTypes.GET_SOLICITUD_DOCUMENTS_SUCCESS,
    payload: { documents }
  };
}

export function changeQuoteStatus(status) {
  return {
    type: ActionTypes.CHANGE_QUOTE_STATUS,
    payload: { status }
  }
}