import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getUserByToken } from '../../ducks/actions';
import {
  approveOffer,
  approveTYC, fetchPaquetesRequest, fetchRatesRequest, resetQuote, saveRespuestasRequest,
  setCurrencyRequest,
  submitQuoteRequest
} from '../../ducks/app/actions';
import { useHomePage } from '../../ducks/app/hooks';
import { useHomepagePosts } from '../../ducks/blog/hooks';
import usePrevious from '../../hooks/usePrevious';
import { getTyCText, scrollTo } from '../../utils/helpers';
import Activate from '../Activate';
import Benefits from '../Benefits';
import Button from '../Button';
import CommonDialog from '../CommonDialog/CommonDialog';
import CompletarRegistro from '../CompletarRegistro';
import AppContext from '../Context/AppContext';
import Cotizacion from '../Cotizacion';
import { currencies } from '../Currency/Currency';
import Dialog from '../Dialog';
import HomepageBlogs from '../HomepageBlogs/HomepageBlogs';
import Loader from '../Loader/Loader';
import ProcessingDialog from '../ProcessingDialog';
import Requirements from '../Requirements';
import ResultadoCotizacion from '../ResultadoCotizacion';
import SignInForm from '../SignInForm';
import SuccessDialog from '../SuccessDialog';
import Testimonial from '../Testimonial';
import TipoFinanciamiento from '../TipoFinanciamiento';
import './Flow.scss';

/* Agregado 4/11/22 */
import RecuperarPass from '../RecuperarPass';

const Flow = () => {
  const location = useLocation();
  const [homePosts, isLoadingPosts] = useHomepagePosts();
  const { openRegister, setOpenRegister, openRecuperar, setOpenRecuperar, showBlocked } = useContext(AppContext);
  const {
    calculatorSettings,
  } = useSelector(state => ({
    calculatorSettings: state.app?.rates?.calculatorSettings,
  }));

  const token = new URLSearchParams(location.search).get('token');
  const dispatch = useDispatch();
  const setCurrency = currency => dispatch(setCurrencyRequest(currency));
  const [vehicleType, setVehicleType] = useState('');
  const [amount, setAmount] = useState(0);
  const { currency } = useSelector(state => ({
    currency: state.app.data.currency,
  }));

  const [homepage] = useHomePage();

  const [step, setStep] = useState(1);
  const { isSignedIn, quote, isSigning, paqueteReady, isLoadingRates, isLoadingTokenInfo, tokenInfo } = useSelector(state => ({
    isSignedIn: !!state.user.data,
    userData: state.user.data,
    quote: state.app.quote,
    isSigning: state.user.isSigning,
    paqueteReady: state.app.paquetes?.isReady,
    isLoadingRates: state.app.rates.isLoading,
    isLoadingTokenInfo: state.user.token.isLoading,
    tokenInfo: state.user.token,
  }));


  const [previousUserSignedIn, setPreviousUserSignedIn] = useState(isSignedIn);
  const { isSending: sendingQuote, sent: quoteSent, data } = quote;
  const allLoading = isSigning || isLoadingRates || isLoadingTokenInfo;
  const submitQuote = quote => dispatch(submitQuoteRequest(quote));

  useEffect(() => {
    if (token) {
      dispatch(getUserByToken(token));
    }
    dispatch(resetQuote());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!calculatorSettings) {
      return;
    }
    if (!!calculatorSettings?.useCR && !calculatorSettings.useUSD) {
      setCurrency(currencies.COLON);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatorSettings]);

  useEffect(() => {
    if (!allLoading && !!tokenInfo.data && token) {
      setStep(-1);
    }
  }, [tokenInfo, allLoading, token]);

  useEffect(() => {
    if (step === 5) {
      if (!openRegister) {
        setStep(1)
      }
      return;
    }
    if (openRegister) {
      setStep(5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRegister]);

  useEffect(() => {
    if (step === 11) {
      if (!openRecuperar) {
        setStep(1)
      }
      return;
    }
    if (openRecuperar) {
      setStep(11);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRecuperar]);

  useEffect(() => {
    if (quoteSent) {
      // if (isSignedIn) {
      setStep(7);
      // } else {
      //   setStep(4);
      // }
    }
  }, [isSignedIn, quoteSent]);

  useEffect(() => {
    setPreviousUserSignedIn(isSignedIn);
  }, [isSignedIn]);

  useEffect(() => {
    if (previousUserSignedIn && !isSignedIn) {
      setStep(1);
      setAmount(0);
    }
  }, [isSignedIn, previousUserSignedIn]);

  const isSigningPrevious = usePrevious(isSigning);
  useEffect(() => {
    if (step === 4 && isSigningPrevious && !isSigning && isSignedIn) {
      setStep(2);
    }
  }, [step, isSigningPrevious, isSignedIn, isSigning]);

  useEffect(() => {
    if (!paqueteReady) {
      dispatch(fetchPaquetesRequest());
    }
  }, [dispatch, paqueteReady]);

  useEffect(() => {
    dispatch(fetchRatesRequest());
  }, [dispatch]);

  const submitRespuestas = (value, isOther, id) => {
    dispatch(
      saveRespuestasRequest({
        solicitudId: id,
        opcion: isOther ? 'Otro' : value,
        observacion: !isOther ? '' : value,
      })
    );
  };

  return (
    <>
      <div className="flow">
        <Dialog open={allLoading}>
          <Loader />
        </Dialog>
        <Activate
          open={step === -1}
          onClose={() => setStep(1)}
          user={tokenInfo.data}
          token={token}
          onLogin={() => setStep(4)}
        />
        <TipoFinanciamiento
          currency={currency}
          setCurrency={setCurrency}
          next={data => {
            dispatch(resetQuote());
            setVehicleType(data.type);
            setAmount(data.valor);
            if (isSignedIn) {
              setStep(2);
              scrollTo('.cotizacion__title');
            } else {
              setStep(4);
            }
          }}
          amount={amount}
        />
        {step === 2 && (
          <Cotizacion
            next={quote => {
              if (amount === 0) {
                setStep(1);
                scrollTo('.currency-input');
                return;
              }
              submitQuote({
                email: quote.email,
                id: quote.numeroIdentificacion,
                typeId: quote.tipoIdentificacion,
                fullname: quote.nombre,
                phone: quote.telefono,
                typeApplicant: quote.tipo,
                typeVehicle: vehicleType,
                price: amount,
                representanteNombre: quote.nombreRep,
                representanteCedula: quote.numeroIdentificacionRep,
                representanteTipo: quote.tipoIdentificacionRep,
                representantePhone: quote.telefonoRep,
                representanteEmail: quote.emailRep,
                representanteTipoAplicante: quote.tipoRep,
                moneda: currency?.name,
              });
              // setStep(3);
              // setTimeout(() => {
              //   if (isSignedIn) {
              //     setStep(7);
              //   } else {
              //     setStep(4);
              //   }
              // }, 800);
            }}
            saveTYCchoice={(data) => {
              var current = new Date();
              dispatch(approveTYC({
                cedula: data?.numeroIdentificacion,
                fecha: current.getTime(),
                texto: getTyCText(data?.nombre, '', data?.numeroIdentificacion),
              }));
            }}
          />
        )}

        <ProcessingDialog open={sendingQuote} />
        <SignInForm
          open={!isSigning && !showBlocked && (step === 4 || (step === 10 && !isSignedIn))}
          onSignIn={() => { }}
          openRegister={() => setOpenRegister(true)}
          onClose={() => {
            if (quoteSent) {
              setStep(7);
            } else {
              setStep(2);
            }
          }}
          canSkip={step === 4}
        />
        <CompletarRegistro
          onClose={() => {
            setOpenRegister(false);
          }}
          openLogin={() => {
            setOpenRegister(false);
            setStep(4);
          }}
          open={step === 5}
        />
        <RecuperarPass
          onClose={() => {
            setOpenRecuperar(false);
          }}
          openLogin={() => {
            setOpenRecuperar(false);
            setStep(4);
          }}
          open={step === 11}
        />
        <SuccessDialog
          message="¡Haz realizado tu registro exitosamente!."
          open={step === 6}
          onEnter={() => setStep(7)}
        />
        {step >= 7 && (
          <ResultadoCotizacion
            onReset={() => {
              setStep(1);
            }}
            continueProcess={() => {
              var current = new Date();
              current.setHours(current.getHours() - 6);
              setStep(10);
              dispatch(approveOffer(data?.id));
              // dispatch(approveTYC({
              //   cedula: data?.cliente?.numeroIdentificacion,
              //   fecha: current.getTime(),
              //   texto: getTyCText(userData?.name, userData?.lastname, userData?.dni),
              // }));
            }}
            sendAnswer={(reason, isOther, id) => {
              submitRespuestas(reason, isOther, id);
              setStep(9);
            }}
            goBack={() => {
              setStep(2);
            }}
          />
        )}
        <CommonDialog
          open={step === 9}
          onClose={() => setStep(1)}
          title="¡Respuesta enviada!"
          body=" Un ejecutivo te contactará próximamente para conversar"
          buttons={<Button black onClick={() => setStep(1)} value="Cerrar" />}
        />

        {step >= 10 && isSignedIn && (
          <div className="flow__requirements">
            <Requirements documents={quote?.data?.documentos || []} home={true} />
          </div>
        )}
      </div>
      <Benefits />
      <HomepageBlogs blogs={homePosts} isLoading={isLoadingPosts} />
      <Testimonial testimonios={homepage?.testimonios} />
    </>
  );
};

export default Flow;
