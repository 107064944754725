import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCedula } from '../../ducks/actions';
import Button from '../Button';
import Checkbox2 from '../Checkbox2';
import Input from '../Input';
import MaskedInput from '../MaskedInput';
import Select from '../Select';
import './Cotizacion.scss';

export const ID_TYPES = [
  { label: 'Fisica', value: 'FISICO', placeholder: '' },
  { label: 'Juridica', value: 'JURIDICO', placeholder: '' },
  { label: 'DIMEX', value: 'DIMEX', placeholder: '' },
];

export const TIPOS_APLICANTE = [
  { label: 'Asalariado', value: 'ASALARIADO' },
  { label: 'Independiente', value: 'INDEPENDIENTE' },
  { label: 'Empresa', value: 'EMPRESA' },
  { label: 'Pensionado', value: 'PENSIONADO' },
];

export const ID_DATA = {
  FISICO: { placeholder: '0 0000 0000', format: '# #### ####' },
  JURIDICO: { placeholder: '0000000000', format: '##########' },
  DIMEX: { placeholder: '000000000000', format: '############' },
};

// const ID_TYPE_API =[
//   { label: 'Fisica', value: 'FISICO', id: 'F' },
//   { label: 'Juridica', value: 'JURIDICO', id: 'J' },
//   { label: 'DIMEX', value: 'DIMEX', id: 'D' },
// ]

const Cotizacion = ({ next, saveTYCchoice }) => {
  const { userData, users, users2 } = useSelector(state => ({
    userData: state.user.data,
    users: state.app.users.data,
    users2: state.app.users2.data,
  }));

  const handleDni = () => {
    const idType = ID_TYPES.find(x => x.value === userData?.dni_type?.toUpperCase());
    if (!idType) {
      return ID_TYPES[0].value;
    }
    return idType.value;
  };

  // const handleDniRep = () => {
  //   const idType = ID_TYPES.find(x => x.value === userData?.juridicoInfo?.dni_type?.toUpperCase());
  //   if (!idType) {
  //     return ID_TYPES[0].value;
  //   }
  //   return idType.value;
  // };

  const handleClientType = () => {
    const clientType = TIPOS_APLICANTE.find(x => x.value === userData?.clientType?.toUpperCase());
    if (!clientType) {
      return TIPOS_APLICANTE[0].value;
    }
    return clientType.value;
  };

  const [accept, setAccept] = useState(false);
  const dispatch = useDispatch();

  const getInfoPerson = (e) => dispatch(fetchCedula(e));
  const getInfoPerson2 = (e) => dispatch(fetchCedula(e, true));

  const idRef = useRef(null);
  const idRefTipo = useRef(null);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      numeroIdentificacion: userData?.dni,
      nombre: `${userData?.name || ''} ${userData?.lastname || ''}`,
      email: userData?.email,
      // telefono: userData?.phone,
      telefono: '',
      tipoIdentificacion: handleDni(),
      tipoIdentificacionRep: ID_TYPES[0].value,
      tipo: handleClientType(),
    },
  });

  const handleJuridico = (value) => {
    if (value === 'JURIDICO') {
      if (idRef.current) {
        setTimeout(() => {
          if (!idRef.current) return;
          idRef.current.selectedIndex = 1;
          setValue('tipo', 'EMPRESA');
        }, 100);
      }
    }
  }

  const handleAplicanteRep = (value) => {
    if (!value) {
      return;
    }

    const index = TIPOS_APLICANTE
      .findIndex(x => x.label === value || x.value === value);
    if (index === -1) {
      return;
    }

    if (!idRefTipo.current) {
      return;
    }

    if (idRef.current) {
      setTimeout(() => {
        if (!idRefTipo.current) return;
        idRefTipo.current.selectedIndex = index + 1;
        if (index === 2) {
          return;
        }
        setValue('tipo', value.toUpperCase());
      }, 100);
    }
  }

  const handleAplicante = (value) => {
    if (!value) {
      return;
    }

    const index = TIPOS_APLICANTE
      .findIndex(x => x.label === value || x.value === value);

    if (index === -1) {
      return;
    }

    if (idRef.current) {
      setTimeout(() => {
        if (!idRef.current) return;
        idRef.current.selectedIndex = index + 1;
        if (index === 2) {
          handleJuridico('JURIDICO');
          return;
        }
        setValue('tipo', value.toUpperCase());
      }, 100);
    }
  };

  useEffect(() => {
    if (userData?.dni_type) {
      handleJuridico(userData.dni_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    setValue('numeroIdentificacion', userData?.dni);
    setValue('nombre', userData?.name ?
      `${userData?.name || ''} ${userData?.lastname || ''}` : null);
    setValue('email', userData?.email);
    setValue('telefono', userData?.phone);
    setValue('tipoIdentificacion', handleDni());
    setValue('tipoIdentificacionRep', ID_TYPES[0].value);
    setValue('tipo', handleClientType());
    if (userData?.dni_type?.toUpperCase() === 'JURIDICO') {
      setValue('numeroIdentificacionRep', userData?.juridicoInfo?.representanteId);
      setValue('nombreRep', (!!userData?.juridicoInfo?.representanteNombre) ?
        `${userData?.juridicoInfo?.representanteNombre} ${userData?.juridicoInfo?.representanteApellido}`
        : (userData?.juridicoInfo?.representantJuridicoNombre ?? null));
      setValue('emailRep', userData?.juridicoInfo?.representanteEmail);
      setValue('telefonoRep', userData?.juridicoInfo?.representanteTelCel);
      // Means user is juridico
      if (userData?.juridicoInfo?.representanteTipo?.toLocaleLowerCase()?.indexOf('juridic') >= 0) {
        setValue('tipoIdentificacionRep', ID_TYPES[1].value);
        handleAplicanteRep(TIPOS_APLICANTE[2].value);
      } else {
        if (!!userData?.juridicoInfo?.representanteTipo) {
          handleAplicanteRep(userData?.juridicoInfo?.representanteClienteTipo?.toUpperCase());
        }
      }
    }
    handleAplicante(userData?.clientType?.toUpperCase());
    //setValue('nombre', users[0]?.fullname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, setValue]);

  useEffect(() => {
    if (!users || users?.length <= 0) {
      return;
    }

    setValue('nombre', users[0]?.fullname);
  }, [users, setValue]);

  useEffect(() => {
    if (!users2 || users2?.length <= 0) {
      return;
    }

    setValue('nombreRep', users2[0]?.fullname);
  }, [users2, setValue]);

  const onSubmit = data => {
    saveTYCchoice(data);
    next(data);
  };

  const idData = ID_DATA[watch('tipoIdentificacion')];
  const repIdData = ID_DATA[watch('tipoIdentificacionRep')];

  const showRep = watch('tipoIdentificacion') === ID_TYPES[1].value;
  const hasError = () => {
    return !accept;
  };

  return (
    <div className="cotizacion">
      <div className="cotizacion__title">Cotización</div>
      <form className="cotizacion__content" onSubmit={handleSubmit(onSubmit)}>
        <div className="cotizacion__group">
          <div className="cotizacion__row cotizacion__row--mobile">
            <div className="cotizacion__column">
              <Select
                label="Tipo de identificación"
                hasError={!!errors.tipo}
                {...register('tipoIdentificacion', { required: 'Requerido' })}
                options={ID_TYPES}
                onChange={({ target: { value } }) => {
                  setValue('tipoIdentificacion', value);
                  handleJuridico(value);
                }}
              />
            </div>
            <div className="cotizacion__column">
              <Controller
                rules={{ required: true }}
                name="numeroIdentificacion"
                control={control}
                render={({ field }) => (
                  <MaskedInput
                    label="Número de identificación"
                    placeholder={idData?.placeholder}
                    onChange={({ value }) => {
                      setValue('numeroIdentificacion', value);
                      if ((value.length === 9 && idData.placeholder.length === 11) ||
                        value.length === idData.placeholder.length) {
                        getInfoPerson(value);
                      }

                      if (errors.numeroIdentificacion && !!value) {
                        clearErrors('numeroIdentificacion');
                      }
                    }}

                    format={idData?.format}
                    value={field.value}
                    hasError={!!errors.numeroIdentificacion}
                  />
                )}
              />
            </div>
          </div>

          <div className="cotizacion__row cotizacion__row--mobile">
            <div className="cotizacion__column">
              <Input
                label={watch('tipoIdentificacion') !== ID_TYPES[1].value
                  ? "Nombre y apellidos" : "Nombre de la empresa"}
                placeholder="ej. Roberto Castro"
                hasError={!!errors.nombre}
                {...register('nombre', { required: 'Requerido' })}
              />
            </div>
            <div className="cotizacion__column">
              <Select
                label="Tipo de aplicante"
                hasError={!!errors.tipo}
                {...register('tipo', { required: 'Requerido' })}
                ref={idRef}
                options={TIPOS_APLICANTE.filter(aplicante => {
                  if (watch('tipoIdentificacion') === 'JURIDICO') {
                    return aplicante.value === 'EMPRESA';
                  } else {
                    return aplicante.value !== 'EMPRESA';
                  }
                })}
              />
            </div>
          </div>
          <div className="cotizacion__row cotizacion__row--mobile">
            <div className="cotizacion__column">
              <Input
                label="Correo electrónico"
                placeholder="correo@mail.com"
                hasError={!!errors.email}
                {...register('email', {
                  required: 'Requerido',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Ingrese un email válido"
                  }
                })}
              />
            </div>
            <div className="cotizacion__column">
              <Input
                label="Número de teléfono"
                placeholder="ej. 83899019"
                maxLength={8}
                hasError={!!errors.telefono}
                {...register('telefono', {
                  required: 'Requerido',
                })}
              />
            </div>
          </div>
        </div>

        {watch('tipoIdentificacion') === ID_TYPES[1].value && (
          <div className="cotizacion__legal cotizacion__group">
            <h4 className="cotizacion__subtitle">Representante legal</h4>
            <div className="cotizacion__row cotizacion__row--mobile">
              <div className="cotizacion__column">
                <Select
                  label="Tipo de identificación"
                  hasError={!!errors.tipoIdentificacionRep}
                  {...register('tipoIdentificacionRep', { required: showRep })}
                  options={ID_TYPES}
                />
              </div>
              <div className="cotizacion__column">
                <Controller
                  name="numeroIdentificacionRep"
                  control={control}
                  rules={{ required: showRep }}
                  render={({ field }) => (
                    <MaskedInput
                      label="Número de identificación"
                      placeholder={repIdData?.placeholder}
                      onChange={({ value }) => {
                        setValue('numeroIdentificacionRep', value);
                        if ((value.length === 9 && repIdData.placeholder.length === 11) ||
                          value.length === repIdData.placeholder.length) {
                          getInfoPerson2(value);
                        }

                        if (errors.numeroIdentificacionRep && !!value) {
                          clearErrors('numeroIdentificacionRep');
                        }
                      }}
                      value={field.value}
                      format={repIdData?.format}
                      hasError={!!errors.numeroIdentificacionRep}
                    />
                  )}
                />
              </div>
            </div>
            <div className="cotizacion__row cotizacion__row--mobile">
              <div className="cotizacion__column">
                <Input
                  hasError={!!errors.nombreRep}
                  {...register('nombreRep', { required: showRep })}
                  label={watch('tipoIdentificacionRep') !== ID_TYPES[1].value
                    ? "Nombre y apellidos" : "Nombre de la empresa"}
                />
              </div>
              <div className="cotizacion__column">
                <Select
                  label="Tipo de aplicante"
                  hasError={!!errors.tipoRep}
                  {...register('tipoRep', { required: showRep })}
                  ref={idRefTipo}
                  options={TIPOS_APLICANTE.filter(aplicante => {
                    if (watch('tipoIdentificacionRep') === 'JURIDICO') {
                      return aplicante.value === 'EMPRESA';
                    } else {
                      return aplicante.value !== 'EMPRESA';
                    }
                  })}
                />
              </div>
            </div>
            <div className="cotizacion__row cotizacion__row--mobile">
              <div className="cotizacion__column">
                <Input
                  label="Correo electrónico"
                  placeholder="correo@mail.com"
                  hasError={!!errors.emailRep}
                  {...register('emailRep', { required: showRep })}
                />
              </div>
              <div className="cotizacion__column">
                <Input
                  label="Número de teléfono"
                  placeholder="ej. 83899019"
                  maxLength={8}
                  hasError={!!errors.telefonoRep}
                  {...register('telefonoRep', {
                    required: showRep,
                  })}
                />
              </div>
            </div>
          </div>
        )}
      </form>
      <div className="cotizacion__submit-container">
        <div className="cotizacion__submit-container-left">
          <Checkbox2
            name="checkbox"
            checked={accept}
            setChecked={status => setAccept(status)}
          />{' '}
          <a
            className="cotizacion__submit-container-left-link"
            href="/wp-content/uploads/docs/Terminos y Condiciones AGE Capital Generales.pdf"
            target="_blank"
          >
            He leído los términos y condiciones
          </a>
        </div>
        <div className="cotizacion__submit-container-right">
          <Button
            name="submit"
            disabled={hasError()}
            onClick={handleSubmit(onSubmit)}
            value="Enviar"
          />
        </div>
      </div>
    </div>

  );
};

export default Cotizacion;
