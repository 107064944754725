// @flow

import { ofType } from 'redux-observable';
import { of, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { BaseApiService } from '../../utils/BaseApiService';
import {
  approveOfferSuccess,
  approveTYCSuccess,
  deleteFileFailure,
  deleteFileSuccess,
  downloadDtcSuccess,
  fetchHomepageFailure,
  fetchHomepageSuccess,
  fetchPaquetesFailure,
  fetchPaquetesSuccess,
  fetchRatesFailure,
  fetchRatesSuccess,
  fetchSolicitudesFailure,
  fetchSolicitudesSuccess,
  requestDocumentSuccess,
  saveCustomerFormFailure,
  saveCustomerFormSuccess,
  saveFilesFailure,
  saveFilesSuccess,
  savePaquetesSuccess,
  saveRespuestasSuccess,
  submitQuoteFailure,
  submitQuoteSuccess,
  fetchCedulaSuccess,
  fetchCedulaFailure,
  continueFormalizationSuccess,
  cancelationRequestSuccess,
  downloadLetterSuccess,
  getDocumentsSuccess,
} from './actions';
import { DownloadFile } from '../../utils/helpers';
import ActionTypes from './actionTypes';

/**
 * @module Epics/app
 * @desc app
 */
export function fetchHomepage(action$) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_HOMEPAGE_REQUEST),
    mergeMap(action => {
      return BaseApiService.get('/content/homepage/', {
        email: action.payload.email,
        password: action.payload.password,
      }).pipe(
        map(response => fetchHomepageSuccess(response?.response?.data)),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'fetchHomepage', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(fetchHomepageFailure(), ...actions)
        )
      );
    })
  );
}

/**
 * @module Epics/app
 * @desc app
 */
export function fetchRates(action$) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_RATES_REQUEST),
    mergeMap(action => {
      return BaseApiService.get('/tasas/all/').pipe(
        map(response => fetchRatesSuccess(response?.response)),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'fetchRates', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(fetchRatesFailure(), ...actions)
        )
      );
    })
  );
}

/**
 * @module Epics/app
 * @desc app
 */
export function submitQuote(action$) {
  return action$.pipe(
    ofType(ActionTypes.SUBMIT_QUOTE_REQUEST),
    mergeMap(action => {
      return BaseApiService.post(
        '/solicitud/crear/',
        action.payload.quote
      ).pipe(
        map(response => submitQuoteSuccess(response?.response?.data)),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'submitQuote', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(submitQuoteFailure(), ...actions)
        )
      );
    })
  );
}

/**
 * @module Epics/app
 * @desc app
 */
export function saveFiles(action$) {
  return action$.pipe(
    ofType(ActionTypes.SAVE_FILES_REQUEST),
    mergeMap(action => {
      // const data = action.payload.files?.map(file => {
      //   const key = Object.keys(file)[0];
      //   const formData = new FormData();
      //   formData.append(file[key]);
      //   return {
      //     [key]: formData,
      //   };
      // });
      const data = new FormData();
      action.payload.files?.forEach(file => {
        const key = Object.keys(file)[0];
        if (file[key]) {
          data.append(key, file[key]);
        }
      });
      return from(
        BaseApiService.axiosClient().post('/solicitud/subir/', data)
      ).pipe(
        map(response => saveFilesSuccess(response?.response?.data)),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'saveFiles', action)
        ),
        catchError(({ actions = [] }) => of(saveFilesFailure(), ...actions))
      );
    })
  );
}

/**
 * @module Epics/app
 * @desc app
 */
export function fetchSolicitudes(action$) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_SOLICITUDES_REQUEST),
    mergeMap(action => {
      return BaseApiService.get('/solicitud/activas/').pipe(
        map(response => fetchSolicitudesSuccess(response?.response?.data)),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'fetchSolicitudes', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(fetchSolicitudesFailure(), ...actions)
        )
      );
    })
  );
}

/**
 * @module Epics/app
 * @desc app
 */
export function fetchPaquetesRequest(action$) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_PAQUETES_REQUEST),
    mergeMap(action => {
      return BaseApiService.get('/paquetes/all/').pipe(
        map(response => fetchPaquetesSuccess(response?.response?.data)),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'fetchPaquetes', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(fetchPaquetesFailure(), ...actions)
        )
      );
    })
  );
}

/**
 * @module Epics/app
 * @desc app
 */
export function savePaquetesRequest(action$) {
  return action$.pipe(
    ofType(ActionTypes.SAVE_PAQUETES_REQUEST),
    mergeMap(action => {
      return BaseApiService.post('/paquetes/save/', action.payload.data).pipe(
        map(response => savePaquetesSuccess()),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'fetchPaquetes', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(savePaquetesSuccess(), ...actions)
        )
      );
    })
  );
}

export function downloadLetterDTC(action$) {
  return action$.pipe(
    ofType(ActionTypes.DOWNLOAD_DTC_REQUEST),
    mergeMap(action => {
      return BaseApiService.post(
        `/solicitud/${action.payload.solicitudId}/descargar_dtr/`,
        null,
        null,
        {
          'Content-Type': 'application/octet-stream',
        },
        true,
        'blob'
      ).pipe(
        map(response => {
          const url = window.URL.createObjectURL(new Blob([response.response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Formulario DTR.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
          return downloadDtcSuccess();
        }),
        catchError(
          BaseApiService.getErrorHandler('userEpic', 'downloadLetter', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(downloadDtcSuccess(), ...actions)
        )
      );
    })
  );
}
/**
 * @module Epics/app
 * @desc app
 */
export function deleteFile(action$) {
  return action$.pipe(
    ofType(ActionTypes.DELETE_FILE_REQUEST),
    mergeMap(action => {
      return BaseApiService.post(
        `/solicitud/remover/${action.payload.fileId}`,
        action.payload.data
      ).pipe(
        map(response => deleteFileSuccess()),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'deleteFile', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(deleteFileFailure(), ...actions)
        )
      );
    })
  );
}

/**
 * @module Epics/app
 * @desc app
 */
export function saveRespuestasRequest(action$) {
  return action$.pipe(
    ofType(ActionTypes.SAVE_RESPUESTAS_REQUEST),
    mergeMap(action => {
      return BaseApiService.post(
        '/solicitud/respuestas/',
        action.payload.data
      ).pipe(
        map(response => saveRespuestasSuccess()),
        catchError(
          BaseApiService.getErrorHandler(
            'appEpic',
            'saveRespuestasRequest',
            action
          )
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(saveRespuestasSuccess(), ...actions)
        )
      );
    })
  );
}

/**
 * @module Epics/app
 * @desc app
 */
export function saveCustomerFormRequest(action$) {
  return action$.pipe(
    ofType(ActionTypes.SAVE_CUSTOMER_FORM_REQUEST),
    mergeMap(action => {
      return BaseApiService.post(
        '/solicitud/savePersonal/',
        action.payload.data
      ).pipe(
        map(response => saveCustomerFormSuccess()),
        catchError(
          BaseApiService.getErrorHandler(
            'appEpic',
            'saveCustomerFormRequest',
            action
          )
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(saveCustomerFormFailure(), ...actions)
        )
      );
    })
  );
}

/**
 * @module Epics/app
 * @desc app
 */
export function approveOffer(action$) {
  return action$.pipe(
    ofType(ActionTypes.APPROVE_OFFER),
    mergeMap(action => {
      return BaseApiService.post(
        `/solicitud/${action.payload.id}/approve`
      ).pipe(
        map(() => approveOfferSuccess()),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'approveOffer', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(approveOfferSuccess(), ...actions)
        )
      );
    })
  );
}

/**
 * @module Epics/app
 * @desc app
 */
export function approveTYC(action$) {
  return action$.pipe(
    ofType(ActionTypes.APPROVE_TYC),
    mergeMap(action => {
      return BaseApiService.post(`/solicitud/tyc`, { ...action.payload }).pipe(
        map(() => approveTYCSuccess()),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'approveTYC', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(approveTYCSuccess(), ...actions)
        )
      );
    })
  );
}

/**
 * @module Epics/app
 * @desc app
 */
export function requestDocument(action$) {
  return action$.pipe(
    ofType(ActionTypes.REQUEST_DOCUMENTO_REQUEST),
    mergeMap(action => {
      return BaseApiService.post(`/operaciones/envio/${action.payload.id}`, {
        documentos: action.payload.documentos,
        moneda: action.payload.moneda
      }).pipe(
        map(() => requestDocumentSuccess()),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'requestDocument', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(requestDocumentSuccess(), ...actions)
        )
      );
    })
  );
}

/**
 * @module Epics/app
 * @desc app
 */
 export function fetchInfoCedula(action$) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_INFO_CEDULA),
    switchMap(action => {
      return BaseApiService.post(`/user/getcedula/?id=${action.payload.data}`).pipe(
        map(response => fetchCedulaSuccess(response?.response?.data, action.payload.isSecond, action.payload.isThird)),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'fetchCedula', action)
        ),
        catchError(
          ({ actions = [] }) =>
            of(fetchCedulaFailure(), ...actions)
        )
      );
    })
  );
}

export function cancelationRequestMethod(action$) {
  return action$.pipe(
    ofType(ActionTypes.CANCELATION_REQUEST),
    mergeMap(action => {
      return BaseApiService.post(
        `/solicitud/${action.payload.id}/cancelar`, {
          motivo: action.payload.reason,
          extra: action.payload.extra,
        }
      ).pipe(
        map(() => {
          action.payload.callback();
          return cancelationRequestSuccess()}),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'cancelationRequestMethod', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) => {
            action.payload.callback();
            return of(cancelationRequestSuccess(), ...actions);
          }
            
        )
      );
    })
  );
}

export function continueFormalizationMethod(action$) {
  return action$.pipe(
    ofType(ActionTypes.FORMALIZATION_REQUEST),
    mergeMap(action => {
      return BaseApiService.post(
        `/solicitud/${action.payload.id}/formalizar`
      ).pipe(
        map(() => continueFormalizationSuccess()),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'continueFormalizationMethod', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(continueFormalizationSuccess(), ...actions)
        )
      );
    })
  );
}


export function downloadLetter(action$) {
  return action$.pipe(
    ofType(ActionTypes.DOWNLOAD_LETTER_REQUEST),
    mergeMap(action => {
      return BaseApiService.post(`/solicitud/${action.payload.id}/descargar/?moneda=${action.payload.moneda}`,
      null,
      null,
      {
        //'Content-Disposition': 'attachment; filename=' + action.payload.id + '.pdf',
        'Content-Type': 'application/octet-stream'
      },
      true,
      'blob'
      ).pipe(
        map((response) => {
          DownloadFile(response.response, 'Carta de PreAprobación.pdf');
          return downloadLetterSuccess()
        }),
        catchError(
          BaseApiService.getErrorHandler('userEpic', 'downloadLetter', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(downloadLetterSuccess(), ...actions)
        )
      );
    })
  );
}

export function downloadApprovedLetter(action$) {
  return action$.pipe(
    ofType(ActionTypes.DOWNLOAD_APPROVED_LETTER_REQUEST),
    mergeMap(action => {
      return BaseApiService.post(`/solicitud/${action.payload.id}/descargar_aprobacion/?moneda=${action.payload.moneda}`,
      null,
      null,
      {
        //'Content-Disposition': 'attachment; filename=' + action.payload.id + '.pdf',
        'Content-Type': 'application/octet-stream'
      },
      true,
      'blob'
      ).pipe(
        map((response) => {
          DownloadFile(response.response, 'Carta de Aprobación.pdf');
          console.log();
          return downloadLetterSuccess()
        }),
        catchError(
          BaseApiService.getErrorHandler('userEpic', 'downloadApprovedLetter', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(downloadLetterSuccess(), ...actions)
        )
      );
    })
  );
}

export function getDocumentsEpic(action$) {
  return action$.pipe(
    ofType(ActionTypes.GET_SOLICITUD_DOCUMENTS),
    mergeMap(action => {
      return BaseApiService.get(`/solicitud/${action.payload.id}/documentos/`).pipe(
        map(response => getDocumentsSuccess(response?.response?.data)),
        catchError(
          BaseApiService.getErrorHandler('appEpic', 'getDocumentsEpic', action)
        ),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(getDocumentsSuccess([]), ...actions)
        )
      );
    })
  );
}