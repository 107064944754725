import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { checkUserLoggedInRequest } from '../../ducks/actions';
import { FB_PIXEL, GA } from '../../utils/constants';
import Blog from '../Blog';
import Dashboard from '../Dashboard';
import Faq from '../Faq';
import Profile from '../Profile';
import RedirectTo from '../RedirectTo';
import Solicitar from '../Solicitar';
import './MainRouter.scss';

const MainRouter = () => {
  const { user } = useSelector(state => ({ user: state.user }));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkUserLoggedInRequest());
  }, [dispatch]);

  const isLoggedIn = !!user.data;

  const history = useHistory();

  useEffect(() => {
    if (!history) {
      return;
    }
    ReactPixel.init(FB_PIXEL);
    ReactPixel.pageView();
    ReactGA.initialize(GA);
    ReactGA.pageview(window.location.pathname);
    history.listen(() => {
      ReactPixel.pageView();
      ReactGA.pageview(window.location.pathname);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      {isLoggedIn && (
        <Route path="/dashboard">
          <Dashboard />
        </Route>
      )}
      {isLoggedIn && (
        <Route path="/perfil">
          <Profile />
        </Route>
      )}
      <Route path="/preguntas-frecuentes">
        <Faq />
      </Route>
      <Route path="/solicitar">
        <Solicitar />
      </Route>
      <Route path="/redirect">
        <RedirectTo />
      </Route>
      <Route path="/blog">
        <Blog />
      </Route>
      <Route path="/">
        <Solicitar />
      </Route>
      {/* {user.isReady && isLoggedIn && <Redirect from="/" to="/dashboard" />} */}
      {user.isReady && <Redirect to="/solicitar" />}
      {/* {user.isReady && redirect} */}
    </Switch>
  );
};

export default MainRouter;
