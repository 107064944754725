import classNames from 'classnames';
import React from 'react';
import './DeleteIcon.scss';

const DeleteIcon = ({ disabled }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.86" height="18.297" viewBox="0 0 14.86 18.297" className={classNames('delete-icon', { 'delete-icon--disabled': disabled })}>
    <g id="delete" transform="translate(0.003 0.001)">
      <path id="Path_13826" data-name="Path 13826" d="M222.827,154.7a.428.428,0,0,0-.429.428v8.1a.428.428,0,0,0,.857,0v-8.1A.428.428,0,0,0,222.827,154.7Zm0,0" transform="translate(-212.872 -148.075)" fill="#939393"/>
      <path id="Path_13827" data-name="Path 13827" d="M104.827,154.7a.428.428,0,0,0-.428.428v8.1a.428.428,0,0,0,.857,0v-8.1A.428.428,0,0,0,104.827,154.7Zm0,0" transform="translate(-99.928 -148.075)" fill="#939393"/>
      <path id="Path_13828" data-name="Path 13828" d="M1.214,5.446V16a2.364,2.364,0,0,0,.629,1.63,2.11,2.11,0,0,0,1.531.662h8.107a2.11,2.11,0,0,0,1.531-.662A2.364,2.364,0,0,0,13.64,16V5.446a1.637,1.637,0,0,0-.42-3.219H11.026V1.691A1.683,1.683,0,0,0,9.329,0h-3.8a1.683,1.683,0,0,0-1.7,1.693v.536H1.634a1.637,1.637,0,0,0-.42,3.219ZM11.481,17.438H3.373A1.357,1.357,0,0,1,2.071,16V5.483H12.783V16A1.357,1.357,0,0,1,11.481,17.438ZM4.685,1.691a.826.826,0,0,1,.84-.836h3.8a.826.826,0,0,1,.84.836v.536H4.685ZM1.634,3.084H13.22a.771.771,0,1,1,0,1.543H1.634a.771.771,0,0,1,0-1.543Zm0,0" transform="translate(0 0)" fill="#939393"/>
      <path id="Path_13829" data-name="Path 13829" d="M163.827,154.7a.428.428,0,0,0-.429.428v8.1a.428.428,0,0,0,.857,0v-8.1A.428.428,0,0,0,163.827,154.7Zm0,0" transform="translate(-156.4 -148.075)" fill="#939393"/>
    </g>
  </svg>

);

export default DeleteIcon;
