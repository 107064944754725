import React from 'react';
import ProfileHeader from '../ProfileHeader/ProfileHeader';
import './ProfileSection.scss';

const ProfileSection = ({ children, title, asset, extraTitle }) => (
  <>
  {extraTitle && <h2 className="profile-section--extra-title">{extraTitle}</h2>}
  <div className="profile-section">
    {title && <ProfileHeader title={title} asset={asset} />}
    {children}
  </div>
  </>
);

export default ProfileSection;
