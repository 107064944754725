import { useAllBlogs } from "../../ducks/blog/hooks";
import { BlogCategoryItem } from "../BlogCategory/BlogCategory";
import { BlogWrapper } from "../BlogCategory/BlogCategoryWrapper";
import EmptyComponent from "../Empty/EmptyComponent";

const BlogAll = () => {

  const [blogs, isLoading] = useAllBlogs();
  return (
    <BlogWrapper title={
      <>Bienvenidos a nuestras<br />Microcápsulas con Daniel Suchar</>
    } subtitle={'¡Estamos aquí para ayudarte! Aprendé con AGE Capital.'}>
      <EmptyComponent data={blogs} isLoading={isLoading} message={"Esta categoría todavía no tiene posts"}>
        <div className="blog-category__content-posts blog-category__content-posts--grid">
          {blogs.map(blog => <BlogCategoryItem key={blog.id} blog={blog} />)}
        </div>
      </EmptyComponent>

    </BlogWrapper>
  );
};

export default BlogAll;