import { default as classNames, default as cx } from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Arrow from '../../assets/images/arrow-left.png';
import Close from '../../assets/images/close.png';
import Download from '../../assets/images/download.png';
import Whatsapp from '../../assets/images/whatsapp.png';
import {
  downloadLetterRequest,
  savePaquetesRequest,
  submitQuoteReset
} from '../../ducks/actions';
import { results, WHATSAPP_LINK } from '../../utils/constants';
import Button from '../Button';
import Checkbox from '../Checkbox';
import CurrencyFormat from '../CurrencyFormat';
import EmptyComponent from '../Empty/EmptyComponent';
import RadioButtons from '../RadioButtons/RadioButtons';
import ResultBadge from '../ResultBadge/ResultBadge';
import './ResultadoCotizacion.scss';

const Vehicle = ({ vehicle, vehiclePicked, setVehiclePicked }) => (
  <div className="vehicle">
    <div className="vehicle-header">
      <img className="vehicle-image" src={vehicle.imagen} alt="Vehicle" />
      <div className="vehicle-checkbox">
        <Checkbox
          gold
          checked={vehiclePicked?.id === vehicle.id}
          setChecked={checked => {
            if (checked) {
              setVehiclePicked(vehicle);
            } else {
              setVehiclePicked(null);
            }
          }}
        />
      </div>
    </div>
    <div className="vehicle-content">
      <div className="vehicle-title">{vehicle.nombre}</div>
      <div className="vehicle-text">{vehicle.descripcion}</div>
      <div className="vehicle-price">
        <div>Tarifa por mes</div>
        <CurrencyFormat value={vehicle.tarifaPorMes || 0} />
      </div>
    </div>
  </div>
);

const ResultadoCotizacion = ({
  continueProcess,
  sendAnswer,
  goBack,
  onReset,
}) => {
  const { result, paquetesObject, loadingApproval } = useSelector(state => ({
    result: state.app.quote.data,
    paquetesObject: state.app.paquetes,
    loadingApproval: state.app.approval.isLoading,

  }));
  const cuota = result?.cuotaOfertaInicial || 0;
  const cuotaGps = result?.cuotaGps || 0;
  const resultBadge = results.APPROVED;
  const [showAlternativas, setShowAlternativas] = useState(false);
  const [reason, setReason] = useState('');
  const [isOther, setIsOther] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (downloading && !loadingApproval) {
      setDownloading(false);
    }
  }, [loadingApproval, downloading]);

  const downloadLetter = quote => {
    dispatch(downloadLetterRequest(quote));
  };

  const reset = () => {
    dispatch(submitQuoteReset());
  };

  const savePaquetes = () => {
    if (!paquetes.length) {
      return;
    }
    dispatch(
      savePaquetesRequest(
        paquetes.map(paquete => {
          const payload = {
            creditoId: result?.id,
            paqueteId: paquete.id,
            monto: paquete.carroMonto1,
            plazo: '',
          };
          const isAlquiler = (paquete.nombre || '')
            .toLowerCase()
            .includes('alquiler');
          if (isAlquiler) {
            payload.vehiculo = `Vehiculo: ${vehicle?.nombre}`;
            payload.monto = vehicle.tarifaPorMes;
          }
          return payload;
        })
      )
    );
  };

  // const vehiclesObject = {
  //   data: [
  //     {
  //       id: 1,
  //       nombre: 'Mitshubishi ASX',
  //       transmision: 'Manual',
  //       pasajeros: 7,
  //       combustible: 'gasolina',
  //       tarifaPorMes: 22.42,
  //       imagen:
  //         'https://veinsacr.quickbase.com/up/brrvkbicy/a/r2/e14/v0/alquiler.b4c86e5a.png',
  //     },
  //   ],
  // };
  const adicionalAlquiler =
    paquetesObject.data?.find(paquete =>
      (paquete.nombre || '').toLowerCase().includes('alquiler')
    ) || {};
  const rentVehicles = Object.keys(adicionalAlquiler).reduce(
    (previous, current) => {
      if (current.includes('carroNombre')) {
        const index = current[current.length - 1];
        return [
          ...previous,
          {
            id: index,
            nombre: adicionalAlquiler[current],
            descripcion: adicionalAlquiler[`carroDesc${index}`],
            tarifaPorMes: adicionalAlquiler[`carroMonto${index}`],
            imagen: adicionalAlquiler[`carroImagen${index}`],
          },
        ];
      }
      return previous;
    },
    []
  );

  const [paquetes, setPaquetes] = useState([]);
  const [vehicle, setVehicle] = useState(null);
  const [showAlquiler, setShowAlquiler] = useState(false);

  const hasAlquilerPicked = paquetes.some(paquete =>
    (paquete.nombre || '').toLowerCase().includes('alquiler')
  );

  const hasError = () => {
    return hasAlquilerPicked && !vehicle;
  };

  const clickAdicional = (checked, paquete, isAlquiler) => {
    if (checked) {
      setPaquetes([...paquetes, paquete]);
    } else {
      setPaquetes([
        ...paquetes.filter(x => x.id !== paquete.id),
      ]);
    }
    if (isAlquiler) {
      setShowAlquiler(checked);
    }
  };

  return (
    <div className="resultado-cotizacion">
      <div className="resultado-cotizacion__title-container">
        <h2 className="resultado-cotizacion__title">
          Resultado de tu cotización
        </h2>
        <p className="resultado-cotizacion__text">
          Por favor leé con atención las condiciones que te especificamos a
          continuación:
        </p>
      </div>
      <div className="resultado-cotizacion__summary">
        <div className="resultado-cotizacion__summary-table">
          <div className="resultado-cotizacion__summary-title">
            Cuota mensual total
          </div>
          <div className="resultado-cotizacion__summary-content">
            <div className="resultado-cotizacion__summary-content-1">
              <div className="resultado-cotizacion__summary-row">
                <div className="resultado-cotizacion__summary-label">
                  Cuota financiera:
                </div>
                <div className="resultado-cotizacion__summary-value">
                  <CurrencyFormat currentCurrency={result?.moneda} value={cuota} />
                </div>
              </div>
              <div className="resultado-cotizacion__summary-row">
                <div className="resultado-cotizacion__summary-label">
                  Cuota GPS/administración:
                </div>
                <div className="resultado-cotizacion__summary-value">
                  <CurrencyFormat currentCurrency={result?.moneda} value={cuotaGps} />
                </div>
              </div>
              <div className="resultado-cotizacion__summary-row">
                <div className="resultado-cotizacion__summary-label">
                  Cuota seguro vida:
                </div>
                <div className="resultado-cotizacion__summary-value">
                  <CurrencyFormat currentCurrency={result?.moneda} value={result?.seguroVida} />
                </div>
              </div>
              <div className="resultado-cotizacion__summary-row">
                <div className="resultado-cotizacion__summary-label">
                  Cuota seguro vehículo:
                </div>
                <div className="resultado-cotizacion__summary-value">
                  <CurrencyFormat currentCurrency={result?.moneda} value={result?.seguroVehiculo} />
                </div>
              </div>
              <div className="resultado-cotizacion__summary-row">
                <div className="resultado-cotizacion__summary-label">
                  Cuota seguro incapacidad:
                </div>
                <div className="resultado-cotizacion__summary-value">
                  <CurrencyFormat currentCurrency={result?.moneda} value={result?.seguroIncapacidad} />
                </div>
              </div>
              <div className="resultado-cotizacion__summary-row">
                <div className="resultado-cotizacion__summary-label">
                  Cuota seguro desempleo:
                </div>
                <div className="resultado-cotizacion__summary-value">
                  <CurrencyFormat currentCurrency={result?.moneda} value={result?.seguroDesempleo} />
                </div>
              </div>
            </div>
            <div className="resultado-cotizacion__summary-footer">
              <div className="resultado-cotizacion__quote-label">
                Cuota mensual aproximada:
              </div>
              <div className="resultado-cotizacion__quote-value">
                <CurrencyFormat currentCurrency={result?.moneda} value={result?.cuotaTotal} />
              </div>
            </div>
          </div>
        </div>
        <div className="resultado-cotizacion__aside">
          <div className="resultado-cotizacion__result-badge">
            <ResultBadge result={resultBadge} />
          </div>
          <div className="resultado-cotizacion__letter">
            <p className="resultado-cotizacion__letter-text">
              Una carta detallada será enviada a tu correo electrónico. Si lo
              deseás, podés descargar la misma aquí.
            </p>
            <div className="resultado-cotizacion__letter-cta">
              <button
                onClick={() => {
                  if (downloading) return;
                  setDownloading(true);
                  downloadLetter(result);
                }}
                className="resultado-cotizacion__letter-link"
              >
                <img
                  src={Download}
                  alt="Download"
                  className="resultado-cotizacion__letter-icon"
                />{' '}
                {downloading ? 'Descargando' : 'Descargar PDF'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <EmptyComponent
        isLoading={paquetesObject.isLoading}
        data={paquetesObject.data}
        message="No tenemos paquetes para mostrar"
        dontShow={true}
      >
        <div className="resultado-cotizacion__adicionales">
          <h2 className="resultado-cotizacion__title">Adicionales</h2>
          <p className="resultado-cotizacion__text">
            Agregá un paquete a tu cuota mensual, y ¡recibí beneficios para tu
            vehículo!
          </p>

          <div className="resultado-cotizacion__adicionales-content">
            {paquetesObject.data.map(paquete => {
              const isAlquiler = (paquete.nombre || '')
                .toLowerCase()
                .includes('alquiler');
              return (
                <button
                  className={classNames('resultado-cotizacion__adicional', {
                    'resultado-cotizacion__adicional--gps':
                      paquete.nombre.toLocaleLowerCase().indexOf('gps') >= 0,
                  })}
                  onClick={() => {
                    clickAdicional(!paquetes.find(x => x.id === paquete.id), paquete, isAlquiler);
                  }}
                  key={paquete.id}
                >
                  <div className="resultado-cotizacion__adicional-header">
                    <img
                      className="resultado-cotizacion__adicional-image"
                      src={paquete.imagen}
                      alt="Marchamos"
                    />
                    <div className="resultado-cotizacion__adicional-checkbox">
                      <Checkbox
                        gold
                        checked={!!paquetes.find(x => x.id === paquete.id)}
                        setChecked={checked => {
                          clickAdicional(checked, paquete, isAlquiler);
                        }}
                      />
                    </div>
                  </div>
                  <div className={cx('resultado-cotizacion__adicional-content', {
                    'resultado-cotizacion__adicional-content--gps': !isAlquiler,
                  })}>
                    <div className="resultado-cotizacion__adicional-title">
                      {paquete.nombre}
                    </div>
                    <div className="resultado-cotizacion__adicional-text" dangerouslySetInnerHTML={{ __html: paquete.disclaimer }} />
                    <div className="resultado-cotizacion__adicional-price">
                      {isAlquiler ? (
                        <>
                          <p>Desde <CurrencyFormat currentCurrency={result?.moneda} value={paquete.carroMonto1} /></p>
                        </>
                      ) : (
                        <>
                          <p className='monto-fijo'>Monto fijo de <CurrencyFormat currentCurrency={result?.moneda} value={paquete.carroMonto1} /></p>
                          <a
                            href="/wp-content/uploads/docs/TÉRMINOS DE ACEPTACIÓN - DE GPS.pdf"
                            target="_blank"
                            rel="noreferrer"
                            className="resultado-cotizacion__alquiler-disclaimer-link"
                          >
                            Ver condiciones de uso
                          </a>
                        </>
                      )}

                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </EmptyComponent>
      {showAlquiler && (
        <div className="resultado-cotizacion__alquiler">
          <button onClick={() => setShowAlquiler(false)}>
            <img src={Close} alt="Close" />
          </button>
          <h2 className="resultado-cotizacion__title">
            Alquiler auto sustituto / vacacional
          </h2>
          <p className="resultado-cotizacion__alquiler-intro">
            <b>Vehículos disponibles</b>
            <div>¡Seleccioná el ideal para vos!</div>
          </p>
          <div className="resultado-cotizacion__alquiler-list">
            {rentVehicles?.map(v => (
              <div className="resultado-cotizacion__alquiler-carro" key={v.id}>
                <Vehicle
                  vehicle={v}
                  vehiclePicked={vehicle}
                  setVehiclePicked={setVehicle}
                />
              </div>
            ))}
          </div>
          <div className="resultado-cotizacion__alquiler-disclaimer">
            <a
              href="/wp-content/uploads/docs/TÉRMINOS DE ACEPTACIÓN - ALQUILER VEHÍCULO SUSTITUTO.pdf"
              target="_blank"
              rel="noreferrer"
              className="resultado-cotizacion__alquiler-disclaimer-link"
            >
              Ver condiciones de uso
            </a>
          </div>
        </div>
      )}
      <div className="resultado-cotizacion__footer">
        {/*<div className="resultado-cotizacion__footer-left">
          <Checkbox2
            checked={accept}
            setChecked={status => setAccept(status)}
          />{' '}
          <a
            className="resultado-cotizacion__footer-left-link"
            href="/wp-content/uploads/docs/Términos y condiciones AGE Capital.pdf"
            target="_blank"
          >
            He leído los términos y condiciones
          </a>
        </div>*/}
        <div className="resultado-cotizacion__footer-right">
          <div className="resultado-cotizacion__footer-button">
            <Button
              black
              className="resultado-cotizacion__footer-back"
              prefix={
                <img
                  className="resultado-cotizacion__arrow--left"
                  src={Arrow}
                  alt="Arrow"
                />
              }
              value="Atrás"
              onClick={goBack}
              fullwidth
            />
          </div>
          <div className="resultado-cotizacion__footer-button">
            <Button
              className="resultado-cotizacion__footer-next"
              suffix={
                <img
                  className="resultado-cotizacion__arrow"
                  src={Arrow}
                  alt="Arrow"
                />
              }
              type="button"
              onClick={() => {
                if (hasError()) {
                  return;
                }
                savePaquetes();
                continueProcess();
                setShowAlternativas(false);
              }}
              value="Continuar proceso"
              fullwidth
            />
          </div>
        </div>
      </div>
      <div className="resultado-cotizacion__alternativas">
        <div className="resultado-cotizacion__trigger">
          <button
            type="button"
            onClick={() => setShowAlternativas(!showAlternativas)}
            className="resultado-cotizacion__button"
          >
            ¿No era lo que esperabas?
          </button>
        </div>
        {showAlternativas && (
          <div className="resultado-cotizacion__alternativas-content">
            <div className="resultado-cotizacion__alternativas-columns">
              <div className="resultado-cotizacion__alternativas-left">
                <RadioButtons
                  name="reason"
                  label="¿Por qué la opción anterior no era lo que esperabas?"
                  showOther
                  onChange={(value, isOther) => {
                    setReason(value);
                    setIsOther(isOther);
                  }}
                  value={reason}
                  options={[
                    {
                      label: 'Cuota financiera o cuota de seguro',
                      value: 'Cuota financiera o cuota de seguro',
                    },
                    {
                      label: 'Monto de prima',
                      value: 'Monto de prima',
                    },
                    {
                      label: 'Tasa de interés',
                      value: 'Tasa de interés',
                    },
                    {
                      label: 'Servicio al cliente',
                      value: 'Servicio al cliente',
                    },
                    {
                      label: 'Plazo',
                      value: 'Plazo',
                    },
                    {
                      label: 'Tiempo de respuesta en la aprobación',
                      value: 'Tiempo de respuesta en la aprobación',
                    },
                    {
                      label: 'Documentación solicitada',
                      value: 'Documentación solicitada',
                    },
                    {
                      label: 'Selección de otro Banco',
                      value: 'Selección de otro Banco',
                    },
                    {
                      label: 'Precio del vehículo',
                      value: 'Precio del vehículo',
                    },
                  ]}
                />
              </div>
              <div className="resultado-cotizacion__alternativas-right">
                <Button
                  value="Enviar respuesta"
                  suffix={<img src={Arrow} alt="Arrow" />}
                  onClick={() => {
                    sendAnswer(reason, isOther, result?.id);
                    setShowAlternativas(false);
                    reset();
                  }}
                />
              </div>
            </div>
            <div className="resultado-cotizacion__alternativas-legend">
              También podés contactarnos a nuestro Whatsapp de servicio al
              cliente de AGE Capital.{' '}
              <a
                target="_blank"
                className="resultado-cotizacion__alternativas-link"
                href={WHATSAPP_LINK}
                rel="noreferrer"
              >
                Hacé click aquí.
              </a>{' '}
              <img src={Whatsapp} alt="Whatsapp" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultadoCotizacion;
