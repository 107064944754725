/**
 * @module Reducers/Root
 * @desc Root Reducers
 */

import user from './user/reducers';
import app from './app/reducers';
import faq from './faq/reducers';
import blog from './blog/reducers';

const reducers = {
  ...user,
  ...app,
  ...faq,
  ...blog,
};

export default reducers;
