import React, { useState } from 'react';
import ProfileSection from '../ProfileSection';
import './MisDatos.scss';
import AgregarDatosIcon from '../../assets/images/agregar_datos_icon.svg';
import { useForm } from 'react-hook-form';
// import Select from '../Select';
// import { ID_TYPES, TIPOS_APLICANTE } from '../Cotizacion/Cotizacion';
import Input from '../Input';
import ProfileHeader from '../ProfileHeader/ProfileHeader';
import Button from '../Button';
import SaveIcon from '../../assets/images/save.svg';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { saveExtraData } from '../../ducks/actions';

const MisDatos = () => {
  const dispatch = useDispatch();
  const { currency } = useSelector(state => ({
    currency: state.user.data,
  }));

  const {
    register,
    handleSubmit,
    reset,
    // watch,
    // control,
    formState: { errors },
    // setValue,
  } = useForm();

  const onSubmit = data => {
    const info = {
      ...data,
    }
    if (!info.email) {
      info.email = currency.email2;
    }

    if (!info.phone) {
      info.phone = currency.phone2;
    }
    dispatch(saveExtraData(info));
    setSaved(true);
    reset();
    setTimeout(() => {
      setSaved(false);
    }, 4000);
  };

  const [showAdd, setShowAdd] = useState(false);
  const [saved, setSaved] = useState(false);

  return (
    <div className="mis-datos">
      <ProfileSection
        title="Mis datos"
        asset={
          <button
            onClick={() => setShowAdd(!showAdd)}
            className="mis-datos__agregar"
          >
            Opción de agregar datos{' '}
            <img
              src={AgregarDatosIcon}
              alt="Icon"
              className="mis-datos__agregar-icon"
            />
          </button>
        }
      >
        <div className="mis-datos__content">
          {/* <form className="mis-datos__content" onSubmit={handleSubmit(onSubmit)}> */}
          <div className="mis-datos__group mis-datos__group--dark">
            <div className="mis-datos__row">
              <div className="mis-datos__column">
                <Input
                  label="Tipo de identificación"
                  value={currency.dni_type}
                  // {...register('tipoIdentificacion')}
                  //options={ID_TYPES}
                  disabled
                />
              </div>
              <div className="mis-datos__column">
                <Input
                  label="Número de identificación"
                  placeholder="e.j 112344567"
                  value={currency.dni}
                  // {...register('numeroIdentificacion')}
                  disabled
                />
              </div>
            </div>
            <div className="mis-datos__row">
              <div className="mis-datos__column">
                <Input
                  value={`${currency.name} ${currency.lastname}`}
                  label="Nombre y apellidos"
                  // {...register('nombre')}
                  disabled
                />
              </div>
              <div className="mis-datos__column">
                <Input
                  label="Tipo de aplicante"
                  placeholder="correo@mail.com"
                  value={currency.clientType}
                  // {...register('email')}
                  disabled
                />
              </div>
              {/* <div className="mis-datos__column">
                <Select
                  label="Tipo de aplicante"
                  // {...register('tipo')}
                  options={TIPOS_APLICANTE}
                  disabled
                />
              </div> */}
            </div>
            <div className="mis-datos__row">
              <div className="mis-datos__column">
                <Input
                  label="Correo electrónico*"
                  placeholder="correo@mail.com"
                  value={currency.email}
                  disabled
                />
              </div>
              <div className="mis-datos__column">
                <Input
                  value={currency.phone}
                  label="Número de teléfono"
                  placeholder="ej. 83899019"
                  maxLength={8}
                  disabled
                />
              </div>
              {/* <div className="mis-datos__column"></div> */}
            </div>
            <div className="mis-datos__row">
              {currency.email2 && <div className="mis-datos__column">
                <Input
                  label={
                    <>
                      Correo electrónico
                      <span className="super-disclaimer">(opción 2)</span>
                    </>
                  }
                  placeholder="correo@mail.com"
                  value={currency.email2}
                  disabled
                />
              </div>}
              {currency.phone2 && <div className="mis-datos__column">
                <Input
                  maxLength={8}
                  value={currency.phone2}
                  label={
                    <>
                      Número de teléfono
                      <span className="super-disclaimer">(opción 2)</span>
                    </>
                  }
                  placeholder="ej. 83899019"
                  disabled
                />
              </div>}
              {!currency.email2 && <div className="mis-datos__column" />}
              {/* <div className="mis-datos__column"></div> */}
            </div>
          </div>
          {showAdd && (
            <>
              <ProfileHeader title="Podés agregar estos datos" />
              <div className="mis-datos__group">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mis-datos__row mis-datos__row--edit">
                    <div className="mis-datos__column">
                      <Input
                        type="text"
                        label={
                          <>
                            Correo electrónico{' '}
                            <span className="super-disclaimer">(opción 2)</span>
                          </>
                        }
                        placeholder="correo@mail.com"
                        {...register('email', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Ingrese un email válido',
                          },
                        })}
                        error={errors?.email?.message}
                        hasError={errors?.email?.message}
                      />
                    </div>
                    <div className="mis-datos__column">
                      <Input
                        maxLength={8}
                        type="text"
                        label={
                          <>
                            Télefono{' '}
                            <span className="super-disclaimer">(opción 2)</span>
                          </>
                        }
                        placeholder="Digite"
                        {...register('phone', {
                          validate: (value) => {
                            if (!value) {
                              return true;
                            }
                            if (value?.length < 8) {
                              return "Ingrese un número de 8 dígitos";
                            }
                            const matches = value.match(
                              /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
                            );
                            return matches?.length > 0 || "Ingrese un número de 8 dígitos";
                          }
                        })}
                        error={errors?.phone?.message}
                        hasError={errors?.phone?.message}
                      />
                    </div>
                  </div>
                  <div className="mis-datos__submit-container">
                    <p>
                      Contacto principal de cuenta, toda información importante se envía a este correo con copia al correo opcional, en caso de necesitar cambiar su correo principal por favor contáctanos al <a className='gold' href="tel:4000-8000">4000-8000</a>
                    </p>
                    <Button
                      type="submit"
                      black
                      prefix={<img src={SaveIcon} alt="Icon" />}
                      value={saved ? 'Guardado' : 'Guardar'}
                    />
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
        {/* </form> */}
      </ProfileSection>
    </div>
  );
};

export default MisDatos;
