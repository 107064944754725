import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '../Button';
import Arrow from '../../assets/images/arrow-left.png';
import Dialog from '../Dialog';
import Close from '../../assets/images/close.png';
import './CompletarRegistro.scss';
import Input from '../Input';
import Select from '../Select';
import { TIPOS_DNI } from '../Requirements/data';
import { useDispatch } from 'react-redux';
import { fetchCedula, registerAgeHub, resetAgeHubRegister } from '../../ducks/actions';
import { useSelector } from 'react-redux';
import { getRegisterMessage } from '../../utils/helpers';
import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import { registerUserRequest } from '../../ducks/actions';

const CompletarRegistro = ({ open, openLogin, onClose }) => {
  const dispatch = useDispatch();
  const { registering, users3 } = useSelector(state => ({
    registering: state.user.registering,
    users3: state.app.users3.data,
  }));

  const {
    register,
    handleSubmit,
    watch,
    unregister,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    dispatch(registerAgeHub({
      ...data,
      capitalUrl: window.location.origin
    }));
  };

  const getInfoPerson = (e) => dispatch(fetchCedula(e, false, true));

  const message = getRegisterMessage(registering?.data);

  const noJuridico = watch('type') !== 'Juridico';

  useEffect(() => {
    if (noJuridico) {
      register('lastname', { required: true });
    } else {
      unregister('lastname');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noJuridico]);

  useEffect(() => {
    if (!users3 || users3?.length <= 0) {
      return;
    }

    setValue('name', users3[0]?.firstname ?? users3[0]?.fullname);
    setValue('lastname', users3[0]?.lastname);
  }, [users3, setValue]);

  return (
    <Dialog open={open} medium scroll>
      <div className="completar-registro">
        <button onClick={() => {
            dispatch(resetAgeHubRegister());
            onClose();
          }} 
          className="completar-registro__close"
        >
          <img src={Close} alt="Close" />
        </button>
        {!message ? (
          <>
            <h1 className="completar-registro__title">Registrá tu usuario</h1>
            <p className="completar-registro__text">
              ¡Solo te tomará unos minutos!
            </p>
            {/* <p className="completar-registro__name">Santiago Bernabeu</p>
            <p className="completar-registro__id">123456789</p> */}
            <form
              className="completar-registro__form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="completar-registro__input-container">
                <Select
                  className="completar-registro__input-new"
                  placeholder="Tipo de identificación"
                  options={TIPOS_DNI}
                  {...register('type', { required: 'Requerido'})}
                  hasError={!!errors.type}
                />
              </div>
              <div className="completar-registro__input-container">
                <Input
                  className="completar-registro__input-new"
                  placeholder="Número de identificación"
                  {...register('cedula', { required: 'Requerido'})}
                  hasError={!!errors.cedula}
                  onChange={({target: { value }}) => {
                    console.log(value);
                    setValue('errors', value);
                    if(value.length >= 9) {
                      getInfoPerson(value);
                    }
                    
                    if (errors.numeroIdentificacion && !!value) {
                      clearErrors('errors');
                    }
                  }}
                />
              </div>
              <div className="completar-registro__input-container">
                <Input
                  className="completar-registro__input-new"
                  placeholder={noJuridico ? "Nombre" : "Nombre de la empresa"}
                  {...register('name', { required: 'Requerido'})}
                  hasError={!!errors.name}
                />
              </div>
              {noJuridico && (
                <div className="completar-registro__input-container">
                  <Input
                    className="completar-registro__input-new"
                    placeholder="Apellidos"
                    {...register('lastname', { required: true })}
                    hasError={!!errors.lastname}
                  />
                </div>
              )}
              <div className="completar-registro__input-container">
                <Input
                  className="completar-registro__input-new"
                  placeholder="Correo electrónico"
                  {...register('email', { 
                    required: 'Requerido',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Ingrese un email válido"
                    }
                  })}
                  hasError={!!errors.email}
                />
              </div>
              <div className="completar-registro__input-container">
                <Input
                  maxLength={8}
                  className="completar-registro__input-new"
                  placeholder="Número de teléfono."
                  {...register('phone', { 
                    required: 'Requerido',
                  })}
                  hasError={!!errors.phone}
                />
              </div>
              <Button
                className="completar-registro__submit"
                value={registering.isLoading? 'Registrando': "Crear cuenta"}
                disabled={registering.isLoading}
                suffix={<img src={Arrow} alt="Arrow" />}
                type="submit"
              />
            </form>
            <button className="completar-registro__open-login" onClick={openLogin}>Si ya tenés cuenta, iniciá sesión</button>
          </>
        ): (
          <>
            {message.image && <div className="completar-registro__image"><img src={message.image} alt="Registro" /></div>}
            <h1 className="completar-registro__title">{message.Titulo}</h1>
            <p className="completar-registro__text">
              {message.Contenido}
            </p>
            {message.Footer && (
              <p className="completar-registro__text completar-registro__text--footer" dangerouslySetInnerHTML={{ __html: message.Footer }} />
            )}
          </>
        )}
      </div>
    </Dialog>
  );
};

export default CompletarRegistro;
