import React from 'react';
import './PersonalDataIcon.scss';

const PersonalDataIcon = ({ active }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Grupo_19318" data-name="Grupo 19318" transform="translate(-220 -471)">
      <g id="Elipse_215" data-name="Elipse 215" transform="translate(220 471)" fill="#fff" stroke="#bb9947" strokeWidth="1">
        <circle cx="20" cy="20" r="20" stroke="none"/>
        <circle cx="20" cy="20" r="19.5" fill="none"/>
      </g>
      <circle id="Elipse_214" data-name="Elipse 214" cx="16" cy="16" r="16" transform="translate(224 475)" fill="#bb9947"/>
      <path id="Icon_awesome-user-edit" data-name="Icon awesome-user-edit" d="M6.955,7.949A3.975,3.975,0,1,0,2.981,3.975,3.974,3.974,0,0,0,6.955,7.949Zm2.782.994H9.219a5.405,5.405,0,0,1-4.527,0H4.173A4.174,4.174,0,0,0,0,13.116v1.292A1.491,1.491,0,0,0,1.49,15.9h8.536a1.491,1.491,0,0,1-.081-.661l.211-1.891L10.194,13l.245-.245,2.4-2.4a4.131,4.131,0,0,0-3.1-1.413Zm2.407,4.512-.211,1.894a.494.494,0,0,0,.547.547l1.891-.211L18.653,11.4,16.426,9.176l-4.282,4.279Zm8.511-5.1L19.479,7.173a.744.744,0,0,0-1.05,0L17.255,8.347l-.127.127L19.358,10.7l1.3-1.3a.748.748,0,0,0,0-1.053Z" transform="translate(232.641 483.051)" fill="none" stroke="#fff" strokeWidth="1"/>
    </g>
  </svg>
);

export default PersonalDataIcon;

