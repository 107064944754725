import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBlogs, fetchBlogPost, fetchCategories, fetchCategoryBlog, fetchHomePageBlogsRequest, fetchSearch } from "./actions";

const MONTHS = [
    'Ene',
    'Feb',
    'Mar',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Oct',
    'Nov',
    'Dic'
];

export const useHomepagePosts = () => {
  const dispatch = useDispatch();
  
  const { homepage } = useSelector(state => ({
    homepage: state.blog.homepage,
  }));
  
  useEffect(() => {
    if (!homepage.data?.length && !homepage.isLoading && !homepage.isReady) {
      dispatch(fetchHomePageBlogsRequest());
    }
  }, [dispatch, homepage]);
  
  return [homepage.data, homepage.isLoading];
};

export const useAllBlogs = () => {
  const dispatch = useDispatch();
  
  const { allPosts } = useSelector(state => ({
    allPosts: state.blog.allPosts,
  }));
  
  useEffect(() => {
    if (!allPosts.data?.length && !allPosts.isLoading && !allPosts.isReady) {
      dispatch(fetchAllBlogs());
    }
  }, [dispatch, allPosts]);
  
  return [allPosts.data, allPosts.isLoading];
};

export const useBlogCategories = () => {
  const dispatch = useDispatch();

  const { categories } = useSelector(state => ({
    categories: state.blog.categories,
  }));
  
  useEffect(() => {
    if (!categories.data?.length && !categories.isLoading && !categories.isReady) {
      dispatch(fetchCategories());
    }
  }, [dispatch, categories]);
  
  return categories;
};

export const useSinglePost = (id) => {
  const dispatch = useDispatch();
  
  const { posts } = useSelector(state => ({
    posts: state.blog.posts,
  }));

  const data = posts?.data[id];
  
  useEffect(() => {
    if (id && !posts.isLoading && !data) {
      dispatch(fetchBlogPost(id));
    }
  }, [dispatch, id, posts, data]);
  
  return [data, posts.isLoading];
};

export const useSingleBlogCategoriy = (slug) => {
  const categories = useBlogCategories();
  return useMemo(() => {
    return categories?.data?.find(x => x.slug === slug);
  }, [categories.data, slug]);
};

export const useBlogSearch = (message) => {
  const dispatch = useDispatch();
  
  const { search } = useSelector(state => ({
    search: state.blog.search,
  }));

  const data = search?.data[message];
  
  useEffect(() => {
    if (message && !search.isLoading && !data) {
      dispatch(fetchSearch(message));
    }
  }, [dispatch, message, search, data]);
  
  return [data, search.isLoading];
};

export const useCategoryPosts = (slug) => {
  const dispatch = useDispatch();
  
  const { categoryPosts } = useSelector(state => ({
    categoryPosts: state.blog.categoryPosts,
  }));

  const data = categoryPosts?.data[slug];
  
  useEffect(() => {
    if (slug && !categoryPosts.isLoading && !data) {
      dispatch(fetchCategoryBlog(slug));
    }
  }, [dispatch, slug, categoryPosts, data]);
  
  return [data, categoryPosts.isLoading];
};

export const useBlogDate = (blog) => {
    return useMemo(() => {
      if (!blog) {
        return null;
      }
      const date = new Date(blog?.date_gmt);
      if (!date) {
        return null
      }
      return `${date.getDate()} ${MONTHS[date.getMonth()]}, ${date.getFullYear()}`;
    }, [blog]);
};

const getPostUrl = (blogId, acfEmbedded) => {
  // Means we are seing microcrapsula
  if (acfEmbedded?.microcapsula) {
    return `/blog/categoria/capsulas-financieras/${blogId}`;
  }
  return `/blog/post/${blogId}`;
}

export const useSearchBlogData = (blog) => {
  return useMemo(() => {
    let date = null;
    let featuredImage = null;
    let excerpt = null;
    let categories = [];
    let url = null;
    const findFirstOfType = (type) => {
      return blog?._embedded?.self?.reduce((acc, curr) => {
        if (curr.hasOwnProperty(type)) {
          return curr[type];
        }
        if (!acc) { 
          return curr.date;
        }
        return acc;
      }, null);
    };

    if (blog) {
      // Processing date
      const dateString = blog?.date_gmt || findFirstOfType('date');

      const dateObj = new Date(dateString);
      if (dateObj) {
        date = `${dateObj.getDate()} ${MONTHS[dateObj.getMonth()]}, ${dateObj.getFullYear()}`;
      }

      // Processing Featured Image
      excerpt = findFirstOfType('excerpt')?.rendered;
      url = getPostUrl(blog.id, findFirstOfType('acf'));
    }
    return {
      ...blog,
      date, 
      featuredImage, 
      excerpt,
      categories,
      url,
    }
    
  }, [blog]);
}

export const useBlogData = (blog) => {
  return useMemo(() => {
    let date = null;
    let featuredImage = null;
    let excerpt = null;
    let categories = [];
    let url = null;
    if (blog) {

      // Processing date
      const dateObj = new Date(blog?.date_gmt);
      if (dateObj) {
        date = `${dateObj.getDate()} ${MONTHS[dateObj.getMonth()]}, ${dateObj.getFullYear()}`;
      }

      // Processing Featured Image
      featuredImage = blog?._embedded?.['wp:featuredmedia']?.find(x => x.source_url);
      excerpt = blog?.excerpt?.rendered;
      
      categories = blog?._embedded?.['wp:term']?.reduce((acc, curr) => {
        return curr.reduce((acc2, curr2) => {
          if (curr2?.taxonomy === 'category') {
            return [
              ...acc2,
              {
                name: curr2?.name,
                slug: curr2?.slug,
                id: curr2?.id,
              }
            ];
          }
          return acc2;
        }, acc);
      }, []);

      url = getPostUrl(blog.id, blog.acf);
    }
    
    return {
      ...blog,
      date, 
      featuredImage, 
      excerpt,
      categories,
      url
    }
    
  }, [blog]);
}