import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '../Button';
import Arrow from '../../assets/images/arrow-left.png';
import Dialog from '../Dialog';
import Close from '../../assets/images/close.png';
import './RecuperarPass.scss';
import Input from '../Input';
import Select from '../Select';
import { TIPOS_DNI } from '../Requirements/data';
import { useDispatch } from 'react-redux';
import { fetchCedula, recoverPassword, resetRecoverForm} from '../../ducks/actions';
import { useSelector } from 'react-redux';
import { getRecoverMessage} from '../../utils/helpers';
import { useEffect } from 'react';
// import { registerUserRequest } from '../../ducks/actions';

const RecuperarPass = ({ open, openLogin, onClose }) => {
  const dispatch = useDispatch();
  const { recovering, users3 } = useSelector(state => ({
    recovering: state.user.recovering,
    users3: state.app.users3.data,
  }));

  const {
    register,
    handleSubmit,
    watch,
    unregister,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    dispatch(recoverPassword({
      ...data,
      capitalUrl: window.location.origin
    }));
  };

  const getInfoPerson = (e) => dispatch(fetchCedula(e, false, true));

  const message = getRecoverMessage(recovering?.data);

  const noJuridico = watch('type') !== 'Juridico';

  return (
    <Dialog open={open} medium scroll>
      <div className="completar-registro">
        <button onClick={() => {
            dispatch(resetRecoverForm());
            onClose();
          }} 
          className="completar-registro__close"
        >
          <img src={Close} alt="Close" />
        </button>
        {!message ? (
          <>
            <h1 className="completar-registro__title">¿Problemas iniciando sesión?</h1>
            <p className="completar-registro__text">
              Ingresa el número de cédula asociado a tu cuenta:
            </p>
            {/* <p className="completar-registro__name">Santiago Bernabeu</p>
            <p className="completar-registro__id">123456789</p> */}
            <form
              className="completar-registro__form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="completar-registro__input-container">
                <Select
                  className="completar-registro__input-new"
                  placeholder="Tipo de identificación"
                  options={TIPOS_DNI}
                  {...register('type', { required: 'Requerido'})}
                  hasError={!!errors.type}
                  disabled={recovering.isLoading}
                />
              </div>
              <div className="completar-registro__input-container">
                <Input
                  className="completar-registro__input-new"
                  placeholder="Número de identificación"
                  {...register('cedula', { required: 'Requerido'})}
                  hasError={!!errors.cedula}
                  onChange={({target: { value }}) => {
                    setValue('errors', value);
                    if(value.length >= 9) {
                      getInfoPerson(value);
                    }
                    
                    if (errors.numeroIdentificacion && !!value) {
                      clearErrors('errors');
                    }
                  }}
                  disabled={recovering.isLoading}
                />
              </div>
              {recovering.loaded && (
                <h2>Hemos enviado los datos para recuperar tu cuenta a tu correo</h2>
              )}
              {!recovering.loaded && (
                <Button
                className="completar-registro__submit"
                value={recovering.isLoading? 'Recuperando': recovering.loaded? "Se enviaron los datos" : "Enviar datos por correo"}
                disabled={recovering.isLoading || recovering.loaded}
                suffix={<img src={Arrow} alt="Arrow" />}
                type="submit"
                />
              )}
              
            </form>
          </>
        ): (
          <>
            {message.image && <div className="completar-registro__image"><img src={message.image} alt="Registro" /></div>}
            <h1 className="completar-registro__title">{message.Titulo}</h1>
            <p className="completar-registro__text">
              {message.Contenido}
            </p>
            {message.Footer && (
              <p className="completar-registro__text completar-registro__text--footer" dangerouslySetInnerHTML={{ __html: message.Footer }} />
            )}
          </>
        )}
      </div>
    </Dialog>
  );
};

export default RecuperarPass;
