import React from 'react';
import Dialog from '../Dialog';
import './SuccessDialog.scss';
import Arrow from '../../assets/images/arrow-left.png';
import Button from '../Button';

const SuccessDialog = ({ message, open, onEnter }) => (
  <Dialog open={open}>
    <div className="success-dialog">
      <p className="success-dialog__message">{message}</p>
      <Button
        className="success-dialog__button"
        value="Ingresar"
        suffix={<img src={Arrow} alt="Arrow" />}
        type="button"
        onClick={onEnter}
      />
    </div>
  </Dialog>
);

export default SuccessDialog;
