// @flow
/**
 * @module Epics/blog
 * @desc user
 */
 import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BaseApiService } from '../../utils/BaseApiService';
import { fetchAllBlogsFailure, fetchAllBlogsSuccess, fetchBlogPostFailure, fetchBlogPostSuccess, fetchCapsulasFailure, fetchCapsulasSuccess, fetchCategoriesFailure, fetchCategoriesSuccess, fetchCategoryBlogFailure, fetchCategoryBlogSuccess, fetchHomePageBlogsFailure, fetchHomePageBlogsSuccess, fetchSearchFailure, fetchSearchSuccess } from './actions';
import ActionTypes from './actionTypes';
 
export function fetchHomePageBlogsRequest(action$) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_HOMEPAGE_POSTS),
    mergeMap(() => {
      return BaseApiService.blogGet('wp-json/wp/v2/posts?per_page=4&orderby=date&_embed=1&category_slug=capsulas-financieras').pipe(
        map(({ response }) => response),
        map(response => fetchHomePageBlogsSuccess(response)),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(fetchHomePageBlogsFailure(), ...actions)
        )
      );
    })
  );
}

export function fetchAllBlogs(action$) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_ALL_BLOG),
    mergeMap(() => {
      return BaseApiService.blogGet('wp-json/wp/v2/posts?orderby=date&_embed=1').pipe(
        map(({ response }) => response),
        map(response => fetchAllBlogsSuccess(response)),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(fetchAllBlogsFailure(), ...actions)
        )
      );
    })
  );
}

export function fetchCategoryBlog(action$) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_CATEGORIES_BY_SLUG),
    mergeMap((action) => {
      return BaseApiService.blogGet(`wp-json/wp/v2/posts?orderby=date&_embed=1&category_slug=${action.payload.slug}`).pipe(
        map(({ response }) => response),
        map(response => fetchCategoryBlogSuccess(response, action.payload.slug)),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(fetchCategoryBlogFailure(), ...actions)
        )
      );
    })
  );
}

export function fetchBlogPost(action$) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_BLOG_POST),
    mergeMap((action) => {
      return BaseApiService.blogGet(`wp-json/wp/v2/posts?&_embed=1&per_page=1&include[]=${action.payload.id}`).pipe(
        map(({ response }) => response),
        map(response => fetchBlogPostSuccess(response)),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(fetchBlogPostFailure(), ...actions)
        )
      );
    })
  );
}

export function fetchCapsulas(action$) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_CAPSULAS),
    mergeMap(() => {
      return BaseApiService.blogGet('wp-json/wp/v2/posts?orderby=date&_embed=1&category_slug=capsulas-financieras').pipe(
        map(({ response }) => response),
        map(response => fetchCapsulasSuccess(response)),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(fetchCapsulasFailure(), ...actions)
        )
      );
    })
  );
}

export function fetchBlogCategories(action$) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_CATEGORIES),
    mergeMap(() => {
      return BaseApiService.blogGet('wp-json/wp/v2/categories?_embed=1').pipe(
        map(({ response }) => response),
        map(response => fetchCategoriesSuccess(response)),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(fetchCategoriesFailure(), ...actions)
        )
      );
    })
  );
}

export function fetchSearch(action$) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_SEARCH),
    mergeMap((action) => {
      return BaseApiService.blogGet(`wp-json/wp/v2/search?_embed=1&search=${action.payload.search}`).pipe(
        map(({ response }) => response),
        map(response => fetchSearchSuccess(response, action.payload.search)),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(fetchSearchFailure(), ...actions)
        )
      );
    })
  );
}
 