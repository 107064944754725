// @flow
/**
 * @module Epics/user
 * @desc user
 */
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import ActionTypes from './actionTypes';
import { BaseApiService } from '../../utils/BaseApiService';
import { fetchFaqSuccess, fetchFaqFailure } from './actions';

export function fetchFaq(action$) {
  return action$.pipe(
    ofType(ActionTypes.FETCH_FAQ_REQUEST),
    mergeMap(() => {
      return BaseApiService.get('/content/preguntas/ ').pipe(
        map(({ response }) => response),
        map(response => fetchFaqSuccess(response)),
        // catchError(BaseApiService.getErrorHandler('userEpic', 'signIn')),
        catchError(
          /* istanbul ignore next  */ ({ actions = [] }) =>
            of(fetchFaqFailure(), ...actions)
        )
      );
    })
  );
}
