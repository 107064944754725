import React from 'react';
import QuestionMark from '../../assets/images/question_mark.svg';
import MainLogoDesktop from '../../assets/images/V2_logos_footer_desktop_2x.png';
import MainLogoMobile from '../../assets/images/V2_logos_footer_Mobile_2x.png';
import './Footer.scss';

import { Link } from 'react-router-dom';

const Footer = () => (
  <div className="footer">
    <div className="footer__faq-bar">
      <div className="footer__faq-content">
        <a
          // href="/wp-content/uploads/docs/Términos y condiciones AGE Capital.pdf"
          href="/wp-content/uploads/docs/Terminos y Condiciones AGE Capital Generales.pdf"
          target="_blank"
          className="footer__faq-content--terms"
        >
          <span className="footer__faq-text">Términos y condiciones</span>
        </a>
        <Link className="footer__faq" to="/preguntas-frecuentes">
          <img src={QuestionMark} alt="Question mark" />{' '}
          <span className="footer__faq-text">Preguntas frecuentes</span>
        </Link>
      </div>
      <hr className="footer__faq-hr" />
    </div>
    <div>
      <div className="footer__logos desktop">
        <img src={MainLogoDesktop} alt="" title="" className="footer__image" />
      </div>
      <div className="footer__logos mobile">
        <img src={MainLogoMobile} alt="Age Holding" title="Age Holding" className="footer__image" />
      </div>
      <p className="footer__terms">Todos los derechos reservados. 2022.</p>
    </div>
  </div>
);

export default Footer;
