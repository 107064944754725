import classNames from 'classnames';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useBlogCategories } from '../../ducks/blog/hooks';
import './BlogSidebar.scss';

const BlogSidebar = ({ children, isPost }) => {
  const categories = useBlogCategories();
  return (
    <div className={classNames('blog-with-sidebar', {
      'blog-with-sidebar--in-post': !!isPost
    })}>
      <div className="blog-with-sidebar__content">
        {children}
      </div>
      <div className="blog-with-sidebar__sidebar">
        {categories?.isLoading ? (
          <>
          <Skeleton height={30}/>
          <Skeleton height={30}/>
          <Skeleton height={30}/>
          </>
        ): (
          <div className="blog-with-sidebar__sidebar-categories">
            {/* <h3><img src={Category} alt="category" />Categorias</h3>
            {categories?.data?.map(category => {
              return (
                <div key={category.id} className="blog-with-sidebar__sidebar-categories-item">
                  <NavLink
                    to={`/blog/categoria/${category.slug}`}
                    activeClassName="blog-with-sidebar__sidebar-categories-item--active">{category.name}</NavLink>
                </div>
              );
            })} */}
          </div>
          
        )}
      </div>
    </div>
  );
};

export default BlogSidebar;