// @flow
/**
 * @module Actions/User
 * @desc Actions for User
 */
import ActionTypes from './actionTypes';

/**
 * Sign in request
 * @param {string} email
 * @param {string} password
 * @returns {Object}
 */
export function signInRequest(email, password, callback, login2) {
  return {
    type: ActionTypes.SIGN_IN_REQUEST,
    payload: { email, password, callback, login2 },
  };
}

/**
 * Sign in success
 *
 * @param {object} user
 * @returns {Object}
 */
export function signInSuccess(user, token) {
  return {
    type: ActionTypes.SIGN_IN_SUCCESS,
    payload: { user, token },
  };
}

/**
 * Sign in failure
 *
 * @returns {Object}
 */
export function signInFailure(error) {
  return {
    type: ActionTypes.SIGN_IN_FAILURE,
    payload: { error },
  };
}

/**
 * Sign in failure
 *
 * @returns {Object}
 */
export function signInReset() {
  return {
    type: ActionTypes.SIGN_IN_RESET,
  };
}

/**
 * Sign out request
 * @returns {Object}
 */
export function signOutSuccess() {
  return {
    type: ActionTypes.SIGN_OUT_SUCCESS,
  };
}

/**
 * Sign out request
 * @returns {Object}
 */
export function signOutRequest() {
  return {
    type: ActionTypes.SIGN_OUT_REQUEST,
  };
}

/**
 * Sign out failure
 * @returns {Object}
 */
export function signOutFailure() {
  return {
    type: ActionTypes.SIGN_OUT_FAILURE,
  };
}

/**
 * Check if use is logged in request
 *
 * @returns {Object}
 */
export function checkUserLoggedInRequest() {
  return {
    type: ActionTypes.CHECK_USER_LOGGED_IN_REQUEST,
  };
}

/**
 * Check if use is logged in success
 * @param {Object} user
 *
 * @returns {Object}
 */
export function checkUserLoggedInSuccess(user, token) {
  return {
    type: ActionTypes.CHECK_USER_LOGGED_IN_SUCCESS,
    payload: { user, token },
  };
}

/**
 * Check if use is logged in failure
 *
 * @returns {Object}
 */
export function checkUserLoggedInFailure() {
  return {
    type: ActionTypes.CHECK_USER_LOGGED_IN_FAILURE,
  };
}

/**
 * Fetch operations
 *
 * @returns {Object}
 */
export function fetchOperationsRequest() {
  return {
    type: ActionTypes.GET_OPERATIONS_REQUEST,
  };
}

/**
 * Fetch operations
 *
 * @returns {Object}
 */
export function fetchOperationsSuccess(data) {
  return {
    type: ActionTypes.GET_OPERATIONS_SUCCESS,
    payload: {
      data
    }
  };
}

/**
 * Fetch operations to pay
 *
 * @returns {Object}
 */
export function fetchOperationsToPayRequest() {
  return {
    type: ActionTypes.GET_OPERATIONS_TOPAY_REQUEST,
  };
}

/**
 * Fetch operations
 *
 * @returns {Object}
 */
export function fetchOperationsToPaySuccess(data) {
  return {
    type: ActionTypes.GET_OPERATIONS_TOPAY_SUCCESS,
    payload: {
      data
    }
  };
}

/**
 * Fetch operations
 *
 * @returns {Object}
 */
export function changePasswordRequest(values, callback, errorCallback) {
  return {
    type: ActionTypes.CHANGE_PASSWORD_REQUEST,
    payload: {
      values, callback, errorCallback
    }
  };
}

/**
 * Fetch operations
 *
 * @returns {Object}
 */
export function saveExtraData(data) {
  return {
    type: ActionTypes.SAVE_EXTRA_FIELDS,
    payload: { data }
  };
}

/**
 * Fetch operations
 *
 * @returns {Object}
 */
export function saveExtraDataSuccess() {
  return {
    type: ActionTypes.SAVE_EXTRA_FIELDS_SUCCESS,
  };
}

/**
 * Register Age User
 *
 * @returns {Object}
 */
export function registerAgeHub(data) {
  return {
    type: ActionTypes.REGISTER_AGE_USER,
    payload: { data },
  }
}

/**
 * Register Age User Success
 *
 * @returns {Object}
 */
export function registerAgeHubSuccess(data) {
  return {
    type: ActionTypes.REGISTER_AGE_USER_SUCCESS,
    payload: { data },
  }
}

/**
 * Reset Age User Success
 *
 * @returns {Object}
 */
export function resetAgeHubRegister() {
  return {
    type: ActionTypes.REGISTER_AGE_USER_RESET,
  }
}

/**
 * GET TOKEN
 *
 * @returns {Object}
 */
export function getUserByToken(token) {
  return {
    type: ActionTypes.GET_USER_BY_TOKEN,
    payload: { token }
  }
}

/**
 * GET TOKEN SUCCESS
 *
 * @returns {Object}
 */
export function getUserByTokenSuccess(data) {
  return {
    type: ActionTypes.GET_USER_BY_TOKEN_SUCCESS,
    payload: { data }
  }
}

/**
 * GET TOKEN Failure
 *
 * @returns {Object}
 */
export function getUserByTokenFailure(data) {
  return {
    type: ActionTypes.GET_USER_BY_TOKEN_FAILURE,
    payload: { data }
  }
}

/**
 * SET Password
 *
 * @returns {Object}
 */
export function setPassword(data, callback) {
  return {
    type: ActionTypes.SET_PASSWORD,
    payload: { data, callback }
  }
}

/**
 * SET Password Success
 *
 * @returns {Object}
 */
export function setPasswordSuccess(data) {
  return {
    type: ActionTypes.SET_PASSWORD_SUCCESS,
    payload: { data }
  }
}

/**
 * SET Password Failure
 *
 * @returns {Object}
 */
export function setPasswordFailure(data) {
  return {
    type: ActionTypes.SET_PASSWORD_FAILURE,
    payload: { data }
  }
}


/* Recover functions*/

export function recoverPassword(data) {
  return {
    type: ActionTypes.RECOVER_USER_PASS,
    payload: { data },
  }
}

export function recoverPasswordSuccess(data) {
  return {
    type: ActionTypes.RECOVER_USER_PASS_SUCCESS,
    payload: { data },
  }
}

export function resetRecoverForm() {
  return {
    type: ActionTypes.RECOVER_USER_PASS_RESET,
  }
}